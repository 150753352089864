import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ButtonCloseModal from '../ButtonCloseModal';
import './style.scss';

const LasPreview = ({splitLas, lasFile, closeWell}) => (
  <>
    <div className="wrapper-preview">
      <Scrollbars
        renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
        renderTrackVertical={props => <div {...props} className="track-vertical"/>}
        renderThumbVertical={props =>
          <div {...props} className="custom-scrollbar" />}
        renderThumbHorizontal={props =>
          <div {...props} className="custom-scrollbar" />}
      >
        {splitLas(lasFile).map(
          value => (
            <>
              <label>{value}</label>
              <br />
            </>
          )
        )}
      </Scrollbars>
    </div>
    <ButtonCloseModal
      closeWell={closeWell}
    />
  </>
);

export default LasPreview;