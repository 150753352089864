import '../../style.scss';
import './style.scss';

import React from 'react';

import {
  Button,
  Divider,
  Drawer,
  Input
} from 'antd';
import { useFormik } from 'formik';

import { errorNotification } from '../../../../utils';

const validateNumbers = (objectValue) => {
  let isValid = true;
  Object.keys(objectValue).forEach(key => {
    const value = objectValue[key];
    if (isNaN(value)) {
      isValid = false;
    }
  });
  return isValid;
};

const convertToNumbersAndFillEmptyValues = (values) => {
  const formattedValues = {};
  Object.keys(values).forEach(key => {
    const value = values[key];
    formattedValues[key] = parseFloat(value || 0);
  });
  return formattedValues;
};

const BaseLinePanel = ({
  onClose, visible, defaultBaselineValue, onSubmit
}) => {
  const validateBaseline = (values) => {
    if (!validateNumbers(values)) {
      errorNotification(`Invalid values, please type valid numbers.`);
      return;
    }
    const formattedValues = convertToNumbersAndFillEmptyValues(values);
    onSubmit(formattedValues);
    onClose();
  };

  const formik = useFormik({
    initialValues: defaultBaselineValue || {
      c1MolPercent: 0,
      c1TStdMolPercent: 0,
      c2MolPercent: 0,
      c2TStdMolPercent: 0,
      c3MolPercent: 0,
      c3TStdMolPercent: 0,
      c4MolPercent: 0,
      c4TStdMolPercent: 0,
      c5MolPercent: 0,
      c5TStdMolPercent: 0
    },
    enableReinitialize: true,
    onSubmit: validateBaseline
  });

  const { handleSubmit, handleChange, values } = formik;

  return(
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={385}
      className="selected-zones"
      getContainer={false}
    >
      <div className="selected-zones-title">Baseline</div>
      <Divider className="selected-zones-divider" />
      <form onSubmit={handleSubmit}>
        <h2 className="section-title">C1</h2>
        <div className="baseline-form">
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c1MolPercent"
                type="text"
                placeholder="10"
                value={values.c1MolPercent}
                className="input-settings-molar-chart"
              />
            </div>
          </div>
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">STD-%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c1TStdMolPercent"
                type="text"
                placeholder="10"
                value={values.c1TStdMolPercent}
                className="input-settings-molar-chart"
              />
            </div>
          </div>
        </div>

        <Divider className="selected-zones-divider" />
        <h2 className="section-title">C2</h2>
        <div className="baseline-form">
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c2MolPercent"
                type="text"
                placeholder="10"
                value={values.c2MolPercent}
                className="input-settings-molar-chart"
              />
            </div>
          </div>
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">STD-%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c2TStdMolPercent"
                type="text"
                placeholder="10"
                value={values.c2TStdMolPercent}
                className="input-settings-molar-chart"
              />
            </div>
          </div>
        </div>

        <Divider className="selected-zones-divider" />
        <h2 className="section-title">C3</h2>
        <div className="baseline-form">
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c3MolPercent"
                type="text"
                placeholder="10"
                value={values.c3MolPercent}
                className="input-settings-molar-chart"
              />
            </div>
          </div>
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">STD-%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c3TStdMolPercent"
                type="text"
                placeholder="10"
                value={values.c3TStdMolPercent}
                className="input-settings-molar-chart"
              />
            </div>
          </div>
        </div>

        <Divider className="selected-zones-divider" />
        <h2 className="section-title">C4</h2>
        <div className="baseline-form">
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c4MolPercent"
                type="text"
                placeholder="10"
                value={values.c4MolPercent}
                className="input-settings-molar-chart"
              />
            </div>
          </div>
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">STD-%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c4TStdMolPercent"
                type="text"
                placeholder="10"
                value={values.c4TStdMolPercent}
                className="input-settings-molar-chart"
              />
            </div>
          </div>
        </div>

        <Divider className="selected-zones-divider" />
        <h2 className="section-title">C5</h2>
        <div className="baseline-form">
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c5MolPercent"
                type="text"
                placeholder="10"
                value={values.c5MolPercent}
                className="input-settings-molar-chart"
              />
            </div>
          </div>
          <div className="box-inputs wrapper-settings-input-molar wrapper-baseline-input">
            <label className="label-settings-molar-chart">STD-%mol</label>
            <div className="text-min box-input-molar">
              <Input
                onChange={handleChange}
                name="c5TStdMolPercent"
                type="text"
                placeholder="10"
                value={values.c5TStdMolPercent}
                className="input-settings-molar-chart"
              />
            </div>
          </div>
        </div>
        <div className="container-actions">
          <Button
            type="primary"
            className="btn-apply"
            htmlType="submit"
          >
            APPLY
          </Button>
          <Button
            className="btn-cancel"
            onClick={onClose}
          >
            CANCEL
          </Button>
        </div>
      </form>
    </Drawer>
  );
}

export default BaseLinePanel;
