import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Container, ErrorTextStyled, SelectStyled } from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SelectProps } from './types';

import { Controller } from 'react-hook-form';
import { Label } from '../Label';

export const Select = ({
  name, control, label, errorMessage,
  options, isDisable = false, defaultValue,
  handleChange, afterChange,
}: SelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, field}) => {
        return (
          <Container>
            <Label text={label} />
            <SelectStyled
              {...field}
              inputProps={{ readOnly: isDisable }}
              value={defaultValue && !value ? defaultValue : value}
              onChange={(e: any) => {
                onChange(e);
                afterChange?.();
                handleChange?.(e);
              }}
              onBlur={onBlur}
              MenuProps={{ disablePortal: true }}
              IconComponent={ExpandMoreIcon}
              disabled={isDisable ? true : false}
              error={!!errorMessage}
            >
              {options.map(({ value, text }, index) => (
                <MenuItem key={index} value={value}>{text}</MenuItem>
              ))}
            </SelectStyled>
            {errorMessage && (
              <ErrorTextStyled>{errorMessage}</ErrorTextStyled>
            )}
          </Container>
        );
      }}
    />  
  );
};

export const SelectWithoutControl = ({
  label, errorMessage,
  options, isDisable = false,
  handleChange, afterChange,
}: SelectProps) => {
  return (
    <Container>
      <Label text={label} />
      <SelectStyled
        inputProps={{ readOnly: isDisable }}
        // value={defaultValue && !value ? defaultValue : value}
        onChange={(e: any) => {
          // onChange(e);
          afterChange?.();
          handleChange?.(e);
        }}
        // onBlur={onBlur}
        MenuProps={{ disablePortal: true }}
        IconComponent={ExpandMoreIcon}
        disabled={isDisable ? true : false}
        error={!!errorMessage}
      >
        {options.map(({ value, text }, index) => (
          <MenuItem key={index} value={value}>{text}</MenuItem>
        ))}
      </SelectStyled>
      {errorMessage && (
        <ErrorTextStyled>{errorMessage}</ErrorTextStyled>
      )}
    </Container>
  );
};