import React from 'react';

const Rectangle = ({
  rotate, fillColor, strokeColor, rectWidth,
  rectHeight, viewBox, strokeWidth, svgWidth,
  svgHeight, className, rectX, rectY, customStyle,
  containerWidth, containerHeight, containerLeft, containerTop
}) => (
  <div
    className={className}
    style={{
      width: containerWidth,
      height: containerHeight,
      left: containerLeft,
      top: containerTop
    }}
  >
    <svg
      version="1.1"
      width={svgWidth}
      height={svgHeight}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      style={customStyle}
    >
      <rect
        x={rectX}
        y={rectY}
        width={rectWidth}
        height={rectHeight}
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        transform={`rotate(${rotate})`}
      />
    </svg>
  </div>
);

Rectangle.defaultProps = {
  rectWidth: '65',
  rectHeight: '242',
  fillColor: '#d1dabc',
  strokeColor: '#718656',
  strokeWidth: 1,
  rotate: -24,
  svgWidth: 125,
  svgHeight: 230,
  zIndex: 1,
  viewBox: '0 0 158 169',
  className: 'rectangle-container',
  rectX: 0,
  rectY: 0,
  containerWidth: null,
  containerHeight: null,
  containerLeft: null,
  containerTop: null
};

export default Rectangle;
