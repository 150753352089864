import React, {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';

import { findMinMaxDepth } from 'lib/Well';
import find from 'lodash.find';
import union from 'lodash.union';
import { useSelector } from 'react-redux';

const findForm = (currentWell, formType, formName) => find(
  (currentWell && currentWell.statistics || []),
  (c) => c.formName === formName || c.typeForm === formType
);

export const WizardContext = createContext();

const { Provider } = WizardContext;

export const useStatisticWizard = () => useContext(WizardContext);

export const useWizards = () => {
  const wells = useSelector(state => state.wells);

  // A array with the name of all valid forms
  const [validFormsCurves, setValidFormsNames] = useState([]);

  // Set current well
  const [selectedWell, setSelectedWell] = useState();

  // Collapse Form
  const [formCollapse, setFormCollapse] = useState();
  const [formCollapseC1, setFormCollapseC1] = useState();
  const [formCollapseC2, setFormCollapseC2] = useState();
  const [formCollapseC3, setFormCollapseC3] = useState();
  const [formCollapseC4, setFormCollapseC4] = useState();
  const [formCollapseIC4, setFormCollapseIC4] = useState();
  const [formCollapseC5, setFormCollapseC5] = useState();
  const [formCollapseIC5, setFormCollapseIC5] = useState();
  const [formCollapseGQR, setFormCollapseGQR] = useState();

  const [formCollapseC1Composition, setFormCollapseC1Composition] = useState();
  const [formCollapseC2Composition, setFormCollapseC2Composition] = useState();
  const [formCollapseC3Composition, setFormCollapseC3Composition] = useState();
  const [formCollapseC4Composition, setFormCollapseC4Composition] = useState();
  const [formCollapseC5Composition, setFormCollapseC5Composition] = useState();

  const [isLoadingStatistics, setIsLoadingStatistics] = useState(false);

  // Steps
  const [gasCurvesValue, setGasCurves] = useState([]);
  const [rangeMin, setRangeTop] = useState(0);
  const [rangeMax, setRangeBottom] = useState(0);
  const [rangeIntervalValue, setRangeInterval] = useState([]);
  const [currentStep, setCurrent] = useState(0);
  const [allSteps, setAllSteps] = useState([]);

  // Well
  const [wellKey, setWellKey] = useState();
  const [curves, setCurves] = useState();

  // Significance Level = step 2
  const [significanceLevelValue, setSignificanceLevel] = useState();
  const [significanceLevelC1, setSignificanceLevelC1] = useState();
  const [significanceLevelC2, setSignificanceLevelC2] = useState();
  const [significanceLevelC3, setSignificanceLevelC3] = useState();
  const [significanceLevelC4, setSignificanceLevelC4] = useState();
  const [significanceLevelC5, setSignificanceLevelC5] = useState();
  const [significanceLevelIC5, setSignificanceLevelIC5] = useState();
  const [significanceLevelIC4, setSignificanceLevelIC4] = useState();
  const [significanceLevelGQR, setSignificanceLevelGQR] = useState();

  const [significanceLevelC1Composition, setSignificanceLevelC1Composition] = useState();
  const [significanceLevelC2Composition, setSignificanceLevelC2Composition] = useState();
  const [significanceLevelC3Composition, setSignificanceLevelC3Composition] = useState();
  const [significanceLevelC4Composition, setSignificanceLevelC4Composition] = useState();
  const [significanceLevelC5Composition, setSignificanceLevelC5Composition] = useState();

  const [invalidAllCurves, setInvalidAllCurves] = useState([]);

  // data
  const [chartDataC1, setChartDataC1] = useState();
  const [chartDataC2, setChartDataC2] = useState();
  const [chartDataC3, setChartDataC3] = useState();
  const [chartDataC4, setChartDataC4] = useState();
  const [chartDataC5, setChartDataC5] = useState();
  const [chartDataIC5, setChartDataIC5] = useState();
  const [chartDataIC4, setChartDataIC4] = useState();
  const [chartDataGQR, setChartDataGQR] = useState();

  const [chartDataC1Composition, setChartDataC1Composition] = useState();
  const [chartDataC2Composition, setChartDataC2Composition] = useState();
  const [chartDataC3Composition, setChartDataC3Composition] = useState();
  const [chartDataC4Composition, setChartDataC4Composition] = useState();
  const [chartDataC5Composition, setChartDataC5Composition] = useState();

  const [payloadCriticalPoints, setPayloadCriticalPoints] = useState({});
  const [checkedTabs, setCheckedTabs] = useState({
    C1: '',
    C2: '',
    C3: '',
    IC4: '',
    IC5: '',
    C4: '',
    C5: '',
    'C1%': '',
    'C2%': '',
    'C3%': '',
    'C4%': '',
    'C5%': '',
    'GQR': ''
  });

  // fetch all data and set to context api
  useEffect(() => {
    const { currentWell } = wells;
    // when there is no statistics but need to get min and max depth to show on the range
    if (currentWell && currentWell.statistics && !currentWell.statistics.length && currentWell.depth) {
      const depthData = currentWell.depth;
      const { minDepth, maxDepth } = findMinMaxDepth(depthData);

      setRangeInterval([minDepth, maxDepth]);

      setRangeTop(minDepth);
      setRangeBottom(maxDepth);
    }
  }, [wells && wells.currentWell]);

  // fetch all data and set to context api
  useEffect(() => {
    const { currentWell } = wells;

    if (currentWell && currentWell.depth && currentWell.depth.length) {
      setSelectedWell(currentWell);

      if (currentWell && currentWell.statistics && currentWell.statistics.length) {
        const curvesList = currentWell.statistics.reduce((acc, curve) => {
          const currentCurve = curve.curves.slice(-1)[0];
          if (curve.curves && !acc.includes(currentCurve)) {
            acc.push(currentCurve);
          }
          return acc;
        }, []);

        setCurves(union(curves, curvesList));

        const depthData = currentWell.depth;
        const { minDepth, maxDepth } = findMinMaxDepth(depthData);
        const [topSelectedValue, bottomSelectedValue] = currentWell.statistics.rangeIntervalValue
          ? currentWell.statistics.rangeIntervalValue : [];

        setRangeInterval([topSelectedValue, bottomSelectedValue]);

        const minRangeValue = currentWell.statistics && currentWell.statistics.rangeMin
          ? currentWell.statistics.rangeMin : minDepth;
        const maxRangeValue = currentWell.statistics && currentWell.statistics.rangeMax
          ? currentWell.statistics.rangeMax : maxDepth;

        setRangeTop(minRangeValue);
        setRangeBottom(maxRangeValue);

        const allValidCurves = (currentWell && currentWell.statistics || [])
          .map(curve => curve.curves[0]);

        if (currentWell && currentWell.statistics && allValidCurves.length) {
          setValidFormsNames(allValidCurves);
        }

        const c1FormValue = findForm(currentWell, 'significanceLevelC1', 'formCollapseC1');

        // FormC1
        if (c1FormValue) {
          setFormCollapseC1(c1FormValue.curveInfo);
          setRangeInterval([c1FormValue.rangeMin, c1FormValue.rangeMax]);

          // significanceLevelC1
          if (c1FormValue.significanceLevelC1) {
            setSignificanceLevelC1(c1FormValue.significanceLevelC1);
          }
          if (c1FormValue.chartData) {
            setChartDataC1(c1FormValue.chartData);
          }
        }

        const c2FormValue = findForm(currentWell, 'significanceLevelC2', 'formCollapseC2');
        // FormC2
        if (c2FormValue) {
          setFormCollapseC2(c2FormValue.curveInfo);

          // significanceLevelC2
          if (c2FormValue.significance_level_percent) {
            setSignificanceLevelC2(c2FormValue.significance_level_percent);
          }
          if (c2FormValue.chartData) {
            setChartDataC2(c2FormValue.chartData);
          }
        }

        const c3FormValue = findForm(currentWell, 'significanceLevelC3', 'formCollapseC3');
        // FormC3
        if (c3FormValue) {
          setFormCollapseC3(c3FormValue.curveInfo);
          // significanceLevelC3
          if (c3FormValue.significance_level_percent) {
            setSignificanceLevelC3(c3FormValue.significance_level_percent);
          }
          if (c3FormValue.chartData) {
            setChartDataC3(c3FormValue.chartData);
          }
        }

        const c4FormValue = findForm(currentWell, 'significanceLevelC4', 'formCollapseC4');
        // C4Form
        if (c4FormValue) {
          setFormCollapseC4(c4FormValue.curveInfo);
          // significanceLevelC4
          if (c4FormValue.significance_level_percent) {
            setSignificanceLevelC4(c4FormValue.significance_level_percent);
          }
          if (c4FormValue.chartData) {
            setChartDataC4(c4FormValue.chartData);
          }
        }

        const c5FormValue = findForm(currentWell, 'significanceLevelC5', 'formCollapseC5');
        // C5Form
        if (c5FormValue) {
          setFormCollapseC5(c5FormValue.curveInfo);
          // significanceLevelC5
          if (c5FormValue.significance_level_percent) {
            setSignificanceLevelC5(c5FormValue.significance_level_percent);
          }
          if (c5FormValue.chartData) {
            setChartDataC5(c5FormValue.chartData);
          }
        }

        const ic4FormValue = findForm(currentWell, 'significanceLevelIC4', 'formCollapseIC4');
        // FormIC4
        if (ic4FormValue) {
          setFormCollapseIC4(ic4FormValue.curveInfo);
          // significanceLevelIC4
          if (ic4FormValue.significance_level_percent) {
            setSignificanceLevelIC4(ic4FormValue.significance_level_percent);
          }
          if (ic4FormValue.chartData) {
            setChartDataIC4(ic4FormValue.chartDataIC4);
          }
        }

        const ic5FormValue = findForm(currentWell, 'significanceLevelIC5', 'formCollapseIC5');
        // IC5Form
        if (ic5FormValue) {
          setFormCollapseIC5(ic5FormValue.curveInfo);
          // significanceLevelIC5
          if (ic5FormValue.significance_level_percent) {
            setSignificanceLevelIC5(ic5FormValue.significance_level_percent);
          }
          if (ic5FormValue.chartData) {
            setChartDataIC5(ic5FormValue.chartData);
          }
        }

        const gqrFormValue = findForm(currentWell, 'significanceLevelGQR', 'formCollapseGQR');
        // FormGQR
        if (gqrFormValue) {
          setFormCollapseGQR(gqrFormValue.curveInfo);
          setRangeInterval([gqrFormValue.rangeMin, gqrFormValue.rangeMax]);

          // significanceLevelC1
          if (gqrFormValue.significanceLevelGQR) {
            setSignificanceLevelGQR(gqrFormValue.significanceLevelGQR);
          }
          if (gqrFormValue.chartData) {
            setChartDataGQR(gqrFormValue.chartData);
          }
        }

        const c1CompositionFormValue = findForm(currentWell, 'significanceLevelC1Composition', 'formCollapseC1Composition');
        // c1Composition
        if (c1CompositionFormValue) {
          setFormCollapseC1Composition(c1CompositionFormValue.curveInfo);
          setRangeInterval([c1CompositionFormValue.rangeMin, c1CompositionFormValue.rangeMax]);

          // significanceLevelC1
          if (c1CompositionFormValue.significanceLevelC1Composition) {
            setSignificanceLevelC1Composition(c1CompositionFormValue.significanceLevelC1Composition);
          }
          if (c1CompositionFormValue.chartData) {
            setChartDataC1Composition(c1CompositionFormValue.chartData);
          }
        }

        const c2CompositionFormValue = findForm(currentWell, 'significanceLevelC2Composition', 'formCollapseC2Composition');
        // c2Composition
        if (c2CompositionFormValue) {
          setFormCollapseC2Composition(c2CompositionFormValue.curveInfo);
          setRangeInterval([c2CompositionFormValue.rangeMin, c2CompositionFormValue.rangeMax]);

          // significanceLevelC2
          if (c2CompositionFormValue.significanceLevelC2Composition) {
            setSignificanceLevelC2Composition(c2CompositionFormValue.significanceLevelC2Composition);
          }
          if (c2CompositionFormValue.chartData) {
            setChartDataC2Composition(c2CompositionFormValue.chartData);
          }
        }

        const c3CompositionFormValue = findForm(currentWell, 'significanceLevelC3Composition', 'formCollapseC3Composition');
        // c3Composition
        if (c3CompositionFormValue) {
          setFormCollapseC3Composition(c3CompositionFormValue.curveInfo);
          setRangeInterval([c3CompositionFormValue.rangeMin, c3CompositionFormValue.rangeMax]);

          // significanceLevelC3
          if (c3CompositionFormValue.significanceLevelC3Composition) {
            setSignificanceLevelC3Composition(c3CompositionFormValue.significanceLevelC3Composition);
          }
          if (c3CompositionFormValue.chartData) {
            setChartDataC3Composition(c3CompositionFormValue.chartData);
          }
        }

        const c4CompositionFormValue = findForm(currentWell, 'significanceLevelC4Composition', 'formCollapseC4Composition');
        // c4Composition
        if (c4CompositionFormValue) {
          setFormCollapseC4Composition(c4CompositionFormValue.curveInfo);
          setRangeInterval([c4CompositionFormValue.rangeMin, c4CompositionFormValue.rangeMax]);

          // significanceLevelC4
          if (c4CompositionFormValue.significanceLevelC4Composition) {
            setSignificanceLevelC4Composition(c4CompositionFormValue.significanceLevelC4Composition);
          }
          if (c4CompositionFormValue.chartData) {
            setChartDataC4Composition(c4CompositionFormValue.chartData);
          }
        }

        const c5CompositionFormValue = findForm(currentWell, 'significanceLevelC5Composition', 'formCollapseC5Composition');
        // c5Composition
        if (c5CompositionFormValue) {
          setFormCollapseC5Composition(c5CompositionFormValue.curveInfo);
          setRangeInterval([c5CompositionFormValue.rangeMin, c5CompositionFormValue.rangeMax]);

          // significanceLevelC5
          if (c5CompositionFormValue.significanceLevelC5Composition) {
            setSignificanceLevelC5Composition(c5CompositionFormValue.significanceLevelC5Composition);
          }
          if (c5CompositionFormValue.chartData) {
            setChartDataC5Composition(c5CompositionFormValue.chartData);
          }
        }

      }
    }
  }, [
    wells.currentWell && wells.currentWell.wellInfo &&  wells.currentWell.wellInfo.wellId,
    wells.currentWell && wells.currentWell.statistics && wells.currentWell.statistics.statisticsId,
  ]);

  return {
    currentWell: wells.currentWell,
    selectedWell,
    setSelectedWell,
    validFormsCurves,
    setValidFormsNames,
    currentStep,
    setCurrent,
    setAllSteps,
    allSteps,
    gasCurvesValue,
    setGasCurves,
    rangeIntervalValue,
    setRangeInterval,
    rangeMin,
    rangeMax,
    setRangeTop,
    setRangeBottom,
    formCollapse,
    setFormCollapse,
    setFormCollapseC1,
    formCollapseC1,
    formCollapseC2,
    setFormCollapseC2,
    formCollapseC3,
    setFormCollapseC3,
    formCollapseC4,
    setFormCollapseC4,
    formCollapseIC4,
    setFormCollapseIC4,
    formCollapseC5,
    setFormCollapseC5,
    formCollapseIC5,
    setFormCollapseIC5,
    formCollapseGQR,
    setFormCollapseGQR,
    formCollapseC1Composition,
    setFormCollapseC1Composition,
    chartDataC1Composition,
    setChartDataC1Composition,
    formCollapseC2Composition,
    setFormCollapseC2Composition,
    chartDataC2Composition,
    setChartDataC2Composition,
    formCollapseC3Composition,
    setFormCollapseC3Composition,
    chartDataC3Composition,
    setChartDataC3Composition,
    formCollapseC4Composition,
    setFormCollapseC4Composition,
    chartDataC4Composition,
    setChartDataC4Composition,
    formCollapseC5Composition,
    setFormCollapseC5Composition,
    chartDataC5Composition,
    setChartDataC5Composition,
    wellKey,
    setWellKey,
    setCurves,
    curves,
    setSignificanceLevel,
    significanceLevelValue,
    significanceLevelC1,
    significanceLevelC2,
    significanceLevelC3,
    significanceLevelC4,
    significanceLevelC5,
    significanceLevelIC5,
    significanceLevelIC4,
    significanceLevelGQR,
    setSignificanceLevelGQR,
    setSignificanceLevelC1,
    setSignificanceLevelC2,
    setSignificanceLevelC3,
    setSignificanceLevelC4,
    setSignificanceLevelC5,
    setSignificanceLevelIC5,
    setSignificanceLevelIC4,
    significanceLevelC1Composition,
    setSignificanceLevelC1Composition,
    significanceLevelC2Composition,
    setSignificanceLevelC2Composition,
    significanceLevelC3Composition,
    setSignificanceLevelC3Composition,
    significanceLevelC4Composition,
    setSignificanceLevelC4Composition,
    significanceLevelC5Composition,
    setSignificanceLevelC5Composition,
    payloadCriticalPoints,
    setPayloadCriticalPoints,
    checkedTabs,
    setCheckedTabs,
    chartDataC1,
    setChartDataC1,
    chartDataC2,
    setChartDataC2,
    chartDataC3,
    setChartDataC3,
    chartDataC4,
    setChartDataC4,
    chartDataC5,
    setChartDataC5,
    chartDataIC5,
    setChartDataIC5,
    chartDataIC4,
    setChartDataIC4,
    chartDataGQR,
    setChartDataGQR,
    isLoadingStatistics,
    setIsLoadingStatistics,
    invalidAllCurves,
    setInvalidAllCurves
  };
};

export const WizardProvider = ({children}) => {
  const globalState = useWizards();
  return <Provider value={{ ...globalState }}>{children}</Provider>;
};
