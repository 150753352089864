import styled from 'styled-components';
import ButtonBase from './ButtonBase';
import { ButtonStyleProps } from './types';

const ButtonGray = styled(ButtonBase)<ButtonStyleProps>`
  && {
    width: ${(props) => props.width};
    background: #1F2937;
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
      background: #1F2937;
    }
  }
`;

export default ButtonGray