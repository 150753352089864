import React from 'react';
import ToggleButton from 'react-toggle-button';
import store from 'store';
import { useRealtimeContext } from '@geowellex/shared-hooks';

const RealtimeSwitch = ({ notificationFunction, keyToUpdateStorage }) => {
    const initialValue = store.get(keyToUpdateStorage || 'openEditLithology');
    const { update, setUpdate, isActive } = useRealtimeContext();

    const toggleActive = () => {
        if (isActive) {
            const currentValue = !update;
            setUpdate(currentValue);
        } else {
            return notificationFunction?.('Please select a active well to enable Realtime Update');
        }
    };

    return (
        <div style={{ display: 'flex', color: '#fff' }}>
            <span style={{ marginRight: 5 }}>Realtime Update</span>
            <ToggleButton
                colors={{
                    activeThumb: {
                        base: 'rgb(255,255,255)'
                    },
                    inactiveThumb: {
                        base: 'rgb(255,255,255)'
                    },
                    active: {
                        base: '#65c970',
                        hover: '#65c970'
                    },
                    inactive: {
                        base: 'rgb(65,66,68)',
                        hover: 'rgb(95,96,98)'
                    }
                }}
                value={update || initialValue}
                
                onToggle={toggleActive}
            />
        </div>
    );
};

export default RealtimeSwitch;
