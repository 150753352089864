import { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
// import { useZones } from './hooks/useApi';
// import { config } from './config/index';
import FormFluidTypeZone from './components/FormFluidTypeZone';
import { useToggle } from './hooks/useToggle';
import { sortZones } from './utils/sort';

export const FluidTypingZone = ({ zoneData, submitZoneFluidType, isLoading, isLoadingCreate, zoneCount }: any) => {
  // const urlParams = new URLSearchParams(window.location.search);
  // const wellId = urlParams.get('wellId');
  // const { data } = useZones(wellId, config.auth);
  const [currentZoneToEdit ] = useState<any>();
  const { setListViewMode } = useToggle();
  // @ts-ignore
  const { currentWell: currentWellInfo } = useSelector(state => state.wells);

  const afterOpenForm = () => {
    setListViewMode();
  };
  
  const defaultLastBottom = useMemo(() => {
    const normalizeZones = zoneData?.zones ?? [];
    const sortedZones = sortZones(normalizeZones);
    const lastValue = sortedZones.slice(-1).pop();
    return lastValue?.bottom;
  }, [zoneData?.zones]);
 
  return (
    <FormFluidTypeZone
      toggleCancel={() => {}}
      submitText={`Create Zone`}
      submitZoneFluidType={submitZoneFluidType}
      afterSubmit={afterOpenForm}
      zoneBottom={defaultLastBottom ?? 0}
      loadingZone={false}
      zoneCount={zoneCount}
      currentWell={currentWellInfo}
      currentZoneToEdit={currentZoneToEdit}
      isLoading={isLoading}
      isLoadingCreate={isLoadingCreate}
      zoneData={zoneData?.zoneData ?? zoneData?.zoneData}
    />
  );
}
