import { Checkbox } from '@mui/material';
import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
	position: relative;
	display: inline-flex;
	align-items: end;
	&& .checkbox-matching-curves > input {
		margin-top: 14px;
    margin-left: 14px;
		width: 18px;
    height: 18px
	}
`;

export const CheckboxInput = styled(Checkbox)`
	&& .MuiSvgIcon-root {
		height: fit-content;
		color: #fff !important;
		fill-color: #fff;
	}
`;

