import React, { useState, useEffect } from 'react';
import logo from '../../assets/icon-geowellex.svg';
import Api from '../../api';
import NewPassword from '../NewPassword';
import _get from 'lodash.get';
import { useParams } from 'react-router-dom';

const UpdatePassword = () => {
  const params = useParams();
  const [formState, setFormState] = useState({
    errorNewPassword: '',
    isSubmited: false,
  });

  const submitNewPassword = async (values, actions) => {
		const { password , confirmPassword } = values;
		if (password !== confirmPassword) {
			setFormState({ errorNewPassword: 'Password is not match' });
			return;
		}
    const userId = params.id;

		Api.patch(`/users/${userId}/`, {
      password,
    }).then(() => {
      actions.setSubmitting(false);
      actions.setErros(null);
      setFormState({
        errorNewPassword: '',
        isSubmited: true
      });
    }).catch((error) => {
      if(error && error.response && error.response.data){
        const errorData = error.response.data[0].result;
        actions.setSubmitting(false);
        setFormState({
          errorNewPassword: errorData
        });
      } else {
        setFormState({
          errorNewPassword: '',
          isSubmited: true
        });
      }
    });
  };

  const { errorNewPassword, isSubmited } = formState;
  return (
    <div className="container-login" id="container">
      <div className="form-container">
        <NewPassword
          onSubmitNewPassword={submitNewPassword}
          errorNewPassword={errorNewPassword}
          isSubmited={isSubmited}
        />
      </div>
    </div>
  );
};

export default UpdatePassword;
