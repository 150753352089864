import React from 'react';
import { Button, Input } from 'antd';
import { useFormik, ErrorMessage } from 'formik';
import { errorNotification, } from '../../utils';

// YaxisForm
const YaxisForm = ({ onSubmitYaxisForm, onClose, yAxisMin, yAxisMax }) => {
  const onSubmit = (values) => {
    if (!values.min || !values.max) {
      errorNotification('All fields are required.');
      return;
    }
    if (isNaN(values.min) || isNaN(values.max)) {
      errorNotification('The values should be valid numbers.');
      return;
    }
    onSubmitYaxisForm(values);
  };

  const formik = useFormik({
    initialValues: {
      min: yAxisMin,
      max: yAxisMax
    },
    enableReinitialize: true,
    onSubmit: onSubmit,
  });

  const { handleSubmit, handleChange, values, errors } = formik;

  return (
    <form onSubmit={handleSubmit}>
    <div className="wrapper-form-molar">
    <div className="box-inputs wrapper-settings-input-molar">
      <label className="label-settings-molar-chart">Min</label>
      <div className="text-min box-input-molar">
        <Input
          onChange={handleChange}
          name="min"
          type="text"
          placeholder="Min"
          value={values.min}
          className="input-settings-molar-chart"
        />
        {errors && errors.min && <ErrorMessage message={errors.min} />}
      </div>
      <label className="label-settings-molar-chart">Max</label>
      <div className="text-max">
        <Input
          onChange={handleChange}
          name="max"
          type="number"
          placeholder="max"
          value={values.max}
          className="input-settings-molar-chart"
        />
        {errors && errors.max && <ErrorMessage message={errors.max} />}
      </div>
    </div>
    <div className="wrapper_buttons wrapper_buttons-molar">
      <Button
        type="submit"
        className="container-significance-form__button button-apply-molar-chart"
        htmlType="submit"
      >
        Apply
      </Button>
      <Button
        className="cy-btn-cancel button-cancel-molar-chart"
        style={{ marginLeft: 8 }}
        onClick={onClose}
      >
        Cancel
      </Button>
    </div>
    </div>
    </form>
  )
};

export default YaxisForm;