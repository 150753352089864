import React from 'react';
import { AutoComplete, Select } from 'antd';
const { Option } = Select;

class InputAutoComplete extends React.Component {
  state = {
    result: [],
    deaultValue: [],
  };

  handleSearch = value => {
    let result;
    this.setState({ result });
  };

  render() {
    const { result } = this.state;
    const children = (result || []).map((email, index) => <Option key={index}>{email}</Option>);
    return (
      <AutoComplete
        onChange={(value) => {
          this.props.onChange(value)
        }}
        style={this.props.style || {}}
        filterOption={(inputValue, option) =>
          option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        dataSource={this.props.dataSource}
        onSearch={this.handleSearch}
        placeholder={this.props.placeholder}
        defaultValue={this.props.defaultValue}
        data-cy={this.props.dataCy}
      >
        {children}
      </AutoComplete>
    );
  }
}

export default InputAutoComplete;
