import useSWR from 'swr';
import { useState, useEffect } from 'react';
import { insertZone, updateZoneInfo, deleteZoneSet } from '../api/zones';
import { ZoneItemType } from '../types/zone';
import { removeAllZones } from '../global-utils/Zones';

export const useZones = (wellId: string | null, token: string, currentWell?: any) => {
  const [selectedWellId, setWellId] = useState<string | undefined>();
  // TO DO: Remove this mock 
  const { data, error, mutate, isValidating, isLoading } = useSWR(
    selectedWellId && selectedWellId !== '-1' || -1 ? ['zones', selectedWellId] : null,
    selectedWellId ? () => currentWell.zone_sets.gold_zones : null,
    { revalidateOnFocus: true }
  );

  useEffect(() => {
    if (wellId !== selectedWellId && wellId !== null && wellId !== undefined && wellId.toString() !== '-1') {
      removeAllZones();
      setWellId(wellId);
    }
  }, [wellId, selectedWellId]);

  const insertNewZone = async (zoneData: any, afterCreate?: (newZone: ZoneItemType) => void) => {
    try {
      const result = await insertZone(zoneData, token);
      // @ts-ignore
      afterCreate?.(result.data);
      mutate();
      return result;
    } catch(error) {
      mutate();
      console.log(error);
    }
  };

  const deleteAndMutateZone = async (zoneSetName: string, wellId:string, afterSuccess: (newZones: any) => void) => {
    if (!isValidating && !isLoading) { 
      try {
        const newZones = (data?.data || []).filter((zone:ZoneItemType) => zone.id !== zoneSetName) || [];
        // @ts-ignore
        mutate({ data: newZones }, false  );
        const result = await deleteZoneSet(zoneSetName, wellId, token);
        const zonesResult = result.data.content.well_metadata.zone_sets;
        afterSuccess?.(zonesResult);
      } catch(e) {
        mutate();
        console.log('error removing zone', e)
      }
    }
  };

  const updateAndMutateZone = async (wellId: string, zoneData: any, token: string) => {
    console.log('updateAndMutateZone',);
    try {
      const result = await updateZoneInfo(wellId, zoneData, token);
      mutate();
      return result;
    } catch(error) {
      console.log('error useUpdateZoneInfo', error);
      return error;
    }
  };
  return {
    updateAndMutateZone,
    isValidating,
    deleteAndMutateZone,
    insertNewZone,
    data,
    isLoading,
    error,
    mutate,
  };
};
