import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Tabs } from 'antd';
import alertCircleGray from 'assets/alert-circle-gray.svg';
import alertCircleYellow from 'assets/alert-circle-yellow.svg';
import checkCircleGray from 'assets/check-circle-gray.svg';
import checkCircleGreen from 'assets/check-circle-green.svg';
import store from 'store';
import ChartSignificanceLevel from '../ChartSignificanceLevel';


import FormSignificanceLevel from '../FormSignificanceLevel';
import { receiveStatistics, receiveCriticalAreas, receiveMetadata, updateCurrentWellData } from '../../../../actions/wells';

import MessageSignificanceLevel from '../MessageSignificanceLevel';
import { useStatisticWizard } from '../ModalStatistics/useStatisticsWizard';
import { calculateCriticalValue } from '../../../../api/statistics';
import { successNotification, warningNotification, errorNotification } from '../../../../utils';

const { TabPane } = Tabs;

const curveMapTabs = {
  'C1': (c1Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {c1Status}
          <span>C1</span>
        </div>
      }
      key="1"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel
          curve="C1"
          differenceType={differenceType}
          movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="C1"
              typeForm="significanceLevelC1"
              dataCurve="c1"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel
                    defaultConfig={defaultConfig}
                    id="chart-significance-1"
                    curveName="C1"
                    onSubmit={onSubmit}
                    movingAvarage={movingAvarage}
                    enableSignificanceLevel={enableSignificanceLevel}
                />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'C2': (c2Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {c2Status}
          <span>C2</span>
        </div>
      }
      key="2"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel
          curve="C2"
          differenceType={differenceType}
          movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="C2"
              typeForm="significanceLevelC2"
              dataCurve="c2"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel
                  defaultConfig={defaultConfig}
                  id="chart-significance-2" curveName="C2" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'C3': (c3Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {c3Status}
          <span>C3</span>
        </div>
      }
      key="3"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel curve="C3" differenceType={differenceType} movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="C3"
              typeForm="significanceLevelC3"
              dataCurve="c3"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel
                    defaultConfig={defaultConfig}
                    id="chart-significance-3" curveName="C3" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'C4': (c4Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {c4Status}
          <span>C4</span>
        </div>
      }
      key="4"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel curve="C4" differenceType={differenceType} movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="C4"
              typeForm="significanceLevelC4"
              dataCurve="nc4"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel defaultConfig={defaultConfig} id="chart-significance-4" curveName="C4" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'C5': (C5Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {C5Status}
          <span>C5</span>
        </div>
      }
      key="5"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel curve="C5" differenceType={differenceType} movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="C5"
              typeForm="significanceLevelC5"
              dataCurve="nc5"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel defaultConfig={defaultConfig} id="chart-significance-5" curveName="C5" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'IC4': (ic4Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {ic4Status}
          <span>IC4</span>
        </div>
      }
      key="6"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel curve="IC4" differenceType={differenceType} movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="IC4"
              typeForm="significanceLevelIC4"
              dataCurve="ic4"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel defaultConfig={defaultConfig} id="significance-level-ic4" curveName="IC4" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'IC5': (ic5Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {ic5Status}
          <span>IC5</span>
        </div>
      }
      key="7"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel curve="IC5" differenceType={differenceType} movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="IC5"
              typeForm="significanceLevelIC5"
              dataCurve="ic5"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel defaultConfig={defaultConfig} id="significance-level-ic5" curveName="IC5" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'C1Composition': (c1Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {c1Status}
          <span>C1%</span>
        </div>
      }
      key="8"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel
          curve="C1%"
          differenceType={differenceType}
          movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="C1Composition"
              typeForm="significanceLevelC1Composition"
              dataCurve="c1Composition"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel defaultConfig={defaultConfig} id="chart-significance-c1Composition" curveName="C1Composition" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'C2Composition': (c2Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {c2Status}
          <span>C2%</span>
        </div>
      }
      key="9"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel
          curve="C2%"
          differenceType={differenceType}
          movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="C2Composition"
              typeForm="significanceLevelC2Composition"
              dataCurve="c2Composition"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel defaultConfig={defaultConfig} id="chart-significance-c2Composition" curveName="C2Composition" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'C3Composition': (c3Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {c3Status}
          <span>C3%</span>
        </div>
      }
      key="10"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel
          curve="C3%"
          differenceType={differenceType}
          movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="C3Composition"
              typeForm="significanceLevelC3Composition"
              dataCurve="c3Composition"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel defaultConfig={defaultConfig} id="chart-significance-c3Composition" curveName="C3Composition" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'C4Composition': (c4Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {c4Status}
          <span>C4%</span>
        </div>
      }
      key="11"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel
          curve="C4%"
          differenceType={differenceType}
          movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="C4Composition"
              typeForm="significanceLevelC4Composition"
              dataCurve="c4Composition"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel defaultConfig={defaultConfig} id="chart-significance-c4Composition" curveName="C4Composition" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'C5Composition': (c4Status, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {c4Status}
          <span>C5%</span>
        </div>
      }
      key="12"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel
          curve="C5%"
          differenceType={differenceType}
          movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="C5Composition"
              typeForm="significanceLevelC5Composition"
              dataCurve="c5Composition"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel defaultConfig={defaultConfig} id="chart-significance-c5Composition" curveName="C5Composition" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  ),
  'GQR': (gqrStatus, differenceType, movingAvarage, defaultConfig) => (
    <TabPane
      tab={
        <div className="container-tabs-significance__title">
          {gqrStatus}
          <span>GQR</span>
        </div>
      }
      key="13"
    >
      <div className="box-chart-form">
        <MessageSignificanceLevel
          curve="GQR"
          differenceType={differenceType}
          movingAvarage={movingAvarage}
          form={
            <FormSignificanceLevel
              defaultConfig={defaultConfig}
              curveName="GQR"
              typeForm="significanceLevelGQR"
              dataCurve="gqr"
              render={
                (onSubmit, enableSignificanceLevel) =>
                  <ChartSignificanceLevel defaultConfig={defaultConfig} id="chart-significance-gqr" curveName="GQR" onSubmit={onSubmit} movingAvarage={movingAvarage} enableSignificanceLevel={enableSignificanceLevel} />
              }
            />
          }
        />
      </div>
    </TabPane>
  )
}

const TabsSignificanceLevel = ({ currentWell }) => {
  const [_, wellId] = location && location.search ? location.search.split('=') : '-1';
  const dispatch = useDispatch();
  const {
    formCollapseC1,
    formCollapseC2,
    formCollapseC3,
    formCollapseIC4,
    formCollapseIC5,
    formCollapseC4,
    formCollapseC5,
    formCollapseGQR,
    formCollapseC1Composition,
    formCollapseC2Composition,
    formCollapseC3Composition,
    formCollapseC4Composition,
    formCollapseC5Composition,
    payloadCriticalPoints,
    setIsLoadingStatistics,
    checkedTabs,
    setCheckedTabs
  } = useStatisticWizard();
  const globalState = useStatisticWizard();
  const wells = useSelector(state => state.wells);
  const selectedCurves = globalState.curves || [];
  const [activeTab, setActiveTab] = useState(1);

  const handleChangeTab = (activeKey) => {
    setActiveTab(Number(activeKey));
  };

  const chekedImage = (currentActiveTab, checkTab) => {
    if (checkTab === 'error') {
      return <img src={alertCircleYellow} alt="alert"/>
    }

    if (checkTab === 'saved') {
      return <img src={checkCircleGreen} alt="checked"/>
    }

    return <img src={checkCircleGray} alt="checked"/>
  };

  const formCollapse = {
    'C1': formCollapseC1,
    'C2': formCollapseC2,
    'C3': formCollapseC3,
    'IC4': formCollapseIC4,
    'IC5': formCollapseIC5,
    'C4':  formCollapseC4,
    'C5': formCollapseC5,
    'C1Composition': formCollapseC1Composition,
    'C2Composition': formCollapseC2Composition,
    'C3Composition': formCollapseC3Composition,
    'C4Composition': formCollapseC4Composition,
    'C5Composition': formCollapseC5Composition,
    'GQR': formCollapseGQR
  };

  const handleApplySignificanceLevel = async () => {
    let readyToApply = true;
    let curvesChecked = {};

    selectedCurves.map((selectedCurve) => {
      if (checkedTabs[selectedCurve] !== 'saved') {
        readyToApply = false;
        curvesChecked = {
          ...curvesChecked,
          [selectedCurve]: 'error'
        }
      }
      return
    })

    setCheckedTabs({
      ...checkedTabs,
      ...curvesChecked
    })

    if (readyToApply) {
      try {
        // dispatch receive statistics action
        setIsLoadingStatistics(true);
        const response = await calculateCriticalValue(payloadCriticalPoints);

        const dataStatisticsCurve = response.data?.content?.well_data?.log_sets?.STATISTICS?.data;

        // @TODO includes c1 to c5 composition and GQR
        const curvesDiff = [
          'c1_diff', 'c2_diff', 'c3_diff', 'c4_diff', 'c5_diff', 'ic4_diff', 'ic5_diff',
          'c1_perc_diff', 'c2_perc_diff', 'c3_perc_diff', 'c4_perc_diff', 'c5_perc_diff', 'ic4_perc_diff', 'ic5_perc_diff'
        ];
        const settings = Object.keys(response?.data?.content?.well_metadata?.modules_parameters?.STATISTICS?.settings);
        const curvesSettings = settings.filter(curve => !['range_min', 'range_max'].includes(curve));

        // @TODO improve name os keys modules.settings data
        const normalizedNameCurve = (str) => str.slice(0, str.lastIndexOf('_'));

        // provide a object with mnemonics on keys, map all and return only the type
        const getCurveType = (curvesObject) => curvesObject.map(v => {
          const result = v.split('  ')?.[0];
          return result;
        });

        const allCriticalValues = (curvesSettings || []).reduce((acc, keyCurve, index) => {
          const curveType = keyCurve?.split('  ')?.[0]?.toLowerCase();

          const criticalValue = {
            [keyCurve]: response?.data?.content?.well_metadata?.modules_parameters?.STATISTICS?.settings?.[keyCurve]?.critical_value,
          };

          const chartData = (dataStatisticsCurve || []).find((currentCurve) => {
              return currentCurve.type === (curveType + `_diff`) || currentCurve.type === (curveType + `_perc_diff`);
          }) || { data: [] };

          const criticalAreas = chartData?.data?.reduce((acc2, criticalValueFromChart, index) => {
            const calculatedCriticalValue = response?.data?.content?.well_metadata?.modules_parameters?.STATISTICS?.settings?.[keyCurve]?.critical_value;
              acc2.push({
                depthIndex: index,
                criticalAreaValue: calculatedCriticalValue,
                shouldShow: criticalValueFromChart >= calculatedCriticalValue,
                color: '#FF0000',
                curveName: curveType.toUpperCase()
              });
              return acc2;
          }, []);

          acc[`criticalAreas${curveType.toUpperCase()}`] = criticalAreas;
          return acc;
        }, {});

        // criticalAreas.reduce((acc, data, index) => {
        //   acc.push({
        //     [`criticalAreas${data.curveName.toUpperCase()}`]: data.criticalAreaValue,
        //     curveInfo: { [`criticalAreas${data.curveName.toUpperCase()}`]: [] },
        //     curves: [data.curveName.toUpperCase()],
        //     typeForm: `significanceLevel${data.curveName.toUpperCase()}`,
        //     validFormsCurves: [data.curveName.toUpperCase()],
        //     wellId: wellId,
        //   });
        //   return acc;
        // }, []);
        //
        // const criticalAreasC1 = [];
        // const statistics = [{
        //   criticalAreasC1: criticalAreasC1,
        //   curveInfo: { criticalAreasC1: criticalAreasC1 } ,
        //   curves: ['C1'],
        //   typeForm: 'significanceLevelC1',
        //   validFormsCurves: ['C1'],
        //   wellId: wellId,
        // }];

        // console.log('response.data ==>', {
        //   response: response.data,
        //   c1ChartData,
        //   criticalValueC1,
        //   criticalAreasC1,
        //   statistics
        // });

        dispatch(receiveCriticalAreas(allCriticalValues));
        successNotification(`Critical Areas calculated successfully!`);

        dispatch(receiveMetadata(response.data?.content?.well_metadata));
        dispatch(updateCurrentWellData(response.data?.content?.well_data));

        setIsLoadingStatistics(false);
      } catch(e) {
        errorNotification('Error when calculating critical areas!');
        setIsLoadingStatistics(false);
        console.log('error handleApplySignificanceLevel', e);
      }
      return;
    }

    warningNotification('Fill in all curves before applying');
  }

  return (
    <div className="container-tabs-significance">
      <div className="container-apply-significance">
        <Button
          className="container-significance-form__button"
          onClick={handleApplySignificanceLevel}
          data-cy="apply-significance-level"
        >
          Apply
        </Button>
      </div>
      <Tabs type="card" onChange={(activeKey) => handleChangeTab(activeKey)}>
        {selectedCurves.map((curveName) => {
          const tabStatus = chekedImage(curveName, checkedTabs?.[curveName]);
          const curveCollapse = formCollapse?.[curveName];
          const collapseCurveMovingAvagerage = curveCollapse?.moving_average_type === 'SMA' ? 'Simple Moving Average' : 'Exponential Moving Average';
          const collapseCurveDifferenceType = curveCollapse?.difference_type === 'ABS' ? 'Absolute Difference ' : 'Relative Difference';
          const configData = currentWell?.log_sets?.STATISTICS?.data;
          const chartDataByCurveName = configData?.find(c => c.type === `${curveName?.toLowerCase()}_diff`)?.data;
          const settings = wells?.metadata?.modules_parameters?.STATISTICS?.settings;
          const defaultConfig = Object.keys(settings || {}).reduce((acc, settingKey) => {
            if (settingKey.includes(curveName)) {
              acc = settings[settingKey];
            }
            return acc;
          }, {});
          return curveMapTabs[curveName](
            tabStatus,
            collapseCurveMovingAvagerage,
            collapseCurveDifferenceType,
            {...defaultConfig, chartData: chartDataByCurveName }
          );
        })
        }
      </Tabs>
    </div>
  );
}

export default TabsSignificanceLevel;
