import React, {
  memo,
  useEffect,
  useState
} from 'react';

import { useSelector } from 'react-redux';
import store from 'store';
import {
  nFormatter,
  toggleC1NormalizedChart,
  toggleTotalCarbonChart,
  toggleTotalGasChart,
  toggleGQRChart,
} from 'utils';
import { getCalculatedCurveData } from '../../../../utils/getCalculatedCurveData';
import { getRawCurveData } from '../../../../utils/getRawCurveData';
import { useScales } from '../../../../hooks/useScales';
import { totalGasCurveTypes } from '../constants';
import { DEFAULT_SCALES } from '../../../../utils/constants';

const ChartHeader = ({
  rangeValues,
  positionsLeft
}) => {
  const wells = useSelector(state => state.wells);

  const initialHeaderState = {
    gasTotal: 0,
    totalCarbon: 0,
    c1Normalized: 0,
    gqr: 0,
  };

  const {
    currentScale, setHeaderState, headerState
  } = useScales(
    totalGasCurveTypes,
    wells.currentWell,
    initialHeaderState
  );

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    totalGas: true,
    totalCarbon: true,
    c1Normalized: true,
    gqr: true
  });

  const tooggleCallbacks = {
    totalGas: toggleTotalGasChart,
    totalCarbon: toggleTotalCarbonChart,
    c1Normalized: toggleC1NormalizedChart,
    gqr: toggleGQRChart
  };

  const toggleTrackCurrentTrack = (event) => {
    const { dataset: { name, index } } = event.target;
    const trackName = tracks[name]; 
    const callback = tooggleCallbacks[name];

    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  const dispatchModal = (typeOfTitle) => (event) => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  // TODO create a custom hook to treat it
  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const { currentWell } = wells;
      const totalGas = getRawCurveData('total_gas', currentWell);
      const totalCarbon = getCalculatedCurveData('total_carbon', currentWell);
      const c1Normalized = getCalculatedCurveData('c1_norm', currentWell);
      const gqr = getCalculatedCurveData('gqr', currentWell);

      const index = wells.selectedDepthIndex || 0;
      const gasTotalValue = totalGas.data && totalGas.data[index] ? totalGas.data[index] : 0;
      const totalCarbonValue = totalCarbon.data && totalCarbon.data[index] ? totalCarbon.data[index] : 0;
      const c1NormalizedValue = c1Normalized.data && c1Normalized.data[index] ? c1Normalized.data[index] : 0;
      const gqrNormalizedValue = gqr.data && gqr.data[index] ? gqr.data[index] : 0;
      setHeaderState({
        ...headerState,
        gasTotal: gasTotalValue < 0 ? '' : gasTotalValue?.toFixed(2),
        totalCarbon: totalCarbonValue < 0 ? '' : totalCarbonValue?.toFixed(2),
        c1Normalized: c1NormalizedValue < 0 ? '' : c1NormalizedValue?.toFixed(2),
        gqrDepth: gqrNormalizedValue < 0 ? '' : gqrNormalizedValue?.toFixed(2),
      });
    }

    if (!wells.currentWell) {
      setHeaderState({
        ...headerState,
        gasTotal: 0,
        totalCarbon: 0,
        c1Normalized: 0,
        gqrDepth: 0
      });
    }
  }, [wells]);
  const { gasTotal, totalCarbon, gqrDepth, c1Normalized} = headerState;
  const { total_gas, total_carbon, gqr, c1_norm } = currentScale;
 
  return (
    <div
      style={{
        display: 'flex',
        height: '133px',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        position:'relative',
        width: 275,
        alignItems: 'center',
      }}
    >
      <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 20,
          'justifyContent': 'flex-end',
          color: total_gas?.color,
          width: 254,
        }}
        onClick={dispatchModal('total_gas')}
        data-cy="open-config-total-gas"
      >
        <div
          className="total-gas-header__title"
          data-name="title"
          data-cmd="openConfig"
          style={{ left: 23, minWidth: 211 }}
        >
          {gasTotal ? `Total Gas ${gasTotal}` : 'Total Gas (units)'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox label-total-gas">
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.totalGas}
            data-name="totalGas"
            data-index="1"
            data-cy="checkbox-total-gas"
          />
          <span className="checkmark" style={{ backgroundColor: total_gas?.color }}/>
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: total_gas?.color
          }}
        >
          <span data-cy="scales-totalGas-min">{nFormatter(total_gas?.min)}</span>
          <span data-cy="scales-totalGas-max">{nFormatter(total_gas?.max)}</span>
        </div>
      </div>
      <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 30,
          justifyContent: 'flex-end',
          color: total_carbon?.color,
          width: 254,
        }}
        onClick={dispatchModal('total_carbon')}
        data-cy="open-config-total-carbon"
      >
        <div
          className="total-gas-header__title"
          data-name="title"
          data-cmd="openConfig"
          style={{
            color: total_carbon?.color,
            width: 254,
            left: 0
          }}
        >
          {totalCarbon ? `Total Carbon ${totalCarbon}` : 'Total Carbon'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox">
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.totalCarbon}
            data-name="totalCarbon"
            data-index="2"
            data-cy="checkbox-total-carbon"
          />
          <span className="checkmark" style={{backgroundColor: total_carbon?.color}}/>
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: total_carbon?.color
          }}
        >
          <span data-cy="scales-total-carbon-min">{nFormatter(total_carbon?.min)}</span>
          <span data-cy="scales-total-carbon-max">{nFormatter(total_carbon?.max)}</span>
        </div>
      </div>
      <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 32,
          'justifyContent': 'flex-end',
          color: c1_norm?.color,
          width: 254,
        }}
        onClick={dispatchModal('c1Normalized')}
        data-cy="open-config-c1-normalized"
      >
        <div
          className="total-gas-header__title"
          data-name="title"
          data-cmd="openConfig"
          style={{ left: 23, minWidth: 211 }}
        >
          {c1Normalized ? `C1 Normalized ${c1Normalized}` : 'C1 Normalized'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox">
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.c1Normalized}
            data-name="c1Normalized"
            data-index="3"
            data-cy="checkbox-c1-normalized"
          />
          <span className="checkmark" style={{backgroundColor: c1_norm?.color}}/>
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: c1_norm?.color,
            color: c1_norm?.color
          }}
        >
          <span data-cy="scales-c1-normalized-min">{nFormatter(c1_norm?.min)}</span>
          <span data-cy="scales-c1-normalized-max">{nFormatter(c1_norm?.max)}</span>
        </div>
      </div>
      <div
        className="total-gas-header"
        data-name="title"
        data-cmd="openConfig"
        style={{
          height: 22,
          'justifyContent': 'flex-end',
          color: gqr?.color || DEFAULT_SCALES?.gqr.color,
          width: 254,
        }}
        onClick={dispatchModal('gqr')}
        data-cy="open-config-c1-normalized"
      >
        <div
          className="total-gas-header__title"
          data-name="title"
          data-cmd="openConfig"
          style={{ left: 23, minWidth: 211,  }}
        >
          {gqr ? `Gas Quality Ratio ${gqrDepth}` : 'Gas Quality Ratio'}
        </div>
        <label className="total-gas-header__checkbox container-checkbox label-gqr" style={{ marginLeft: 10 }}>
          <input
            data-cmd="toggle"
            type="checkbox"
            checked={tracks.gqr}
            data-name="gqr"
            data-index="4"
            data-cy="checkbox-c1-normalized"
            style={{ marginTop: 0, paddingTop: 0 }}
          />
          <span className="checkmark" style={{ backgroundColor: gqr?.color || DEFAULT_SCALES?.gqr.color }}/>
        </label>
        <div
          className="total-gas-header__values"
          style={{
            borderColor: gqr?.color || DEFAULT_SCALES?.gqr.color,
            color: gqr?.color || DEFAULT_SCALES?.gqr.color,
          }}
        >
          <span data-cy="scales-c1-normalized-min">{nFormatter(gqr?.min || 0.2)}</span>
          <span data-cy="scales-c1-normalized-max">{nFormatter(gqr?.max || 2)}</span>
        </div>
      </div>

      <div className="total-gas-labels" style={{ position: 'relative', borderBottom: '1px solid #000' }}>
        {
          rangeValues.map((label, index) => (
            <span
              className={`total-gas-label-${index}`}
              index={label.toString()}
              style={{
                left: positionsLeft[index]
              }}
            >
              {label ? label.replace('<tspan>', '').replace('</tspan>', '') : null}
            </span>
          ))
        }
      </div>
    </div>
  );
};

export default memo(ChartHeader);
