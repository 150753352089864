import React from 'react'

import { HeaderProps } from "../../types/login"
import {
  HeaderImage, HeaderImageContainer, HeaderLogin
} from "../../styles/styles"

export const Header = ({ errorLogin, logo }: HeaderProps) => {
  return (
    <HeaderLogin isError={!!errorLogin} data-testid="header">
      <HeaderImageContainer
        isError={!!errorLogin}
        data-aos="fade-down"
        data-aos-delay="300"
        data-aos-duration="500"
        data-aos-easing="ease"
      >
        <HeaderImage
          src={logo}
          data-testid="header-logo"
        />
      </HeaderImageContainer>
    </HeaderLogin>
  )
}
