import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Modal from 'react-modal';
import store from 'store';

import { doLogout } from 'components/Login/api';
import Logo from '../../assets/logo.png';
import Depth from '../../images/depth-white.svg';
import Logout from '../../images/exit-white.svg';
import GasLogData from '../../images/gaslogdata-white.svg';
import Home from '../../images/home-white.svg';
import TimeData from '../../images/timedata-white.svg';
import WellexGold from '../../images/wellex-white.svg';
import AutomudIcon from '../../assets/automud-icon.svg';

import { FileSearchOutlined } from '@ant-design/icons';
import { warningNotification } from '../../utils';

import { modalStyle } from '../../utils';
import { DEFAULT_SCALES } from '../../utils/constants';
import Nav from '../Nav';
import ScalesConfig from '../ScalesConfig';

const icons = {
  home: Home,
  depthdata: Depth,
  timedata: TimeData,
  gaslogdata: GasLogData,
  wellex: WellexGold,
  automudIcon: AutomudIcon
};

const SidebarItem = ({
  icon, text, title, onClick, href
}) => (
  <li>
    <div
      onClick={onClick}
      className="sidebar__list-item"
      title={title}
    >
      <img src={icons[icon]} alt="icon"/>
      <span className="sidebar__list-text">
        {text}
      </span>
    </div>
  </li>
);

const SidebarNavigation = ({
  classes: { wrapper }
}) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);

  const [modalExpanded, setModalExpanded] = useState(false);
  const toggleModalExpanded = () => setModalExpanded(!modalExpanded);
  const location = useLocation();
  const [_, wellId] = location && location.search ? location.search.split('=') : '-1';

  const openQualityControlModule =  () =>{
    if (wellId.toString() === '-1') {
      warningNotification('You should select well first!')
      return;
    }
    navigate(`/home/quality-control?wellId=${wellId}`)
  }

  const openWellexGold = () => {
    navigate(`/home?wellId=${wellId}`);
  }

  const wipeSession = () => {
    store.set('logged', false);
    store.set('token', '');
    navigate('/');
  };

  const wipeCredentials = async () => {
    // TODO leo should remove it after final API is ready
    wipeSession();
    try {
      const response = await doLogout();
      const authenticated = response.headers['gw-authenticated'];
      if (authenticated === 'false') {
        wipeSession();
      }
    } catch(e) {
      console.log('LOGOUT Error:', e);
    } finally {
      store.set('configScales', DEFAULT_SCALES);
    };
  };

  return (
    <Nav
      className={classNames(
        { [wrapper]: wrapper },
        { expanded }
      )}
    >
      <button
        type="button"
        className='sidebar__toggle-button'
        onClick={toggleExpanded}
      >
        <span className="sidebar__toggle-bar1" />
        <span className="sidebar__toggle-bar2" />
        <span className="sidebar__toggle-bar3" />
      </button>
      <a href="https://www.geowellex.com" rel="noopener noreferrer" target="_blank"
        className={classNames('sidebar__logo', {'sidebar__logo-centralized': expanded })}
      >
        <img src={Logo} alt="logo"/>
      </a>
      <div className="sidebar__aling">
        <div
          className={classNames({
            'sidebar__content--openend': expanded,
            'sidebar__content': !expanded
          })}
        >
          <Modal
            isOpen={modalExpanded}
            style={{ ...modalStyle }}
            onRequestClose={toggleModalExpanded}
          >
            <ScalesConfig />
          </Modal>
          <ul className="sidebar__list">
            {/* <SidebarItem
              text="Home"
              icon="home"
              title="Home Page"
            /> */}
            {/* <SidebarItem
              text="Depth Data"
              icon="depthdata"
              title="Depth Data"
            />
            <SidebarItem
              text="Time Data"
              icon="timedata"
              title="Time Data"
            />
            <SidebarItem
              text="Gas Log Data"
              icon="gaslogdata"
              title="Gas Log Data"
            /> */}
            <SidebarItem
              onClick={() => openWellexGold()}
              text="Wellex Gold"
              icon="wellex"
              title="Wellex Gold"
            />
            <li>
              <a
                className="sidebar__list-item"
                href="https://automud.geowellex.com/"
                rel="noopener noreferrer" target="_blank"
                title="Wellex Automud"
              >
                <img src={icons.automudIcon} alt="Wellex Automud"/>
                <span className="sidebar__list-text">
                  Wellex Automud
                </span>
              </a>
            </li>
            <li>
              <a
                className="sidebar__list-item"
                role='button'
                rel="noopener noreferrer"
                title="Quality Control Module"
                onClick={()=> openQualityControlModule()}
              >
                <FileSearchOutlined style={{fontSize: 24}}/>
                <span className="sidebar__list-text">
                  Quality Control Module
                </span>
              </a>
            </li>
            {/* <a
              onClick={() => history.push('/admin')}
              title="Admin Area"
              className="sidebar__settings-item"
            >
              <img  className="icon-size" src={admin} alt="Admin Area" />
              <span className="sidebar__list-text">
                Admin Area
              </span>
            </a> */}
          </ul>
        </div>
        <div className="col-2">
          {/* <a
            href="#"
            title="My Account"
            className="sidebar__settings-item"
          >
            <img src={UserIcon} alt="My Account" />
            <span className="sidebar__list-text">
              My Account
            </span>
          </a> */}
          {/* <a
            href="javascript:void(0)"
            onClick={() => history.push('/admin')}
            title="Admin Area"
            className="sidebar__settings-item"
          >
            <img  className="icon-size" src={admin} alt="Admin Area" />
            <span className="sidebar__list-text">
              Admin Area
            </span>
          </a> */}
          {/* <a
            href="#"
            title="Configurações"
            className="sidebar__settings-item"
            onClick={() => ({})}
          >
            <img  className="icon-size" src={Settings} alt="settings" />
            <span className="sidebar__list-text">
              Settings
            </span>
          </a> */}
          <button
            type="button"
            title="Log out"
            className="sidebar__settings-item sidebar__logout-btn"
            style={{
              background: '#2f2e36',
              border: '0px',
              cursor: 'pointer',
              outline: '0'
            }}
            onClick={() => {
              wipeSession();
              wipeCredentials();
            }}
            data-cy="log-out"
          >
            <img  className="icon-size" src={Logout} alt="logout icon" />
            <span className="sidebar__list-text">
              Log out
            </span>
          </button>
        </div>
      </div>
    </Nav>
  );
};

SidebarNavigation.defaultProps = {
  classes: {
    toggleIcon: '',
    wrapper: 'sidebar__nav'
  }
};

export default SidebarNavigation;
