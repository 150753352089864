import { StepLabel } from "@mui/material";
import styled from "styled-components";
import { StepTitleProps } from "./types";

export const StepLabelStyled = styled(StepLabel)`
  && {
    .Mui-active, .Mui-completed {
      color: #4F62C5;
    }

    .MuiStepIcon-text {
      fill: #030A12;
    }

    .Mui-disabled {
      .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
        color: #4B5563;
      }
    }

  }
`

export const StepTitle = styled.span<StepTitleProps>`
  color: ${(props) => props.isActiveStep ? '#FFFFFF' : '#737373'};
`