import { RECEIVE_CUT_OFFS, RECEIVE_CUT_OFF } from '../constants/cutoff';
import { createReducer } from './createReducer';

const initialState = {
  items: [],
  previous: []
};

function receiveCutOffs(state, action) {
  const { cutoffs } = action;
  return {
    ...state,
    previous: state.items,
    items: cutoffs
  }
}

function receiveCutOff(state, action) {
  const { cutoff } = action;
  return {
    ...state,
    previous: state.items,
    items: cutoff
  }
}

export default createReducer(initialState, {
  [RECEIVE_CUT_OFFS]: receiveCutOffs,
  [RECEIVE_CUT_OFF]: receiveCutOff
});
