import Highcharts from 'highcharts';
import {
  getAllZones,
  insertZone,
  removeZone,
  updateZoneInfo
} from 'api/zones';
import map from 'lodash.map';
import store from 'store';
import {
  removeAllZones
} from 'utils/Zones';
import {
  applyZoneToCharts,
} from '@geowellex/shared-zone/src/utils/applyZoneToCharts';

import {
  DELETE_ALL,
  RECEIVE_ZONE,
  RECEIVE_ZONES,
  UPDATE_ZONE
} from '../constants/zone';

export const deleteAll = () => ({
  type: DELETE_ALL,
});

export const receiveZones = zones => ({
  type: RECEIVE_ZONES,
  zones,
});

export const receiveZone = zone => ({
  type: RECEIVE_ZONE,
  zone,
});

export const updateSingleZone = zone => ({
  type: UPDATE_ZONE,
  zone,
})

export const converZoneToApply = (zone) => {
  return {
    ...zone,
    borderWidth: Number(zone.borderWidth),
    bottom: Number(zone.bottom),
    bottomX: Number(zone.bottomX),
    top: Number(zone.top),
    topX: Number(zone.topX),
    color: zone.color ? { a: parseFloat(zone.color.a), b: Number(zone.color.b), g: Number(zone.color.g), r: Number(zone.color.r) } : undefined,
  };
};

export const fetchZones = (wellId) => {
  return async (dispatch, getState) => {
    try {
      const response = await getAllZones(wellId, store.get('token'));
      const oldZones = getState().zone.items;
      dispatch(receiveZones(response.data));
      map(oldZones || [], (z) => removeAllZones(z.id));
      map(response.data, zone => applyZoneToCharts('total-gas')(converZoneToApply(zone), Highcharts.charts));
    } catch (e) {
      console.log('error fetchZones', e)
    }
  };
};

export const addZone = (zoneWithWellId) => {
  return async (dispatch) => {
    try {
      await insertZone(zoneWithWellId, store.get('token'));
      dispatch(fetchZones(zoneWithWellId.wellId));
      dispatch(receiveZone(zoneWithWellId));
    } catch (e) {
      console.log('error addZone', e.response)
    }
  };
};

export const deleteZone = (zoneId, token, wellId, afterSuccess, afterError) => {
  return async (dispatch) => {
    try {
      await removeZone(zoneId, token);
      afterSuccess && afterSuccess();
      dispatch(fetchZones(wellId));
    } catch (e) {
      console.log('e', e);
      afterError && afterError(e);
    }
  }
};

export const updateZone = (zoneId, zone, wellId, token, afterSuccess, afterError) => {
  return async (dispatch) => {
    try {
      await updateZoneInfo(zoneId, zone, token);
      dispatch(fetchZones(wellId));
      afterSuccess();
    } catch (e) {
      console.log('e updateZone', e);
      afterError(e);
    }
  }
};
