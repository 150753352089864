import { Snackbar, Alert as AlertMui } from "@mui/material";
import { AlertProps } from "./types";

const Alert = ({ open, message, severity, onClose }: AlertProps) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <AlertMui
        severity={severity}
        sx={{ width: '100%' }}
      >
        {message}
      </AlertMui>
    </Snackbar>
  )
}

export default  Alert;
