import { Box } from "@mui/material";
import styled from "styled-components";

export const WrapperPageTitle = styled(Box)`
  margin-bottom: 24px;
`

export const WrapperTitleInputs = styled(Box)`
  margin-bottom: 8px;
`

export const WrapperInputsLatLong = styled(Box)`
  display: flex;
  gap: 16px;
`

export const WrapperReference = styled(Box)`
  margin-top: 24px;
  margin-bottom: 48px;
`