import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Input, Select, } from 'antd';
import { Button } from 'antd';
import { ColorPicker } from '../ColorPicker/ColorPicker.tsx';
// import { ZoneScheme } from '../../utils/validateForm.ts';
import { defaultZoneFluidType } from '../../utils/defaultZoneValues.ts';
import FormLabel from '../FormLabel/index.ts';

const fluidTypes = [
  { value: 'Oil', label: <span>Oil Zone</span> },
  { value: 'Gas', label: <span>Gas Zone </span> },
  { value: 'Transition', label: <span>Transition</span> },
  { value: 'Undefined', label: <span>Undefined</span> },
];

const FormFluidTypeZone = ({
  submitZoneFluidType,
  zoneBottom,
  zoneCount,
  currentZoneToEdit,
  isLoading,
  isLoadingCreate,
}: any) => {
  const [fluidTypeValue, setSelectFluidType] = useState('Undefined');
  const [_, setNewZoneName] = useState('Undefined');

  const formik = useFormik({
    initialValues: currentZoneToEdit || { ...defaultZoneFluidType, name: `Zone ${zoneCount}`, top: zoneBottom, fluidType: 'Undefined'},
    onSubmit: (values) => {
      submitZoneFluidType(values);
      // formik.resetForm({});
    },
    // validationSchema: ZoneScheme,
    enableReinitialize: true,
  });

  const { values, setFieldValue, handleSubmit, handleChange, handleBlur } = formik;

  useEffect(() => {
    if (zoneCount > 0 && values?.name === 'Zone 1') {
      setNewZoneName(`Zone ${zoneCount}`);
      setFieldValue('name', `Zone ${zoneCount}`);
    }
  }, [zoneCount]);

  const handleSelectChange = (value:string) => {
    setSelectFluidType(value);
    setFieldValue('fluidType', value);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="zones__form"
    >
      <FormLabel
        label="Zone Name"
        required
        data-cy="label-zone-name"
      >
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          name="name"
          value={values.name || `Zone ${zoneCount}`}
          defaultValue={currentZoneToEdit ? currentZoneToEdit.name : ''}
          className="input--zone"
          data-cy="input-zone-name"
        />
      </FormLabel>
      <br />
      <div style={{ width: 200 }}>
      <FormLabel
        label="Fluid Type"
        required
        data-cy="label-fluid-type"
      >
        <Select
          placeholder="Select X Axis"
          style={{ width: '100%', minWidth: '200px' }}
          value={fluidTypeValue}
          onChange={handleSelectChange}
        >
          {fluidTypes.map(option => (
            // @ts-ignore
            <Option key={option.label} value={option.value}>{option.value}</Option>
          ))}
        </Select>
      </FormLabel>
      <br />
        <div>
          <ColorPicker
            fluidTypeValue={fluidTypeValue}
            currentZoneToEdit={currentZoneToEdit}
            afterChange={setFieldValue}
          />
        </div>
      </div>
      <br/>
      <div style={{ width: 150 }}>
          <Button
            type="primary"
            htmlType="submit"
            data-cy="submit-zone"
            loading={isLoadingCreate}
          >
            Create zone 
          </Button>
          {isLoading ?
            <div style={{ 
              width: 250,
              padding: 10,
              marginTop: 30,
              borderRadius: 5,
              color: '#155724',
              border: 'solid 1px #fff',
              background: '#d4edda',
              textAlign: 'center'
              }}>
              Created zone sucessfully!
            </div>
            : ''
          }
      </div>
    </form>
  );
};
  
export default FormFluidTypeZone;
  