import React from 'react';

import {
  Icon,
  Popover,
  Select
} from 'antd';
import classNames from 'classnames';

import { filterOption } from '../../utils';

const { Option } = Select;

const SelectWrapper = ({ children, id, className, classLabel, label }) => {
  return (
    <div
      className={
        classNames(
          'home__modal-group',
          'select__wrapper',
          className && className === 'ethene' ? className='relative_ethene' : className,
          { [className]: className }
        )}
    >
      <label
        htmlFor={id}
        className={
          classNames(
            'match-relative-label',
            { 'match-relative-label': classLabel }
          )
        }
      >
        {label}&nbsp;&nbsp;
        {label === 'C4' ? (
          <Popover
            overlayClassName="popover"
            style={{ width: 200 }}
            content={<div><div>If there are no NC4 and IC4 curves,</div><div>you need at least select the C4 curve </div><div>that will be considered in this case.</div></div>}
            title={<p><Icon type="warning" style={{ color: '#ffd100' }} /> Warning</p>}
          >
            <div style={{ cursor: 'pointer' }}><Icon type="info-circle" /></div>
          </Popover>
        ) : null}
        {label === 'C5' ? (
          <Popover
            overlayClassName="popover"
            style={{ width: 200 }}
            content={<div><div>If there are no NC5 and IC5 curves,</div><div>you need at least select the C5 curve </div><div>that will be considered in this case.</div></div>}
            title={<p><Icon type="warning" style={{ color: '#ffd100' }} /> Warning</p>}
          >
            <div style={{ cursor: 'pointer' }}><Icon type="info-circle" /></div>
          </Popover>
        ) : null}
      </label>
      {children}
    </div>
  );
};

function SelectMnemonic({
  onChange, options, label, id, value, className, classLabel,
  selectWidth
}) {
  return (
    <>
      <SelectWrapper id={id} className={className} classLabel={classLabel} label={label}>
        <Select
          showSearch
          id={id}
          defaultValue={value || -1}
          value={value}
          style={{ width: selectWidth || 200 }}
          placeholder=""
          optionFilterProp="children"
          className={`cy-${className}`}
          onChange={onChange}
          onFocus={() => ({})}
          onBlur={() => ({})}
          onSearch={() => ({})}
          filterOption={(input, option) => filterOption(input, option)}
        >
          <Option value="-1" disabled style={{ color: '#ccc' }}>
            Select a mnemonic
          </Option>
          {options.map((option) => (
            <Option key={option.label} value={option.value}>{option.label}</Option>
          )
          )}
        </Select>
      </SelectWrapper>
    </>
  )
};

export default SelectMnemonic;
