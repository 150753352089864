/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React, { Component, useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';
import store from 'store';
import { get } from 'lodash';
import Exporting from 'highcharts/modules/exporting';
import { applyDefaultZoomToChart } from '../../../lib/charts';
import {
  syncronizeTooltip, getToolTipValues,
  disableDefaultZoom, displayZoomBtn, propagateZoom,
  disableDots, disableLabels, toggleTrack, toggleBalanceRatio,
  nFormatter, defaultCrossHair, defaultValuesToCrosshair,
  formattingMin
} from '../../../utils';
import { DEFAULT_SCALES } from '../../../utils/constants';
import GlobalCutOff from '../../../utils/GlobalCutOff';
import { useScales } from '../../../hooks/useScales';

import { getRawCurveData } from '../../../utils/getRawCurveData';

const curveTypes = ['gqr']

const updatedHeader = {
  scales: store.get('configScales') || { ...DEFAULT_SCALES },
  gqr: 0
};

const initialHeader = {
  gqr: 0
};

Exporting(Highcharts);

const HeaderChart = React.memo(() => {
  const wells = useSelector(state => state.wells);
  // --- Intital State
  const {
    currentScale, setHeaderState, headerState
  } = useScales(
    curveTypes,
    wells.currentWell,
    initialHeader
  );

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    gqrTrack: true,
  });

  const tooggleCallbacks = {
    gqr: toggleBalanceRatio,
  };

  const toggleTrackCurrentTrack = (event) => {
    const { dataset: { name, index } } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    callback(parseInt(index))(event);
    setToggle({
      ...tracks,
      isOpen: !tracks.isOpen,
      [name]: !trackName
    });
  };

  // --- Open modal with config or toggle track
  const dispatchModal = (typeOfTitle) => (event) => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const toggleTrackCurrentTrackCurrentTrack = (event) => {
    const { dataset: { name, index } } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0 && wells?.currentWell?.calculated?.gqr) {
      const {
        gqr
      } = wells?.currentWell?.calculated;
      const index = wells.selectedDepthIndex;
      const gqrValue = gqr && gqr?.data[index] ? gqr?.data[index] : 0;
      setHeaderState({
        ...headerState,
        gqr: gqrValue?.toFixed(3),
      });
    }

    if (!wells.currentWell) {
      setHeaderState({
        ...headerState,
        gqr: 0,
      });
    }

  }, [wells]);


  useEffect(() => {
    const gqrListener = ({ detail }) => {
      updatedHeader.scales.gqr = detail;
      headerState.scales.gqr = detail;
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener('gqr', gqrListener);

    return () => {
      document.removeEventListener('gqr', gqrListener);
    };
  }, []);

  const {
    gqr,
    scales
  } = headerState;

  return (
    <div
      className="balance-ratio"
      style={{
        height: '132px',
        justifyContent: 'flex-end'
      }}
    >
      <div
        className="character-title"
        style={{
          width: '160px',
          color: '#1B1BD1',
          borderColor: '#1B1BD1'
        }}
        onClick={dispatchModal('characterRatio')}
        data-cmd="openConfig"
        data-cy="open-config-gqr"
      >
        <div
          style={{ width: '140px' }}
          data-name="title"
          className="target-balanceRatio"
          data-cmd="openConfig"
        >
          <span
            data-cy="open-config-gqr-min"
          >
            {nFormatter(scales.gqr.min)}
          </span>
          <span
            className="title-characterRatio"
            data-cmd="openConfig"
          >
            GQR
            <span data-cmd="openConfig">
              {`(${gqr})`}
            </span>
          </span>
          <label className="show-checkbox-characterRatio container-checkbox">
            <input
              type="checkbox"
              checked={tracks.character}
              className="checkbox-input"
              data-name="character"
              data-index="1"
              data-cmd="toggle"
              onChange={toggleTrackCurrentTrack}
              data-cy="checkbox-character-ratio"
            />
            <span className="checkmark" style={{ backgroundColor: '#1B1BD1' }} />
          </label>
        </div>
        <span
          data-cy="open-config-cha-ratio-max"
        >
          {nFormatter(scales.gqr.max)}
        </span>
      </div>
    </div>
  );
});

const getDefaultSeries = (gqrData) => {
  const {
    gqr,
  } = store.get('configScales');

  const placeholderData = defaultValuesToCrosshair(gqrData, 0.1);
  return [
    {
      ...disableDots,
      fillOpacity: 0.5,
      name: 'gqr',
      type: 'spline',
      tooltip: {
        valueSuffix: ' ',
        enabled: false
      },
      fill: {
        color: '#fff'
      },
      data: placeholderData,
      lineColor: '#fff',
      min: gqr.min,
      max: gqr.max,
      yAxis: 0,
      zIndex: 3,
      lineWidth: 1.2,
      shadow: false,
      labels: {
        format: ' ',
        enabled: false
      },
      selected: true,
      index: 0
    },
    {
      ...disableDots,
      fillOpacity: 0.5,
      name: 'character-ratio',
      type: 'spline',
      tooltip: {
        valueSuffix: ' ',
        enabled: false
      },
      fill: {
        color: '#3fcf3f'
      },
      data: gqrData,
      lineColor: gqr.color,
      min: gqr.min,
      max: gqr.max,
      yAxis: 1,
      zIndex: 3,
      lineWidth: 1.2,
      shadow: false,
      labels: {
        format: ' ',
        enabled: false
      },
      selected: true,
      index: 1
    },
  ]
};


class GqrChart extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      selectedId: 0,
      plotBands: [],
      scatters: [],
      gqrData: props.gqrData,
      series: {
        0: true,
        1: true,
      }
    };
    this.balanceRef = React.createRef();
  }

  componentDidMount() {
    const { selectedId } = this.state;

    this.createChart();

    if (this.chart) {
      this.zoomId = applyDefaultZoomToChart(this.chart);
    }

    GlobalCutOff.registerInstances(
      'gqrChart',
      this.chart
    );

    // TODO refactor this workaround because with two windows we need to comunicate
    // theses tabs using some reactive way
    // this was made one day before alfa presentation
    this.timerId = window.setInterval(() => {
      const shoudlClose = !!store.get('opened-cross-plot-gqrToggle');
      if (shoudlClose === true) {
        this.chart.xAxis[0].removePlotBand(`selection${selectedId}`);
      }
    }, 100);

    document.addEventListener('removePlotBands', () => {
      const count = store.get('openedCrossPlots')
        ? parseInt(store.get('openedCrossPlots'))
        : 0;

      if (count) {
        this.chart.xAxis[0].removePlotBand(`selection${selectedId}`);
      }
    });

    // TODO refactor this syncronization
    document.addEventListener('sincronize-selected', event => {
      const { detail: { min, max } } = event;
      this.chart.xAxis[0].setExtremes(min, max, true, true);
      this.chart.redraw();
    });

    syncronizeTooltip('#gqr-chart');

    document.addEventListener('toggle-gqr', ({ detail: { index } }) => {
      toggleTrack(this)(index);
    });

    // Change scales
    document.addEventListener('gqr', ({ detail }) => {
      this.chart.yAxis[1].update({
        min: formattingMin(detail.min),
        max: detail.max
      });
      const serie = this.chart.series[1];
      serie.update({
        color: detail.color,
        lineColor: detail.color
      })
      serie.redraw();
    });
    
  }

  shouldComponentUpdate(nextProps) {
    const {
      gqrData, selectedWell, currentWell, min, max
    } = nextProps;

    const { props } = this;

    const beforeDepthData = get(getRawCurveData('depth', props.beforeWell), 'data', []);
    const currentDepthData = get(getRawCurveData('depth', nextProps.currentWell), 'data', []);

    if (
      selectedWell !== props.selectedWell
      || nextProps.selectedWell !== props.selectedWell
      || beforeDepthData !== currentDepthData
      || props.beforeWell?.gqr !== currentWell?.gqr
    ) {
      this.chart.update({
        series: getDefaultSeries(
          gqrData,
        )
      });
      this.zoomId = setTimeout(() => {
        this.chart.xAxis[0].setExtremes(min, max);
      }, 300);
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    window.clearInterval(this.timerId);
    // eslint-disable-next-line no-unused-expressions
    this.zoomId && clearTimeout(this.zoomId);
  }

  // TODO create a util this code exists in reasons charts
  selectingAreas = event => {
    event.preventDefault();
    const { customEventType, currentWell } = this.props;
    const { selectedId } = this.state;
    if (store.get('crossPlots')) {
      event.preventDefault();
      const axis = event.xAxis[0];
      const xAxis = this.chart.xAxis[0];

      xAxis.removePlotBand(`selection${selectedId}`);

      const count = store.get('openedCrossPlots')
        ? parseInt(store.get('openedCrossPlots'))
        : 0;
      store.set('openedCrossPlots', count + 1);

      const id = `selection${selectedId}`;
      const plotSelection = {
        from: axis.min,
        to: axis.max,
        color: 'rgba(51, 92, 173, 0.25)',
        id,
        zIndex: 20
      };

      xAxis.addPlotBand(plotSelection);

      const minValue = Math.floor(axis?.min?.toFixed());
      const maxValue = Math.floor(axis?.max?.toFixed());
      let minLabel = minValue;
      let maxLabel = maxValue;
      if (currentWell) {
        const depthData = currentWell.depth;
        minLabel = depthData[minLabel];
        maxLabel = depthData[maxLabel];
      }

      const togglePlotBox = new CustomEvent('showPlotBox', {
        detail: {
          type: customEventType,
          min: minValue,
          max: maxValue,
          minLabel,
          maxLabel
        }
      });
      document.dispatchEvent(togglePlotBox);
    } else {
      displayZoomBtn();
      propagateZoom(event);
    }
  }

  createChart = () => {
    const {
      id, gqrData
    } = this.props;

    const scales = store.get('configScales') || DEFAULT_SCALES;
    this.chart = Highcharts.chart(id, {
      chart: {
        zoomType: 'x',
        type: 'area',
        inverted: true,
        width: 162,
        marginTop: 2,
        marginBottom: 2,
        backgroundColor: window.color || '#fff',
        ...disableDefaultZoom,
        events: {
          selection: this.selectingAreas
        },
        animation: false,
        shadow: false
      },
      boost: {
        useGPUTranslations: true,
        usePreAllocated: true
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          enableMouseTracking: false,
          showInLegend: false
        }
      },
      exporting: { enabled: false },
      title: ' ',
      subtitle: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: [
        {
          minorGridLineWidth: 1,
          minorGridLineColor: '#E0E0E0',
          minorTickInterval: 1,
          showFirstLabel: true,
          showLastLabel: true,
          minPadding: 0.0,
          maxPadding: 0.0,
          lineWidth: 0,
          opposite: true,
          labels: {
            enabled: false
          },
          gridLineColor: '#a4a4a4',
          gridLineWidth: 1,
          tickLength: 0
        }
      ],
      yAxis: [
        {
          type: 'linear',
          minorTickInterval: 0.1,
          ...disableLabels,
          allowDecimals: true,
          index: 0,
          labels: {
            enabled: false
          },
          min: 0,
          max: 10
        },
        {
          type: 'linear',
          minorTickInterval: 0.1,
          ...disableLabels,
          allowDecimals: true,
          index: 1,
          labels: {
            enabled: false
          },
          min: scales.gqr.min,
          max: scales.gqr.max
        },
      ],
      tooltip: {
        ...defaultCrossHair,
        shared: true,
        formatter: getToolTipValues('gqr-chart')
      },
      series: getDefaultSeries(
        gqrData,
      )
    });
  }

  // componentWillUnmount() {
  //   window.clearInterval(this.timerId);
  // }

  render() {
    const { id } = this.props;
    return (
      <div
        className="chart__box-container"
        style={{ marginLeft: '10px' }}
      >
        <HeaderChart />
        <div
          id={id}
          style={{
            height: 'calc(100% - 132px)'
          }}
          data-cy="chart-balance-ratio"
        />
      </div>
    );
  }
}

GqrChart.defaultProps = {
  gqrColor: '#1B1BD1',
  id: 'gqr-chart',
  parentContainer: '.charts-container',
  customEventType: 'balanceRatioToggle'
};

export default React.memo(GqrChart);
