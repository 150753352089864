import styled from "styled-components";
import { TitleStyledProps } from "./types";


export const TitleStyled = styled.h1<TitleStyledProps>`
  font-weight: ${(props) => props.weight};
  font-size: ${(props) => props.size};
  margin: 0;
  color: #FFFFFF;
`
