import styled from "styled-components";
import TuneIcon from '@mui/icons-material/Tune';
import { Box } from '@mui/material';

export const FormStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const OverwriteMessage = styled(Box)`
  color: #EB3434;
  font-size: 16px;
`;

export const WrapperInputs = styled.div`
  gap: 8px;
`

export const WrapperLogSetSelect = styled.div`
  display: flex;
  gap: 8px;
`

export const TuneIconStyled = styled(TuneIcon)`
  && {
    transform: rotate(90deg);
  }
`

export const WrapperLoadedInterval = styled.div`
  display: flex;
  min-height: 287px;
  background-color: #111827;
  padding: 16px;
  border-radius: 8px;
  gap: 44px;

  > span:nth-child(1) {
    margin-left: 40px;
  }
`

export const WrapperLoadedIntervalInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-left: 34px;
`

export const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px 16px 24px;
  margin-top: 40px;
`