import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export const Progress = ({ size }: { size: number }) => {
  return (
    <Box sx={{ display: 'flex', zIndex: 600 }}>
      <CircularProgress
        size={size}
        sx={{
          color: '#fff',
          // (theme) =>
            // theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
      />
    </Box>
  );
}
