import React, {
  useEffect,
  useState
} from 'react';

import {
  Button,
  Steps
} from 'antd';
import { useFormik } from 'formik';
import omit from 'lodash.omit';
import { useDispatch } from 'react-redux';
import find from 'lodash.find';

import { getStatistcsConfig, receiveMetadata, updateCurrentWellData } from '../../../../actions/wells';
import { registerMovingAverage } from '../../../../api/statistics';
import { successNotification, errorNotification, warningNotification } from '../../../../utils';
import AlertError from '../AlertError';
import MiniSpinner from '../MiniSpinner';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import { useStatisticWizard } from './useStatisticsWizard';
import { useSelector } from 'react-redux';

const { Step } = Steps;

const getSteps = (FirstStep, SecondStep) => [
  {
    title: 'Selected Curves',
    content: FirstStep
  },
  {
    title: 'Selected Significance level',
    content: SecondStep
  }
];

const ModalStatistics = () => {
  const [curveKeyIndexDB, setCurveKeyIndexDB] = useState();
  const [errorMultipleSelect, setErrorMultipleSelect] = useState('');
  const [loading, setLoading] = useState(false);
  const [listOfCurvesCalculated, setListOfCurvesCalculated] = useState();
  const [listOfDefaultCurves, setListOfDefaultCurves] = useState([]);
  const wells = useSelector(state => state.wells);
  const urlParams = new URLSearchParams(window.location.search);
  const wellId = urlParams.get('wellId');

  const dispatch = useDispatch();
  const settingsCurve = wells?.metadata?.modules_parameters.STATISTICS?.settings;

  const wizardHook = useStatisticWizard();
  const {
    currentWell,
    setValidFormsNames,
    currentStep,
    setCurrent,
    gasCurvesValue,
    rangeIntervalValue,
    setGasCurves,
    formCollapseC1,
    formCollapseGQR,
    formCollapseC1Composition,
    formCollapseC2Composition,
    formCollapseC3Composition,
    formCollapseC4Composition,
    formCollapseC5Composition,
    curves,
    rangeMin,
    rangeMax,
    validFormsCurves,
    formCollapseC2,
    formCollapseC3,
    formCollapseC4,
    formCollapseIC4,
    formCollapseC5,
    formCollapseIC5,
    setWellKey,
    setIsLoadingStatistics,
    checkedTabs,
    invalidAllCurves,
    setCurves
  } = wizardHook;

  const formik = useFormik({
    initialValues: {
      gasCurves: undefined,
      rangeInterval: rangeIntervalValue || [],
      windowlength: formCollapseC1 && formCollapseC1.windowlength,
      checkBoxMetrics: formCollapseC1 && formCollapseC1.checkBoxMetrics,
      checkBoxModel: formCollapseC1 && formCollapseC1.checkBoxModel
    },
    enableReinitialize: true,
    onSubmit: onSubmitForm
  });

  const { handleSubmit, values, setFieldValue } = formik;

  useEffect(() => {
    if (settingsCurve) {
      const defaultSelectedCurves = [];
      const newListOfCurvesCalculated = Object.keys(settingsCurve).reduce((acc,curve) => {
        if (curve !== 'range_max' && curve !== 'range_min') {
          const formatedCurve = curve?.split('  ')?.[0];
          defaultSelectedCurves.push(formatedCurve);
          const config = settingsCurve[curve].curve_config;
          acc[formatedCurve] = config;
        }
        return acc;
      }, []);
      setListOfCurvesCalculated(newListOfCurvesCalculated);

      setCurves(defaultSelectedCurves);
      setValidFormsNames(defaultSelectedCurves);
      setListOfDefaultCurves(defaultSelectedCurves);
    }
  }, [settingsCurve]);

  useEffect(() => {
    if (curves && errorMultipleSelect) {
      setErrorMultipleSelect('');
    }
  }, [curves]);

  useEffect(() => {
    if (wellId) {
      setLoading(true);
      dispatch(
        getStatistcsConfig(wellId, () => setLoading(false))
      );
    }
  }, [wellId]);

  const nextStep = () => {
    if (!curves || (curves && !curves.length)) {
      setErrorMultipleSelect('You should select at least one gas curve!');
      return;
    }
    setCurrent(currentStep + 1);
  };

  const prev = () => {
    setCurrent(currentStep - 1);
  };

  // Get only values of curve
  const curvesMap = {
    formCollapseC1: 'c1',
    formCollapseC2: 'c2',
    formCollapseC3: 'c3',
    formCollapseC4: 'nc4',
    formCollapseC5: 'nc5',
    formCollapseIC4: 'ic4',
    formCollapseIC5: 'ic5',
    formCollapseC1Composition: 'C1Composition',
    formCollapseC2Composition: 'C2Composition',
    formCollapseC3Composition: 'C3Composition',
    formCollapseC4Composition: 'C4Composition',
    formCollapseC5Composition: 'C5Composition',
    formCollapseGQR: 'gqr'
  };

  const curvesType = {
    C1: 'c1',
    C2: 'c2',
    C3: 'c3',
    NC4: 'nc4',
    NC5: 'nc5',
    IC4: 'ic4',
    IC5: 'ic5',
    C1Composition: 'c1_perc',
    C2Composition: 'c2_perc',
    C3Composition: 'c3_perc',
    C4Composition: 'c4_perc',
    C5Composition: 'c5_perc',
    GQR: 'gqr'
  };

  function curveMnemonic(curve) {
    const curveType = curvesType[curve];
    const curvesComposition = ['c1_perc', 'c2_perc', 'c3_perc', 'c4_perc', 'c5_perc']

    if (curvesComposition.includes(curveType)) {
      return currentWell.calculated[curveType].mnemonic;
    }
    return currentWell.RAW[curveType].mnemonic;
  }

  function onSubmitForm() {
    if (invalidAllCurves.length !== 0) {
      warningNotification('Configure all curves')
      return;
    }

    const statisticsData = {
      wellId,
      currentStep,
      gasCurvesValue,
      rangeIntervalValue,
      formCollapseC1,
      formCollapseC1Composition,
      formCollapseC2Composition,
      formCollapseC3Composition,
      formCollapseC4Composition,
      formCollapseC5Composition,
      curves,
      rangeMin,
      rangeMax,
      validFormsCurves,
      formCollapseC2,
      formCollapseC3,
      formCollapseC4,
      formCollapseIC4,
      formCollapseC5,
      formCollapseIC5,
      formCollapseGQR,
      wellKey: currentWell.well_uid,
      setWellKey
    };
    try {
      if (currentWell && currentWell.well_uid) {
        // Get curves with data
        const currentCurves = [];

        // check the current active form with data
        [
          'formCollapseC1',
          'formCollapseC2',
          'formCollapseC3',
          'formCollapseC4',
          'formCollapseC5',
          'formCollapseIC4',
          'formCollapseIC5',
          'formCollapseC1Composition',
          'formCollapseC2Composition',
          'formCollapseC3Composition',
          'formCollapseC4Composition',
          'formCollapseC5Composition',
          'formCollapseGQR'
        ].filter(curve => {
          return statisticsData[curve] !== undefined && currentCurves.push(curve);
        });

        // Get all params like data, moving_average_type, window_length_value and so on.
        const normalizedPayload = currentCurves.map((curve) => {
          let currentCurve = curvesMap[curve];
          if (currentCurve === 'ic4') {
            currentCurve = 'ic4Normal';
          }
          if (currentCurve === 'ic5') {
            currentCurve = 'ic5Normal';
          }
          if (currentCurve === 'C1Composition') {
            currentCurve = 'c1Composition';
          }

          if (currentCurve === 'C2Composition') {
            currentCurve = 'c2Composition';
          }

          if (currentCurve === 'C3Composition') {
            currentCurve = 'c3Composition';
          }

          if (currentCurve === 'C4Composition') {
            currentCurve = 'nc4Composition';
            if (!currentWell[currentCurve]) {
              currentCurve = 'c4Composition';
            }
          }

          if (currentCurve === 'C5Composition') {
            currentCurve = 'nc5Composition';
            if (!currentWell[currentCurve]) {
              currentCurve = 'c5Composition';
            }
          }

          return {
            formType: !curve.includes('formCollapse') ? `formCollapse${curve}` : curve,
            ...statisticsData[curve],
            // data: currentWell[currentCurve]
          }
        });

        const payload = curves.reduce((acc, curve) => {
          const payloadCurve = find(normalizedPayload,
            tabName => {
              return (tabName.formType || '') === `formCollapse${curve}` || (tabName.formType || '') === curve
            }
          );

          if (curve === 'GQR') {
            payloadCurve.data = payloadCurve.data.map(value => Number((Number(value) * 1000)?.toFixed()));
          }
          if (payloadCurve && payloadCurve.formType) {
            const payloadCurveFormatted = omit(payloadCurve, 'formType');
              const payloadFormatted = {
                curve_mnemonic: curveMnemonic(curve),
                log_set_name: "RAW",
                settings: {
                  moving_average_type: payloadCurveFormatted.moving_average_type,
                  difference_type: payloadCurveFormatted.difference_type,
                  window_length_value: Number(payloadCurveFormatted.window_length_value),
                  sensitivity_value: payloadCurveFormatted.sensitivity_value ?? 0
                }
              }
              acc.push(payloadFormatted);
              return acc;
          }
        }, []);

        (async () => {
          try {
            setIsLoadingStatistics(true);

            const payloadFormatted = {
              new_module_name: "STATISTICS",
              well_uid: currentWell.well_uid,
              curves_settings: payload
            };

            const movingAverageResponse = await registerMovingAverage(payloadFormatted, currentWell.well_uid);
            const movingAverageData = movingAverageResponse.data.content.well_data.log_sets.STATISTICS.data;
            curves.map((curve, index) => {
              let significanceChartData;
              if (payload[index].difference_type === 'ABS') {
                significanceChartData = (movingAverageData || []).find(curveData => curveData?.type.toString() === `${curvesType[curve]}`);
              } else {
                significanceChartData = movingAverageData.find(curveData => curveData?.type.toString() === `${curvesType[curve]}`);
              }
              const setterFunctionName = `${`setChartData` + curve}`;
              wizardHook[setterFunctionName]({
                formType: `formCollapse${curve}`,
                data: significanceChartData?.data || []
              });
              setIsLoadingStatistics(false);
            })
            dispatch(receiveMetadata(movingAverageResponse.data?.content?.well_metadata));
            dispatch(updateCurrentWellData(movingAverageResponse.data?.content?.well_data));
            successNotification(`Configuration created successfully!`);
          } catch(e) {
            setIsLoadingStatistics(false);
            errorNotification('Configuration error')
            console.log('catch', e)
          }
        })();
      }
      // Select significance level tab
      const tabs = document.querySelectorAll('.ant-tabs-tab');
      if (tabs && tabs.length && tabs[1]) {
        tabs[1].click();
      }
    } catch(e) {
      console.log('Error trying saving statistics values -->', e);
    }
  };

  const onSelectCurves = (value) => {
    setFieldValue('gasCurves', value);
    setGasCurves(value);
  };

  // const defaultCriticalTypes = ['c1_diff', 'c2_diff', 'c3_diff', 'nc4_diff', 'ic4_diff', 'nc5_diff', 'ic5_diff'];
  // const normalizedCurve = defaultCriticalTypes.map(item => item.split('_')[0].toLocaleUpperCase());
  // const curvesConfiguration = normalizedCurve.reduce((acc, curve, index) => {
  //   const config = {
  //     [`${curve}  MAIN`]: {
  //         window_length_value: settingsCurve[`${curve}  MAIN`]?.curve_config?.window_length_value,
  //         difference_type: settingsCurve[`${curve}  MAIN`]?.curve_config?.difference_type,
  //         moving_average_type: settingsCurve[`${curve}  MAIN`]?.curve_config?.moving_average_type,
  //         sensitivity_value: settingsCurve[`${curve}  MAIN`]?.curve_config?.sensitivity_value
  //       }
  //     }
  //   acc.push(config);
  //   return acc;
  // }, [])
  const allSteps = getSteps(
    <FirstStep
      onSelectCurves={onSelectCurves}
      values={values}
      errorMultipleSelect={errorMultipleSelect}
      errorMessage={<AlertError messageTitle="Invalid Gas Curves" description={errorMultipleSelect} />}
      settingsCurve={settingsCurve}
      listOfDefaultCurves={listOfDefaultCurves}
    />,
    <SecondStep values={values} curvesConfiguration={listOfCurvesCalculated} />
  );

  return (
    <>
      {loading ? (
        <MiniSpinner />
      ) : null}
      {!loading ? (
        <>
          <div className="statistic-modal-wrapper__indicator">
            <div className="statistic-modal-curves statistic-modal__indicator">
              <Steps current={currentStep}>
                {allSteps.map(item => (
                  <Step key={item.title} title={item.title}  />
                ))}
              </Steps>
            </div>
          </div>
          <div className="statistic-modal-wrapper-content">
            <form onSubmit={handleSubmit}>
              <div className="statistic-modal-curves__content">
                {allSteps[currentStep].content}
              </div>
              <div className="statistic-modal__actions">
                {currentStep < allSteps.length - 1 && (
                  <Button data-cy="next-step-statistics" type="primary" onClick={nextStep}>Next</Button>
                )}
                {currentStep > 0 && (
                  <>
                    <Button type="button" htmlType="button" style={{ margin: '0 8px' }} onClick={prev}>
                      Previous
                    </Button>
                    <button
                      style={{
                        backgroundColor: '#1890ff',
                        fontSize: 14,
                        border: 'none',
                        width: 85,
                        color: '#fff',
                        borderRadius: 3,
                        cursor: 'pointer'
                      }}
                      type="submit"
                      data-cy="save-step-statistic"
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ModalStatistics;
