import { useState } from 'react';

export enum ViewModes {
  LIST,
  FORM
};

export const useToggle = () => {
  const [viewMode, setViewMode] = useState<ViewModes>(ViewModes.LIST);

  const setListViewMode = () => {
    setViewMode(ViewModes.LIST);
  };

  const setFormViewMode = () => {
    setViewMode(ViewModes.FORM);
  };
  
  return {
    viewMode,
    setListViewMode,
    setFormViewMode,
  }
};
