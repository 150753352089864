// @ts-ignore
import find from 'lodash.find';

// @ts-ignore
export const openCrossPlots = (type, zones, currentWell, fluidTypeForm) => (zoneInfo) => {
  // @ts-ignore
  const zone = find(currentWell?.zone_sets, z => z?.label === zoneInfo?.label);
  const sensorType = currentWell?.ethene_sensor ?? '';
  const label = zone?.label || zoneInfo?.label;
  const max = zone?.bottom_depth_index || zoneInfo?.bottom_depth_index;
  const min =  zone?.top_depth_index || zoneInfo?.top_depth_index;
  const minLabel = zone?.top || zoneInfo?.top;
  const maxLabel = zone?.bottom || zoneInfo?.bottom;
  const detail = {
    type,
    label,
    min,
    max,
    minLabel,
    maxLabel,
    sensorType,
    openCrossPlotsEthene,
    zone
  };


  const openWindow = new CustomEvent('showPlotBox', { detail });
  document.dispatchEvent(openWindow);
};

// @ts-ignore
export const openMolarAnalysis = (type, zonesState, currentWell, wells) => (zoneInfo) => {
  // @ts-ignore
  const zone = (zonesState || []).find((z: any) => z.id === zoneInfo.id);
  const minLabel = zone.top;
  const maxLabel = zone.bottom;
  const { wellId } = currentWell;
  
  const currentWellWithPathFile = wells.find((well: any) => well.wellId === zone.wellId);

  const detail = {
    ...zone,
    pathFile: currentWellWithPathFile && currentWellWithPathFile.pathFile ? currentWellWithPathFile.pathFile.replaceAll('/', '@') : '',
    type,
    minLabel,
    maxLabel,
    wellId
  };

  const openWindow = new CustomEvent('plotBoxMolarAnalysis', { detail });
  document.dispatchEvent(openWindow);
};

export const openCrossPlotsEthene = (type: any, zoneItems: any, currentWell: any) => (zoneInfo:any) => {
  // @ts-ignore
  const zone = find(zoneItems || currentWell.zones, z => z.id === zoneInfo.id);
  const minLabel = zone.top;
  const maxLabel = zone.bottom;
  const { well_uid: wellId } = currentWell;

  const detail = {
    type,
    minLabel,
    maxLabel,
    wellId
  };

  const openWindow = new CustomEvent('plotBoxEthene', { detail });
  document.dispatchEvent(openWindow);
};

export const openRelativeEthene = (
  type: any,
  zoneItems: any,
  currentWell: any,
  setAlertNoDataEthene: any
) => (zoneInfo: any) => {
  if (!currentWell || currentWell.checkedSensor) {
    setAlertNoDataEthene(true);
    return;
  }
  openCrossPlotsEthene(type, zoneItems, currentWell)(zoneInfo);
};
