import axios from 'axios';
import store from 'store';
import { BASE_URL } from './wells';

export const API_URL = `${BASE_URL}/gold-statistics`;
export const NEW_API_URL = 'https://wellex-wells-oofp55ubjq-uc.a.run.app/wellex-wells/v1/wells';
const GPC_URL = "https://wellex-wells-oofp55ubjq-uc.a.run.app" // Ask for base API URL from gcp

const Api = axios.create({
  baseURL: API_URL
});

const config = {
  headers: {
    'access-token': store.get('token')
  }
};

const config_wellex_well = {
  headers: {
    'Authorization': `Bearer ${store.get('token')}`
  }
};

export const fetchMovingAverage = async () => {
  const response = await Api.post(API_URL);
  return response;
};


export const calculateReduceNoise = async (value) => axios.post(
  `${API_URL}/statistics-reduce-noise`,
  {
    data: value.data,
    moving_average_type: value.moving_average_type,
    difference_type: value.difference_type,
    window_length_value: Number(value.window_length_value),
    sensitivity_value: value.sensitivity_value,
    curve: value.curve,
  },
  {
    headers: {
      'access-token': store.get('token'),
      'Content-type': 'application/json'
    }
  }
);

export const registerMovingAverage = async (data, wellUid) => axios.post(
  `${NEW_API_URL}/${wellUid}/statistics`,
  data,
  {
    headers: {
      Authorization: `Bearer ${store.get('token')}`
    }
  }
);

export const calculateCriticalValue = async (data, wellUid) => axios.post(
  `${NEW_API_URL}/${wellUid}/critical-values`,
  data,
  {
    headers: {
      Authorization: `Bearer ${store.get('token')}`
    }
  }
)

export const postNormalizeGas = async (data) => axios.post(
  `${API_URL}/curve-normalization`,
  data,
  config
)

export const postGasQualityRatio = async (data) => axios.post(
  `${API_URL}/gas-quality-ratio`,
  data,
  config
)

export const postCalculationApiAndGor = async (data) => axios.post(
  `${API_URL}/api-gor-calculation`,
  data,
  config
)

export const PostGqcPayload = async (data, token) => axios.post(
  `${GPC_URL}/wellex-wells/v1/wells/${data.well_uid}/gqc`,
  data,
  {
    headers: {
      Authorization: `Bearer ${store.get('token') || token}`
    }
  }
)

export default Api;
