import axios from 'axios';
import map from 'lodash.map';
import { BASE_URL } from './wells';

export const WELLEX_DATA_TEMP_URL = `${BASE_URL}/wellex-data-integrations`;

export function fetchInWellWatcher(token, source, uid, uidWell) {
    const resource = `${WELLEX_DATA_TEMP_URL}/wells/${source}/${uid}/${uidWell}/data`;
    return axios.get(
        resource,
        {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json'
            }
        }
    );
}

export function fetchInWellWatcherSites(token) {
    return axios.get(`${WELLEX_DATA_TEMP_URL}/wells`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        }
    });
}

export function fetchInWellWatcherSite(source, uid, uidWell, token) {
    return axios.get(`${WELLEX_DATA_TEMP_URL}/wells/${source}/${uid}/${uidWell}/data`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json'
        }
    });
}

export function isWellexAPIRunning() {
    return axios.get(`${WELLEX_DATA_TEMP_URL}/healthcheck`, {
        headers: {
            'Content-type': 'application/json'
        }
    });
}