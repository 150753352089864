/* eslint-disable */
import React, { Component, useEffect, useState, useCallback } from 'react';

import * as cutoffActions from 'actions/cutoff';
import * as wellPhasesActions from 'actions/wellphases';
import * as wellActions from 'actions/wells';
import * as zoneActions from 'actions/zone';
import StatisticsModule from '../Statistics';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  message,
  Popconfirm,
  Radio,
  Select,
  Switch as SwitchComponent,
  Tabs
} from 'antd';
import {
  fetchInWell,
  getURLToDownloadLas
} from 'api/wells';
import {
  fetchInWellWatcher,
} from 'api/wellwatcher';

import {
  postNormalizeGas,
  postGasQualityRatio
} from 'api/statistics';

import folderIcon from 'assets/folder.svg';
import removeIcon from 'assets/trash-white.svg';
import classNames from 'classnames';
import BitSizeForm from 'components/BitSize/BitSizeForm';
import BitSizeClipboard from 'components/BitSizeClipboard';
import ConfigModal from 'components/ConfigModal';
import ConflictData from 'components/ConflictData';
import CreateWell from 'components/CreateWell';
import GeneratePlotButton from 'components/GeneratePlotButton';
import { DataSourceModal } from 'components/InWellModal/DataSourceModal';
import SelectModalInWell from 'components/InWellModal/SelectModalInWell';
import LasPreview from 'components/LasPreview';
import ModalExportData from 'components/ModalExportData';
import ModalSensorType from 'components/ModalSensorType';
import Overlay from 'components/Overlay';
import {
  generateBalanceCrossplots,
  generateGeochemistryCharts
} from 'components/PlotBox';
import PopupSensorType from 'components/PopupSensorType';
import SelectMnemonic from 'components/SelectMnemonic';
import SelectMnemonicSensorType
  from 'components/SelectMnemonic/SelectMnemonicSensorType';
import SelectMnemonicSensorTypeC2s
  from 'components/SelectMnemonic/SelectMnemonicSensorTypeC2s';
import SelectReferenceCurve from 'components/SelectReferenceCurve';
import SidebarNavigation from 'components/SidebarNavigation';
import Spinner from 'components/Spinner';
import SwipeRightNav from 'components/SwipeRightNav';
import TopBottomSlider from 'components/TopBottomSlider';
import WellPhasesModal from 'components/WellPhasesModal';
import SelectedWell from 'components/SelectedWell';
import Highcharts from 'highcharts';
import { Parser } from 'json2csv';
import {
  formatParsedArrayToWellObject,
  processingInWell,
  processingLas
} from 'lib/Well';
import clone from 'lodash.clone';
import filter from 'lodash.filter';
import get from 'lodash.get';
import keys from 'lodash.keys';
import map from 'lodash.map';
import Papa from 'papaparse';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import {
  Route,
  Routes,
  Outlet
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import store from 'store';
import {
  applyZoom,
  arrayFlipToObject,
  avoidZero,
  calculateAPIC2C3,
  calculateAPIGravityC2C34,
  calculateGasComposition,
  calculateGORC2C3,
  calculateGORC2C34,
  calculateSteps,
  checkIfThereAreTopAndBottom,
  generateWellOptions,
  generateWellOptionsFromApi,
  getUnit,
  modalStyleCreateWell,
  setOnIndexDB,
  setUnit,
  successNotification,
  warningNotification,
  syncronizeDepthZoom,
  getForm
} from 'utils';

import {
  CREATE,
  defaultCurveKeys,
  defaultMnemonics,
  defaultMnemonicsSensorType,
  defaultMnemonicsSensorTypeC2s,
  UPDATE
} from 'utils/constants';
import {
  aliasesToCsv,
  createRowsToCsv,
  crossPlotsKeysToSynchronizeWithDepth,
  csvToJSON,
  fieldsToCsv,
  fluidTypeCsvNames,
  geoChemistryCsvNames
} from 'utils/Csv';
import {
  calculateBalanceRatio,
  calculateC1Normalized,
  calculateCharacterRatio,
  calculateWetness
} from 'utils/GasesCalculations';
import GlobalCutOff from 'utils/GlobalCutOff';
import {
  removeAllZones
} from '@geowellex/shared-zone/src/global-utils/Zones';
import {
  applyZoneToCharts,
} from '@geowellex/shared-zone/src/utils/applyZoneToCharts';
import uuid from 'uuid/v1';
import ListWellAfterFocus from '../../components/ListWellAfterFocus';

import { DownloadOutlined } from '@ant-design/icons';

import SyncOldWellsModal from '../../components/SyncOldWellsModal';
import StatisticButton from '../Statistics/components/StatisticButton';
import {
  useDispatch
} from 'react-redux';
import Dashboard from '../../components/Dashboard';
import { Importdata } from '@geowellex/shared-importdata';
import { CHROMA_C1, scales as DEFAULT_SCALES } from '../../utils/constants';
import { parseLasFile, saveParsedData, updateWellData, parseWellDataIntegration } from '../../api/import-data';
import SelectWell from '../../components/SelectWell';
import Alert from '../../components/Alert';
import { calculateBitSize } from 'utils/GasesCalculations';
import { after } from 'lodash';
import { formatZones } from '../../utils/Zones';
import wells from '../../reducers/wells';
import { RealtimeSwitch } from '@geowellex/shared-hooks';
import { formatCutoffsForRedux } from '../../utils/cutoffs';

const { TabPane } = Tabs;

const initialData = {
  depth: [],
  BH: [],
  WH: [],
  totalGas: [],
  C1C2: [],
  c1: [],
  c2: [],
  c3: [],
  c4: [],
  ic4: [],
  nc4: [],
  ic5: [],
  nc5: [],
  c2h6: [],
  c2h4: [],
  c2s: [],
  c1Composition: [],
  c2Composition: [],
  c3Composition: [],
  nc4Composition: [],
  nc5Composition: [],
  isotopeData: [],
  wetnessRatio: [],
  balanceRatioData: [],
  characterRatioData: [],
  c1Byc2: [],
  ic4BynC4: [],
  ic5BynC5: [],
  rop: [],
  slopeFactor: [],
  wob: [],
  gammaRay: [],
  bitSize: [],
  oldWellNames: [],
  syncedWells: false,
  isInWellTableIsVisible: false,
  calculating: false,
  temporaryWellPhases: [],
  loadingWellPhases: false,
  GWITSML: false,
  downloadLasFileIsProcessing: false,
  fileParse: null,
};

const calculateTotalCarbon = (c1, c2, c3, c4, c5) => {
  return (c1 || 0) + (c2 || 0) + (c3 || 0) + (c4 || 0) + (c5 || 0);
};

const checkIfExistsWell = (wells) => (wellName) => {
  return wells.find((well) => well.wellName === wellName)
};

const initialState = {
  token: '',
  // @TODO move it to the modal state
  rotating: null,
  processing: false,
  modalIsOpen: false,
  etheneRadio: null, // MS = 0, FID = 1
  step: 1,
  errors: {
    top: null,
    bottom: null,
    wellName: null
  },
  defaultcurves: {
    depth: 'Depth',
    rop: 'ROP',
    gas_ratio: 'Gas Ratio',
    total_gas: 'Total Gas',
    c1: 'C1',
    c2: 'C2',
    c3: 'C3',
    c4: 'C4',
    nc4: 'nc4',
    nc5: 'nc5',
    c5: 'C5',
    character_ratio: 'Character Ratio',
    balance_ratio: 'Balance Ratio',
    wetness_ratio: 'Wetness Ratio',
    isotope: 'Isotope',
    chartType: '',
    flow: 'flow'
  },
  errorCurves: {
    depth: null,
    c1: null,
    c2: null,
    c3: null,
    c4: null,
    c5: null,
    ic4: null,
    ic5: null,
    c3h6: null,
    c2h4: null,
    c2s: null,
    flow: null
  },
  top: 0,
  bottom: 0,
  // Plotbox
  chartType: null,
  // Upload well
  loadingWell: false,
  curves: [],
  current: 0,
  curvesKeys: defaultCurveKeys,
  currentWell: {
    name: '',
    lasVersion: ''
  },
  checkedSensor: false,
  // @TODO move it to the wells reducer
  data: initialData,
  max: 0,
  min: 0,
  selectedMax: 0,
  selectedMin: 0,
  unit: '',
  wellNames: [],
  selectedWell: -1,
  rangeTop: 0,
  rangeBottom: 0,
  rangeTopIndex: 0,
  rangeBottomIndex: 0,
  visibleTooltip: false,
  showClass: 'fadeOut',
  visibleTooltip2: false,
  showClass2: 'fadeOut',
  hasConflictData: false,
  hadConflict: false,
  newWellName: '',
  hasNewWell: false,
  conflictingType: '',
  bitSize: [],
  lasFile: '',
  showImportLas: true,
  prepareToParseInCSV: [],

  modalInWellIsVisible: false,
  modalSensorIsVisible: false,
  modalExportDataIsVisible: false,
  checkingToken: false,
  loading: false,
  isModalVisibleWellPhases: false,
  showOverlay: false,
  gasData: {
    curves: {
      c1: [],
      c2: [],
      c3: [],
      nc4: [],
      ic4: [],
      c4: [],
      nc5: [],
      ic5: [],
      c5: [],
    },
    weights: {
      c1: '',
      c2: '',
      c3: '',
      nc4: '',
      ic4: '',
      c4: '',
      nc5: '',
      ic5: '',
      c5: '',
    }
  },
  typeActionImportData: 'CREATE',
  curveNormalizeError: false,
  msgErrorGasData: false,
  metadata: null,
  isOpenConfigImport: false,
  currentUnit: '',
  alert: {
    isOpen: false,
    message: '',
    severity: ''
  }
};

const WatchCutoffFromCutoffViewChart = ({ currentWell, wellId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newCutoffs = store.get('guideline-cutoff');
      if (currentWell && wellId && newCutoffs) {
        dispatch(cutoffActions.receiveCutOffs(newCutoffs));
        store.set('shouldResetCutoffs');
        store.set('guideline-cutoff');
      }
    }, 2500);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentWell, wellId]);

  return null;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.uploadRef = React.createRef();
    this.uploadRefInWell = React.createRef();
    this.initLocalStorageData();
    this.sliderRef = React.createRef();
  }

  toggleModalToSyncOldWells = (open = true) => {
    this.setState({
      openModalToSync: open,
    });
  }

  syncOldWellsToCloud = () => {
    console.log('on after open');
  }

  setOldWellNames = (oldWellNames) => {
    this.setState({
      oldWellNames: oldWellNames,
    });
  }

  setSyncWells = () => {
    this.setState({
      syncedWells: true,
    });
  }

  setOverlay = (showOrHide = false) => {
    this.setState({
      showOverlay: showOrHide,
    });
  }

  async componentDidMount() {
    const scales = store.get('configScales');

    if (!scales?.wh_ratio || !scales?.c1_perc || !scales?.[CHROMA_C1]?.color) {
      store.set('configScales', DEFAULT_SCALES);
    }

    this.props.wellAction.checkOldWellsAndStoreToCloud(
      this.toggleModalToSyncOldWells,
      this.setOldWellNames,
      this.setSyncWells,
    );

    this.setState({
      loadingWell: true,
    });
    try {
      if (store.get('email') && store.get('token')) {
        const wells = await this.props.wellAction.listWells();
        this.setState(() => ({
          ...generateWellOptionsFromApi(wells),
          wells,
        }));
        // if (!wells.length) {
        //   setOnIndexDB('wells', {});
        // }
        store.set('isQualityControlOn', false);
        this.setState({
          loadingWell: false,
        });
      }
    } catch (e) {
      console.log('error when fetch userInfo and wellsites', e);
    } finally {
      this.setState({
        loadingWell: false,
      });
    }
  }

  componentWillUnmount() {
    this.props.wellAction.receiveWells([]);
    this.props.wellAction.filterWell(-1);
    // this.props.history.go(-1);
  }


  initLocalStorageData = () => {
    if (!store.get('querys')) {
      store.set('querys', []);
    }
    if (!store.get('crossPlots')) {
      store.set('crossPlots', false);
    }
  }

  onChangeUpload = event => {
    const scope = this;
    const file = event.target.files[0];
    scope.setState({ fileParse: file });
    const reader = new FileReader();

    const wells = this.props.wells.items;
    const thereIsConflict = checkIfExistsWell(wells);

    this.setState(initialState);

    // TODO refactor this function has much responsability
    reader.onload = async function () {
      const lines = this.result;
      scope.setState({
        curvesKeys: {
          ...defaultCurveKeys,
          c2h4: null,
          c3h6: null,
          c2s: null
        },
        lasFile: lines,
        showOverlay: true
      });

      // Check if is a CSV
      // if (file.type && file.type.includes('csv')) {
      //   // Enable to process with web workers
      //   const worker = !!Papa.WORKERS_SUPPORTED;
      //   try {
      //     csvToJSON(lines, { worker }).then(({ errors, data, valid }) => {
      //       console.log("csv error ====>", errors);
      //       console.log("errors ====>", JSON.stringify(errors));
      //       console.log("csv error ====>", errors);
      //       if (!valid) {
      //         alert(
      //           `Error: ${errors[0].message}.\nInvalid .csv file.\nContact the support: suporte@geowellex.com`
      //         );
      //         return;
      //       }
      //       formatParsedArrayToWellObject(data, thereIsConflict, scope);
      //     });
      //   } catch(e) {
      //     console.log("catch csv error", e)
      //   }
      // } else {
      //   processingLas(lines, file, thereIsConflict, scope);
      // }

      var metadata;

      var formData = new FormData();
      formData.append("las_file", file);
      const token = store.get('token');

      try {
        const { data: { content: metadataResponse } } = await parseLasFile(formData, token)
        scope.props.wellAction.receiveMetadata(metadataResponse);
        scope.setState({ metadata: metadataResponse });
        metadata = metadataResponse
        scope.setState({ metadata: metadataResponse });
        scope.setState({ indexUnit: metadataResponse?.index_unit });

        scope.setState({
          showOverlay: false,
          modalInWellIsVisible: false,
          isOpenConfigImport: true,
          metadata,
          alert: {
            isOpen: true,
            message: 'Parsed las file successfully',
            severity: 'success'
          }
        });
      } catch (e) {
        console.log('error: ', e)
        scope.setState({
          alert: {
            isOpen: true,
            message: 'Error when parsing las file',
            severity: 'error'
          }
        })
      }
    }
    if (file) {
      reader.readAsText(file);
    }
  }

  handleCloseAlert = () => {
    this.setState({
      alert: {
        isOpen: false,
        message: '',
        severity: ''
      }
    })
  }

  handleCloseDrawer = () => {
    this.setState({
      isOpenConfigImport: false
    })
  }

  uploadFile = () => {
    this.uploadRef.current.value = null;
    this.uploadRef.current.click();
  }

  uploadFileInWell = () => {
    this.uploadRefInWell.current.value = null;
    this.uploadRefInWell.current.click();
  }

  onChangeUploadInWell = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    // reader.onload = function () {
    // }
  }

  propagateTotalDetphZoom = (min, max) => {
    this.setState({
      selectedMin: min,
      selectedMax: max
    });
    syncronizeDepthZoom(Highcharts, min, max);
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  changeWellRanges = value => {
    const [rangeTopIndex, rangeBottomIndex] = value;
    const depth = this.state.data[this.state.curvesKeys.depth];
    const top = depth[rangeTopIndex];
    const bottom = depth[rangeBottomIndex];

    this.setState(() => ({
      rangeTop: top,
      rangeBottom: bottom,
      rangeTopIndex,
      rangeBottomIndex
    }));
  }

  selectMnemnonics = field => value => {
    const topAndBottom = {};
    const { data } = this.state;

    if ((field === 'depth' || value === 'depth_(ft)') && data && data[value]) {
      topAndBottom.top = data[value][0];
      topAndBottom.bottom = data[value].slice(-1)[0];
      const firstDepthValues = data[value].slice(0, 100);
      const step = calculateSteps(firstDepthValues);
      const swappedKeyValues = arrayFlipToObject(data[value]);

      // Get the step
      this.setState((state) => ({
        step: step || state.step,
        swappedKeyValues,
        rangeTop: topAndBottom.top,
        rangeBottom: topAndBottom.bottom,
      }));
    }
    // else if (field === 'depth' && !JSONData[value]) {
    //   alert('Please select a valid Depth curve!');
    //   return;
    // }

    this.setState(state => ({
      curvesKeys: { ...state.curvesKeys, [field]: value },
      errorCurves: {
        ...state.errorCurves,
        [field]: null
      },
      rangeTop: topAndBottom.top,
      rangeBottom: topAndBottom.bottom,
      ...topAndBottom
    }));
  }

  onChangeEtheneRadio = (e) => {
    this.setState((prev, state) => {
      if (prev.etheneRadio !== e.target.value) {
        let curves = {
          depth: '',
          c1: '',
          c2: '',
          c3: '',
          c4: '',
          nc4: '',
          nc5: '',
          c5: '',
          c2h4: '',
          c3h6: '',
          c2s: ''
        }
        return {
          etheneRadio: e.target.value,
          errorCurves: { ...state.errorCurves, curves }
        }
      }
    });
  }

  normalizeMnemonics = () => {
    this.closeModal();
  }

  next = () => {
    const { top, bottom, current, wellNames, currentWell, hadConflict } = this.state;

    const { wells } = this.props;

    // try to avoid if someone wrote already well and avoid duplicate
    // ensure that everytime to when to click in next check if there are conflict
    const thereIsConflict = checkIfExistsWell(wells.items)(currentWell.well);
    if (thereIsConflict && current === 0 && !hadConflict) {
      this.setState({ hasConflictData: true, hadConflict: true });
      return;
    }

    if (
      current === 0 && !currentWell.well
    ) {
      this.setState({
        errors: {
          wellName: 'This field is required'
        }
      });
      return;
    }

    this.setState({
      hadConflict: true,
      hasConflictData: false,
      current: current + 1
    });
  }

  stepModal = (conflictingType, newWell) => {
    if (conflictingType === UPDATE) {
      this.setState(state => ({
        hasConflictData: false,
        hadConflict: true,
        conflictingType: UPDATE,
        current: state.current,
        currentWell: {
          ...state.currentWell,
          bitSize: [...get(this.props.wells, 'currentWell.bitSize', []),]
        }
      }));
    } else if (conflictingType === CREATE) {
      this.setState(state => ({
        hasConflictData: false,
        newWellName: newWell,
        conflictingType: CREATE,
        currentWell: {
          ...state.currentWell,
          well: newWell,
          bitSize: null,
          zones: {}
        }
      }));
    }
  }

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
    if (this.state.checkedSensor) {
      this.onChangeSwitchSensor();
    }
    if (this.state.checkedNormalizeGasData) {
      this.onChangeSwitchNormalizeGasData();
    }
  }

  goPrevBitSizes = (bitSize) => {
    const current = this.state.current - 1;
    this.setState(state => ({
      currentWell: {
        ...state.currentWell,
        bitSize
      },
      current
    }));
  }

  startProcess = () => {
    const {
      JSONData, curvesKeys, currentWell,
      bottom, top, rangeBottom, rangeTop, curves, current, gasData
    } = this.state;
    if (gasData.weights.c1 > 100 || gasData.weights.c2 > 100 || gasData.weights.c3 > 100 ||
      gasData.weights.c4 > 100 || gasData.weights.ic4 > 100 || gasData.weights.nc4 > 100 ||
      gasData.weights.c5 > 100 || gasData.weights.ic5 > 100 || gasData.weights.nc5 > 100 &&
      current === 2
    ) {
      this.setState({
        msgErrorGasData: true
      })
      return;
    }

    const { curve, well, rangeTopIndex, rangeBottomIndex } = this.state;
    const { checkedSensor } = this.state;
    const oldData = JSONData.data ? JSONData.data : JSONData;
    const depthKey = curvesKeys.depth;
    const checkedSensorValuesFID = this.state.checkedSensor && (!c2h4 || !c3h6);
    const checkedSensorValuesMS = this.state.checkedSensor && !curvesKeys.c2s;

    const {
      c1: c1CurveKey, c2: c2CurveKey, c3: c3CurveKey, c4: c4CurveKey,
      nc4: nc4CurveKey, ic4: ic4CurveKey, c5: c5CurveKey, nc5: nc5CurveKey, ic5: ic5CurveKey,
    } = curvesKeys;

    if (this.state.etheneRadio === 1) {
      if (!depth || !c1 || !c2 || !c3 || checkedSensorValuesFID) {
        this.setState({
          errorCurves: {
            depth: !depth ? 'Depth field is required!' : null,
            c1: !c1 ? 'C1 field is required!' : null,
            c2: !c2 ? 'C2 field is required!' : null,
            c3: !c3 ? 'C3 field is required!' : null,
            // c4: !c4 ? 'nC4 field is required!' : null,
            // c5: !c5 ? 'nC5 field is required!' : null,
            c2h4: !c2h4 ? 'c2h4 field is required!' : null,
            c3h6: !c3h6 ? 'C3H6 field is required!' : null,
          }
        });
        return;
      }
    } else {
      if (!depthKey || !c1CurveKey || !c2CurveKey || !c3CurveKey || checkedSensorValuesMS) {
        this.setState({
          errorCurves: {
            depth: !depthKey ? 'Depth field is required!' : null,
            c1: !c1CurveKey ? 'C1 field is required!' : null,
            c2: !c2CurveKey ? 'C2 field is required!' : null,
            c3: !c3CurveKey ? 'C3 field is required!' : null,
            c2s: !curvesKeys.c2s ? 'c2s field is required!' : null
          }
          // FID requiresd c1h4 and c3h6
          // MS required only c2s
          /*
            <Radio value={1} className="item-radio-group-sensor">FID</Radio>
            <Radio value={0} className="item-radio-group-sensor">MS</Radio>
          */
        });
        return;
      }
    }

    if (!c4CurveKey && !nc4CurveKey && !ic4CurveKey) {
      this.setState((s) => ({
        errorCurves: {
          ...(s.errorCurves || {}),
          c4: !c4CurveKey ? 'C4 field is required!' : null,
        }
      }));
      return;
    }
    if (!c5CurveKey && !nc5CurveKey && !ic5CurveKey) {
      this.setState((s) => ({
        errorCurves: {
          ...(s.errorCurves || {}),
          c5: !c5CurveKey ? 'C5 field is required!' : null,
        }
      }));
      return;
    }

    this.setState({
      processing: true,
      errorCurves: {
        depth: null,
        c1: null,
        c2: null,
        c3: null,
        c4: null,
        nc4: null,
        nc5: null,
        ic4: null,
        ic5: null,
        c5: null,
        c2h4: null,
        c3h6: null,
        c2s: null
      }
    }, () => {
      setTimeout(async () => {
        // Now I'm know the limits to top / bottom
        // I will find the indext and cut all
        // TODO create a function to validate
        // TODO add number validation or mask on these fields
        const defaultRangeValues = {
          top: { index: null, value: null },
          bottom: { index: null, value: null }
        };

        // // TODO Moove it out
        const depth = this.state[depthKey];
        const topAndBottom = {
          top: {
            index: rangeTopIndex,
            value: depth[rangeTopIndex]
          },
          bottom: {
            index: rangeBottomIndex,
            value: depth[rangeBottomIndex]
          }
        }

        const curveData = this.state[depthKey];
        let cuttedData = {};

        if (topAndBottom.bottom.index >= 0) {
          cuttedData = keys(oldData).reduce((acc, key) => {
            if (oldData && oldData[key] && oldData[key].slice) {
              const currentColumnData = oldData[key].slice(
                topAndBottom.top.index,
                topAndBottom.bottom.index + 1
              );
              acc[key] = currentColumnData || [];
            }
            return acc;
          },
            initialData
          );
        }

        const data = cuttedData;

        const c1Byc2 = [];
        const ic5BynC5 = [];
        const ic4BynC4 = [];
        const characterRatioData = [];
        const balanceRatioData = [];
        const wetnessRatio = [];
        const isotopes = [];
        const rop = [];

        const c1Composition = [];
        const c2Composition = [];
        const c3Composition = [];
        const c4Composition = [];
        const c5Composition = [];

        const ic5Values = [];
        const ic4Values = [];
        const ic4Normal = [];
        const ic5Normal = [];

        const api = [];
        const gor = [];
        const slopeFactor = [];

        const gammaRay = [];

        const c2c3Ratios = [];
        const c3c2Ratios = [];
        const flow = [];

        // Alias that we get from the select mnemonic
        const c1Key = curvesKeys.c1;
        const c2Key = curvesKeys.c2;
        const c3Key = curvesKeys.c3;
        const c4Key = curvesKeys.c4;
        const nc4Key = curvesKeys.nc4;
        const ic4Key = curvesKeys.ic4;
        const c5Key = curvesKeys.c5;
        const nc5Key = curvesKeys.nc5;
        const ic5Key = curvesKeys.ic5;
        const c2sKey = curvesKeys.c2s;

        const ropKey = curvesKeys.rop;

        const gammaRayKey = curvesKeys.gamma_ray;

        const flowInOutKey = curvesKeys.flow;

        const totalGas = [];
        const totalCarbonWithDepth = [];
        const totalCarbon = [];

        const c1Normalized = [];
        const gqr = [];
        const c2s = [];

        const { bitSize } = currentWell;
        const c1RawData = c1Key ? data && data[c1Key] : [];
        const c2RawData = c2Key ? data && data[c2Key] : [];
        const c3RawData = c3Key ? data && data[c3Key] : [];
        const c4RawData = c4Key ? data && data[c4Key] : [];
        const nc4KeyRawData = nc4Key ? data && data[nc4Key] : [];
        const ic4KeyRawData = ic4Key ? data && data[ic4Key] : [];
        const c5RawData = c5Key ? data && data[c5Key] : [];
        const nc5RawData = nc5Key ? data && data[nc5Key] : [];
        const ic5RawData = ic5Key ? data && data[ic5Key] : [];
        /*
        *  Check curves to normalize and calculate
        */
        const curvesToExtraction = this.state.gasData && this.state.gasData.weights && this.state.gasData.weights;
        let defaultC1Data = c1RawData;
        let defaultC2Data = c2RawData;
        let defaultC3Data = c3RawData;
        let defaultC4Data = c4RawData;
        let defaultIc4Data = ic4KeyRawData;
        let defaultNc4Data = nc4KeyRawData;
        let defaultC5Data = c5RawData;
        let defaultIc5Data = ic5RawData;
        let defaultNc5Data = nc5RawData;

        if (curvesToExtraction.c1 || curvesToExtraction.c2 || curvesToExtraction.c3 ||
          curvesToExtraction.c4 || curvesToExtraction.nc4 || curvesToExtraction.ic4 ||
          curvesToExtraction.c5 || curvesToExtraction.ic5 || curvesToExtraction.nc5) {
          try {
            const gasData = {
              ...this.state.gasData,
              curves: {
                c1: c1RawData.map(v => v || 0),
                c2: c2RawData.map(v => v || 0),
                c3: c3RawData.map(v => v || 0),
                c4: c4RawData.map(v => v || 0),
                nc4: nc4KeyRawData.map(v => v || 0),
                ic4: ic4KeyRawData.map(v => v || 0),
                c5: c5RawData.map(v => v || 0),
                ic5: ic5RawData.map(v => v || 0),
                nc5: nc5RawData.map(v => v || 0),
              },
              weights: {
                c1: curvesToExtraction.c1 ? curvesToExtraction.c1 / 100 : 1,
                c2: curvesToExtraction.c2 ? curvesToExtraction.c2 / 100 : 1,
                c3: curvesToExtraction.c3 ? curvesToExtraction.c3 / 100 : 1,
                c4: curvesToExtraction.c4 ? curvesToExtraction.c4 / 100 : 1,
                ic4: curvesToExtraction.ic4 ? curvesToExtraction.ic4 / 100 : 1,
                nc4: curvesToExtraction.nc4 ? curvesToExtraction.nc4 / 100 : 1,
                c5: curvesToExtraction.c5 ? curvesToExtraction.c5 / 100 : 1,
                nc5: curvesToExtraction.nc5 ? curvesToExtraction.nc5 / 100 : 1,
                ic5: curvesToExtraction.ic5 ? curvesToExtraction.ic5 / 100 : 1,
              },
            };
            const response = await postNormalizeGas(gasData);
            defaultC1Data = response.data.c1;
            defaultC2Data = response.data.c2;
            defaultC3Data = response.data.c3;
            defaultC4Data = response.data.c4;
            deaultIc4Data = response.data.ic4;
            deaultNc4Key = response.data.nc4;
            defaultC5Data = response.data.c5;
            defaultIc5Data = response.data.ic5;
            defaultNc5Data = response.data.nc5;
          } catch (e) {
            console.log('e', e);
          }
        };

        // TODO refactor it and move it out from this class
        for (let i = 0; i <= c1RawData.length; i++) {
          const gammaRayValue = gammaRayKey ? data[gammaRayKey][i] : 0;
          gammaRay.push(gammaRayValue);

          const flowInOutValue = flowInOutKey ? data[flowInOutKey][i] : 0;
          flow.push(flowInOutValue);

          let c1 = c1Key ? data[c1Key][i] : 0;
          let c2 = c2Key ? data[c2Key][i] : 0;

          const ropValue = ropKey ? data[ropKey][i] : 0;
          rop.push(ropValue);

          const normalC5Key = nc5Key || curvesKeys.c5;
          let nc5 = data && data[normalC5Key] && Array.isArray(data[normalC5Key]) && data[normalC5Key][i] ? data[normalC5Key][i] : 0;
          let ic5 = data && ic5Key && data[ic5Key] ? data[ic5Key][i] : 0;
          // Avoid to broken when there is no ic5
          ic5BynC5.push(
            isNaN(ic5 / nc5) ? 0 : (ic5 / nc5)
          );

          const normalC4Key = nc4Key || c4Key;
          let nc4 = data && data[normalC4Key] && Array.isArray(data[normalC4Key]) ? data[normalC4Key][i] : 0;
          let ic4 = ic4Key ? data[ic4Key][i] : 0;

          // Avoid to broken when there is no ic5
          ic4BynC4.push(
            isNaN(ic4 / nc4) ? 0 : ic4 / nc4
          );

          let c3 = c3Key ? data[c3Key][i] : 0;
          c1 = c1 || 0;
          c2 = c2 || 0;
          c3 = c3 || 0;
          ic4 = ic4 || 0;
          nc4 = nc4 || 0;
          ic5 = ic5 || 0;
          nc5 = nc5 || 0;

          const totalGasKey = curvesKeys.total_gas;
          const currentTotalGas = totalGasKey ? data[totalGasKey][i] : 0;
          totalGas.push(currentTotalGas);

          const currentTotalCarbon = calculateTotalCarbon(c1, c2, c3, nc4 + ic4, nc5 + ic5);
          const depth = data[curvesKeys.depth];
          const currentDepth = depth[i];

          totalCarbonWithDepth.push([currentTotalCarbon, avoidZero(currentDepth)]);
          totalCarbon.push(currentTotalCarbon);

          ic4Normal.push(ic4);
          ic5Normal.push(ic5);

          // iC4
          ic4Values.push([
            [0, avoidZero(currentDepth),],
            [ic4, avoidZero(currentDepth),],
          ]);

          // iC5
          ic5Values.push([
            [0, avoidZero(currentDepth),],
            [ic5, avoidZero(currentDepth),],
          ]);

          const c2c3Ratio = c2 === 0 || c3 === 0 ? 'N/A' : (c2 / c3)?.toFixed(3);
          const c3c2Ratio = c3 === 0 || c2 === 0 ? 'N/A' : (c3 / c2)?.toFixed(3);

          c2c3Ratios.push(c2c3Ratio);
          c3c2Ratios.push(c3c2Ratio);

          if (c2sKey) {
            c2s.push(data[c2sKey][i]);
          }

          // TODO leo need create a function to refactor it
          // All equations here was revised with Gabriel
          const getGasComposition = calculateGasComposition(c1, c2, c3, nc4, nc5, ic4, ic5);

          const gasComp1 = getGasComposition(c1);
          const gasComp2 = getGasComposition(c2);
          const gasComp3 = getGasComposition(c3);
          const gasComp4 = getGasComposition(nc4 + ic4);
          const gasComp5 = getGasComposition(nc5 + ic5);

          c1Composition.push(gasComp1);
          c2Composition.push(gasComp2);
          c3Composition.push(gasComp3);
          c4Composition.push(gasComp4);
          c5Composition.push(gasComp5);

          // to populate in reason chart
          if (gasComp1 && gasComp2) {
            c1Byc2.push(gasComp1 / gasComp2);
          } else {
            c1Byc2.push(0);
          }

          const wetnessValue = calculateWetness(gasComp1, gasComp2, gasComp3, gasComp4, gasComp5);
          wetnessRatio.push(wetnessValue);

          const ballance = calculateBalanceRatio(gasComp1, gasComp2, gasComp3, gasComp4, gasComp5);
          balanceRatioData.push(ballance);

          const characterRatio = calculateCharacterRatio(gasComp3, gasComp4, gasComp5);
          characterRatioData.push(characterRatio);

          const c2ByC3SlopeFactor = gasComp2 == 0 || gasComp3 == 0 ? 0 : gasComp2 / gasComp3;
          const c3ByC4SlopeFactor = gasComp3 == 0 || gasComp4 == 0 ? 0 : gasComp3 / gasComp4;
          const c4ByC5SlopeFactor = gasComp4 == 0 || gasComp5 == 0 ? 0 : (gasComp4 / gasComp5);
          const currentSlopeFactor = c2ByC3SlopeFactor + c3ByC4SlopeFactor + c4ByC5SlopeFactor == 0 ? 0 : (c2ByC3SlopeFactor + c3ByC4SlopeFactor + c4ByC5SlopeFactor) / 3;
          slopeFactor.push(currentSlopeFactor);

          const isotopeKey = curvesKeys.isotope;
          if (isotopeKey && data[isotopeKey]) {
            isotopes.push(data[isotopeKey][i]);
          }

          // ---------- API and GOR calculated ---------------
          const c4 = ic4 + nc4;
          const apiC2C3 = calculateAPIC2C3(c2, c3);
          const apiC2C3C4 = calculateAPIGravityC2C34(c2, c3, c4);
          const apiCalulated = {
            c2c3: apiC2C3,
            c2c34: apiC2C3C4,
          };
          const gorC2C3 = calculateGORC2C3(c2, c3);
          const gorC2C34 = calculateGORC2C34(c2, c3, c4);
          const gorCalculated = {
            c2c3: gorC2C3,
            c2c34: gorC2C34,
          };

          api.push(apiCalulated);
          gor.push(gorCalculated);

          // Calculate C1Normalized
          const c1NormalizedValue = currentWell.bitSize.reduce((acc, currentBitSize) => {
            if (currentDepth <= currentBitSize.bottom && currentDepth >= currentBitSize.top) {
              acc = calculateC1Normalized(
                currentBitSize.bitSize,
                ropValue,
                c1,
                flowInOutValue
              );
            }
            return acc;
          }, null);
          c1Normalized.push(c1NormalizedValue);
        }

        data.sensortType = this.state.etheneRadio;
        data.c2s = c2s;
        data.c1Normalized = c1Normalized;
        data.gqr = gqr;
        data.ic4Normal = ic4Normal;
        data.ic5Normal = ic5Normal;
        data.totalCarbonWithDepth = totalCarbonWithDepth || [];
        data.c1Byc2 = c1Byc2 || [];
        data.ic4BynC4 = ic4BynC4 || [];
        data.ic5BynC5 = ic5BynC5 || [];
        data.wetnessRatio = wetnessRatio || [];
        data.balanceRatioData = balanceRatioData || [];
        data.characterRatioData = characterRatioData || [];
        data.c1Composition = c1Composition || [];
        data.c2Composition = c2Composition || [];
        data.c3Composition = c3Composition || [];
        data.nc4Composition = c4Composition || [];
        data.nc5Composition = c5Composition || [];
        data.gor = gor || [];
        data.api = api || [];
        data.slopeFactor = slopeFactor || [];
        data.gammaRay = gammaRay || [];
        data.ic4 = ic4Values || [];
        data.ic5 = ic5Values || [];
        data.c2c3Ratios = c2c3Ratios;
        data.c3c2Ratios = c3c2Ratios;
        data.rop = rop;
        data.isotope = isotopes;
        data.flow = flow || [];
        data.totalCarbon = totalCarbon;
        data.totalGas = totalGas;
        data.depth = data[depthKey];
        data.c1 = defaultC1Data;
        data.c2 = defaultC2Data;
        data.c3 = defaultC3Data;
        data.c4 = defaultC4Data;
        data.nc4 = defaultNc4Data;
        data.ic4 = defaultIc4Data;
        data.c5 = defaultC5Data;
        data.nc5 = defaultNc5Data;
        data.ic5 = defaultIc5Data;
        data.bitSize = bitSize;

        data.c2h4 = data[curvesKeys.c2h4];
        data.c3h6 = data[curvesKeys.c3h6];

        const wellKey = uuid();

        // This should be here because we have the new value of data when the top and bottom is applyed
        let end = 0;
        if (data && data[depthKey]) {
          end = data[depthKey].length - 1;
        }
        const minusSelected = 300;
        const min = end - 300;

        const { well, version, curve } = this.state;

        const depthCurve = this.state.curve[depthKey];
        const unit = depthCurve && depthCurve.unit ? depthCurve.unit.toLowerCase() : '';
        store.set('unitOfMeansurement', unit);

        const wellName = this.state.currentWell.well;
        const oldNames = generateWellOptions(this.props.wells.items).wellNames;

        const isUpdate = this.state.conflictingType === UPDATE;
        const { selectedWell } = this.props.wells;
        const { zoneAction, zone } = this.props;

        const newWell = {
          wellId: isUpdate ? selectedWell : wellKey,
          bitSize,
          wellInfo: well,
          version,
          curve,
          curvesNames: Object.keys(curve),
          wellName,
          min,
          max: end - minusSelected,
          maxDepth: end - minusSelected,
          selectedMin: min,
          selectedMax: end,
          sensorType: this.state.etheneRadio,
          unit,
          current: 0,
          selectedWell: selectedWell,
          wellNames: [...oldNames, wellName,],
          zones: isUpdate ? zone.items : [],
          cutOffs: {},
          rangeTop: 0,
          rangeBottom: 0,
          curvesKeys,
          indexCurve: curve.indexCurve || '',
          indexUnit: curve.indexUnit || '',
          nameWell: curve.nameWell || '',
          mnemonicList: curve.mnemonicList || '',
          uid: curve.uid || '',
          uidWell: curve.uidWell || '',
          uidWellbore: curve.uidWellbore || '',
          unitList: curve.unitList || '',
          checkedSensor: this.state.checkedSensor,
        };

        /**
         * ----------- Should separate rawData from calculatedData ---------------
         */
        const rawCurves = this.state.curves ? Object.keys(this.state.curves) : [];
        let rawData = rawCurves.reduce((acc, rawCurveName) => {
          if (data && data[rawCurveName]) {
            acc[rawCurveName] = data[rawCurveName];
          }
          if (!data && data[rawCurveName]) {
            acc[rawCurveName] = data[rawCurveName] || null;
          }
          return acc;
        }, {});

        rawData = {
          curvesKeys: this.state.curvesKeys,
          ...rawData,
        };

        /**
         * ---------- Should return all keys that are not raw key only calculated -----------
         */
        const dataKeys = Object.keys(data);
        const calculatedKeys = dataKeys.filter(dataKey => !rawCurves.includes(dataKey));

        /**
         * ---------- Should mount a object with calculated data -----------
         */
        const calculatedCurvesData = calculatedKeys.reduce((acc, calculatedCurve) => {
          if (data && data[calculatedCurve]) {
            acc[calculatedCurve] = data[calculatedCurve];
          }
          return acc;
        }, {});
        const calculatedData = { ...calculatedCurvesData, ...newWell };

        GlobalCutOff.registerDefaultData({
          ...calculatedCurvesData,
          ...newWell,
          rawData
        });

        const actionMessage = isUpdate ? 'updated' : 'created';
        // TODO move it out to utils/Zones.js
        this.setState(newWell, async () => {
          // Starts the zoom from the zero value
          applyZoom(0, 200);
          syncronizeDepthZoom(Highcharts, 0, 200);

          const zones = zone.items;
          zones.map(zone => removeAllZones(zone.id, Highcharts));

          this.props.zoneAction.receiveZones([]);
          this.props.zoneAction.deleteAll();

          if (this.state.conflictingType !== CREATE) {
            // check if the zones are within the new range of the new updated well data
            const currentZones = get(newWell, 'zones', zone.items);
            const filteredZones = Object.keys(currentZones).reduce((acc, zoneKey) => {
              const currentZone = currentZones[zoneKey];

              // check if the this zone is in a valid range of depth
              const thereIsValidTop = newWell.depth.filter(currentDepth => {
                const [roundNumber,] = String(currentDepth).split('.');
                return roundNumber === currentZone.top;
              });
              // check if the this zone is in a valid range of depth
              const thereIsValidBottom = newWell.depth.filter(currentDepth => {
                const [roundNumber,] = String(currentDepth).split('.');
                return roundNumber === currentZone.bottom;
              });

              // if the zones are in a valid range of the new well data ok update then
              if (thereIsValidBottom.length && thereIsValidTop.length) {
                const depthData = get(this.props.wells, 'depthData', []);
                // find by depth the index of bottom within highcharts instance
                const bottomX = depthData.findIndex(value => {
                  const [currentDepth,] = String(value.y).split('.');
                  if (currentDepth === currentZone.bottom) {
                    return value.y;
                  }
                });
                // find by depth the index of top within highcharts instance
                const topX = depthData.findIndex(value => {
                  const [currentDepth,] = String(value.y).split('.');
                  if (currentDepth === currentZone.top) {
                    return value.y;
                  }
                });
                const updatedZone = {
                  ...currentZone,
                  bottomX,
                  topX
                };
                acc[zoneKey] = updatedZone;
              }

              return acc;
            }, {});
          }

          const afterCreateOrUpdate = (currentWellId) => {
            this.submitBitSize(false, isUpdate ? 'update' : 'create')({
              bitSize: this.state.bitSize,
              wellIdFromRequest: currentWellId
            });
            this.setOverlay(false);

            this.setState((old => ({
              ...old,
              selectedWell: currentWellId,
              wellId: currentWellId,
            })));

            // this.props.wellAction.receiveWell(newWell);
            map(newWell.zones, zone => applyZoneToCharts('total-gas')(zone, Highcharts.charts));
            zoneAction.receiveZones(newWell.zones);

            /**
             * TODO find why we need to dispatch a event to change cutoffs, why it is not updating there
             * the temporary solution was dispatch a event to notify  autocomplete scope
             */
            const detail = {
              well: newWell
            };

            const changeCutoffs = new CustomEvent('changeCutoffs', { detail });
            document.dispatchEvent(changeCutoffs);

            // TODO store the well on localStorage
            successNotification(
              `${currentWell.well} ${actionMessage} successfully!`,
              'Now you are able to select this well to plot the curves.'
            );

            this.setState({
              processing: false,
              modalInWellIsVisible: false,
              isInWellTableIsVisible: false
            });
          };

          this.setOverlay(true);
          if (isUpdate) {
            if (
              !this.props.wells.currentWell || this.props.wells.currentWell &&
              !this.props.wells.currentWell.rawFileName
            ) {
              const conflictedWellWithFileName = this.props.wells.items.find(w => w.wellName === currentWell.well) || {};

              const wellWithData = {
                ...this.props.wells.currentWell,
                ...conflictedWellWithFileName,
                rawFileName: `data_${conflictedWellWithFileName.id}_raw`,
                calculatedFileName: `data_${conflictedWellWithFileName.id}_calculated`,
              };

              this.props.wellAction.updateWell(
                wellWithData,
                this.props.wells.selectedWell,
                rawData,
                calculatedData,
                store.get('token'),
                afterCreateOrUpdate,
              );
              this.setState({
                modalInWellIsVisible: false,
                isInWellTableIsVisible: false
              });
              this.closeModal();
              return;
            }
            this.props.wellAction.updateWell(
              this.props.wells.currentWell,
              this.props.wells.selectedWell,
              rawData,
              calculatedData,
              store.get('token'),
              afterCreateOrUpdate,
            );
          } else {
            const isValidRawCurves = data && data.c1 && data.c1.length > 1 &&
              data && data.c2 && data.c2.length > 1 &&
              data && data.c3 && data.c3.length > 1 &&
              data && data.ic4 && data.ic4.length > 1 &&
              data && data.nc4 && data.nc4.length > 1 &&
              data && data.ic5 && data.ic5.length > 1 &&
              data && data.nc5 && data.nc5.length > 1 &&
              data && data.totalGas && data.totalGas.length > 1;
            const isAllZero = data.totalGas && data.totalGas.every(item => item === 0);
            if (isValidRawCurves && !isAllZero) {
              try {
                const responseGQR = await postGasQualityRatio(
                  {
                    c1: data.c1.map(item => item < 0 ? 0 : item),
                    c2: data.c2.map(item => item < 0 ? 0 : item),
                    c3: data.c3.map(item => item < 0 ? 0 : item),
                    ic4: data.ic4.map(item => item < 0 ? 0 : item),
                    nc4: data.nc4.map(item => item < 0 ? 0 : item),
                    ic5: data.ic5.map(item => item < 0 ? 0 : item),
                    nc5: data.nc5.map(item => item < 0 ? 0 : item),
                    total_gas: ((data.totalGas || []).map(item => item <= 0 ? 0 : item).filter(item => item != null)).slice(0, data.c1.length)
                  }
                );

                if (responseGQR && responseGQR.data && responseGQR.data.gqr) {
                  const calculatatedWithGQR = {
                    ...calculatedData,
                    gqr: responseGQR.data.gqr,
                  };
                  this.props.wellAction.createBucket(
                    newWell.wellName,
                    rawData,
                    calculatatedWithGQR,
                    store.get('token'),
                    afterCreateOrUpdate,
                  );
                  this.props.zoneAction.receiveZones([]);
                  this.props.zoneAction.deleteAll();
                }
              } catch (error) {
                warningNotification('Internal server error: GQR could not be calculated!')
                console.log('error', error);
              }
            } else {

              this.props.wellAction.createBucket(
                newWell.wellName,
                rawData,
                calculatedData,
                store.get('token'),
                afterCreateOrUpdate,
              );
              isAllZero ? warningNotification(`GQR could not be calculated. There is no total gas! `) : warningNotification('GQR could not be calculated!');
            }
          }

          this.setState({
            modalInWellIsVisible: false,
            isInWellTableIsVisible: false,
            rangeBottom: data.depth[data.depth.length - 1],
            rangeTop: depth[0],
          });
          this.closeModal();
        });
      }, 200);
    });
  }
  closeWell = () => {
    this.setState({
      rotating: null,
      data: initialData,
      selectedMax: 0,
      selectedMin: 0,
      unit: '',
      processing: false,
      max: 0,
      min: 0,
      modalIsOpen: false,
      curves: [],
      current: 0,
      rangeTop: 0,
      rangeBottom: 0,
      currentWell: {
        name: '',
        lasVersion: ''
      },
      errors: {
        top: null,
        bottom: null,
        wellName: null
      },
      curveKeys: '',
      bitSize: [],
      hasConflictData: false,
      hasNewWell: false,
      GWITSML: false,
      downloadLasFileIsProcessing: false,
    }, () => {
      this.uploadRef.current.value = null;
      this.closeModal();
    });
  }

  loadWell = async (wellKey) => {
    this.setState({ loadingWell: true });
    setTimeout(() => {
      this.setOverlay(true);
    }, 300);

    const { wellAction, zoneAction, zone, wells: { wellsWithPathFile } } = this.props;

    const currentWell = this.props?.wells?.items?.find((w) => w?.uid === wellKey);

    store.set('wellId', wellKey);
    this.listWellPhases(currentWell);
    const authToken = store.get('token');

    if (wellKey === -1) {
      this.props.wellAction.filterWell(-1);
    } else {
      if (this.props.wells && this?.props?.zone?.items?.length) {
        map(this.props?.zone?.items || [], (zone) => {
          removeAllZones(zone?.uuid, Highcharts);
        });
        zoneAction.deleteAll();
      }

      const wellWithMetadata = await this.props.wellAction.wellMetadata(wellKey);
      const zoneData = formatZones(wellWithMetadata.zone_sets);
      // console.log('loadwell: ', wellWithMetadata);
      const cutoffSettings = wellWithMetadata?.modules_parameters?.gqc?.settings?.cutoff_settings;
      const cutoffsFormatted = formatCutoffsForRedux(cutoffSettings);
      this.props.cutoffAction.receiveCutOffs(cutoffsFormatted);
      await wellAction.receiveMetadata(wellWithMetadata);
      await wellAction.listWell(wellKey, authToken);
      await wellAction.getStatistcsConfig(wellKey);
      await zoneAction.receiveZones(zoneData);
      map(zoneData, zone => applyZoneToCharts('total-gas')(zone, Highcharts.charts));

      this.setState({
        loadingWell: false,
        wellKeySelected: wellKey,
        showOverlay: false
      });

      // --------------------------- MOUNTING CRITICAL AREAS -------------------------------

      // 1. accessing current well
      const currentWell = this.props.wells.currentWell;
      GlobalCutOff.connectToRedux(wellActions.receiveWell, currentWell, this.props.wells);
      GlobalCutOff.registerDefaultData({
        ...currentWell
      });
      GlobalCutOff.registerFilters(
        currentWell.wellId,
        cutoffsFormatted,
        true,
        currentWell
      );
      // see in currentWell?.modules_parameters?.STATISTICS?.settings
      // should return ['C1  MAIN', 'C2  MAIN']
      const mnemonics = currentWell?.modules_parameters?.STATISTICS?.settings ? Object.keys(
        currentWell?.modules_parameters?.STATISTICS?.settings
      ) : [];

      // TODO check with backend c1_perc_diff and gqr_diff
      // DEFINE the default types to associate with mnemonics c1 to c5 and c1_perc to c5_perc and gqr_diff
      const curvesNames = ['C1', 'C2', 'C3', 'NC4', 'IC4', 'NC5', 'IC5'];
      const defaultCriticalTypes = ['c1_diff', 'c2_diff', 'c3_diff', 'nc4_diff', 'ic4_diff', 'nc5_diff', 'ic5_diff'];

      // go through each statistics data until get data related with each mnemonic
      // should return a object with
      // { criticalAreasC1: [], criticalAreasC2: [] etc... }
      const mountingCriticalAreas = currentWell?.log_sets?.STATISTICS?.data?.reduce((acc, item) => {
        // c1_diff
        const currentType = defaultCriticalTypes.find(type => type === item?.type);
        if (currentType && item?.type) {
          // transform c1_diff to C1
          const curveName = item?.type?.split('_')?.[0].toUpperCase();

          // accessing all curves settings
          const curvesSettings = currentWell?.modules_parameters?.STATISTICS?.settings;

          //  find current mnemonic to access via settings
          const currentMnemonic = mnemonics.find(c => c.includes(curveName));

          // accessing settings to see critical_value
          const settingMnemonicCurve = currentWell?.modules_parameters?.STATISTICS?.settings?.[currentMnemonic];
          // create the lines to mark selectedDepth
          const criticalAreaValues = item?.data?.reduce((acc, criticalArea, index) => {
            acc.push({
              depthIndex: index,
              criticalAreaValue: criticalArea,
              shouldShow: criticalArea >= settingMnemonicCurve.critical_value,
              color: '#FF2727',
              curveName
            });
            return acc;
          }, []);
          acc[`criticalAreas${curveName}`] = criticalAreaValues;
        }
        return acc;
      }, {});

      this.props.wellAction.receiveCriticalAreas(mountingCriticalAreas);
      // --------------------------- MOUNTING CRITICAL AREAS -------------------------------
    }

    setOnIndexDB('wellKey', wellKey);
    this.setState({
      loadingWell: true,
      ...currentWell,
      ...generateWellOptions(currentWell),
      selectedWell: wellKey,
    }, () => {
      if (wellKey === -1) {
        this.props.wellAction.receiveWells(this.props.wells.items);
      }

      /**
       * TODO find why we need to dispatch a event to change cutoffs, why it is not updating there
       * the temporary solution was dispatch a event to notify  autocomplete scope
       */
      const detail = {
        well: currentWell,
      };

      const changeCutoffs = new CustomEvent('changeCutoffs', { detail });
      document.dispatchEvent(changeCutoffs);

      this.setState({ loadingWell: false, bitSize: this.props.wellphases?.items[0]?.data ?? [] });
    });
    this.setOverlay(false);
  }

  selectWell = wellKey => {
    this.setState({ loadingWell: true }, () => {
      setTimeout(() => {
        this.loadWell(wellKey);
      }, 500);
    });
  }

  changeWellName = (event) => {
    event.persist();
    this.setState(state => ({
      currentWell: {
        ...state.currentWell,
        well: event.target.value
      },
      errors: {
        ...state.errors,
        wellName: null
      }
    }));
  }

  // TODO refactor it
  changeRanges = (key) => ({ target }) => {
    const value = target.value.replace(/,/g, '.');
    this.setState((state) => ({
      [key]: value,
      errors: {
        ...state.errors,
        [key]: ''
      }
    }));
  }

  // TODO implement aquisition step
  removeWell = async () => {
    const changeCutoffs = new CustomEvent('deleteCuttOffs');
    document.dispatchEvent(changeCutoffs);

    const { selectedWell } = this.props.wells;

    const zones = this.props.zone.items;
    // Removing all zones from each chart
    zones.map(zone => removeAllZones(zone.uuid, Highcharts));
    await this.props.zoneAction.receiveZones([]);

    await this.props.zoneAction.deleteAll();

    const authToken = store.get('token');

    const response = await this.props.wellAction.removeWell(
      authToken,
      selectedWell,
    );
    if (response?.data?.message) {
      this.setState({
        alert: {
          isOpen: true,
          message: response?.data?.message,
          severity: 'success'
        },
        loadingWell: false,
      });
    }

    const wells = this.props.wells.items;
    const newWells = filter(wells, well => well.id !== selectedWell);

    /**
       * TODO find why we need to dispatch a event to change cutoffs, why it is not updating there
       * the temporary solution was dispatch a event to notify  autocomplete scope
       */
    const detail = {
      well: { cutOffs: {} }
    };


    this.setState({
      data: initialData,
      selectedWell: -1,
      JSONData: null,
      showOverlay: false
    }, () => {
      store.set('selectedWell', '');
    });
  }

  afterSuccess = () => {
    this.setState({ loading: false });
  }

  listWellPhases = (currentWell) => {
    this.setState({ ...initialState, loading: true });
    this.props.wellPhasesAction.fetchWellPhases(
      store.get('token'),
      currentWell && currentWell.id,
      () => this.setState({ loading: false })
    );
  }

  removeWellPhases = (index) => {
    const updatedList = this.state.bitSize.filter((phase, phaseIndex) => phaseIndex != index);

    this.setState({
      bitSize: updatedList,
    });
  }

  submitBitSize = (inWellFlow = false, action) => ({ bitSize, wellIdFromRequest }) => {
    if (!inWellFlow) {
      const wellId = this.props.wells.currentWell && this.props.wells.currentWell.wellId
        ? this.props.wells.currentWell.wellId : wellIdFromRequest;
      const bitSizeAndWellId = bitSize.map(phase => ({ ...phase, id: uuid() }));
      this.setState({ loadingWellPhases: true });

      if (this.props.wellphases.items && this.props.wellphases.items.length) {
        this.props.wellPhasesAction.updateBucketOfWellPhases(
          {
            data: bitSizeAndWellId,
            id: this.props.wellphases.items[0].id,
            wellId,
          },
          this.props.wellphases.items[0].id,
          wellId || wellIdFromRequest,
          () => {
            if (action && action === 'update') {
              message.success('Well phases updated successfully.');
            }
            this.setState({ loadingWellPhases: false, isModalVisibleWellPhases: false, bitSize });
          }
        );
      } else {
        this.props.wellPhasesAction.createBucketOfWellPhases(
          { data: bitSizeAndWellId, wellId: wellId || wellIdFromRequest },
          store.get('token'),
          wellId || wellIdFromRequest,
          () => {
            if (action && action === 'create') {
              message.success('Well phases created successfully.');
            }
            this.setState({ loadingWellPhases: false, isModalVisibleWellPhases: false, bitSize });
          }
        );
      }
    } else {
      this.setState(state => ({
        currentWell: {
          ...state.currentWell,
          bitSize
        },
        bitSize,
      }));
      this.next();
    }
  }

  convertJsonToCsv = () => {
    const { wells: { currentWell } } = this.props;
    const min = 0;
    const max = currentWell.depth.length;
    const { curveKeys } = currentWell;
    const data = currentWell.data ? currentWell.data : currentWell;

    // Fluid Types
    const { chart2, chart3, chart4, chart5 } = generateBalanceCrossplots('', min, max, data, curveKeys);
    const resFluidType2 = chart2.itemsWithDepth;
    const resFluidType3 = chart3.itemsWithDepth;
    const resFluidType4 = chart4.itemsWithDepth;
    const resFluidType5 = chart5.itemsWithDepth;

    // Geochemistry
    const sensorType = currentWell?.ethene_sensor?.has_sensor;
    const checkedSensor = currentWell.checkedSensor;
    const crossPlotsGeochemistry = generateGeochemistryCharts(0, min, max, currentWell, curveKeys, sensorType);
    const resGeoChemistry1 = crossPlotsGeochemistry.chart1.itemsWithDepth;
    const resGeoChemistry2 = crossPlotsGeochemistry.chart2.itemsWithDepth;
    const resGeoChemistry3 = crossPlotsGeochemistry.chart3.items;
    const resGeoChemistry10 = crossPlotsGeochemistry.chart10.itemsWithDepth;
    const resGeoChemistry9 = crossPlotsGeochemistry.chart9.itemsWithDepth;
    const resGeoChemistry7 = crossPlotsGeochemistry.chart7.items;
    const resGeoChemistry8 = crossPlotsGeochemistry.chart8.items;

    const currentWellValues = {
      ...currentWell,
      [fluidTypeCsvNames.chart2]: resFluidType2,
      [fluidTypeCsvNames.chart3]: resFluidType3,
      [fluidTypeCsvNames.chart4]: resFluidType4,
      [fluidTypeCsvNames.chart5]: resFluidType5,
      [geoChemistryCsvNames.chart1]: resGeoChemistry1,
      [geoChemistryCsvNames.chart2]: resGeoChemistry2,
      [geoChemistryCsvNames.chart10]: resGeoChemistry10,
      [geoChemistryCsvNames.chart9]: resGeoChemistry9,
      [geoChemistryCsvNames.chart7]: resGeoChemistry7,
      [geoChemistryCsvNames.chart8]: resGeoChemistry8
    };

    const prepareToParseInCSV = createRowsToCsv(
      currentWellValues,
      fieldsToCsv,
      aliasesToCsv,
      crossPlotsKeysToSynchronizeWithDepth
    );

    this.setState({ prepareToParseInCSV: prepareToParseInCSV });
  }

  exportCurvesToCsv = (values) => {
    const { wells: { currentWell, selectedWell, wellsWithPathFile } } = this.props;
    try {
      const json2csvParser = new Parser();
      const csv = json2csvParser.parse(values);
      let wellName = currentWell.wellName;
      if (!wellName) {
        const well = wellsWithPathFile.find(well => well.wellId === selectedWell);
        if (well && well.wellName) {
          wellName = well.wellName.replace(' ', '-');
        }
      } else if (!wellName) {
        wellName = uuidV1();
      }
      this.downloadFile(csv, `${wellName}.csv`);
    } catch (error) {
      console.log("csv error", error);
    }
  }

  changeInWellModal = () => {
    this.setState((state) => ({ modalInWellIsVisible: !state.modalInWellIsVisible }));
  }

  closeModaltable = () => {
    this.setState((state) => ({
      modalInWellIsVisible: false,
      isInWellTableIsVisible: false,
    }));
  };

  changeDataSourceInWellTableModal = () => {
    this.setState((state) => ({
      isInWellTableIsVisible: !state.isInWellTableIsVisible,
      GWITSML: true,
    }));
  }

  changeInWellTableModal = () => {
    this.setState((state) => ({
      isInWellTableIsVisible: !state.isInWellTableIsVisible,
      GWITSML: true,
    }))
  }

  changeExportDataModal = () => {
    const { modalExportDataIsVisible } = this.state;
    this.setState({ modalExportDataIsVisible: !modalExportDataIsVisible });
  }

  downlodLasFile = async () => {
    const { wells: { currentWell } } = this.props;
    const token = store.get('token');
    try {
      const { data } = await getURLToDownloadLas(currentWell.well_uid, token);
      function download(fileName = 'well') {
        const element = document.createElement('a');
        element.setAttribute('href', data.path);
        element.setAttribute('download', fileName);
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }

      download(`well-${currentWell.well_uid}`);
    } catch (e) {
      console.log('error donwloadLasFile', e);
    }
  }

  selectCurves = (exportType) => {
    const { wells: { currentWell } } = this.props;
    if (!currentWell) {
      alert('Please, select a well');
      return;
    }

    if (exportType.key === 'las') {
      this.downlodLasFile();
    } else {
      this.changeExportDataModal();
      this.convertJsonToCsv();
    }
  }

  downloadFile = (csvFile, fileName) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(
      new Blob([csvFile,], { type: 'text/csv' })
    );
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  splitLas = (lasFile) => {
    return lasFile.split(/\n/);
  }

  changeTab = () => {
    let showImportLas = false;
    if (!this.state.showImportLas) {
      showImportLas = true;
    }
    this.setState({
      showImportLas
    });
    if (this.sliderRef) {
      // TODO when refactor home remove it
      setTimeout(() => {
        if (this.sliderRef && this.sliderRef.forceUpdate) {
          this.sliderRef.forceUpdate();
        }
      }, 1000);
    }
  }

  onChangeSwitchSensor = (checked) => {
    this.setState({
      checkedSensor: checked
    });
  }

  onChangeSwitchNormalizeGasData = (checked) => {
    this.setState({
      checkedNormalizeGasData: checked
    });
  }

  onChangeSensorSelect = field => value => {
    this.setState(state => ({
      curvesKeys: { ...state.curvesKeys, [field]: value }
    }));
  }

  onChangeModalSensorType = (value) => {
    this.setState({
      modalSensorIsVisible: value
    });
  }

  importNextInWell = async (well) => {
    this.setState({ calculating: true, showOverlay: true });
    try {
      const response = await fetchInWellWatcher(store.get('token'), well.source, well.uid, well.uidWell)

      this.changeInWellTableModal();
      const file = {
        name: well.nameWell,
        size: 1000,
      };
      const wellData = { ...response.data };
      const isThereConflict = checkIfExistsWell(this.props.wells.items);

      const payload = { well_metadata: well, well_data: response.data };
      const { data: { content: parsedMetadata } } = await parseWellDataIntegration(payload, store.get('token'));

      const gasData = this.state.gasData && this.state.gasData.weights && this.state.gasData.weights;

      this.props.wellAction.receiveMetadata(parsedMetadata)
      this.setState({
        showOverlay: false,
        calculating: false,
        modalInWellIsVisible: false,
        isOpenConfigImport: true,
        metadata: parsedMetadata,
        alert: {
          isOpen: true,
          message: 'Well choose successfully',
          severity: 'success'
        }
      });
      //processingInWell(wellData, file, isThereConflict, this, () => this.openModal());
    } catch (e) {
      this.setState({
        showOverlay: false,
        calculating: false,
        modalInWellIsVisible: false,
        alert: {
          isOpen: true,
          message: 'This well is unacessible! please choose another one.',
          severity: 'warning'
        }
      });

    }
  }

  openModalWellPhases = () => {
    this.setState({
      isModalVisibleWellPhases: true,
      bitSize: this.props.wellphases.items[0]?.data ?? []
    });
  }

  handleCancelModalWellPhases = () => {
    this.setState({
      isModalVisibleWellPhases: false
    });
  };

  handleNormalizeGas = (event) => {
    const value = event.target.value;
    const curveName = event.target.name;

    if (value === '') {
      this.setState({ msgErrorGasData: false });
    }

    const newGasData = {
      curves: {
        ...this.state.gasData.curves,
        [curveName]: [],
      },
      weights: {
        ...this.state.gasData.weights,
        [curveName]: Number(value),
      }
    };
    this.setState({ gasData: newGasData });
  }

  unSelectWell = () => {
    let zones = clone(this.props.zone.items);
    if (!zones && this.props.wells.currentWell && this.props.wells.currentWell.zones) {
      zones = clone(this.props.wells.currentWell.zones);
    }

    map(zones, (zone) => {
      removeAllZones(zone.id);
    });

    setTimeout(() => {
      this.setState(() => ({
        selectedWell: -1,
        // @TODO move it to the modal state
        rotating: null,
        processing: false,
        modalIsOpen: false,
        etheneRadio: null,
        step: 1,
        errors: {
          top: null,
          bottom: null,
          wellName: null
        },
        defaultcurves: {
          depth: 'Depth',
          rop: 'ROP',
          gas_ratio: 'Gas Ratio',
          total_gas: 'Total Gas',
          c1: 'C1',
          c2: 'C2',
          c3: 'C3',
          c4: 'C4',
          nc4: 'nc4',
          nc5: 'nc5',
          c5: 'C5',
          character_ratio: 'Character Ratio',
          balance_ratio: 'Balance Ratio',
          wetness_ratio: 'Wetness Ratio',
          isotope: 'Isotope',
          chartType: '',
          flow: 'flow'
        },
        errorCurves: {
          depth: null,
          c1: null,
          c2: null,
          c3: null,
          c4: null,
          c5: null,
          ic4: null,
          ic5: null,
          c2h6: null,
          c2h4: null,
          c2s: null,
          flow: null
        },
        top: 0,
        bottom: 0,
        // Plotbox
        chartType: null,
        // Upload well
        loadingWell: false,
        curves: [],
        current: 0,
        curvesKeys: defaultCurveKeys,
        currentWell: {
          name: '',
          lasVersion: ''
        },
        checkedSensor: false,
        checkedNormalizeGasData: false,
        // @TODO move it to the wells reducer
        data: initialData,
        max: 0,
        min: 0,
        selectedMax: 0,
        selectedMin: 0,
        unit: '',
        wellNames: [],
        selectedWell: -1,
        rangeTop: 0,
        rangeBottom: 0,
        visibleTooltip: false,
        showClass: 'fadeOut',
        visibleTooltip2: false,
        showClass2: 'fadeOut',
        hasConflictData: false,
        hadConflict: false,
        newWellName: '',
        hasNewWell: false,
        conflictingType: '',
        bitSize: [],
        lasFile: '',
        showImportLas: true,
        prepareToParseInCSV: [],
      }));
      this.props.zoneAction.deleteAll();
      store.set('configScales', DEFAULT_SCALES);
      this.props.wellAction.filterWell(-1);
    }, 500);
  }

  getDataType = (typeAction) => {

    this.setState({ typeActionImportData: typeAction });
    // primeiro passo consigo saber quando é update alterando opção
    // console.log('typeAction', typeAction);
    // segundo passo ver se ao alterar o form é alterado os valor no submit
    // terceiro passo ao clicar no submit enviar para api

  }

  formatCoordinates = (values) => {
    const { degrees_lat, degrees_long, minutes_lat, minutes_long, seconds_lat, seconds_long, x_value, y_value, zone, reference, reference_zone  } = values;

    if (degrees_lat === '') {
      return {
        lat_long_coordinates: null,
        utm_coordinates: null,
      }
    }

    return {
      lat_long_coordinates: {
        latitude: {
          degrees: degrees_lat,
          minutes: minutes_lat,
          seconds: seconds_lat,
        },
        longitude: {
          degrees: degrees_long,
          minutes: minutes_long,
          seconds: seconds_long,
        },
        geodatum: reference
      },
      utm_coordinates: {
        X: x_value,
        Y: y_value,
        geodatum: reference_zone,
        zone,
      },
    }
  }

  handleSubmitImportData = async (data) => {
    this.setState({
      loadingWell: true,
      loading: true,
      showOverlay: true,
    });
    const token = store.get('token');
    const {
      name, range, unit, sensor_type, degrees_lat, minutes_lat, seconds_lat,
      degrees_long, minutes_long, seconds_long, reference, x_value, y_value,
      reference_zone, zone, name1, unit1, type1, mnemonicRow1, bs, curves, depth_reference
    } = data;
    const { start_depth, stop_depth } = range;

    const transformFloatValueBitSize = bs?.[0]?.diameter ? calculateBitSize(bs?.[0]?.diameter) : 0;
    let normalizedBitsSize = bs.length > 0 ? [{...bs[0], diameter: transformFloatValueBitSize}] : [];
    const indexCurveWithName = curves.find(c => c.mnemonic === depth_reference);

    const currentIndexCurve = (indexCurveWithName || this?.props.wells?.metadata?.log_sets?.[0]?.index_curve);
    const coordinates = this.formatCoordinates(data);

    const curvesUpdatedMinAndMaxDepth = curves.map((item) => {
      if(item.type === 'depth') {
        return {
          ...item,
            min_value: start_depth,
            max_value: stop_depth,
            unit
        }
      } else {
        return item
      }
    });

    const newMetadata = {
      ...this.state.metadata,
      ...coordinates,
      name,
      start_depth: start_depth,
      stop_depth: stop_depth,
      base: start_depth,
      top: stop_depth,
      index_unit: unit,
      ethene_sensor: {
        fid: sensor_type === 'FID',
        has_sensor: !!sensor_type,
        ms: sensor_type === 'MS'
      },
      well_phases: normalizedBitsSize || [],
      log_sets: [
        {
          ...this.state?.metadata?.log_sets?.[0],
          curves: curvesUpdatedMinAndMaxDepth,
          index_curve: {
            name: currentIndexCurve?.name,
            unit: unit,
            type: currentIndexCurve?.type,
            mnemonic: currentIndexCurve?.mnemonic,
            data_style: currentIndexCurve?.data_style,
            discrete_with_value: currentIndexCurve?.discrete_with_value,
            visualization_config: currentIndexCurve?.visualization_config,
            max_value: stop_depth,
            min_value: start_depth,
          },
          start_depth: start_depth,
          stop_depth: stop_depth
        }
      ],
      start_depth: start_depth,
      stop_depth: stop_depth
    };

    if (this.state.typeActionImportData === 'CREATE') {
      try {
        const curvesRequired = ['c1','c2', 'c3', 'c4', 'c5', 'ic5', 'nc5', 'ic4', 'nc4'];
        const normalizedValue = (newMetadata?.log_sets?.[0]?.curves || []).map(item => item.type);
        let curvesNotExists = curvesRequired.reduce((acc, curve) => {
          if (curve === 'c4' && !normalizedValue.includes('ic4') || !normalizedValue.includes('nc4')) {
            acc.push(curve);
            return acc;
          }

          if (curve === 'c5' && !normalizedValue.includes('ic5') || !normalizedValue.includes('nc5')) {
            acc.push(curve);
            return acc;
          }

          if (curve !== 'c4' && curve !== 'c5' && !normalizedValue.includes(curve)) {
            acc.push(curve);
          }

          return acc;
        }, []);

        if (curvesNotExists.length > 0) {
          this.setState({
            alert: {
              isOpen: true,
              message: `There are no ${curvesNotExists.map(item => ` ${item}`)}, curves. To have great results for calculations you must provide all gas curves.`,
              severity: 'warning',
            },
          });
        }
        const saveParsedDataResponse = await saveParsedData(newMetadata, token);
        const zones = this.props.zone.items;
        zones.map(zone => removeAllZones(zone.id, Highcharts));
        this.props.zoneAction.receiveZones([]);
        this.props.wellAction.updateMetadata(saveParsedDataResponse?.data?.content?.well_metadata);
        this.props.wellAction.receiveWell(saveParsedDataResponse?.data?.content?.well_metadata);
        this.setState({
          alert: {
            isOpen: true,
            message: 'Well created successfully',
            severity: 'success',
          },
        });
        const afterList = () => {
          this.setState({
            loadingWell: false,
            loading: false,
            showOverlay: false
          });
        };
        this.handleCloseDrawer();
        await this.props.wellAction.listWell(
          saveParsedDataResponse?.data?.content?.well_metadata?.uid,
          store.get('token'),
          afterList,
        );
      } catch (e) {
        this.setState({
          alert: {
            isOpen: true,
            message: 'Error when creating the well',
            severity: 'error'
          },
          loadingWell: false,
        });
      }
    }
  }

  changedUnit = (unit) => {
    const metadata = this?.props?.wells?.metadata;
    const unitLasFile = metadata?.index_unit;
    const start = metadata?.start_depth;
    const stop = metadata?.stop_depth;
    const step = metadata?.step;

    this.setState({ currentUnit: unit });
    if (unitLasFile !== unit) {
      if (unitLasFile === 'm' && unit === 'ft') {
        this.props.wellAction.updateMetadata({
          ...metadata,
          index_unit: unit,
          start_depth: start * 3.28084,
          stop_depth: stop * 3.28084,
        });
      } else if (unitLasFile === 'ft' && unit === 'm') {
        this.props.wellAction.updateMetadata({
          ...metadata,
          index_unit: unit,
          start_depth: Math.round(start * 0.3048),
          stop_depth: Math.round(stop * 0.3048),
        });
      }
    }
  }

  render() {
    const {
      min, maxDepth, selectedMin, selectedMax, unit,
      processing, data, modalIsOpen, curves,
      current, loadingWell, modalExportDataIsVisible, modalInWellIsVisible,
      curvesKeys, errors, top, bottom, prepareToParseInCSV,
      rangeTop, rangeBottom, hasConflictData, lasFile, showImportLas, isModalVisibleWellPhases,
      rangeTopIndex, rangeBottomIndex, metadata, isOpenConfigImport, alert
    } = this.state;


    const { wellOptions, selectedWell, currentWell } = this.props.wells;
    let curvesNamesOptions = [];
    if (curves && Array.isArray(curves)) {
      curvesNamesOptions = curves;
    }
    const curvesOptions = curvesNamesOptions.map(opt => (
      { value: opt, label: opt.toUpperCase() })
    );

    const thereIsASelectedWell = selectedWell && selectedWell !== -1;

    const wellName = this.state.newWellName || get(currentWell, 'well', get(this.state, 'currentWell.well', ''));

    const sensorOptions = [{ value: '', label: '-' }, ...curvesOptions,];

    // should update range slide when is ft or m
    let defaultStep = 1;
    if (
      this.state.curvesKeys &&
      this.state.curvesKeys.depth &&
      this.state.curvesKeys.depth.includes('ft')
    ) {
      setUnit('ft');
      defaultStep = 1;
    } else {
      setUnit('m');
    }

    // update step
    if (this.state.stepInterval && this.state.stepInterval.value) {
      const [stepValue] = this.state.stepInterval.value.includes(':')
        ? this.state.stepInterval.value.split(':') : [this.state.stepInterval.value];
      if (stepValue) {
        defaultStep = Number(stepValue);
      }
    }
    if (!defaultStep) {
      const [depth1, depth2, depth3] = data[this.state.curvesKeys.depth];
      let diffOfStep = depth2 - depth1;
      if (diffOfStep === 0) {
        diffOfStep = depth3 - depth2;
      }
      defaultStep = diffOfStep;
    }

    let rangeBottomValue = rangeBottom;
    let rangeTopValue = rangeTop;
    const validDepth = this.state.data &&
      this.state.curvesKeys.depth &&
      this.state.data[this.state.curvesKeys.depth];
    if (
      getUnit() === 'ft' &&
      validDepth
    ) {
      rangeBottomValue = this.state.data[this.state.curvesKeys.depth].slice(-1)[0];
      rangeTopValue = this.state.data[this.state.curvesKeys.depth][0];
    } else if (validDepth) {
      rangeBottomValue = this.state.data[this.state.curvesKeys.depth].slice(-1)[0];
      rangeTopValue = this.state.data[this.state.curvesKeys.depth][0];
    }
    if (!rangeBottomValue && !rangeTopValue && this.state.depth) {
      const stopDepth = this.state.depth.slice(-1)[0];
      rangeBottomValue = stopDepth ? stopDepth : 0;
      rangeTopValue = this.state.depth[0] ? this.state.depth[0] : 0;
    }
    const createWellLabel = this.state.conflictingType !== UPDATE && this.state.GWITSML ? 'Import Data' : 'Create a well';
    this.steps = [
      {
        title: <div data-cy="create-well-step-title">{this.state.conflictingType === UPDATE ? 'Update well' : createWellLabel}</div>,
        content: () => {
          return (
            <div className="home__modal-container">
              <div className="home__modal-form-group">
                <label
                  htmlFor="wellName"
                  style={{ color: '#fff', textAlign: 'left' }}
                >
                  Well
                </label>
                <Input
                  value={this.state.newWellName ? this.state.newWellName : wellName}
                  type="text"
                  id="wellName"
                  placehloder="Well name"
                  defaultValue={wellName}
                  onChange={this.changeWellName}
                  data-cy="well-name-input"
                />
                <div
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    position: 'absolute',
                    bottom: '-18px',
                    left: '88px'
                  }}
                >
                  {errors.wellName && errors.wellName}
                </div>
              </div>
              <SelectReferenceCurve
                value={curvesKeys.depth}
                classContainer="relative-curve"
                // classLabel="label-curve"
                onChange={this.selectMnemnonics('depth')}
                label="Reference Depth"
                id="depth"
                options={sensorOptions}
              />
              <TopBottomSlider
                assignRef={(ref) => {
                  this.sliderRef = ref;
                }}
                rangeTopValue={rangeTop}
                rangeBottomValue={rangeBottom}
                rangeTopIndex={rangeTopIndex}
                rangeBottomIndex={rangeBottomIndex}
                showImportLas={showImportLas}
                afterChange={this.changeWellRanges}
                depth={data[curvesKeys.depth]}
              />
              <div className="home__modal-form-group">
                <label
                  htmlFor="version"
                  style={{ color: '#fff', textAlign: 'left' }}
                >
                  Version
                </label>
                <Input
                  id="version"
                  defaultValue={get(this.state, 'currentWell.version')}
                  readOnly
                />
              </div>
            </div>
          );
        }
      },
      {
        title: 'Well Phases',
        content: (
          _, isProcessing, current, submitBitSize,
          goPrev, closeWell, steps, initialBitSize, wellphases,
          loadingWellPhases,
        ) => {
          return (
            <div className="home__modal-container">
              <div className="wrapper-header-modal">
                <h1 className="well-phase__title" data-cy="well-phases-title">
                  Well Phases
                </h1>
                <div className="bit-size__description">
                  <span className="bit-size__title">
                    Copy the values of bit size and then enter in the Top and Bottom inputs:
                  </span>
                  <BitSizeClipboard
                    rangeTop={rangeTop}
                    rangeBottom={rangeBottom}
                    bottom={rangeBottom}
                    top={rangeTop}
                  />
                </div>
              </div>
              <BitSizeForm
                onSubmit={this.submitBitSize(true)}
                submitBitSize={this.submitBitSize(true)}
                current={current}
                processing={isProcessing}
                goPrev={goPrev}
                closeWell={closeWell}
                steps={steps}
                initialBitSize={[]}
                loading={this.state.loading}
                currentWell={currentCreateWell}
                loadingWellPhases={this.state.loadingWellPhases}
                removeWellPhase={this.removeWellPhases}
                wellphases={this.state.bitSize}
              />
            </div>
          );
        }
      },
      {
        title: 'Match Curves',
        content: (curvesKeys, isProcessing) => {
          return (
            <div>
              <div className="home__modal-header" data-cy="match-curves-title">
                <h2>Match curves</h2>
              </div>
              <div className="home__modal-content">
                {Object.keys(defaultMnemonics).map((field, index) => {
                  return (
                    <>
                      <SelectMnemonic
                        key={index}
                        value={curvesKeys[field]}
                        className={field}
                        onChange={this.selectMnemnonics(field)}
                        label={field.replace('_', ' ').toUpperCase()}
                        id={field}
                        options={sensorOptions}
                      />
                      {this.state.errorCurves[field] && (
                        <span style={{ color: 'red' }} data-cy={`${field}-error`}>{this.state.errorCurves[field]}</span>
                      )}
                    </>
                  );
                })}
                <div className="wrapper-switch-sensor">
                  <label className="label-switch-sensor">Is there Ethene Sensor? </label>
                  <SwitchComponent
                    className="switch-component"
                    onChange={this.onChangeSwitchSensor}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    defaultChecked={false}
                  />
                </div>
                {this.state.checkedSensor &&
                  <div className="wrapper-select-sensor">
                    <ModalSensorType
                      openModal={this.state.modalSensorIsVisible}
                      closeModal={(value) => this.onChangeModalSensorType(value)}
                    />
                    <label>Sensor Type</label>
                    <PopupSensorType
                      openModalSensor={(value) => { this.onChangeModalSensorType(value) }}
                    />
                    <Radio.Group
                      onChange={this.onChangeEtheneRadio}
                      value={this.state.etheneRadio}
                    >
                      <Radio value={1} className="item-radio-group-sensor">FID</Radio>
                      <Radio value={0} className="item-radio-group-sensor">MS</Radio>
                    </Radio.Group><br />
                    {Object.keys(defaultMnemonicsSensorType).map((field, index) => {
                      return (
                        <>
                          <SelectMnemonicSensorType
                            key={index}
                            field={field}
                            value={curvesKeys[field]}
                            // label="Select a mnemonic"
                            className={field}
                            onChange={this.selectMnemnonics(field)}
                            label={field.replace('_', ' ').toUpperCase()}
                            id={field}
                            options={sensorOptions}
                            disabled={this.state.etheneRadio === 0 ? true : false}
                          />
                          {this.state.checkedSensor && this.state.errorCurves[field] && (
                            <span style={{ color: 'red' }} data-cy={`${field}-error`}>{this.state.errorCurves[field]}</span>
                          )}
                        </>
                      )
                    })}
                    <div>
                      {Object.keys(defaultMnemonicsSensorTypeC2s).map((field) => {
                        return (
                          <>
                            <SelectMnemonicSensorTypeC2s
                              field={'c2s'}
                              value={curvesKeys['c2s']}
                              className={'c2s'}
                              onChange={this.selectMnemnonics('c2s')}
                              label={field.replace('_', ' ').toUpperCase()}
                              id={'c2s'}
                              options={sensorOptions}
                            />
                            <div>
                              {this.state.checkedSensor && this.state.errorCurves[field] && (
                                <span style={{ color: 'red' }} data-cy={`${field}-error`}>{this.state.errorCurves[field]}</span>
                              )}
                            </div>
                          </>
                        )
                      })
                      }
                    </div>
                  </div>
                }
              </div>
              <div className="wrapper-switch-sensor">
                <label className="label-switch-sensor">Normalize Gas Data? </label>
                <SwitchComponent
                  className="switch-component"
                  onChange={this.onChangeSwitchNormalizeGasData}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  defaultChecked={false}
                />
              </div>
              {this.state.checkedNormalizeGasData &&
                <div>
                  <h3 style={{ color: '#fff', textAlign: 'center' }}>Extraction Efficiency Coefficients(%)</h3>
                  {this.state.msgErrorGasData
                    ? <div style={{
                      textAlign: 'center',
                      marginBottom: 5,
                      borderRadius: 5,
                      padding: 5,
                      color: '#B22222',
                      backgroundColor: '#f8d7da',
                      borderColor: '#f5c6cb'
                    }}>
                      The highest value allowed is 100%
                    </div>
                    : ''
                  }
                  <label>
                    <div>C1</div>
                    <input
                      type="text"
                      name="c1"
                      placeholder="100%"
                      className='input-normalize-gas-data'
                      value={this.state.gasData && this.state.gasData.c1}
                      style={{
                        width: 200,
                        padding: 4,
                        color: '#fff',
                        background: '#45444f',
                        border: 'solid 1px #fff',
                        borderRadius: 4,
                      }}
                      onChange={this.handleNormalizeGas}
                    />
                  </label>
                  <label>
                    <div style={{ marginTop: 8 }}>C2</div>
                    <input
                      type="text"
                      name="c2"
                      placeholder="100%"
                      className='input-normalize-gas-data'
                      value={this.state.gasData && this.state.gasData.c2}
                      style={{
                        width: 200,
                        padding: 4,
                        color: '#fff',
                        background: '#45444f',
                        border: 'solid 1px #fff',
                        borderRadius: 4,
                      }}
                      onChange={this.handleNormalizeGas}
                    />
                  </label>
                  <label>
                    <div style={{ marginTop: 8 }}>C3</div>
                    <input
                      type="text"
                      name="c3"
                      placeholder="100%"
                      className='input-normalize-gas-data'
                      value={this.state.gasData && this.state.gasData.c3}
                      style={{
                        width: 200,
                        padding: 4,
                        color: '#fff',
                        background: '#45444f',
                        border: 'solid 1px #fff',
                        borderRadius: 4,
                      }}
                      onChange={this.handleNormalizeGas}
                    />
                  </label>
                  <label>
                    <div style={{ marginTop: 8 }}>C4</div>
                    <input
                      type="text"
                      name="c4"
                      placeholder="100%"
                      className='input-normalize-gas-data'
                      value={this.state.gasData && this.state.gasData.c4}
                      style={{
                        width: 200,
                        padding: 4,
                        color: '#fff',
                        background: '#45444f',
                        border: 'solid 1px #fff',
                        borderRadius: 4,
                      }}
                      onChange={this.handleNormalizeGas}
                    />
                  </label>
                  <label>
                    <div style={{ marginTop: 8 }}>IC4</div>
                    <input
                      type="text"
                      name="ic4"
                      placeholder="100%"
                      className='input-normalize-gas-data'
                      value={this.state.gasData && this.state.gasData.ic4}
                      style={{
                        width: 200,
                        padding: 4,
                        color: '#fff',
                        background: '#45444f',
                        border: 'solid 1px #fff',
                        borderRadius: 4,
                      }}
                      onChange={this.handleNormalizeGas}
                    />
                  </label>
                  <label>
                    <div style={{ marginTop: 8 }}>NC4</div>
                    <input
                      type="text"
                      name="nc4"
                      placeholder="100%"
                      className='input-normalize-gas-data'
                      value={this.state.gasData && this.state.gasData.nc4}
                      style={{
                        width: 200,
                        padding: 4,
                        color: '#fff',
                        background: '#45444f',
                        border: 'solid 1px #fff',
                        borderRadius: 4,
                      }}
                      onChange={this.handleNormalizeGas}
                    />
                  </label>
                  <label>
                    <div style={{ marginTop: 8 }}>C5</div>
                    <input
                      type="text"
                      name="c5"
                      placeholder="100%"
                      className='input-normalize-gas-data'
                      value={this.state.gasData && this.state.gasData.c5}
                      style={{
                        width: 200,
                        padding: 4,
                        color: '#fff',
                        background: '#45444f',
                        border: 'solid 1px #fff',
                        borderRadius: 4,
                      }}
                      onChange={this.handleNormalizeGas}
                    />
                  </label>
                  <label>
                    <div style={{ marginTop: 8 }}>NC5</div>
                    <input
                      type="text"
                      name="nc5"
                      placeholder="100%"
                      className='input-normalize-gas-data'
                      value={this.state.gasData && this.state.gasData.nc5}
                      style={{
                        width: 200,
                        padding: 4,
                        color: '#fff',
                        background: '#45444f',
                        border: 'solid 1px #fff',
                        borderRadius: 4,
                      }}
                      onChange={this.handleNormalizeGas}
                    />
                  </label>
                  <label>
                    <div style={{ marginTop: 8 }}>IC5</div>
                    <input
                      type="text"
                      name="ic5"
                      placeholder="100%"
                      className='input-normalize-gas-data'
                      value={this.state.gasData && this.state.gasData.ic5}
                      style={{
                        width: 200,
                        padding: 4,
                        color: '#fff',
                        background: '#45444f',
                        border: 'solid 1px #fff',
                        borderRadius: 4,
                      }}
                      onChange={this.handleNormalizeGas}
                    />
                  </label>
                </div>
              }
              {isProcessing && (
                <p>Processing, creating and saving the well...</p>
              )}
              {isProcessing && (
                <div className="charts-container__spinner">
                  <Spinner />
                </div>
              )}
            </div>
          );
        }
      },
    ];

    // Current well on Redux
    const { wells } = this.props;
    const defaultWell = { depth: [] };

    const depth = get(wells, 'currentWell.depth', []);
    const totalGas = get(wells, 'currentWell.totalGas', []);

    const maxDepthHoleDepthCard = depth && depth.length > 0 ? depth.slice(-1) : 'N/A';
    const c1 = get(wells, 'currentWell.c1', []);
    const c2 = get(wells, 'currentWell.c2', []);
    const c3 = get(wells, 'currentWell.c3', []);
    const ic4 = get(wells, 'currentWell.ic4', []);
    const nc4 = get(wells, 'currentWell.nc4', []);
    const ic5 = get(wells, 'currentWell.ic5', []);
    const ic4Normal = get(wells, 'currentWell.ic4Normal', []);
    const ic5Normal = get(wells, 'currentWell.ic5Normal', []);
    const nc5 = get(wells, 'currentWell.nc5', []);
    const c1Composition = get(wells, 'currentWell.c1Composition', []);
    const c2Composition = get(wells, 'currentWell.c2Composition', []);
    const c3Composition = get(wells, 'currentWell.c3Composition', []);
    const nc4Composition = get(wells, 'currentWell.nc4Composition', []);
    const nc5Composition = get(wells, 'currentWell.nc5Composition', []);
    const ic4BynC4 = get(wells, 'currentWell.ic4BynC4', []);
    const ic5BynC5 = get(wells, 'currentWell.ic5BynC5', []);
    const c1Byc2 = get(wells, 'currentWell.c1Byc2', []);
    const rop = get(wells, 'currentWell.rop', []);
    const wetnessRatio = get(wells, 'currentWell.wetnessRatio', []);
    const balanceRatioData = get(wells, 'currentWell.balanceRatioData', []);
    const characterRatioData = get(wells, 'currentWell.characterRatioData', []);
    const slopeFactor = get(wells, 'currentWell.slopeFactor', []);
    const gammaRay = get(wells, 'currentWell.gammaRay', []);
    const isotopeData = get(wells, 'currentWell.isotope', []);
    //const cutOffs = selectedWell !== -1 ? wells?.currentWell?.cutOffs : {};
    const cutOffs = {};
    const totalCarbon = get(wells, 'currentWell.totalCarbon', []);
    const c1Normalized = get(wells, 'currentWell.c1Normalized', []);
    const gqr = get(wells, 'currentWell.gqr', []);
    const bitSize = get(wells, 'currentWell.bitSize', []);
    const criticalAreasC1 = get(wells, 'currentWell.statistics.criticalAreasC1', []);
    const criticalAreasC2 = get(wells, 'currentWell.statistics.criticalAreasC2', []);
    const criticalAreasC3 = get(wells, 'currentWell.statistics.criticalAreasC3', []);
    const criticalAreasC4 = get(wells, 'currentWell.statistics.criticalAreasC4', []);
    const criticalAreasC5 = get(wells, 'currentWell.statistics.criticalAreasC5', []);
    const criticalAreasIC4 = get(wells, 'currentWell.statistics.criticalAreasIC4', []);
    const criticalAreasIC5 = get(wells, 'currentWell.statistics.criticalAreasIC5', []);
    const criticalAreasC1Composition = get(wells, 'currentWell.statistics') ?
      getForm(this.props.wells.currentWell.statistics, 'formCollapseC1Composition') : [];
    const criticalAreasC2Composition = get(wells, 'currentWell.statistics') ?
      getForm(this.props.wells.currentWell.statistics, 'formCollapseC2Composition') : [];
    const criticalAreasC3Composition = get(wells, 'currentWell.statistics') ?
      getForm(this.props.wells.currentWell.statistics, 'formCollapseC3Composition') : [];
    const criticalAreasC4Composition = get(wells, 'currentWell.statistics') ?
      getForm(this.props.wells.currentWell.statistics, 'formCollapseC4Composition') : [];
    const criticalAreasC5Composition = get(wells, 'currentWell.statistics') ?
      getForm(this.props.wells.currentWell.statistics, 'formCollapseC5Composition') : [];

    const ExportFileOptions = ({ handleMenuClick }) => (
      <Menu onClick={handleMenuClick}>
        {/* <Menu.Item key="csv" style={{ color: '#1D1C22' }}>
          CSV File
        </Menu.Item> */}
        <Menu.Item key="las" style={{ color: '#1D1C22' }}>
          LAS File
        </Menu.Item>
      </Menu>
    );

    const currentCreateWell =
      this.state.conflictingType === CREATE && !wells.currentWell
        ? { ...this.state.currentWell, bitSize: null }
        : {
          ...wells.currentWell,
          bitSize: get(this.state, 'currentWell.bitSize', null)
        };

    return (
      <div className="home">
        {/* <BtnTest /> */}
        <ListWellAfterFocus />
        <Alert
          open={alert.isOpen}
          message={alert.message}
          severity={alert.severity}
          onClose={this.handleCloseAlert}
        />
        <SelectedWell wellId={selectedWell} />
        <WatchCutoffFromCutoffViewChart currentWell={this.props.wells.currentWell} wellId={selectedWell} />
        <Overlay show={this.state.showOverlay} />
        <SidebarNavigation history={this.props.history} />
        <WellPhasesModal
          isOpen={isModalVisibleWellPhases}
          visible={isModalVisibleWellPhases}
          onCloseModal={this.handleCancelModalWellPhases}
          rangeTop={rangeTop}
          rangeBottom={rangeBottom}
          bottom={rangeBottom}
          top={rangeTop}
          submitBitSize={this.submitBitSize(false)}
          current={current}
          loading={this.state.loading}
          loadingWellPhases={this.state.loadingWellPhases}
          goPrev={this.goPrev}
          closeWell={this.handleCancelModalWellPhases}
          steps={this.steps}
          initialBitSize={[]}
          currentWell={currentCreateWell}
          removeWellPhase={this.removeWellPhases}
          wellphases={this.state.bitSize}
        />
        <DataSourceModal
          isOpen={modalInWellIsVisible}
          changeInWellModal={this.onChangeUploadInWell}
          uploadFile={this.uploadFile}
          wellOptions={this.props.wells.items}
          nextAction={this.selectWell}
          selectedWellKey={this.props.wells.selectedWell}
          changeInWellTableModal={() => {
            // this.unSelectWell();
            this.changeInWellTableModal();
          }}
          onCloseModal={() => {
            this.changeInWellModal();
            this.closeWell();
          }}
        />
        <SelectModalInWell
          isOpen={this.state.isInWellTableIsVisible}
          changeInWellModal={this.closeModaltable}
          onCloseModal={() => {
            this.closeWell();
            this.closeModaltable();
          }}
          onCloseSteps={() => {
            this.closeWell();
            this.closeModaltable();
          }}
          nextAction={this.importNextInWell}
          calculating={this.state.calculating}
        />
        <ConfigModal />
        <ModalExportData
          isOpen={modalExportDataIsVisible}
          changeModal={this.changeExportDataModal}
          exportCurvesToCsv={this.exportCurvesToCsv}
          prepareToParseInCSV={prepareToParseInCSV}
        />
        <Modal
          isOpen={modalIsOpen}
          style={modalStyleCreateWell}
        >
          <div className="home__modal">
            {hasConflictData ? (
              <ConflictData
                wellName={wellName}
                closeWell={this.closeWell}
                stepModal={this.stepModal}
                selectedWell={this.props.wells.selectedWell}
              />
            ) : (
              <Tabs
                defaultActiveKey="1"
                className={classNames(
                  'tabs-create-well',
                  {
                    'tabs-create-well--import': !!showImportLas,
                    'tabs-create-well--preview': !showImportLas
                  }
                )}
                onChange={this.changeTab}
              >
                <TabPane tab="Import LAS" key="1">
                  <CreateWell
                    current={current}
                    steps={this.steps}
                    closeWell={this.closeWell}
                    curvesKeys={curvesKeys}
                    processing={processing}
                    submitBitSize={this.submitBitSize}
                    goPrevBitSizes={this.goPrevBitSizes}
                    currentWell={currentCreateWell}
                    wellphases={this.props.wellphases}
                    prev={this.prev}
                    next={this.next}
                    startProcess={this.startProcess}
                  />
                </TabPane>
                <TabPane tab="LAS Preview" key="2">
                  <LasPreview
                    splitLas={this.splitLas}
                    lasFile={lasFile}
                    closeWell={this.closeWell}
                  />
                </TabPane>
              </Tabs>
            )}
          </div>
        </Modal>
        <div className="home__content">
          <header className="home__header">
            <div
              className="home__header-name"
              data-cy="home__header-name"
            >
              Wellex Gold
            </div>
            <div className="home__header-actions">
            <Importdata
              // TODO listing wells
              getDataType={this.getDataType}
              wellOptions={wellOptions}
              onSubmit={this.handleSubmitImportData}
              isOpen={isOpenConfigImport}
              lasInfo={lasFile}
              defaultData={this.props.wells.metadata}
              isLoading={this.state.loadingWell}
              onClose={this.handleCloseDrawer}
              changedUnit={this.changedUnit}
            />
              <div style={{ display: 'flex' }}>
                {loadingWell && (
                  <div
                    className="loading__spinner"
                    style={{ marginRight: '4px', marginTop: '4px' }}
                  >
                    <Spinner classes="spinner--small" />
                  </div>
                )}
                {/*<SelectWell
                  selectedWell={selectedWell}
                  wellOptions={wellOptions}
                  />*/}
                <div
                  id="home__header-select-well"
                  className="home__header-select-well"
                  style={{
                    maxWidth: 300,
                    border: 'none',
                    color: '#fff',
                    marginRight: '8px',
                    marginTop: '2px',
                  }}
                  onChange={this.selectWell}
                  value={selectedWell}
                  data-cy="select-well"
                > {selectedWell == -1 ?
                    <div style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      whiteSpace: 'nowrap',
                      alignItems: 'center',
                      background: '#222',
                      overflow: 'hidden',
                      color: '#bbb',
                      padding: '5px 10px',
                      borderRadius: 5}}>
                        <span>Select a well</span>
                    </div> :
                    <div style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      whiteSpace: 'nowrap',
                      alignItems: 'center',
                      background: '#222',
                      overflow: 'hidden',
                      color: '#bbb',
                      padding: '5px 10px',
                      borderRadius: 5}}>
                      <span style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'}}>
                        WELL: {wellOptions.find(well => well.value == selectedWell)?.label}
                      </span>
                    </div>}
                  {/* <Select.Option value={-1} style={{ color: '#ccc' }}>
                    Select a well
                  </Select.Option>
                  {(wellOptions || []).map((option) => (
                    <Select.Option value={option.value} data-cy="option-select-well">
                      {option.label}
                    </Select.Option>
                  ))} */}
                </div>
                {selectedWell !== -1 && (
                  <Popconfirm
                    placement="bottom"
                    title="Are you sure you want to delete this well?"
                    onConfirm={async () => {
                      this.setState({ showOverlay: true });
                      await this.removeWell();
                      // message.success('Deleted successfully!');
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{
                        marginRigth: 0,
                        marginLeft: 0,
                        backgroundColor: 'transparent',
                        border: 'none',
                        paddingRight: 10,
                        paddingLeft: 10
                      }}
                      title="Remove Well"
                      data-cy="remove-well"
                    >
                      <img src={removeIcon} alt="remove" style={{ width: '16px', height: '16px' }} />
                    </Button>
                  </Popconfirm>
                )}
              </div>
              {/* {this.props.wells.currentWell ? (
                <div>
                  <button
                    onClick={this.openModalWellPhases}
                    type="button"
                    className="btn-upload"
                    title="Edit Data"
                  >
                    Edit Data
                  </button>
                </div>
              ) : null} */}
              {/* <input
                id="lasFile"
                type="file"
                data-cy="upload-las"
                accept=".las, .txt, .csv"
                onChange={this.onChangeUpload}
                style={{ display: 'none' }}
                ref={this.uploadRef}
              /> */}
              <input
                id="lasFile"
                type="file"
                data-cy="upload-las"
                accept=".las, .txt, .csv"
                onChange={this.onChangeUpload}
                style={{ display: 'none' }}
                ref={this.uploadRef}
              />
              <button
                type="button"
                className="btn-upload"
                onClick={this.changeInWellModal}
                title="Import Data"
              >
                <img src={folderIcon} />
                Select Well
              </button>
              {this.state.downloadLasFileIsProcessing && (
                <div
                  className="loading__spinner"
                  style={{ marginRight: '4px', marginTop: '4px' }}
                >
                  <Spinner classes="spinner--small" />
                </div>
              )}
              <Dropdown
                name="field1"
                overlay={<ExportFileOptions handleMenuClick={this.selectCurves} />}
                className="btn-upload-csv btn-export-well"
                overlayClassName="overlay-export-well"
              >
                <Button>
                  <DownloadOutlined /> Export Well
                </Button>
              </Dropdown>
              <StatisticButton
                currentWell={this.props.wells.currentWell}
              />
              <GeneratePlotButton />
              <RealtimeSwitch notificationFunction={warningNotification} />
            </div>
          </header>
          <Outlet />
          <SyncOldWellsModal
            isModalOpen={this.state.openModalToSync}
            onAfterOpen={this.syncOldWellsToCloud}
            wellList={this.state.oldWellNames}
            syncedWells={this.state.syncedWells}
          />
          <SwipeRightNav
            minDepth={depth.length > 0 ? depth[0] : 0}
            maxDepth={depth.length > 0 ? depth.slice(-1)[0] : 0}
            wellId={get(this.props, 'wells.currentWell.id', get(this.props, 'wells.currentWell.wellId', selectedWell))}
            data={this.state.data.totalCarbon}
            wellAction={this.props.wellAction}
            zoneAction={this.props.zoneAction}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ zone, wells, wellphases }, state) => ({
  zone,
  wells,
  wellphases,
  state
});

const mapDispatchToProps = dispatch => ({
  zoneAction: bindActionCreators(zoneActions, dispatch),
  wellAction: bindActionCreators(wellActions, dispatch),
  cutoffAction: bindActionCreators(cutoffActions, dispatch),
  wellPhasesAction: bindActionCreators(wellPhasesActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
