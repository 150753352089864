import React, { useState } from 'react';
import { Popover } from 'antd';
import './style.scss';
import infoIcon from '../../assets/info-icon.svg';

const content = (openSensor) => (
  <div>
    <p className="popup-info">
      This sensor is necessary to calculate the values in
      Relative Ethene and Biodegradation charts.
    </p>
    <p
      className="popup-info-fid-ms"
      onClick={openSensor}
    >
      What is FID and MS Sensor?
    </p>
  </div>
);

const PopupSensorType = ({ openModalSensor }) => {
  const [isVisible, setIsVisible] = useState(false)

  const closeSensor = () => setIsVisible(false);

  const openSensor = () => {
    closeSensor();
    setTimeout(() => {openModalSensor(true)}, 300);
  };
  
  const handleVisibleChange = visible => {
    setIsVisible(visible);
  };

  return(
    <Popover
      content={content(openSensor)}
      title="Sensor Type"
      visible={isVisible}
      onVisibleChange={handleVisibleChange}
      overlayClassName="popup-sensor-type"
    >
      <img
        src={infoIcon}
        alt="Sensor type"
      />
    </Popover>
  );
}

export default PopupSensorType;
