
/*
  processedXData: array with xAxis 0, 1, 2, 3, 4, 10, 40...
  processedYData: array with count of occurrences
*/
function calculatePercenteOfOCurrences(processedXData, processedYData, depthIndexToCut) {
  let indexToCut = processedXData.findIndex(xValue => parseInt(xValue) === parseInt(depthIndexToCut));

  if (indexToCut === -1) {
    indexToCut = processedXData.findIndex(xValue => parseInt(xValue) >= parseInt(depthIndexToCut));
  }

  const occurrencesGreatherThanIndexToCute = processedYData.slice(indexToCut, processedYData.length);
  const sumOfOccurrencesGreatherThanIndexToCute = occurrencesGreatherThanIndexToCute.reduce(
    (acc, v) => acc + v,
    0
  );
  const totalOfOccurrences = processedYData.reduce((acc, sum) => acc += sum, 0);
  const percenteOfOcurrences = (sumOfOccurrencesGreatherThanIndexToCute / totalOfOccurrences) * 100;
  return percenteOfOcurrences;
}

export { calculatePercenteOfOCurrences };
