import { flatten, get } from 'lodash';

export const formatterAutocompleteData = (currentWell: any) => {
  let dataSource: any = [];
  if (
    currentWell
    && currentWell.statistics
    && currentWell?.RAW?.depth?.data && currentWell?.RAW?.depth?.data?.length
  ) {
    const criticalAreasGQR = get(currentWell, 'GQR', []);

    const criticalAreasC1 = get(currentWell, 'criticalAreasC1', []);
    const criticalAreasC2 = get(currentWell, 'criticalAreasC2', []);
    const criticalAreasC3 = get(currentWell, 'criticalAreasC3', []);
    const criticalAreasNC4 = get(currentWell, 'criticalAreasC4', []);
    const criticalAreasNC5 = get(currentWell, 'criticalAreasC5', []);
    const criticalAreasIC4 = get(currentWell, 'criticalAreasIC4', []);
    const criticalAreasIC5 = get(currentWell, 'criticalAreasIC5', []);

    const criticalAreasC1Composition = get(currentWell, 'criticalAreasC1Composition', []);
    const criticalAreasC2Composition = get(currentWell, 'criticalAreasC2Composition', []);
    const criticalAreasC3Composition = get(currentWell, 'criticalAreasC3Composition', []);
    const criticalAreasC4Composition = get(currentWell, 'criticalAreasC4Composition', []);
    const criticalAreasC5Composition = get(currentWell, 'criticalAreasC5Composition', []);

    const allDepth = currentWell && currentWell.RAW.depth.data;

    const allCriticalValuesWithCurves: any = {};
    (allDepth || []).forEach((depthIndex: any, index: any) => {
      // gqr curve
      const currentGQRCriticalValue = criticalAreasGQR
        .find((criticalValueObject: any) => 
          criticalValueObject.depthIndex === index && !!criticalValueObject.shouldShow
        );
      if (currentGQRCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentGQRCriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentGQRCriticalValue];
        }
      } else {
        // when there is no critical value keep the default depth to the current depth index
        allCriticalValuesWithCurves[index] = depthIndex;
      }

      const currentC1CriticalValue = criticalAreasC1
        .find((criticalValueObject: any) => 
          criticalValueObject.depthIndex === index && !!criticalValueObject.shouldShow
        );
      if (currentC1CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentC1CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC1CriticalValue];
        }
      } else {
        // when there is no critical value keep the default depth to the current depth index
        allCriticalValuesWithCurves[index] = depthIndex;
      }

      const currentC2CriticalValue = criticalAreasC2
        .find((criticalValueObject: any) => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC2CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentC2CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC2CriticalValue];
        }
      }

      const currentC3CriticalValue = criticalAreasC3
        .find((criticalValueObject: any) => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC3CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentC3CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC3CriticalValue];
        }
      }

      const currentNC4CriticalValue = criticalAreasNC4
        .find((criticalValueObject: any) => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentNC4CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentNC4CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentNC4CriticalValue];
        }
      }

      const currentIC4CriticalValue = criticalAreasIC4
        .find((criticalValueObject: any) => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentIC4CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentIC4CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentIC4CriticalValue];
        }
      }

      const currentNC5CriticalValue = criticalAreasNC5
        .find((criticalValueObject: any) => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentNC5CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentNC5CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentNC5CriticalValue];
        }
      }

      const currentIC5CriticalValue = criticalAreasIC5
        .find((criticalValueObject: any) => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentIC5CriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentIC5CriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentIC5CriticalValue];
        }
      }

      const currentC1CompositionCriticalValue = criticalAreasC1Composition
        .find((criticalValueObject: any) => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC1CompositionCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentC1CompositionCriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC1CompositionCriticalValue];
        }
      }

      const currentC2CompositionCriticalValue = criticalAreasC2Composition
        .find((criticalValueObject: any) => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC2CompositionCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentC2CompositionCriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC2CompositionCriticalValue];
        }
      }

      const currentC3CompositionCriticalValue = criticalAreasC3Composition
        .find((criticalValueObject: any) => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC3CompositionCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentC3CompositionCriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC3CompositionCriticalValue];
        }
      }

      const currentC4CompositionCriticalValue = criticalAreasC4Composition
        .find((criticalValueObject: any) => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC4CompositionCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentC3CompositionCriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC4CompositionCriticalValue];
        }
      }

      const currentC5CompositionCriticalValue = criticalAreasC5Composition
        .find((criticalValueObject: any) => criticalValueObject.depthIndex === index && criticalValueObject.shouldShow);
      if (currentC5CompositionCriticalValue) {
        if (allCriticalValuesWithCurves[index]) {
          allCriticalValuesWithCurves[index]= [
            allCriticalValuesWithCurves[index],
            currentC5CompositionCriticalValue
          ];
        } else {
          allCriticalValuesWithCurves[index] = [currentC5CompositionCriticalValue];
        }
      }

      // fill array to add depth that there is no criticalValue
      // if (!currentC1CriticalValue && !currentC2CriticalValue 
      //   && !currentC2CriticalValue && !currentNC4CriticalValue
      //   && !currentIC4CriticalValue && !currentNC5CriticalValue
      //   && !currentIC5CriticalValue && !currentC1CompositionCriticalValue
      //   && !currentC2CompositionCriticalValue
      //   && !currentC3CompositionCriticalValue && !currentC4CompositionCriticalValue
      //   && !currentC5CompositionCriticalValue
      // ) {
      //   allCriticalValuesWithCurves[index] = [];
      // }
    });

    const criticalAreasGroupedByCurves: any = [];

    Object.keys(allCriticalValuesWithCurves).forEach((depthIndex) => {
      let criticalCurvesHeader = null;
      let currentCriticalAreaByCurves = allCriticalValuesWithCurves[depthIndex];
      if (currentCriticalAreaByCurves && currentCriticalAreaByCurves.length) {
        currentCriticalAreaByCurves = flatten(currentCriticalAreaByCurves);
      }
      if (currentCriticalAreaByCurves && currentCriticalAreaByCurves.length) {
        criticalCurvesHeader = currentCriticalAreaByCurves.reduce((acc: any, criticalValue: any) => {
          if (criticalValue && criticalValue.curveName) {
            acc += `${criticalValue.curveName}, `;
          }
          return acc;
        }, '');
        const currentDepthValue = allDepth[depthIndex];
        const criticalCurveName = criticalCurvesHeader.slice(0, criticalCurvesHeader.length - 2);
        const formattedCriticalPointName = criticalCurveName.includes('Composition')
          ? criticalCurveName.replace('Composition', '%') : criticalCurveName;
        criticalCurvesHeader = `${currentDepthValue} - Critical points to: ${formattedCriticalPointName}`;
        criticalAreasGroupedByCurves.push(criticalCurvesHeader);
      } else {
        criticalAreasGroupedByCurves.push(`${allDepth[depthIndex]}`);
      }
    });
    dataSource = criticalAreasGroupedByCurves;
    if (dataSource.length === 0) {
      dataSource = currentWell.RAW.depth.data.map((depth: any) => `${depth}`);
    }
  }
  return dataSource;
}