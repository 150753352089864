import React from 'react';
import classNames from 'classnames';
import { Select } from 'antd';
import { filterOption } from '../../utils';
import { COLORS } from '../../utils/constants';

const Option = { Select };

const SelectReferenceCurve = ({
  onChange, options, label, id, value,
  className, classLabel, selectStyle,
  placeholder, classContainer,
  optionFilterProp, defaultValue
}) => {
  return (
    <div
      className={
        classNames(
          'home__modal-form-group group-reference-curve',
          { [classContainer]: classContainer }
        )}
    >
      <label
        htmlFor={id}
        className={classLabel}
        style={{ textAlign: 'left' }}
      >
        {label === 'C4' && 'nC4'}
        {label === 'C5' && 'nC5'}
        {!['C4', 'C5'].includes(label) && label}
      </label>
      <Select
        showSearch
        id={id}
        className={className}
        defaultValue={value || defaultValue}
        value={value}
        style={selectStyle}
        placeholder={placeholder}
        optionFilterProp={optionFilterProp}
        onChange={onChange}
        filterOption={filterOption}
      >
        <Option
          value={defaultValue}
          disabled
          dropdownClassName={COLORS.color1}
          dropdownStyle={COLORS.color1}
          dropdownMenuStyle={COLORS.color1}
        >
          Select the reference curve
        </Option>
        {options.map(option => (
          <Option
            key={option.label}
            value={option.value}
          >
            {option.label}
          </Option>
        ))}
      </Select>
    </div>
  );
};

SelectReferenceCurve.defaultProperties = {
  placeholder: '',
  selectStyle: { width: '100%' },
  defaultValue: -1,
  optionFilterProp: 'children'
};

export default SelectReferenceCurve;
