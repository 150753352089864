import { fetchZones } from 'actions/zone';
import mockWellData from 'reducers/well_data_optional.json';
import mockMetadataOptionals from 'reducers/well_metadata_mock_optionals.json';
import {
  createStatistcsConfig,
  createWell,
  deleteWell,
  fetchWellSite,
  fetchWellSites,
  listStatistcsConfig,
  normalizeWellBoreToStore,
  updateStatistcsConfig,
  fetchBucketFile,
  getFileToParse,
  postBucket,
  getSignedUrl,
  updateBucketWellFile
} from 'api/wells';
import store from 'store';
import GlobalCutOff from 'utils/GlobalCutOff';
import { removeAllZones } from 'utils/Zones';
import { createCutoff } from '../api/cutoff';

import * as actions from '../constants/wells';
import {
  findOnIndexDB,
  setOnIndexDB
} from '../utils';
import { getWellSiteScales } from 'utils/getWellSiteScales';
import { getFormattedWell } from 'utils/getFormattedWell';
import { receiveCutOffs } from './cutoff';
import { getWellData, getWellMetadata, listWellsMetadata } from '../api/import-data';
import { useSelector } from 'react-redux';

export const receiveLogsetGqc = (gqc) => ({
  type: actions.RECEIVE_LOGSET_GQC,
  gqc
});

export const receiveQualityData = (data) => ({
  type: actions.RECEIVE_QUALITY_DATA,
  data
});


export const updateMetadata = (metadata) => ({
  type: actions.UPDATE_METADATA,
  metadata
});

export const receiveFilteredData = (filteredData, storeCutoff) => ({
  type: actions.FILTERED_DATA,
  filteredData,
  storeCutoff
});

export const updateWellInfo = well => ({
  type: actions.UPDATE_WELL,
  well,
});

export const receiveWells = (wells) => ({
  type: actions.RECEIVE_WELLS,
  wells,
});

export const removeCurrentWell = wellId => ({
  type: actions.DELETE_WELL,
  wellId,
});

export const registerDepthData = data => ({
  type: actions.REGISTER_DEPTH_DATA,
  data,
});

export const receiveSelectedDepthIndex = index => ({
  type: actions.RECEIVE_SELECTED_INDEX,
  index,
});

export const filterWell = wellId => ({
  type: actions.FILTER_WELL,
  wellId,
});

export const receiveWell = well => ({
  type: actions.RECEIVE_WELL,
  well
});

export const receiveMetadata = metadata => ({
  type: actions.RECEIVE_METADATA,
  metadata
});

export const updateCurrentWellData = well => ({
  type: actions.UPDATE_CURRENT_WELL_DATA,
  well
});

export const receiveSelectedMinMax = data => ({
  type: actions.RECEIVE_SELECTED_MIN_MAX,
  data
});

export const listWells = () => {
  const token = store.get('token');
  return async (dispatch) => {
    try {
      const { data: { content: wells } } = await listWellsMetadata(token);
      dispatch(receiveWells(wells))
      return wells;
    } catch (e) {
      console.log('Error listWells', e);
    }
  };
};

export const wellMetadata = (wellId) => {
  const token = store.get('token');
  return async () => {
    try {
      const { data: { content: well } } = await getWellMetadata(wellId, token);
      return well;
    } catch (e) {
      console.log('Error well metadata', e);
    }
  };
};

export const checkOldWellsAndStoreToCloud = (
  openModalToSyncWells,
  setOldWellNames,
  afterSyncWells
) => {
  return async (dispatch) => {
    const wells = await findOnIndexDB('wells');
    // const oldWells = Object.keys(wells || {})
    //   .filter(wellKey => wells[wellKey] && !wells[wellKey].pathFile);
    if (wells && wells.length) {
      openModalToSyncWells(true);
      setOldWellNames(wells.map(w => w.wellName));
      try {
        const wellPromises = wells.map(w => {
          createWell(
            normalizeWellBoreToStore(w),
            store.get('token'),
            store.get('email')
          )
        });
        await Promise.all(wellPromises);
        await setOnIndexDB('wells', {});
        afterSyncWells();
        dispatch(receiveWells([]));
        dispatch(listWells());
      } catch(e) {
        console.log('error when try to sync wells', e);
      }
    }
  };
};

export const listWell = (wellId, token, afterSuccess) => {
  return (async (dispatch, getState) => {
    try {
      const { data: { content: wellData } } = await getWellData(wellId);

      // TODO change this when list well from API
      const wellWithZone = {
        ...wellData,
      };
      const formattedWell = getFormattedWell(wellWithZone);
      const scales = getWellSiteScales(formattedWell);
      const wellSiteWithScales = {
        ...formattedWell,
        scales
      };
      dispatch(receiveWell(wellSiteWithScales));
      // if (formattedWell.cutoff) {
      //   dispatch(receiveCutOffs(formattedWell.cutoff))
      // }

      if (afterSuccess && typeof afterSuccess === 'function') {
        afterSuccess();
      }
    } catch(e) {
      console.log(e)
    }
    // try {
    //   // wells files
    //   const { data: { files } } = await fetchWellSite(wellId, token);

    //   // --------------- fetch calculated data ----------------
    //   const calculatedFileName = files && files[0] ? files[0].file_name : '';
    //   if (!files.length) {
    //     alert('There are no data for this well');
    //     return;
    //   }

    //   const responseCalculatedData = await fetchBucketFile(calculatedFileName, token);
    //   const calculatedData = await getFileToParse(
    //     responseCalculatedData.data.signed_url,
    //     token
    //   );

    //   // --------------- fetch rawData ----------------
    //   const rawDataFileName = files && files[1] ? files[1].file_name : '';
    //   const responseRawData = await fetchBucketFile(rawDataFileName, token);
    //   const rawData = await getFileToParse(
    //     responseRawData.data.signed_url
    //   );

    //   const well = {
    //     ...rawData,
    //     ...calculatedData,
    //     wellId,
    //     rawFileName: rawDataFileName,
    //     calculatedFileName,
    //     rawData,
    //     calculatedData
    //   };
    //   const selectedWell = getState().wells.currentWell;
    //   GlobalCutOff.registerDefaultData({ ...calculatedData, ...selectedWell, ...rawData, ...well });
    //   dispatch(receiveWell(well));
    //   // afterSuccess && afterSuccess();
    //   if (afterSuccess) {
    //     afterSuccess();
    //   }
    //   // map(response.zones, (zone) => {
    //   //   removeAllZones(zone.id);
    //   // });
    // } catch (e) {
    //   console.log('Error list well', e);
    // }
  })
};

export const updateWell = (conflictedWell, wellId, rawData, calculatedData, token, afterSuccess) => {
  const { rawFileName, calculatedFileName } = conflictedWell;
  return async (dispatch) => {
    try {
      const rawDataSignedURL = await getSignedUrl(
        'wellex-well-storage',
        'data',
        rawFileName,
        'raw',
        'json',
        token
      );

      const calculatedSignedURL = await getSignedUrl(
        'wellex-well-storage',
        'data',
        calculatedFileName,
        'calculated',
        'json',
        token
      );

      const rawResponse = await updateBucketWellFile(
        rawDataSignedURL.data.signed_url,
        JSON.stringify(rawData)
      );

      const calculatedResponse = await updateBucketWellFile(
        calculatedSignedURL.data.signed_url,
        JSON.stringify(calculatedData)
      );

      const rawResponseData = rawResponse ? JSON.parse(rawResponse) : {};
      const calculatedResponseData = calculatedResponse ? JSON.parse(calculatedResponse) : {};

      const wellData = {
        ...rawResponseData,
        ...calculatedResponseData
      };


      // dispatch(receiveWell({ wellId, ...rawData, ...calculatedData, wellData }));

      // afterSuccess && afterSuccess(wellId);
      // dispatch(listWell(wellId, store.get('token')));
      // dispatch(fetchZones(wellId, store.get('token')));
    } catch (e) {
      console.log('error create well', e);
    }
  };
};

export const createBucket = (wellName, rawData, calculatedData, token, afterSuccess) => {
  const payload = {
    name: wellName,
    description: '-',
    provider: 'GOLD'
  };

  return async (dispatch) => {
    try {
      const buketResponse = await postBucket(payload, token);
      const {
        file_name,
        file_extension,
        bucket_name,
        id
      } = buketResponse.data;
      const previousWellData = {
        ...rawData,
        ...calculatedData
      };
      store.set('wellId', id);

      const rawDataSignedURL = await getSignedUrl(
        bucket_name,
        'data',
        file_name,
        'raw',
        file_extension,
        token
      );

      const calculatedSignedURL = await getSignedUrl(
        bucket_name,
        'data',
        file_name,
        'calculated',
        file_extension,
        token
      );

      const rawResponse = await updateBucketWellFile(
        rawDataSignedURL.data.signed_url,
        JSON.stringify(rawData)
      );

      const calculatedResponse = await updateBucketWellFile(
        calculatedSignedURL.data.signed_url,
        JSON.stringify(calculatedData)
      );

      const rawResponseData = rawResponse ? JSON.parse(rawResponse) : {};
      const calculatedResponseData = calculatedResponse ? JSON.parse(calculatedResponse) : {};

      const wellData = {
        ...rawResponseData,
        ...calculatedResponseData
      };
      dispatch(listWells());
      dispatch(receiveWell({ ...wellData, id, wellId: id }));
      afterSuccess && afterSuccess(id);
    } catch (e) {
      console.log('error create well', e);
    }
  };
};

export const removeWell = (token, wellId) => {
  return async (dispatch) => {
    try {
      const response = await deleteWell(token, wellId);
      dispatch(removeCurrentWell({ wellId }));
      dispatch(listWells());
      return response;
    } catch (e) {
      console.log('error remove well', e);
    }
  };
};

export const receiveStatistics = (statistics) => ({
  type: actions.RECEIVE_STATISTICS,
  statistics
});

export const receiveCriticalAreas = (criticalAreas) => ({
  type: actions.RECEIVE_CRITICAL_AREAS,
  criticalAreas
});

export const getStatistcsConfig = (wellId, afterSucess) => {
  return async (dispatch) => {
    try {
      const { data } = await listStatistcsConfig(wellId, store.get('token'));
      dispatch(receiveStatistics(data));
      afterSucess && afterSucess();
    } catch (e) {
      console.log('storeStatisticConfig', e);
    }
  };
};

export const storeStatistcsConfig = (config, wellId) => {
  return async (dispatch) => {
    try {
      await createStatistcsConfig(config, store.get('token'));
      dispatch(getStatistcsConfig(wellId));
    } catch (e) {
      console.log('storeStatisticConfig', e);
    }
  };
};

export const updateStatistcsConfigAction = (config, configId, wellId) => {
  return async (dispatch) => {
    try {
      await updateStatistcsConfig(config, store.get('token'), configId);
      await dispatch(getStatistcsConfig(wellId));
    } catch (e) {
      console.log('storeStatisticConfig', e);
    }
  };
};


