import React, { Component } from 'react';
import Highcharts from 'highcharts';
import { Button } from 'antd';
import Exporting from 'highcharts/modules/exporting';
import classNames from 'classnames';
import { drawPath, drawPathWithPadding } from '../../../utils/Svg';
import {
  getWetnessPointsByZones, filterToAvoidZeroYAxis
} from '../../../utils';

Exporting(Highcharts);

const P1 = [4.33 , 20.8];
const P2 = [8.3, 28];
const P3 = [0.39, 96.5];
const P4 = [0.2, 89];
const P5 = [11, 2.8];
const P6 = [465.08, 0];
const P7 = [540.08, 17.5];
const P8 = [13.3, 25.2];

const interestedCoordinates = [
  P1,
  P2,
  P3,
  P4,
  P5,
  P6,
  P7,
  P8
];

const P1X2 = 4.33;
const P1Y2 = 20.8;

const P2X3 = 8.3;
const P2Y3 = 28;

const P3X1 = 0.39;
const P3Y1 = 96.5;

const P4X1 = 0.02;
const P4Y1 = 89;

const P5X = 11;
const P5Y = 2.8;

const P6X = 465.06;
const P6Y = 0;

const P7X = 540.08;
const P7Y = 17.5;

const P8X = 13.3;
const P8Y = 25.2;

// Oil area
const drawOil = chart => drawPathWithPadding(77)(
  P4X1, P4Y1,
  P1X2, P1Y2,
  P2X3, P2Y3,
  P3X1, P3Y1,
  chart,
  {
    'fill': '#d1dabc',
    'stroke': '#718656',
    'stroke-width': 2,
    'opacity': '0.5'
  },
  true
);


// Gas area
const drawGas = (chart) => drawPath(
  P5X, P5Y,
  P6X, P6Y,
  P7X, P7Y,
  P8X, P8Y,
  chart,
  {
    'fill': '#f1a7a7',
    'stroke': '#f15555',
    'stroke-width': 2,
    'opacity': '0.5'
  }
);

// Transition area
const drawTransition = (chart) => drawPath(
  P1X2, P1Y2,
  P5X, P5Y,
  P8X, P8Y,
  P2X3, P2Y3,
  chart,
  {
    'fill': '#f4c094',
    'stroke': '#ed713e',
    'stroke-width': 2,
    'opacity': '0.5'
  }
);

const getDefaultSeries = (c1Data, c2Data) => {
  return [
    {
      name: '',
      color: 'rgba(0, 0, 0, 0)',
      data: [],
      marker: {
        fillColor: 'rgba(0, 0, 0, 0)',
        symbol: 'circle',
        radius: 2
      },
      allowDecimals: true
    },
    {
      name: '',
      color: 'rgba(231, 76, 60, 0.5)',
      data: c2Data || [],
      marker: {
        fillColor: 'rgba(231, 76, 60, 0.5)',
        symbol: 'circle',
        radius: 3
      },
      allowDecimals: true
    }
  ];
};

/*
 * BR6 - Balance
 * BQ6 - Wetness
 * =IF(AND(BR6<=13,2;BR6>=5,6;BQ6<=24,8;BQ6>=11,8)=TRUE;20;IF(BR6<=10;10;30)
*/
function wetnessBalanceNormalization(wetness, balance) {
  const BR6 = balance;
  const BQ6 = wetness;
  if (BR6 <= 13.2 && BR6 >= 5.6 && BQ6 <= 24.8 && BQ6 >= 11.8) {
    return 20;
  }
  if (BR6 <= 10) {
    return 10;
  }
  return  30;

}

class ScatterPlotWetness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true
    };
    this.chart = null;
  }

  componentDidMount() {
    this.createScatterPlot();

    this.setupChart();
  }

  shouldComponentUpdate(nextProps) {
    const { data } = this.props;
    if ((nextProps.data && nextProps.data.length) !== (data && data.length)) {
      this.createScatterPlot(nextProps);
      this.setupChart();
      return true;
    }
    return false;
  }

  setupChart = () => {
    this.drawOil = () => drawOil(this.chart);
    this.drawGas = () => drawGas(this.chart);
    this.drawTransition = () => drawTransition(this.chart);

    // Oil
    this.chart.oil = this.drawOil();
    // Gas area
    this.chart.gas = this.drawGas();
    // Transition area
    this.chart.transition = this.drawTransition();

    const { idListener } = this.props;

    document.addEventListener(`${idListener}-chart3`, ({ detail }) => {
      const { config } = detail;
      const {yAxis, xAxis} = this.chart;
      if (yAxis && yAxis[0]) {
        yAxis[0].update({
          min: config.y.min,
          max: config.y.max,
          title: {
            text: config.y.title
          }
        });
      }
      if (xAxis && xAxis[0]) {
        xAxis[0].update({
          min: config.x.min,
          max: config.x.max,
          title: {
            text: config.x.title
          }
        });
      }

      this.chart.oil.destroy();
      this.chart.oil = this.drawOil();

      this.chart.gas.destroy();
      this.chart.gas = this.drawGas();

      this.chart.transition.destroy();
      this.chart.transition= this.drawTransition();

      this.setState({
        isVisible: config.isVisible
      });
    });
  }

  createScatterPlot = (nextProps) => {
    const { id, data, receivePointsIsideZone, dataWithDepth } =  nextProps || this.props;

    const filteredToAvoidZeroValue = filterToAvoidZeroYAxis(data);

    const oilP1 = { x: P1[0], y: P1[1] };
    const oilP2 = { x: P2[0], y: P2[1] };
    const oilP3 = { x: P3[0], y: P3[1] };
    const oilP4 = { x: P4[0], y: P4[1] };

    const gasP5 = { x: P5[0], y: P5[1] };
    const gasP6 = { x: P6[0], y: P6[1] };
    const gasP7 = { x: P7[0], y: P7[1] };
    const gasP8 = { x: P8[0], y: P8[1] };

    const resultInterestedArea = getWetnessPointsByZones(
      filteredToAvoidZeroValue,
      oilP1, oilP2, oilP3, oilP4,
      gasP5, gasP6, gasP7, gasP8
    );

    if (resultInterestedArea) {
      const filteredPoints = this.shouldFilterPointsWithinZoneByDepth(
        dataWithDepth,
        resultInterestedArea
      );
      receivePointsIsideZone(filteredPoints);
    }

    const getTooltipWithDepth = (dataWithDepth = []) => ({
      headerFormat: '<b>{series.name}</b><br>',
      // pointFormat: `x: {point.x}, y: {point.y}`,
      formatter: function() {
        const itemWithDepth = dataWithDepth?.find(
          value => value?.item?.[0] === this?.x && value?.item?.[1] === this?.y
        );
        return `
          <b>Depth</b>: ${itemWithDepth?.depth}<br/>
          <b>BH - Balance</b>: ${itemWithDepth?.item?.[0]?.toFixed(2)}  <br/>
          <b>WH - Wetness</b>: ${itemWithDepth?.item?.[1]?.toFixed(2)}
        `;
      },
      enabled: true
    });

    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'scatter'
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      credits: {
        enabled: false
      },
      fillOpacity: 1,
      opacity: 1,
      exporting: { enabled: false },
      xAxis: {
        title: {
          enabled: true,
          text: 'BH - Balance'
        },
        type: 'logarithmic',
        minorTickInterval: 0.1,
        pointStart: 0.1,
        max: 1000,
        min: 0.1
      },
      yAxis: {
        title: {
          text: 'WH - Wetness'
        },
        allowDecimals: true,
        tickInterval: 10,
        max: 100,
        pointStart: 0.0001,
        min: 0,
        gridLineWidth: 0,
        lineWidth: 1,
        lineColor: '#ccd6eb'
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: -14,
        floating: true,
        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
        borderWidth: 0
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 4,
            states: {
              hover: {
                enabled: false,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          // tooltip: {
          //   headerFormat: '<b>{series.name}</b><br>',
          //   pointFormat: '{point.x} x, {point.y} y',
          //   enabled: true
          // }
        }
      },
      tooltip: getTooltipWithDepth(this?.props?.dataWithDepth),
      series: getDefaultSeries(interestedCoordinates, filteredToAvoidZeroValue)
    });
  }

  // TODO LEO might refactor it
  // implement the filter and normalization
  shouldFilterPointsWithinZoneByDepth = (dataWithDepth, resultInterestedArea) => {
    const filteredPoints = dataWithDepth.reduce((acc, currentValue) => {
      resultInterestedArea.oil.reduce((acc2, oilPoint) => {
        if (
          JSON.stringify(oilPoint) === JSON.stringify(currentValue.item)
        ) {
          const { depth, wetness, balance } = currentValue;
          const normalizedX = wetnessBalanceNormalization(balance, wetness);
          acc.push([normalizedX, depth]);
        }
        return acc2;
      }, []);
      resultInterestedArea.gas.reduce((acc3, gasPoint) => {
        if (
          JSON.stringify(gasPoint) === JSON.stringify(currentValue.item)
        ) {
          const { depth, wetness, balance } = currentValue;
          const normalizedX = wetnessBalanceNormalization(balance, wetness);
          acc.push([normalizedX, depth]);
        }
        return acc3;
      }, []);
      resultInterestedArea.transition.reduce((acc4, transitionPoint) => {
        if (
          JSON.stringify(transitionPoint) === JSON.stringify(currentValue.item)
        ) {
          const { depth, wetness, balance } = currentValue;
          const normalizedX = wetnessBalanceNormalization(balance, wetness);
          acc.push([normalizedX, depth]);
        }
        return acc4;
      }, []);
      return acc;
    }, []);
    return filteredPoints;
  }

  render() {
    const {
      id, withMinorGridLines, className, openConfigureScale
    } = this.props;
    const { isVisible } = this.state;
    return (
      <div
        className="scatter-general wetness-container"
        style={{
          width: '400px',
          display: isVisible ? 'flex' : 'none',
          alignItems: 'center'
        }}
      >
        {
          openConfigureScale && (
            <Button
              type="primary"
              shape="circle"
              icon="setting"
              size="large"
              className="toggle-crossplot-config--right"
              onClick={openConfigureScale}
            />
          )
        }
        <div
          className={classNames(
            className,
            'deactivate-legend',
            { 'reset-minor-grids': !withMinorGridLines }
          )}
          id={id}
          style={{
            height: '330px',
            width: '360px',
            flex: 1
          }} />
      </div>
    );
  }
}

ScatterPlotWetness.defaultProps = {
  withMinorGridLines: false,
  className: 'scatter-plot'
};

export default ScatterPlotWetness;

