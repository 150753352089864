import React, { Component } from 'react';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { Button } from 'antd';
import classNames from 'classnames';
import Rectangle from './Rectangle';
import ArrowLine from './ArrowLine';

Exporting(Highcharts);

const c1 = [
  [10,50], [10,60],
  [7,20], [4,30]
];

class C2ByC3 extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
  }

  componentDidMount() {
    this.createScatterPlot();

    const { idListener } = this.props;

    document.addEventListener(`${idListener}-chart7`, ({ detail }) => {
      const { config } = detail;
      const {yAxis, xAxis} = this.chart;
      if (yAxis && yAxis[0]) {
        yAxis[0].update({
          min: config.y.min,
          max: config.y.max,
          title: {
            text: config.y.title
          }
        });
      }
      if (xAxis && xAxis[0]) {
        xAxis[0].update({
          min: config.x.min,
          max: config.x.max,
          title: {
            text: config.x.title
          }
        });
      }

      this.setState({
        isVisible: config.isVisible
      });
    });
  }

  createScatterPlot = () => {
    const { id, data } = this.props;

    const getTooltipWithDepth = (dataWithDepth = []) => ({
      headerFormat: '<b>{series.name}</b><br>',
      // pointFormat: `x: {point.x}, y: {point.y}`,
      formatter: function() {
        const itemWithDepth = dataWithDepth?.find(
          value => value?.x === this?.x && value?.y === this?.y
        );
        return `
          <b>Depth</b>: ${itemWithDepth?.depth}<br/>
          <b>(C2/C3)</b>: ${itemWithDepth?.x?.toFixed(2)}<br/>
          <b>(C1/C2)</b>: ${itemWithDepth?.y?.toFixed(2)}
        `;
      },
      enabled: true
    });
  
    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'scatter'
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      credits: {
        enabled: false
      },
      fillOpacity: 1,
      opacity: 1,
      exporting: { enabled: false },
      xAxis: {
        title: {
          enabled: true,
          text: '<b>C2/C3</b>'
        },
        startOnTick: true,
        endOnTick: true,
        showLastLabel: true,
        pointStart: 0,
        tickInterval: 1,
        max: 10,
        min: 0,
        gridLineWidth: 0,
        gridLineColor: 'blue'
      },
      yAxis: {
        title: {
          text: '<b>C1/C2</b>'
        },
        gridLineWidth: 0,
        lineWidth: 1,
        lineColor: '#ccd6eb',
        pointStart: 10,
        max: 100,
        min: 1,
        tickStart: 1,
        type: 'logarithmic',
        minorTickInterval: 0.1
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: -14,
        floating: true,
        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
        borderWidth: 0
      },
      plotOptions: {
        scatter: {
          marker: {
            lineWidth: 2,
            radius: 5,
            lineColor: 'rgba(255, 160, 97, 0.98)',
            states: {
              hover: {
                enabled: true
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: true
              }
            }
          },
          // tooltip: {
          //   headerFormat: '<b>{series.name}</b><br>',
          //   pointFormat: '{point.x} cm, {point.y} kg'
          // }
        }
      },
      tooltip: getTooltipWithDepth(this?.props?.dataWithDepth),
      series: [{
        name: 'C2',
        color: 'rgba(255,224,1)',
        zIndex: 100,
        data,
        marker: {
          widthLine: 1,
          fillColor: 'rgba(255,224,1)',
          symbol: 'circle',
          zIndex: 100,
          width: 1,
          height: 1,
          hover: true,
          radius: 2.5,
          states: {
            hover: {
              enabled: true,
              lineColor: 'rgba(255, 160, 97, 0.98)'
            }
          }
        }
      }]
    });
  }

  render() {
    const {
      id, withMinorGridLines, className,deactivateLegend, openConfigureScale
    } = this.props;
    return (
      <div className="scatter-general" style={{ width: '400px'}} >
        {/* {
          openConfigureScale && (
            <Button
              type="primary"
              shape="circle"
              icon="setting"
              size="large"
              className="toggle-crossplot-config"
              onClick={openConfigureScale}
            />
          )
        } */}
        <ArrowLine
          className="c2-c3-arrow-top"
          text="Biodegradation"
          fillColor="#333"
          textColor="#333"
        />
        <Rectangle
          fillColor="rgba(113, 134, 86, 0.7)"
          strokeColor="#718656"
          strokeWidth={2}
          rotate={0}
          rectX={18}
          rectY={50}
          rectWidth={102}
          rectHeight={190}
          viewBox="0 0 158 169"
          svgWidth={114}
          svgHeight={261}
          zIndex={1}
          customStyle={{ opacity: '1' }}
        />
        {/* Red */}
        <Rectangle
          fillColor="#f5a6a7"
          strokeColor="#333"
          strokeWidth={1}
          rotate={0}
          rectX={4}
          rectY={54}
          rectWidth={103}
          rectHeight={92}
          viewBox="0 0 100 169"
          svgWidth={327}
          zIndex={1}
          svgHeight={280}
          customStyle={{ opacity: '0.8' }}
        />
        {/* Orange */}
        <Rectangle
          fillColor="#ffb779"
          strokeColor="#f15555"
          rotate={-90}
          rectX={-20}
          rectY={12}
          rectWidth={88}
          rectHeight={221}
          viewBox="0 0 240 100"
          svgWidth={268}
          zIndex={1}
          svgHeight={300}
          customStyle={{ opacity: '0.8' }}
        />
        <ArrowLine
          className="c2-c3-arrow-bottom"
          text="Biodegradation"
          fillColor="#333"
          textColor="#333"
        />
        <div
          className={
            classNames(
            className,
            { 'reset-minor-grids': !withMinorGridLines },
            { 'deactivate-legend': deactivateLegend }
          )
        }
          id={id}
          style={{
            zIndex: 20,
            minWidth: '316px',
            height: '280px',
            maxWidth: '330px',
            flex: 1
          }} />
      </div>
    );
  }
}

C2ByC3.defaultProps = {
  withMinorGridLines: false,
  className: 'scatter-plot',
  deactivateLegend: true
};


export default C2ByC3;
