import React from 'react';
import { Spin } from 'antd';
import './style.scss';

const Overlay = ({ show = false }) => {
  return (
    <div
      data-cy="overlay-load"
      className={`overlay-wrapper overlay-${show ? 'show' : 'hide'}`}
    >
      <div className="overlay-loading-wrapper">
        <div className="overlay-load">
          <Spin size="large" />
        </div>
        <span className="overlay-title">Loading...</span>
      </div>
    </div>
  )
};

export default Overlay;
