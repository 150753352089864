import React from 'react';
import classNames from 'classnames';

const Spinner = ({ classes }: { classes: string }) => (
  <div
    className={classNames(
      'spinner',
      {
        [classes]: !!classes
      }
    )}
  />
);

export default Spinner;
