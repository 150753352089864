import { warningNotification, successNotification } from ".";

export const validateCurveConfigurationForm = (
  formCurve,
  {window_length_value, difference_type, moving_average_type, sensitivity_value},
  setValidFormsNames,
  validFormsCurves
) => {
  const windowLength = window_length_value;
  const checkBoxMetrics = difference_type;
  const checkBoxModel = moving_average_type;
  const sensitivity = sensitivity_value;

  const isSimpleMoveAverageForm = !!(!!windowLength && windowLength !== '' && windowLength !== undefined)
    && !!checkBoxModel
    && checkBoxModel === 'SMA'
    && checkBoxMetrics !== '' && checkBoxMetrics !== undefined;

  const isExponentialValidForm = !!(!!windowLength && windowLength !== '' && windowLength !== undefined)
    && checkBoxModel !== '' && checkBoxModel !== undefined
    && checkBoxModel === 'EMA'
    && checkBoxMetrics !== '' && checkBoxMetrics !== undefined
    && sensitivity !== '' && sensitivity !== undefined;

  const filteredForms = validFormsCurves.filter(formName => formName !== formCurve);
  if (isSimpleMoveAverageForm || isExponentialValidForm) {
    setValidFormsNames([...filteredForms, formCurve]);
    successNotification(`${formCurve} curve configs saved successfully`);
  } else {
    warningNotification(`Configure the ${formCurve} curve before saving`);
    setValidFormsNames(filteredForms);
  }
};
