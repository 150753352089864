import { Button } from '@mui/material';
import styled from 'styled-components';
import { ButtonStyleProps } from './types';

const ButtonBase = styled(Button)<ButtonStyleProps>`
	&& {
		color: #F5FAFF;
		font-size: 16px;
		height: 36px;
		padding: 8px 16px 8px 16px;
		text-transform: none;
		border-radius: 6px;
		font-weight: 400;
		white-space: nowrap;
		min-width: ${(props) => props.width};
	}
`;

export default ButtonBase;
