import React, { 
  ReactNode, useEffect, useState,
  createContext, useContext, useMemo,
  SetStateAction, Dispatch as DispatchReact
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import store from 'store';
import { get } from 'lodash';
import useSWR from 'swr';

type ContextValue = {
  isLoading: boolean;
  setIsLoading: DispatchReact<SetStateAction<boolean>>;
  update: boolean;
  setUpdate: DispatchReact<SetStateAction<boolean>>;
  isActive: boolean;
} | undefined;

export const RealtimeContext = createContext(undefined as ContextValue);

// TODO leo should define generic types
type ProviderProps = {
  children: ReactNode;
  listWell: any;
  parseWellDataIntegration: any;
  updateWellData: any;
  fetchInWellWatcherSite: any;
  afterSuccess: any;
  afterError: any;
  WELLEX_DATA_TEMP_URL: any;
  refreshTime?: number;
};

export const RealtimeProvider = ({
  children,
  listWell,
  parseWellDataIntegration,
  updateWellData,
  fetchInWellWatcherSite,
  afterSuccess,
  afterError,
  WELLEX_DATA_TEMP_URL,
  refreshTime,
}: ProviderProps) => {
  const TIME_INTERVAL = 1000 * (refreshTime || 60);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [dataUpdated, setDataUpdated] = useState(null);
  const [wellData, setWellData] = useState(null);
  const dispatch = useDispatch();

  const wells = useSelector((state: any) => state?.wells) || {};
  const currentWell = get(wells, 'currentWell', null);

  const isActive = currentWell?.isActive || currentWell?.is_active;
  const accessToken = store.get('token');

  const {
    source, source_uid, source_uid_well,
  } = currentWell?.source_info || {};

  const fetcher = (url: string) => axios.get(
    url,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  ).then(res => res.data);

  const API_URL = `${WELLEX_DATA_TEMP_URL}/wells/${source}/${source_uid}/${source_uid_well}`;

  const { data: well_metadata } = useSWR(
    () => update ? `${API_URL}` : null,
    fetcher,
    { 
      revalidateOnFocus: false,
      refreshInterval: TIME_INTERVAL
    }
  );

  const getWellData = async ({ source, source_uid, source_uid_well }: any, token: any) => {
    const { data } = await fetchInWellWatcherSite(
      source, source_uid,
      source_uid_well,
      token
    );
    setWellData(data);
  };

  // TODO Leo should move each function out of this hook
  function parseWellData(payload: any, token: any) {
    parseWellDataIntegration(payload, token).then((response: any) => {
      setDataUpdated(response?.data);
    }).catch((e: any) => {
      console.error(e);
      afterError('Error Updating Well Data');
    });
  };

  function getUpdatePayload(currentWell: any, dataUpdated: any) {
    const existingLogSets = currentWell?.log_sets || [];
    let updatedLogSets: any = []
    const newLogSet =  {
      name: currentWell?.log_sets?.RAW?.name,
      curves: currentWell?.log_sets?.RAW?.curves,
      index_curve: currentWell?.log_sets?.RAW?.index_curve,
      well_phases: currentWell?.log_sets?.RAW?.well_phases,
      provider: dataUpdated?.content?.log_sets[0]?.provider,
      start_depth: dataUpdated?.content?.log_sets[0]?.start_depth,
      step: dataUpdated?.content?.log_sets[0]?.step,
      stop_depth: dataUpdated?.content?.log_sets[0]?.stop_depth,
    };
    
    Object.keys(existingLogSets).forEach((set:any) =>{
       if (set === 'CUTTINGS') {
         updatedLogSets.push(dataUpdated?.content?.log_sets?.find((logSet:any) => logSet.name === 'CUTTINGS'))
      } else if (set === 'RAW') {
        updatedLogSets.push(newLogSet)
      }
    });

    return {
      well_uid: currentWell.well_uid,
      well_metadata: {
        ...dataUpdated.content,
        log_sets: updatedLogSets,
      },
    };
  }

  function sendUpdatedWell(payload: any, uid: any, dispatch: any) {
    updateWellData(payload, uid).then((response: any) => {
      const message = response.data.message;
      const well_uid = response.data?.content?.well_data?.well_uid;
      const currentWell = response.data?.content?.well_metadata;
      afterSuccess(message);
      dispatch(listWell(well_uid, accessToken, currentWell));
    }).catch((error: any) => {
      console.error(error);
      afterError('Error Updating Well Data');
    });
  }

  useEffect(() => {
    if (currentWell?.source_info && update) {
      getWellData(currentWell.source_info, accessToken).catch(console.error);
    }
  }, [well_metadata]);

  useEffect(() => {
    if (wellData) {
      const payload = { well_metadata, well_data: wellData };
      parseWellData(payload, accessToken);
    }
  }, [wellData]);

  useEffect(() => {
    if (dataUpdated) {
      const updatePayload = getUpdatePayload(currentWell, dataUpdated);
      sendUpdatedWell(updatePayload, currentWell.well_uid, dispatch);
    }
  }, [dataUpdated]);

  const state = useMemo(() => {
    return {
      isLoading,
      setIsLoading,
      update,
      setUpdate,
      isActive
    };
  }, [
    isLoading,
    setIsLoading,
    update,
    setUpdate,
    isActive
  ]);

  return (
    <RealtimeContext.Provider value={state}>
      {children}
    </RealtimeContext.Provider>
  );
};

export const useRealtimeContext = () => {
  const context = useContext(RealtimeContext);
  if (context === undefined) {
    throw new Error('useRealtimeContext must be used within a RealtimeProvider');
  }
  return context;
};

export default useRealtimeContext;
