import axios from 'axios';

export const API_URL = 'https://prod.gateway.geowellex.com/wellex-gold/v2';

export const resource = 'zones';

export function getAllZones(wellId, token) {
  return axios.get(`${API_URL}/zones/?wellId=${wellId}`, {
    headers: {
      'access-token': token,
      'Content-type': 'application/json'
    }
  });
}

export function insertZone(data, token) {
  console.log('data', data);
  return axios.post(
    `${API_URL}/${resource}/`,
    data,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function updateZoneInfo(zoneId, data, token) {
  return axios.put(
    `${API_URL}/${resource}/${zoneId}`,
    data,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function removeZone(zoneId, token) {
  return axios.delete(
    `${API_URL}/zones/${zoneId}`,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}
