import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { Slider, Select, Button, Spin, Checkbox, message } from 'antd';
import { useFormik } from 'formik';
import { findMinMaxDepth, findIndexDepth} from 'lib/Well';
import { fetchWellSite } from 'api/wells';
import { listWell } from '../../../actions/wells';
import {
  createPlotLine, generateConfigPlotLine
} from '../../../utils';
import { useDispatch } from 'react-redux';
import ChartContainer from '../../Shared/ChartContainer';
import RenderChart from './RenderChart';
import { getConfig, generateSerie, defaultConfigAxis } from './config';
import Settings  from './Settings';
import Input from '../../Input';
import { useParams } from 'react-router-dom';
import { getCurveData } from '../../../utils/getCurveDataBySet';

import './styles.scss';

const { Option } = Select;

function avoidZero(value) {
  if (!value || value < 1) {
    return 0.01;
  }
  return value;
}

Exporting(Highcharts);
const createChart = (
  config,
  id
) => {
  const chart = new Highcharts.Chart(id, {
    ...config,
    chart: {
      ...config.chart
    }
  });
  return chart;
};

export const curveKeys = [
  'c1',
  'c2',
  'c3',
  'nc4',
  'ic4',
  'nc5',
  'ic5',
  'isotope',
  'c1Composition',
  'c2Composition',
  'c3Composition',
  'nc4Composition',
  'nc5Composition'
];

const DEFAULT_MIN = 100;
const DEFAULT_MAX = 10000;

export const curvesOptions = [
  { label: 'C1', value: 'c1' },
  { label: 'C2', value: 'c2' },
  { label: 'C3', value: 'c3' },
  { label: 'Nc4', value: 'nc4' },
  { label: 'Ic4', value: 'ic4' },
  { label: 'Nc5', value: 'nc5' },
  { label: 'Ic5', value: 'ic5' },
  { label: 'Isotope', value: 'isotope' },
  { label: 'C1%',  value:'c1_perc' },
  { label: 'C2%', value: 'c2_perc' },
  { label: 'C3%', value: 'c3_perc' },
  { label: 'C4%', value: 'c4_perc' },
  { label: 'C5%', value: 'c5_perc' }
];

const getDataByCurve = (curve, wellData) => {
  const balanceRatioData = getCurveData('bh_ratio', wellData, 'calculated');
  const wetnessRatioData = getCurveData('wh_ratio', wellData, 'calculated');
  let formattedCurve = curve.includes('%') ? `${curve.replace('%', '').toLowerCase()}Composition` : curve;

  if (formattedCurve === 'c4Composition' || formattedCurve === 'c5Composition') {
    formattedCurve = `n${formattedCurve}`
  }
  
  const rawCurves = [
    'c1', 'c2', 'c3', 'nc4', 'ic4', 'nc5', 'ic5'
  ];
  const setName = rawCurves.includes(curve) ? 'RAW' : 'calculated';
  const curveData = getCurveData(curve, wellData, 'RAW')?.data || [];
  const currentData = curveData || [];
  
  const dataWh = currentData.reduce((acc, v, index)  => {
    acc.push([
      avoidZero(v, 1),
      avoidZero(wetnessRatioData?.data?.[index], 1)
    ]);
    return acc;
  }, []);

  const dataBh = currentData.reduce((acc, v, index)  => {
    acc.push([
      avoidZero(v, 1),
      avoidZero(balanceRatioData?.data?.[index], 1)
    ]);
    return acc;
  }, []);

  return { dataWh, dataBh };
};

const cuttedMinData = (newIndexMin, newIndexMax, whData) =>
  whData.slice(newIndexMin, newIndexMax);

const cuttedMaxData = (newIndexMin, newIndexMax, bhData) =>
  bhData.slice(newIndexMin, newIndexMax);

export const findNumberMinIndex = (depthData, numberMin) =>
  depthData.findIndex(depthValue => Number(depthValue.toFixed()) === numberMin);

export const findNumberMaxIndex = (depthData, numberMax) =>
  depthData.findIndex(depthValue => Number(Math.floor(depthValue)) === numberMax);

const updateAxisCofig = (indexYAxis, axisIndex, serieIndex, typeAxis) => (
  chart, min, max, axisTitle, color
) => {
  store.set(typeAxis, color);
  chart.yAxis[indexYAxis].update({
    title: { text: axisTitle },
    min,
    max
  }, false);
  chart.axes[axisIndex].series[serieIndex].update({
    color
  }, false);
  chart.redraw();
};

const updateLeftAxis = updateAxisCofig(0, 1, 0, 'yLeft');
const updateRightAxis = updateAxisCofig(1, 1, 1, 'yRight');

const getColorFromStore = (axisType) => store.get(axisType);

const getMinMaxConfig = (curveName) => curveName.includes('%')
  ? { min: 0.1, max: 100 } : { min: DEFAULT_MIN, max: DEFAULT_MAX };

const getCurrentCurveConfig = (curveName) => {
  const selectedCurve = `cutoff-${curveName}`;
  return store.get(selectedCurve) || {
    ...getMinMaxConfig(curveName),
    firstCheckbox: false,
    secondCheckbox: false
  };
};

const CutOffChart = () => {
  const defaultYLeftColor = getColorFromStore('yLeft');
  const defaultYRightColor = getColorFromStore('yRight');

  const inititalConfigAxis = {
    ...defaultConfigAxis,
    yLeft: {
      ...defaultConfigAxis.yLeft,
      color: defaultYLeftColor || defaultConfigAxis.yLeft.color
    },
    yRight: {
      ...defaultConfigAxis.yRight,
      color: defaultYRightColor || defaultConfigAxis.yRight.color
    }
  };

  const wells = useSelector(state => state.wells);

  const [visible, setVisible] = useState(false);
  const [chart, setChart] = useState(null);
  const [subscribeKeysIdValue, setSubscribeKeysId] = useState(null);
  const dispatch = useDispatch();
  // Select component value
  const [depth, setDepth] = useState([]);
  const [cutMin, setMinIndex] = useState(0);
  const [cutMax, setMaxIndex] = useState(0);
  const [scales, setMin] = useState({ min: 0, max: 0 });
  const [loading, setLoad] = useState(true);
  const [loadingSettings, setLoadSettings] = useState(false);
  const [cutOffConfig, setCutOffConfig] = useState(inititalConfigAxis);
  const [currentCurve, setCurrentCurve] = useState('c1');
  const [data, setData] = useState(null);
  const [tabKey, setTabKey] = useState(null);
  const [errorCurve, setErrorCurve] = useState();

  const defaultCurveConfig = getCurrentCurveConfig(currentCurve);

  const params = useParams();

  // checkboxes
  const [firstCheckbox, setFirstCheckbox] = useState(defaultCurveConfig.firstCheckbox);
  const [secondCheckbox, setSecondCheckbox] = useState(defaultCurveConfig.secondCheckbox);

  const handleFirstCheckbox = (event) => {
    const { checked } = event.target;
    setFirstCheckbox(checked);
    const curveConfig = getCurrentCurveConfig(currentCurve);
    store.set(`cutoff-${currentCurve}`, { ...curveConfig, curveName: currentCurve, firstCheckbox: checked });
  };

  const handleSecondCheckbox = (event) => {
    const { checked } = event.target;
    setSecondCheckbox(checked);
    const curveConfig = getCurrentCurveConfig(currentCurve);
    store.set(`cutoff-${currentCurve}`, { ...curveConfig, curveName: currentCurve, secondCheckbox: checked });
  };

  const [curveMin, setCurveValueMin] = useState(defaultCurveConfig.min);
  const [curveMax, setCurveValueMax] = useState(defaultCurveConfig.max);

  const { values, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues: {
      curve1: currentCurve,
      curveValue1: curveMin,
      operator1: '>',
      curve2: currentCurve,
      curveValue2: curveMax,
      operator2: '<'
    },
    onSubmit: formValues => {
      if (Number(formValues.curveValue1) < Number(formValues.curveValue2)) {
        setErrorCurve('');

        const listOfApplyedCutoffs = curveKeys.reduce((acc, curve) => {
          const rowNumber = acc.length + 1;
          let rowCount = rowNumber;
          const currentCurveconfig = getCurrentCurveConfig(curve);
          if (currentCurveconfig && currentCurveconfig.firstCheckbox || currentCurveconfig && currentCurveconfig.secondCheckbox) {
            const { curveName, min, max } = currentCurveconfig;
            if (currentCurveconfig.firstCheckbox) {
              const filter1 = {
                currentFilter: {
                  [`curve2Row${rowNumber}`]: min,
                  [`checkbox${rowNumber}`]: true,
                  [`curve1Row${rowNumber}`]: curveName.toLocaleUpperCase(),
                  [`operator${rowNumber}`]: '>'
                },
                expression: '&&',
                filterFnExpressions: 'return &&',
                filterKey: curveName,
                filterFnKey: curveName,
                curveName,
                operator: '&&',
                expressions: `${curveName} > ${min}`
              };
              rowCount += 1;
              acc.push(filter1);
            }
            if (currentCurveconfig.secondCheckbox) {
              const filter2 = {
                currentFilter: {
                  [`curve1Row${rowCount}`]: curveName.toLocaleUpperCase(),
                  [`checkbox${rowCount}`]: true,
                  [`curve2Row${rowCount}`]: max,
                  [`operator${rowCount}`]: '<'
                },
                expression: '&&',
                filterFnExpressions: 'return &&',
                filterKey: curveName,
                filterFnKey: curveName,
                curveName,
                operator: '&&',
                expressions: `${curveName} < ${max}`
              };
              acc.push(filter2);
            }
            // if (!currentCurveconfig.firstCheckbox && !currentCurveconfig.secondCheckbox) {
            //   store.remove(`cutoff-${currentCurve}`);
            // }
          }
          return acc;
        }, []);
        store.set('guideline-cutoff', listOfApplyedCutoffs);
        message.success('Cutoffs updated successfully on main form.');
        setTimeout(() => {
          window.close();
        }, 3000);
      } else {
        setErrorCurve('Please, first value should be less than second value');
      }
    }
  });

  useEffect(() => {
    if (currentCurve.includes('%') && chart) {
      chart.xAxis[0].update({
        min: 0.1,
        max: 100
      });
      chart.redraw();
    } else if (chart) {
      chart.xAxis[0].update({
        min: defaultConfigAxis.xAxis.min,
        max: defaultConfigAxis.xAxis.max
      });
      chart.redraw();
    }
  }, [currentCurve, chart])

  useEffect(() => {
    const defaultCurve = getCurrentCurveConfig(currentCurve);
    setFieldValue('curveValue1', defaultCurve.min);
    setFieldValue('curveValue2', defaultCurve.max);
    setFirstCheckbox(defaultCurve.firstCheckbox);
    setSecondCheckbox(defaultCurve.secondCheckbox);

    if (!defaultCurve && chart) {
      setCurveValueMin(DEFAULT_MIN);
      setCurveValueMin(DEFAULT_MAX);
      createPlotLine(
        DEFAULT_MIN,
        DEFAULT_MAX,
        chart
      );
    } else if (chart) {
      createPlotLine(
        defaultCurve.min,
        defaultCurve.max,
        chart
      );
    }
  }, [currentCurve, chart]);

  useEffect(() => {
    if (chart) {
      createPlotLine(
        curveMin,
        curveMax,
        chart
      );
    }
  }, [curveMin, curveMax, chart]);

  useEffect(() => {
    const config = getConfig([], [], inititalConfigAxis);
    const chartInstance = createChart(config, 'cutoff-chart');
    setChart(chartInstance);

    // Get id toQuery on indexDB
    const [wellId] = params.id.split('--');
    (async () => {
      try {
        await dispatch(listWell(wellId, store.get('token')));
      } catch(e) {
        console.log('CutOfss/Chart/index error fetchWellSite', e);
      }
    })();
  }, []);

  useEffect(() => {
    if (wells && wells.currentWell) {
      const config = getConfig([], [], inititalConfigAxis);
      const chartInstance = createChart(config, 'cutoff-chart');
      setChart(chartInstance);
      const { currentWell } = wells;
      const depthData = currentWell?.calculated?.depth?.data;
      setData(currentWell);
      setDepth(depthData);
      const { minDepth, maxDepth } = findMinMaxDepth(depthData);
      setMin({ min: minDepth, max: maxDepth });
      const { dataWh, dataBh } = getDataByCurve(currentCurve, currentWell);

      setLoad(false);

      chartInstance.update({
        series: generateSerie([...dataWh], [...dataBh], inititalConfigAxis)
      });

      const defaultCurve = getCurrentCurveConfig(currentCurve);
      if (defaultCurve && chart) {
        setCurveValueMin(DEFAULT_MIN);
        setCurveValueMin(DEFAULT_MAX);
        setFirstCheckbox(defaultCurve.firstCheckbox);
        setSecondCheckbox(defaultCurve.secondCheckbox);
        createPlotLine(
          defaultCurve.min,
          defaultCurve.max,
          chartInstance
        );
      } else if (chart) {
        createPlotLine(
          DEFAULT_MIN,
          DEFAULT_MAX,
          chartInstance
        );
      }
      // subscribe the keys in localStorage to update between differents browsers
      // each color applyed in each serie from cuttoff view chart
    //   const subscribeKeysId = setInterval(() => {
    //   const currentYLeftColor = getColorFromStore('yLeft');
    //   const currentYRightColor = getColorFromStore('yRight');

    //   if (chartInstance) {
    //     if (currentYRightColor !== defaultYRightColor) {
    //       chartInstance.axes[1].series[1].update({
    //         color: currentYRightColor
    //       }, false);
    //     }

    //     if (currentYLeftColor !== defaultYLeftColor) {
    //       chartInstance.axes[1].series[0].update({
    //         color: currentYLeftColor
    //       }, false);
    //     }
    //     chartInstance.redraw();
    //   }
    // }, 1000);

    // setSubscribeKeysId(subscribeKeysId);

    const boundaryChangeFn = ({ detail }) => {
      const { value, type } = detail;
      const axis = chartInstance.xAxis[0];
      axis.removePlotLine(type);
      const plotMin = generateConfigPlotLine(type, value);
      axis.addPlotLine(plotMin);
      chartInstance.redraw();
    };

    window.addEventListener('updateBoundary', boundaryChangeFn);
  }
  return () => {
    if(chart)
      // chart.destroy();
    if(subscribeKeysIdValue)
      clearInterval(subscribeKeysIdValue);
  };

  }, [wells && wells.currentWell]);

  const onChangeSlider = slideValues => {
    const { dataWh, dataBh } = getDataByCurve(currentCurve, data);
    const [valueMin, valueMax] = slideValues;
    const [newIndexMin, newIndexMax] = findIndexDepth(depth, valueMin, valueMax);
    setMinIndex(newIndexMin);
    setMaxIndex(newIndexMax);

    if (chart) {
      const cuttedMin = cuttedMinData(newIndexMin, newIndexMax, dataWh);
      const cuttedMax = cuttedMaxData(newIndexMin, newIndexMax, dataBh);

      chart.update({
        series: generateSerie(cuttedMin, cuttedMax, cutOffConfig)
      });
    }
  };

  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  const onChangeCurve = curveName => {
    // update select
    setCurrentCurve(curveName);

    // update the form curve
    setCutOffConfig({
      ...cutOffConfig,
      xAxis: {
        ...cutOffConfig.xAxis,
        curve: curveName
      }
    });

    // update chart
    chart.xAxis[0].update({ title: { text: curveName.toUpperCase() } }, false);

    const { dataWh, dataBh } = getDataByCurve(curveName, data);

    // Check if the slider has value changed to cut
    const cuttedWh = cutMax > 0 ? cuttedMinData(cutMin, cutMax, dataWh) : dataWh;
    const cuttedBh = cutMax > 0 ? cuttedMaxData(cutMin, cutMax, dataBh) : dataBh;

    chart.update({
      series: generateSerie([...cuttedWh], [...cuttedBh], cutOffConfig)
    }, false);
    chart.redraw();
  };

  const onSubmitSetting = tabType => formValues => {
    const { min, max, curve, color } = formValues;
    const axisTitle = formValues.curve.toUpperCase();
    setLoadSettings(true);
    setTimeout(() => {
      if (tabType === 'xAxis') {
        setCurrentCurve(curve);
        const { dataWh, dataBh } = getDataByCurve(currentCurve, data);
        // Update series
        chart.update({
          series: generateSerie([...dataWh], [...dataBh], cutOffConfig),
          xAxis: {
            title: { text: axisTitle },
            min,
            max
          }
        }, true);
        const { boundaryMax, boundaryMin } = formValues;
        createPlotLine(
          boundaryMin,
          boundaryMax,
          chart
        );
      } else if (tabType === 'yLeft') {
        setCutOffConfig({
          ...cutOffConfig,
          yLeft: { ...cutOffConfig.yLeft, min, max, color }
        });
        updateLeftAxis(chart, min, max, axisTitle, color);
      } else if (tabType === 'yRight') {
        setCutOffConfig({
          ...cutOffConfig,
          yRight: { ...cutOffConfig.yRight, min, max, color }
        });
        updateRightAxis(chart, min, max, axisTitle, color);
      }
      setLoadSettings(false);
    }, 100);
  };

  const onChangeTab = () => setTabKey(tabKey);

  const handleChangeMin = (event) => {
    const value = event.target.value ? parseInt(event.target.value) : 0;
    setCurveValueMin(value);
    handleChange(event);

    // update min by curve level
    const curve = `cutoff-${currentCurve}`;
    const defaultCurve = getCurrentCurveConfig(currentCurve);
    store.set(curve, { ...defaultCurve, curveName: currentCurve, min: value });
  };

  const handleChangeMax = (event) => {
    const value = event.target.value ? parseInt(event.target.value) : 0;
    setCurveValueMax(parseInt(value));
    handleChange(event);

    // update max by curve level
    const curve = `cutoff-${currentCurve}`;
    const defaultCurve = getCurrentCurveConfig(currentCurve);
    store.set(curve, { ...defaultCurve, curveName: currentCurve, max: value });
  };

  return (
    <div className="scatters-plot cutoff-plot">
      <>
        {loadingSettings &&
          <div className="container-spin-update">
            <Spin spinning={loadingSettings} size="large" tip="Updating..." />
          </div>
        }
        <div className="scatter-title-wrapper scatter-title-curve">
          <div className="scatter-title-box">
            <h3 className="scatters-title scatters-cutoff-viewer">
              Cutoff Viewer
            </h3>
          </div>
          <div className="scatter-curve">
            <label>Curve:</label>
            <div>
              <Select
                defaultValue={currentCurve}
                value={currentCurve}
                onChange={onChangeCurve}
                className="scatter-curve__select"
              >
                {curvesOptions.map(curve => (
                  <Option className="option-select" value={curve.value}>{curve.label}</Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        {loading ? <Spin spinning={loading} tip="Loading..." /> : (
          <>
            <div className='wrapper-slider'>
              <h3 className="scatters-range">
                Top-bottom interval
              </h3>
              <div className="wrapper-slider-width">
                <Slider
                  range
                  defaultValue={[scales.min, scales.max]}
                  tooltipVisible
                  step={1}
                  min={scales.min}
                  max={scales.max}
                  onAfterChange={onChangeSlider}
                />
              </div>
            </div>
          </>
        )}
        <div className="cutoff-container">
          <ChartContainer>
            <div className="wrapper-button-cutoff">
              <Button
                type="primary"
                shape="circle"
                icon="setting"
                size="large"
                className="toggle-crossplot-cutoff"
                onClick={showDrawer}
              />
            </div>
            <Settings
              visible={visible}
              onClose={onClose}
              currentCurve={currentCurve}
              currentScaleConfig={cutOffConfig}
              onSubmitLeft={onSubmitSetting('yLeft')}
              onSubmitRight={onSubmitSetting('yRight')}
              onSubmitXAxis={onSubmitSetting('xAxis')}
              onChangeTab={onChangeTab}
            />
            <div>
              <RenderChart id="cutoff-chart" />
              <form onSubmit={handleSubmit}>
                <div className="general-container">
                  <div className="wrapper-cut-offs content-inputs-guidelines"
                    style={{
                      flexDirection: 'column'
                    }}
                  >
                    <div className="main-inputs">
                      {/* Head */}
                      {errorCurve ? <div style={{ color: "#dc3545", padding: 10, fontWeight: 600 }}>{errorCurve}</div> : null}
                      <div className="wrapper-input-cutoffs-guidelines">
                        <div className="wrapper-info">
                          <div className="box-info-head">
                            <span className="box-info-head-1">Curve</span>
                          </div>
                          <div className="box-info-head">
                            <span className="box-info-head-2">Operator</span>
                          </div>
                          <div className="box-info-head">
                            <span className="box-info-head-3">Value</span>
                          </div>
                        </div>
                      </div>
                      <div className="wrapper-input-cutoffs-guidelines wrapper-input-filters-guidelines">
                        <div className="box-input-cutoffs">
                          <Checkbox
                            onChange={handleFirstCheckbox}
                            className="checkbox-cut-offs"
                            id="checkbox1"
                            name="checkbox1"
                            data-group="filter1"
                            checked={firstCheckbox}
                          />
                        </div>
                        <div className="box-input-cutoffs">
                          <Input
                            onChange={() => {}}
                            onBlur={() => {}}
                            type="text"
                            name="curve1"
                            value={currentCurve.toUpperCase()}
                            disabled
                            className="input-cutoff"
                          />
                        </div>
                        <div className="box-input-cutoffs">
                          <Input
                            onChange={() => {}}
                            onBlur={() => {}}
                            type="text"
                            name="operator1"
                            value={values.operator1}
                            className="input-cutoff"
                            disabled
                          />
                        </div>
                        <div className="box-input-cutoffs">
                          <Input
                            onChange={handleChangeMin}
                            onBlur={() => {}}
                            type="number"
                            name="curveValue1"
                            value={values.curveValue1}
                            className="input-cutoff"
                          />
                        </div>
                      </div>
                      <div className="wrapper-input-cutoffs-guidelines wrapper-input-filters-guidelines">
                        <div className="box-input-cutoffs">
                          <Checkbox
                            onChange={handleSecondCheckbox}
                            className="checkbox-cut-offs"
                            id="checkbox2"
                            name="checkbox2"
                            data-group="filter2"
                            checked={secondCheckbox}
                          />
                        </div>
                        <div className="box-input-cutoffs">
                          <Input
                            onChange={() => {}}
                            onBlur={() => {}}
                            type="text"
                            name="curve2"
                            value={currentCurve.toUpperCase()}
                            className="input-cutoff"
                            disabled
                          />
                        </div>
                        <div className="box-input-cutoffs">
                          <Input
                            onChange={() => {}}
                            onBlur={() => {}}
                            type="text"
                            name="operator2"
                            value={values.operator2}
                            className="input-cutoff"
                            disabled
                          />
                        </div>
                        <div className="box-input-cutoffs">
                          <Input
                            onChange={handleChangeMax}
                            onBlur={() => {}}
                            type="number"
                            name="curveValue2"
                            value={values.curveValue2}
                            className="input-cutoff"
                          />
                        </div>
                      </div>
                      <div>
                      </div>
                    </div>
                    <div className="wrapper-scatter-curve" style={{
                      height: '100%',
                      width: '100%',
                      alignItems: 'center',
                      marginTop: 10
                    }}>
                      {/* <div className="scatter-curve">
                        <div>
                          <Select
                            value="AND"
                            onChange={() => {}}
                            className="scatter-curve__select"
                            style={{ width: '100%'}}
                          >
                            {[{ label: 'AND', value: 'AND' }].map(curve => (
                              <Option className="option-select" deaultValue="AND" value={curve.value}>{curve.label}</Option>
                            ))}
                          </Select>
                        </div>
                      </div> */}
                      {/* <button
                        type="button"
                        className="cutoff-save-button cutoff-clear-button"
                        onClick={clearCutoffs}
                        style={{ marginBottom: 10 }}
                      >
                        Reset Cutoffs
                      </button> */}
                      <Button
                        type="primary"
                        htmlType="submit"
                        // style={{ width: 100 }}
                        size="large"
                      >
                        Finish Cutoff Viewer
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ChartContainer>
        </div>
      </>
    </div>
  );
}

CutOffChart.defaultProps = {
  id: 'cutoff-chart',
  data: []
};

export default CutOffChart;
