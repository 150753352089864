import React, { Component } from 'react';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { Button } from 'antd';
import classNames from 'classnames';
import ArrowLine from  './ArrowLine';
import { drawPath } from '../utils/Svg';

Exporting(Highcharts);

const c1 = [
  [1,7],
  [20,5],
  [10,2]
];

// Gas area 1
const drawGas1 = chart => drawPath(
  0, 2.7,
  20, 5.2,
  0, 5.2,
  0, 3,
  chart,
  {
    'fill': '#f1a7a7',
    'stroke': '#f15555',
    'stroke-width': 2,
    'opacity': '0.5'
  },
  true
);

const drawGas2 = chart => drawPath(
  0, 10,
  40, 10,
  40, 5.28,
  0, 5.28,
  chart,
  {
    'fill': '#f1a7a7',
    'stroke': '#f15555',
    'stroke-width': 2,
    'opacity': '0.5'
  },
  true
);

class ScatterPlotC2iC4 extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
  }

  componentDidMount() {
    this.createScatterPlot();
    this.drawGas1 = () => drawGas1(this.chart);
    this.drawGas2 = () => drawGas2(this.chart);

    // Gas area
    this.chart.gas1 = this.drawGas1();
    this.chart.gas2 = this.drawGas2();

    const { idListener } = this.props;

    document.addEventListener(`${idListener}-chart8`, ({ detail }) => {
      const { config } = detail;
      const {yAxis, xAxis} = this.chart;
      if (yAxis && yAxis[0]) {
        yAxis[0].update({
          min: config.y.min,
          max: config.y.max,
          title: {
            text: config.y.title
          }
        });
      }
      if (xAxis && xAxis[0]) {
        xAxis[0].update({
          min: config.x.min,
          max: config.x.max,
          title: {
            text: config.x.title
          }
        });
      }

      this.setState({
        isVisible: config.isVisible
      });
    });    
  }

  createScatterPlot = () => {
    const { id, data } = this.props;

    const getTooltipWithDepth = (dataWithDepth = []) => ({
      headerFormat: '<b>{series.name}</b><br>',
      // pointFormat: `x: {point.x}, y: {point.y}`,
      formatter: function() {
        const itemWithDepth = dataWithDepth?.find(
          value => value?.x === this?.x && value?.y === this?.y
        );
        return `
          <b>Depth</b>: ${itemWithDepth?.depth}<br/>
          <b>(C2/ic4)</b>: ${itemWithDepth?.x?.toFixed(2)}<br/>
          <b>(C2/C3)</b>: ${itemWithDepth?.y?.toFixed(2)}
        `;
      },
      enabled: true
    });

    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'scatter'
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      credits: {
        enabled: false
      },
      fillOpacity: 1,
      opacity: 1,
      exporting: { enabled: false },
      xAxis: {
        title: {
          enabled: true,
          text: 'C2/iC4'
        },
        startOnTick: true,
        endOnTick: true,
        type: '',
        showLastLabel: true,
        tickInterval: 10,
        pointStart: 0,
        max: 80,
        min: 0,
        gridLineWidth: 0
      },
      yAxis: {
        title: {
          text: 'C2/C3'
        },
        lineWidth: 1,
        lineColor: '#ccd6eb',
        startOnTick: true,
        endOnTick: true,
        type: '',
        showLastLabel: true,
        pointStart: 0,
        tickInterval: 1,
        max: 10,
        min: 1,
        gridLineWidth: 0
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: -14,
        floating: true,
        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
        borderWidth: 0
      },
      plotOptions: {
        scatter: {
          marker: {
            lineWidth: 2,
            radius: 5,
            lineColor: 'rgba(255, 160, 97, 0.98)',
            states: {
              hover: {
                enabled: false
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          // tooltip: {
          //   headerFormat: '<b>{series.name}</b><br>',
          //   pointFormat: '{point.x} cm, {point.y} kg'
          // }
        }
      },
      tooltip: getTooltipWithDepth(this?.props?.dataWithDepth),
      series: [{
        name: ' ',
        color: 'rgb(255, 193, 7)',
        data,
        marker: {
          widthLine: 1,
          fillColor: 'rgba(255,224,1)',
          symbol: 'circle',
          width: 1,
          height: 1,
          hover: false,
          radius: 2.5,
          states: {
            hover: {
              enabled: false,
              lineColor: 'rgba(255, 160, 97, 0.98)'
            }
          }
        }
      }]
    });
  }

  render() {
    const {
      id, withMinorGridLines, className, openConfigureScale
    } = this.props;

    return (
      <div style={{ width: '400px' }} className="scatter-general">
        {
          openConfigureScale && (
            <Button
              type="primary"
              shape="circle"
              icon="setting"
              size="large"
              className="toggle-crossplot-config"
              onClick={openConfigureScale}
            />
          )
        }
        <ArrowLine
          className="c2-iC4-arrow-top"
          text="Biodegradation"
          fillColor="#333"
          textColor="#333"
        />
        <div
          className={classNames(
            className,
            { 'reset-minor-grids': !withMinorGridLines },
            'deactivate-legend'
          )}
          id={id}
          style={{
            minWidth: '320px',
            height: '280px',
            maxWidth: '330px',
            flex: 1
          }} />
      </div>
    );
  }
}

ScatterPlotC2iC4.defaultProps = {
  withMinorGridLines: false,
  className: 'scatter-plot',
  deactivateLegend: true
};

export default ScatterPlotC2iC4;
