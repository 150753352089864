import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useFormik } from 'formik';
import InputAutoComplete from '../InputAutoComplete/InputAutoComplete';
import { formatterAutocompleteData } from '../../utils/formatterAutocompleteData';
import { validateDepthValue } from '../../utils/validateForm';

interface submitRangeProps {
  top: string;
  bottom: string;
}

interface FormRangeTopBottomProps {
  top: number;
  bottom: number;
  currentWell: any;
  submitRange: ({top, bottom}:submitRangeProps) => void;
}

export const FormRangeTopBottom = ({ top, bottom, currentWell, submitRange }: FormRangeTopBottomProps) => {
  const dataSource = formatterAutocompleteData(currentWell);
  const formik = useFormik({
    initialValues: { top: top, bottom: bottom },
    onSubmit: (values) => {
      const validateTopValue = dataSource.find((depth: any) => (values.top || String(top)) === depth);
      const validateBottomValue = dataSource.find((depth: any) => (values.bottom || String(bottom)) === depth);
      const validateDepth = validateDepthValue(validateTopValue || top, validateBottomValue || bottom);
      if (validateDepth.length !== 0) {
        return;
      }
      // @ts-ignore
      submitRange(values);
    },
  });

  const { values, setFieldValue, handleSubmit, initialValues } = formik;

  useEffect(() => {
    if (values.top === undefined && values.bottom === undefined) {
      setFieldValue('top', initialValues.top);
      setFieldValue('bottom', initialValues.bottom);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} className='form-top-bottom'>
      <div className='input-container-top-bottom'>
        <span>Min:</span>
        <div className="input-zone-top-bottom">
          <InputAutoComplete
            style={{ width: 100 }}
            formatterAutocompleteData={formatterAutocompleteData}
            onChange={(value: number) => setFieldValue('top', value)}
            type="text"
            name="top"
            placeholder="top"
            value={values.top}
            dataSource={dataSource}
            defaultValue={initialValues ? initialValues.top : ''}
          />
        </div>
      
      </div>
      <span> - </span>
      <div className='input-container-top-bottom'>
        <span>Max:</span>
        <div className="input-zone-top-bottom">
          <InputAutoComplete
            style={{ width: 100 }}
            formatterAutocompleteData={formatterAutocompleteData}
            onChange={(value: number) => setFieldValue('bottom', value)}
            type="text"
            name="bottom"
            placeholder="bottom"
            value={values.bottom}
            dataSource={dataSource}
            defaultValue={initialValues ? initialValues.bottom : ''}
          />
        </div>
      </div>
      <Button
        type='primary'
        htmlType='submit'
        className='button-apply-top-bottom'  
      >
        Apply
      </Button>
    </form>
  );
}
