import React from 'react';
import {
  Button,
  Tooltip
} from 'antd';
import { ZoneType } from '../../types/zone';

const BtnAction = (
  afterClick: (zone: ZoneType) => void,
  customBtnTitle: string,
  actionConfig: {
    className?: string;
    icon: string;
    children: React.JSX.Element
  },
) => {
  return (zone: ZoneType) => (
    <Tooltip title={customBtnTitle}>
      <Button
        type="link"
        icon={actionConfig?.icon}
        onClick={() => afterClick(zone)}
      >
        {actionConfig?.children} 
      </Button>
    </Tooltip>
  );
};

export default BtnAction;
