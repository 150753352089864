// well actions
export const FILTER_WELL = 'FILTER_WELL';
export const RECEIVE_WELL = 'RECEIVE_WELL';
export const RECEIVE_METADATA = 'RECEIVE_METADATA';
export const RECEIVE_WELLS = 'RECEIVE_WELLS';
export const DELETE_WELL = 'DELETE_WELL';
export const REGISTER_DEPTH_DATA = 'REGISTER_DEPTH_DATA';
export const UPDATE_WELL = 'UPDATE_WELL';
export const RECEIVE_SELECTED_INDEX = 'RECEIVE_SELECTED_INDEX';
export const RECEIVE_STATISTICS = 'RECEIVE_STATISTICS';
export const RECEIVE_WELLS_FROM_API = 'RECEIVE_WELLS_FROM_API';
export const RECEIVE_SELECTED_MIN_MAX = 'RECEIVE_SELECTED_MIN_MAX';
export const FILTERED_DATA = 'FILTERED_DATA';
export const RECEIVE_LOGSET_GQC = 'RECEIVE_LOGSET_GQC';
export const RECEIVE_CRITICAL_AREAS = 'RECEIVE_CRITICAL_AREAS';
export const UPDATE_METADATA = 'UPDATE_METADATA';
export const UPDATE_CURRENT_WELL_DATA = 'UPDATE_CURRENT_WELL_DATA';
export const RECEIVE_QUALITY_DATA = 'RECEIVE_QUALITY_DATA';
