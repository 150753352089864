import { reduce } from 'lodash';
import { z } from 'zod';

const applyErrors = (row, index, setError, isBitSize = false,
  topBaseRangeInvalid, topBaseInverted) => {
  reduce(row, (acc: any, value: any, key: any) => {
    if (!value) {
      setError(
        `bs${index}${key}`,
        { type: 'custom', message: 'Required' }
      );
    }
    if (topBaseRangeInvalid[0] === key || topBaseRangeInvalid[1] === key) {
      setError(
        `bs${index}${key}`,
        { type: 'custom', message: 'Out of the depth range' }
      );
    }

    if (topBaseInverted[0] === key) {
      setError(
        `bs${index}${key}`,
        { type: 'custom', message: 'Must be less than base' }
      );
    }

    if (topBaseInverted[1] === key) {
      setError(
        `bs${index}${key}`,
        { type: 'custom', message: 'Must be bigger than top' }
      );
    }

    if (isBitSize) {
      setError(
        `bs${index}${key}`,
        { type: 'custom', message: 'Invalid Format' }
      );
    }
  }, {});
};

const isInvalidFields = ({ top, base, diameter }) => (
  top === '' || base === '' || diameter === 0
);

const isOutsideDepthRange = (bitSizeValues, rangeDepth) => {
  let error: string[] = [];

  const topRange = rangeDepth?.start_depth >= 0 ? parseFloat(rangeDepth?.start_depth)?.toFixed(2) : rangeDepth?.start_depth;
  const bottomRange = rangeDepth?.stop_depth >= 0 ? parseFloat(rangeDepth?.stop_depth)?.toFixed(2) : rangeDepth?.stop_depth;

  // @ts-ignore
  if (parseFloat(bitSizeValues.top || 0) < topRange || parseFloat(bitSizeValues.top || 0) > bottomRange) {
    error.push('top');
  }
    // @ts-ignore
  if (parseFloat(bitSizeValues.base || 0) < parseFloat(topRange) || parseFloat(bitSizeValues.base || 0) > parseFloat(bottomRange)) {
    error.push('base');
  }
  return error;
}

const topBaseInverted = (bitSizeValues) => {
  let error: any = [];
  if (parseFloat(bitSizeValues.top) >= parseFloat(bitSizeValues.base)) {
    error.push('top');
  }
  if (parseFloat(bitSizeValues.base) <= parseFloat(bitSizeValues.top)) {
    error.push('base');
  }
  return error;
}

const checkBitSize = ({ diameter }) => {
  const [left, right] = String(diameter).split(' ');
  const DIVISION_SIGN = '/';
  if ((!right && !left) || (left && Number.isNaN(Number(left)))) {
    return true;
  } if (right) {
    const [dividend, divider] = String(right).split(DIVISION_SIGN);
    if (!divider || !dividend) {
      return true;
    }
  }
  return false;
};

export const checkAndValidateBitSizes = (bitSizeValues, rangeDepth, setError) => {
  return bitSizeValues.reduce((acc, row, index) => {
    if (isInvalidFields(row)) {
      applyErrors(
        row, index, setError, false, false, []
      );
      acc = false;
    }

    const topBaseRangeInvalid = isOutsideDepthRange(row, rangeDepth);
    if (topBaseRangeInvalid.length > 0) {
      applyErrors(
        row, index, setError, false, topBaseRangeInvalid, []
      );
      acc = false;
    }

    const topBaseInvalid = topBaseInverted(row);
    if (topBaseInvalid.length > 0) {
      applyErrors(
        row, index, setError, false, topBaseRangeInvalid, topBaseInvalid
      );
      acc = false;
    }

    const isBitSizeInvalid = row.diameter ? checkBitSize(row) : false;
    if (isBitSizeInvalid) {
      applyErrors(
        row, index, setError, true, false, []
      );
      acc = false;
    }
    return acc;
  }, true);
};

const REQUIRED_MESSAGE = 'Required';
const ZONE_MESSAGE = 'Must be two numbers from 01 to 60 followed by the capital letter S or N. Ex: 02S'
const greaterLess = (base, top) => `Must be greater than ${base} and less than ${top}`;
const lessgreaterEqual = (base, top) => `must be less than ${top} and greater than or equal to ${base}`;
const greater = (number) => `Must be greater than ${number}`;

export const wellCoodinatesSchema = z.object({
    degrees_lat: z.number({invalid_type_error: REQUIRED_MESSAGE}).min(-90, greaterLess(-90, 90)).max(90, greaterLess(-90, 90)),
    minutes_lat: z.number({invalid_type_error: REQUIRED_MESSAGE}).min(0, lessgreaterEqual(60, 0)).max(59, lessgreaterEqual(0, 60)),
    seconds_lat: z.number({invalid_type_error: REQUIRED_MESSAGE}).min(0, greater(0)),
    degrees_long: z.number({invalid_type_error: REQUIRED_MESSAGE}).min(-180, greaterLess(-180, 180)).max(180, greaterLess(-180, 180)),
    minutes_long: z.number({invalid_type_error: REQUIRED_MESSAGE}).min(0, lessgreaterEqual(60, 0)).max(59, lessgreaterEqual(60, 0)),
    seconds_long: z.number({invalid_type_error: REQUIRED_MESSAGE}).min(-180, greaterLess(-180, 180)).max(180, greaterLess(-180, 180)),
    reference: z.string({required_error: REQUIRED_MESSAGE}),
    x_value: z.number({invalid_type_error: REQUIRED_MESSAGE}).min(0, greater(0)),
    y_value: z.number({invalid_type_error: REQUIRED_MESSAGE}).min(0, greater(0)),
    zone: z.string({required_error: REQUIRED_MESSAGE}).regex(/^(0[1-9]|[1-5][0-9]|60)[SN]$/, ZONE_MESSAGE),
    reference_zone: z.string({required_error: REQUIRED_MESSAGE}),
  });

export const WellCoordinatesAreEmpty = (values) => {
  const { degrees_lat, degrees_long, minutes_lat, minutes_long, seconds_lat, seconds_long, x_value, y_value, zone  } = values;
  return !!(
    degrees_lat === '' && degrees_long === '' && minutes_lat === '' && minutes_long === '' &&
    seconds_lat === '' && seconds_long === '' && x_value === '' && y_value === '' && zone === ''
  )
}
