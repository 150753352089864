import React from 'react';
import { Row, Col, Spin } from 'antd';
import LogoWellexGold from 'images/logo-wellex-gold.svg';
import ReactSvg from 'react-svg';

const SpinnerLogo = () => {
  return (
    <Row type="flex" justify="center" align="middle" style={{ width: '100%', height: '100%' }}>
      <Col xs={24} md={14} lg={10} className="login-page__section-form">
        <Row type="flex" justify="center" align="middle">
          <Col xs={22} sm={22} md={16} lg={18} xl={14}>
            <div style={{
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
              <div className="login-page__header">
                <ReactSvg
                  src={LogoWellexGold}
                  className="login-page__header-image-logo"
                />
              </div>
              <span style={{ marginBottom: 10, }}>Checking your credentials...</span>
              <Spin size="large" />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SpinnerLogo;
