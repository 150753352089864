import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  label: string;
  hasError?: boolean,
  required: boolean;
};

export const FormLabel = ({ children, label, hasError = false, required, ...props }: Props) => {
  return (
    <label data-testid="form-label" className="zones-form-label" {...props}>
      <span className={`zones-form-label${hasError ? '__error_text' : '__default_text' }`}>
        {required && <span data-testid="symbol-required" className="text-danger">*&nbsp;</span>}
        {label}
      </span>
      {children}
    </label>
  );
};
