import styled from "styled-components";
import ErrorIcon from '@mui/icons-material/Error';
import { Badge } from "@mui/material";

export const TitleTooltip = styled.span`
  font-size: 14px;
  color: #FFFFFF;
`;

export const ErrorIconStyled = styled(ErrorIcon)`
  && {
    color: #EB3434;
  }
`

export const BadgeStyled = styled(Badge)`
  && {
    .MuiBadge-badge	{
      min-width: auto;
      min-height: auto;
      width: 10px;
      height: 17px;
      padding: 0;
      background-color: #FFFFFF;
    }
  }
`