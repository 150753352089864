import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { receiveWell } from '../../../../actions/wells';
import { Button, Input, Select } from 'antd';
import { CloseOutlined, CheckOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { defaultNotification } from '../../../../utils';
import { PostGqcPayload } from '../../../../api/statistics';
import { useSelector } from 'react-redux';

const { Option } = Select;

const RemoveDataModal = ({ isModalOpen, currentWell, dispatch, close, setIntervals, setPayload, payload }) => {
  const wells = useSelector(state => state.wells);
  const depth = wells?.currentWell?.RAW?.depth?.data;
  const [rows, setRows] = useState([
    { id: 1, top: '', bottom: '' },
  ]);

  const closeModal = () => {
    close();
  };

  const addRow = () => {
    const newRowId = rows.length + 1;
    const newRow = { id: newRowId, top: '', bottom: '' };
    setRows([...rows, newRow]);
  };

  const updateRowField = (id, field, value) => {
    const updatedRows = rows.map(row =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setRows(updatedRows);
  };

  const deleteRow = (id) => {
    const updatedRows = rows.filter(row => row.id !== id);
    setRows(updatedRows);
  };

  function updateData(data, depth, min, max, percentage) {
    let updatedData = depth.map((value, index) => {
      if (value > min && value < max) {
        return percentage;
      }
      return data[index];
    });
    return updatedData;
  }

  const sendRemove = async () => {

    const intervals = rows.map(row => [parseInt(row.top), parseInt(row.bottom)]);
    const wellId = wells.currentWell.well_uid;
    setIntervals(intervals);
    const transformedIntervals = intervals.map(interval => ({
      top: interval[0],
      bottom: interval[1]
    }));

    console.log('intervals=>', intervals);
    // const payload = {
    //   "well_uid": wellId,
    //   "normalization_settings": null,
    //   "remove_data_settings": {
    //     "intervals": transformedIntervals
    //   },
    //   "cutoff_settings": null,
    //   "reduce_noise_settings": null,
    // }

    setPayload(prevState => ({
      ...prevState,
      remove_data_settings: {
        "intervals": transformedIntervals
      }

    }));

    if (currentWell && currentWell?.calculated?.qualityData) {
      let removedData = [];
      let keepData = [];
      intervals.forEach((interval) => {
        removedData = updateData(currentWell.calculated?.qualityData?.badData, depth, interval[0], interval[1], 100)
        keepData = updateData(currentWell.calculated?.qualityData?.goodData, depth, interval[0], interval[1], 0)
      });

      const newQualityData = { goodData: keepData, badData: removedData }
      console.log('New Quality Data =>', newQualityData)

      dispatch(receiveWell({
        ...currentWell,
        calculated: {
          ...currentWell.calculated,
          qualityData: newQualityData
        }
      }))
    }
    closeModal();
    defaultNotification('Remove data applied !');
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          width: '100%',
          height: '806px',
          padding: '20px',
          backgroundColor: '#09090B',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <h3 style={{ textAlign: 'start', marginBottom: '16px', color: 'white' }}><b>Remove Data</b></h3>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', color: 'white' }}>
          <span style={{ flex: 1, textAlign: 'start' }}>Top</span>
          <span style={{ flex: 1, textAlign: 'start' }}>Bottom</span>
        </div>
        {rows.map((row) => (
          <div key={row.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
            <Input
              value={row.top}
              onChange={(e) => updateRowField(row.id, 'top', e.target.value)}
              style={{ flex: 1, marginRight: '8px', background: '#18181B', color: 'white', border: '1px solid #52525B' }}
              placeholder="Ex.: 3300"
            />
            <Input
              value={row.bottom}
              onChange={(e) => updateRowField(row.id, 'bottom', e.target.value)}
              style={{ flex: 1, background: '#18181B', color: 'white', border: '1px solid #52525B' }}
              placeholder="Ex.: 3300"
            />
            <Button
              style={{ backgroundColor: 'black', color: 'white', marginLeft: '8px' }}
            >
              Select Interval
            </Button>
            <Button
              onClick={() => deleteRow(row.id)}
              style={{ marginLeft: '8px', backgroundColor: '#18181B', color: 'white', border: '1px solid #ff4d4f' }}
            >
              X
            </Button>
          </div>
        ))}
        <Button onClick={addRow} style={{ width: '20%', marginBottom: '8px', marginTop: '18px', alignSelf: 'start', background: '#18181B', color: 'white' }}>
          <PlusOutlined />
          Interval
        </Button>
        <div style={{ flex: 1 }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={sendRemove} style={{ backgroundColor: '#233BB6', color: 'white', border: 'none' }}>
            <DeleteOutlined />
            Remove
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveDataModal;
