import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IndicatorsCard from '../IndicatorsCard';
import { getRawCurveData } from '../../utils/getRawCurveData';

const NOT_AVAILABLE = 'N/A';

const GasesCards = ({ selectedWell }) => {
  const wells = useSelector(state => state.wells);
  const [state, setState] = useState({
    c2h4: NOT_AVAILABLE,
    c3h6: NOT_AVAILABLE
  });

  useEffect(() => {
    const { currentWell, selectedDepthIndex } = wells;
    if (currentWell && selectedDepthIndex >= 0) {

      const c2h4 = getRawCurveData('c2h4', currentWell);
      const c3h6 = getRawCurveData('c3h6', currentWell);

      const index = selectedDepthIndex || 0;

      if (c2h4 && c3h6) {
        const c2h4Value = c2h4?.data?.[index] ? c2h4?.data?.[index]?.toFixed(2) : NOT_AVAILABLE;
        const c3h6Value = c3h6?.data?.[index] ? c3h6?.data?.[index]?.toFixed(2) : NOT_AVAILABLE;
        setState({ c2h4: c2h4Value, c3h6: c3h6Value });
      }
    }
  }, [wells.selectedDepthIndex]);

  return (
    <>
      <IndicatorsCard
        description="Ethene"
        type="ethene"
        value={selectedWell && state.c2h4 > 0 ? state.c2h4 : NOT_AVAILABLE}
      />
      <IndicatorsCard
        description="Propene"
        type="propene"
        value={selectedWell && state.c3h6 > 0 ? state.c3h6 : NOT_AVAILABLE}
      />
    </>
  );
};

export default GasesCards;
