import { Slider } from "@mui/material";
import styled from "styled-components";

export const SliderStyled = styled(Slider)`
  && {
    min-height: 287px;

    .MuiSlider-track {
      background-color: #90CAF9;
      border-color: #90CAF9;
    }

    .MuiSlider-rail {
      background-color: #425B70;
    }

    .MuiSlider-thumb {
      background-color: #90CAF9;
    }

    .MuiSlider-valueLabel {
      padding: 0;
      background-color: transparent;
      font-weight: 400;
      font-size: 12px;
      color: #F5FAFF;
      /* transform: rotate(180deg); */
    }

    .MuiSlider-mark {
      background-color: transparent;
    }

    .MuiSlider-markLabel  {
      color: #F5FAFF;
      right: 30px;
      font-size: 12px;
    }
  }
`