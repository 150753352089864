import { get } from 'lodash';
import { chartsToApplyZones, allCharts } from '../config';
import { ZoneItemType } from '../types/zone';

type PlotLineColor = {
  r: string | number;
  g: string | number;
  b: string | number;
  a: string | string;
};

export type PlotLineType = {
  id: string;
  name?: string;
  value: string | number;
  width?: number | number;
  color?: PlotLineColor;
  zIndex: number;
  label: {
    color: string;
    strokWidth: number;
    style: { color: string, fontSize: number };
    text?: string;
    y: number;
  };
};

/*
* generatePlotLine
* This function will generate a scheme to a plotLine to
* represent a zone within the chart
*/
export const generatePlotLine = (
  id: string,
  value: number | string,
  width?: number,
  color?: PlotLineColor,
  name?: string
): PlotLineType => ({
  value,
  width: width ?? 1,
  color,
  id,
  zIndex: 500,
  label: {
    text: name,
    y: 20,
    strokWidth: 6,
    color: '#000',
    style: {
      color: '#000',
      fontSize: 16
    }
  },
});

export const applyZoneToCharts = (targetChart: string) => (zone: ZoneItemType, charts: Highcharts.Chart[]) => {
  let i = 0;
  let chart = null;
  for (i; i < charts.length; i++) {
    chart = charts[i];
    const chartId = get(chart, 'renderTo.id', '');
    if (
      get(chart, 'xAxis[0].setExtremes', false) &&
      chartsToApplyZones.includes(chartId)
    ) {
      const thereIsName = chartId === targetChart;
      applyZoneToChart(
        zone,
        chartId,
        thereIsName,
        generatePlotLine,
        chart?.xAxis?.[0],
        chart,
      );
    }
  }
};

/*
* createZones
* This function will apply the zones
*/
type AxisCallback = (zone: any) => void;

type DefaultAxis = {
  plotLinesAndBands?: [],
  removePlotLine: AxisCallback;
  addPlotLine: AxisCallback;

  addPlotBand: AxisCallback;
  removePlotBand: AxisCallback;
};

export const applyZoneToChart = (
  zone: any,
  chartName: string,
  thereIsName: boolean,
  generatePlotLine: (
    id: string,
    value: number | string,
    width?: number,
    color?: PlotLineColor,
    name?: string
  ) => PlotLineType,
  axis?: DefaultAxis,
  instance?: any,
) => {
  const chart = instance ?? allCharts?.instances?.[chartName];

  const {
    label, top_depth_index, bottom_depth_index, uuid,
    zone_plot_settings: { color, border_width }
  } = zone;
  const textName = thereIsName ? label : '';
  const startId =  uuid !== null? `start-${uuid}` : 'start-null';
  const endId = uuid !== null ? `end-${uuid}` : 'start-null';

  const zoneLineStart: PlotLineType = generatePlotLine(
    startId,
    top_depth_index,
    border_width,
    color,
    textName
  );
  const zoneLineEnd: PlotLineType = generatePlotLine(
    endId,
    bottom_depth_index,
    border_width,
    color,
    ''
  );

  const plotBand = {
    from: top_depth_index,
    to: bottom_depth_index,
    color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    id: startId,
    zIndex: 20
  };

  chart.xAxis[0].plotLinesAndBands.map((plot: any) => {
    if (startId === plot.id) {
      chart.xAxis[0].removePlotBand(startId);
      chart.xAxis[0].removePlotLine(startId);
      chart.xAxis[0].removePlotLine(endId);
    }
  });

  axis?.addPlotLine(zoneLineStart);
  axis?.addPlotLine(zoneLineEnd);
  axis?.addPlotBand(plotBand);

  chart?.redraw();
};
