import React, { useState } from 'react';
import { useFormContext } from "react-hook-form";
import { Box } from '@mui/material';
import { Subtitle } from '../Subtitle'
import { Title } from '../Title'
import { Label } from '../Label'
import InputContainer from './components/InputContainer';
import { ToggleButton as EtheneSensor } from './components/ToggleButton';
import { CurvesTable } from './components/CurvesTable';
import {
  FormStyled, WrapperInputs
} from './styles'
import { SensorTypeRadio } from './components/SensorTypeRadio';

export const MatchingCurves = ({ setValue, hasEtheneSensor, clearErrors }) => {
  const [showSensors, setShowSensors] = useState<boolean | undefined>(false);
  const { control } = useFormContext();

  const toggleSensors = () => {
    setShowSensors(showDisplaySensors => !showDisplaySensors);
  };

  return (
    <FormStyled>
      <WrapperInputs>
        <Title text='Matching curves' />
        <Subtitle text='Select which curves will be loaded' />
        <CurvesTable clearErrors={clearErrors} />
      </WrapperInputs>
      {/* <WrapperInputs>
        <InputContainer sx={{ mt: 4}}>
          <Box sx={{ mb: 1 }}>
            <Label text='Ethene Sensor' />
            <Subtitle text='Is There an Ethene Sensor?' />
          </Box>
          <EtheneSensor
            defaultChecked={hasEtheneSensor}
            name="has_sensor"
            control={control}
            setValue={setValue}
            toggleSensors={toggleSensors}
          />
        </InputContainer>
        {showSensors ? (
          <InputContainer sx={{ mt: 3 }}>
            <Box sx={{ mb: 1 }}>
              <Label text='Sensor Type' />
            </Box>
            <Box sx={{ mt: 1 }}>
              <SensorTypeRadio
                name="sensor_type"
                control={control}
                setValue={setValue}
              />
            </Box>
          </InputContainer>
        ) : null}
      </WrapperInputs> */}
    </FormStyled>
  );
};
