export const defaultConfigAxis = {
  xAxis: {
    min: 1,
    max: 100000,
    curve: 'c1',
    boundaryMin: 100,
    boundaryMax: 10000,
    color: '#000'
  },
  yLeft: {
    min: 1,
    max: 1000,
    curve: 'wh',
    color: '#FF8C23'
  },
  yRight: {
    min: 0.1,
    max: 100000,
    curve: 'bh',
    color: '#b9b9b9'
  }
};

export const generateSerie = (whData, bhData, config) => {
  const { yLeft, yRight } = config;
  return [
    {
      name: 'WH',
      data: whData,
      color: yLeft.color,
      marker: {
        symbol: 'round'
      }
    },
    {
      name: 'BH',
      data: bhData,
      color: yRight.color,
      marker: {
        symbol: 'round'
      }
    }
  ];
};

export const getConfig = (bhData, whData, inititalConfigAxis) => ({
  chart: {
    type: 'scatter',
    height: 425,
    width: 921,
    marginTop: 50,
    style: {
      fontFamily: "'Poppins', 'sans-serif'"
    }
  },
  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
    x: -20,
    y: -10,
    floating: true
  },
  xAxis: {
    title: {
      enabled: true,
      text: 'C1 (ppm)',
      style: {
        color: '#000000'
      }
    },
    pointStart: 1,
    max: 100000,
    min: 1,
    tickStart: 1,
    type: 'logarithmic',
    gridLineWidth: 1,
    gridLineColor: '#E0E0E0'
  },
  yAxis: [{
    type: 'logarithmic',
    title: {
      text: 'WH',
      style: {
        color: '#000000'
      }
    },
    tickStart: 1,
    max: 1000,
    min: 1,
    minorTickInterval: 0.1,
    gridLineColor: '#E0E0E0'
  },
  {
    type: 'logarithmic',
    title: {
      text: 'BH',
      rotation: 270,
      style: {
        color: '#000000'
      }
    },
    opposite: true,
    max: 100000,
    min: 0.1,
    minorTickInterval: 0.1
  }],
  plotOptions: {
    series: {
      states: {
        inactive: {
          enabled: false
        }
      }
    }
  },
  series: generateSerie(bhData, whData, inititalConfigAxis),
  tooltip: {
    enabled: false
  },
  credits: {
    enabled: false
  }
});
