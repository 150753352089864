import React from 'react'
import { Container, TextFieldStyled } from './styles'
import { Label } from '../Label'
import { Controller } from 'react-hook-form';
import Badge from '../Badge';

export const InputText = ({
  control, name, label, inputProps, defaultValue, handleChange,
  type, errorBadge = false, ...rest
}: any) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Container>
            <Label text={label} />
            <Badge
              open={errorBadge && !!fieldState.error?.message}
              text={fieldState.error?.message}
            >
              <TextFieldStyled
                {...field}
                defaultValue={defaultValue}
                InputProps={inputProps}
                error={!errorBadge && !!fieldState.error?.message}
                helperText={!errorBadge && fieldState.error?.message}
                value={field.value}
                onChange={(e) => {
                  const currentValue = e.target.value && type === "number" ?
                    Number(e.target.value) : e.target.value;

                  handleChange?.(currentValue);
                  rest?.afterChange?.();
                  field?.onChange(currentValue);
                  rest?.clearError?.(name);
                  
                }}
                type={type}
                {...rest}
              />
            </Badge>
          </Container>
        )
      }}
    />
  )
}