import React from 'react'

import { TitleProps } from "../../types/login"
import {
  LoginTitle, LoginSubtitle
} from "../../styles/styles"

export const Title = ({ title, subtitle }: TitleProps) => {
  return (
    <div
        data-aos="fade-left"
        data-aos-delay="350"
        data-aos-duration="550"
        data-aos-easing="ease"
    >
        <LoginTitle>{title}!</LoginTitle>
        <LoginSubtitle>{subtitle}</LoginSubtitle>
    </div>
  )
}
