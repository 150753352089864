import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import Aos from 'aos';
import { zodResolver } from '@hookform/resolvers/zod'
import { InputAdornment, CircularProgress } from '@material-ui/core';
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

import { FormProps, onSubmitLoginValues } from '../../types/login';
import {
  LoadingForm, Input, FormLogin, ErrorMessage, IconError,
  IconButton, ButtonSubmit, InputAdornmentPassword, IconErrorContainer
} from '../../styles/styles';

const REQUIRED_MESSAGE = 'This field is required';

const loginSchema = z.object({
  username: z.string()
    .min(1, REQUIRED_MESSAGE)
    .email('Invalid email format!'),
  password: z.string()
    .min(1, REQUIRED_MESSAGE)
});

export const Form = ({
  onSubmitLogin, errorLogin, loading
}: FormProps) => {
  const { register, handleSubmit, formState: { errors } } = useForm<onSubmitLoginValues>({
    resolver: zodResolver(loginSchema)
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    Aos.init();
  }, []);

  return(
    <FormLogin
      onSubmit={handleSubmit(onSubmitLogin)}
      data-testid="form"
      data-aos="fade-up"
      data-aos-delay="450"
      data-aos-duration="650"
      data-aos-easing="ease"
    >
      {loading ? (
        <LoadingForm>
          <CircularProgress />
        </LoadingForm>
      ) : null}

      {errorLogin ? (
        <Alert severity="error" data-testid="alert-error">
          {errorLogin}
        </Alert>
      ): null}

      <Input
        label="Username"
        variant="outlined"
        placeholder="Ex: your.name@geowellex.com"
        data-cy="email-input"
        autoFocus
        fullWidth
        error={!!errors.username}
        helperText={
          errors.username ? <ErrorMessage data-cy="error-message">
            {errors.username.message?.toString()}
          </ErrorMessage> : null
        }
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          inputProps: {
            'data-testid': 'username'
          },
          endAdornment: errors.username ?
            <InputAdornment position="end">
              <IconError />
            </InputAdornment>
          : null
        }}
        {...register("username")}
      />

      <Input
        label="Password"
        variant="outlined"
        placeholder="Enter your password"
        data-cy="password-input"
        type={showPassword ? "text" : "password"}
        fullWidth
        error={!!errors.password}
        helperText={
          errors.password ? <ErrorMessage data-cy="error-message">
            {errors.password.message?.toString()}
          </ErrorMessage> : null
        }
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          inputProps: {
            'data-testid': 'password'
          },
          endAdornment:
            <InputAdornmentPassword position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
                disableTouchRipple
              >
                {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
              </IconButton>
              <IconErrorContainer>
                {errors.password ? <IconError /> : null}
              </IconErrorContainer>
            </InputAdornmentPassword>
        }}
        {...register("password")}
      />

      <ButtonSubmit
        variant="contained"
        color="primary"
        type="submit"
        data-cy="login-button"
        data-testid="login-button"
        aria-label='submit'
      >
        LOG IN
      </ButtonSubmit>
    </FormLogin>
  );
}
