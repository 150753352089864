import { applyDefaultZoomToChart } from 'lib/charts';
/* eslint-disable */
import React, {
  memo,
  useEffect,
  useState,
  useRef
} from 'react';

import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { handleSelectArea } from 'utils';

import ChartHeader from './ChartHeader';
import { getConfig } from './config';
import RenderChart from './RenderChart';

Exporting(Highcharts);

const SYNCHRONIZE_TOTAL_DEPTH = 'total-depth-sync';

const TOGGLE_TOTAL_DEPTH = 'TOGGLE_TOTAL_DEPTH';

const createSelectionId = id => `selection${id}`;

const defaultSelectionColor = 'rgba(51, 92, 173, 0.25)';

const createPlotSlection = (min, max, id, color = defaultSelectionColor) => ({
  from: min,
  to: max,
  color,
  id
});

const minus = y => x => x - y;

const getMinSelection = minus(200);
const getMaxSelection = minus(100);

const createChart = (
  handleSelect,
  config,
  id,
  afterSelect,
  selectedId
) => {
  const chart = new Highcharts.Chart(id, {
    ...config,
    chart: {
      ...config.chart,
      events: {
        selection: event => handleSelect(
          chart,
          afterSelect,
          selectedId
        )(event)
      },
    }
  });
  return chart;
};

const TotalDepth = ({
  afterSelect, id, data, onAfter, min, max,
  unit, selectedWell, zoomType, previousSelectedWell
}) => {
  const [state , setState] = useState({
    selectedId: 0,
    data: [],
    selectedWell: null,
    unit: ''
  });
  const [chart, setChart] = useState(null);
  const config = getConfig(data, zoomType);

  const createSeriesChart = () => createChart(
    handleSelectArea,
    config,
    id,
    afterSelect,
    state.selectedId
  );

  useEffect(() => {
    const chartInstance = createSeriesChart();
    setChart(chartInstance);
    return () => {
      chart && chart.destroy();
    };
  }, []);

  useEffect(() => {
    setState({
      ...state,
      unit
    });
  }, [unit]);

  useEffect(() => {
    const chartInstance = createSeriesChart();

    setChart(chartInstance);

    setState({
      ...state,
      data,
      selectedWell
    });

    const [xAxis] = chartInstance.xAxis;
    const [firstSerie] = xAxis.series;

    const id = createSelectionId(state.selectedId);
    const plotSelection = createPlotSlection(min, max, id);

    xAxis.addPlotBand(plotSelection);

    if (onAfter) {
      onAfter(firstSerie.data);
    }
  }, [data.length, previousSelectedWell]);

  useEffect(() => {
    const synchronizeSelectedDepth = event => {
      const {
        detail: { min: selectedMin, max: selectedMax }
      } = event;
      const plotSelection = createPlotSlection(selectedMin, selectedMax, state.selectedId);
      const [xAxis] = chart?.xAxis;
      (xAxis?.plotLinesAndBands || []).map(band => {
        xAxis?.removePlotBand(band.id);
      });

      xAxis?.addPlotBand(plotSelection);
      chart?.redraw();
    };

    if (chart) {
      document.addEventListener(SYNCHRONIZE_TOTAL_DEPTH, synchronizeSelectedDepth);
    }

    return () => {
      document.removeEventListener(SYNCHRONIZE_TOTAL_DEPTH, synchronizeSelectedDepth);
    }
  }, [chart]);

  return (
    <ChartContainer>
      <ChartHeader unit={unit} />
      <RenderChart id={id} />
    </ChartContainer>
  );
};

TotalDepth.defaultProps = {
  id: 'total-depth',
  zoomType: 'x',
  withZoom: false,
  parentContainer: '.charts-wrapper',
  data: []
};

export default memo(TotalDepth);
