import { Box } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const WrapperNextPreviousButton = styled(Box)`
  display: flex;
  gap: 8px;
`