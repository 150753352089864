import * as React from 'react';
import {
  RouterProvider
} from "react-router-dom";
import { createRoot } from 'react-dom/client';
import 'antd/dist/antd.css';
import './styles/app.scss';
import Routes from './Routes';
import * as serviceWorker from './serviceWorker';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <RouterProvider router={Routes} />
  </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
