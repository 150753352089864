import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import zone from './zone';
import wells from './wells';
import crossplots from './crossplots';
import cutoff from './cutoff';
import wellphases from './wellphases';

const rootReducer = combineReducers({
  zone,
  wells,
  cutoff,
  wellphases,
  crossplots,
  routing: routerReducer
});

export default rootReducer;
