import React from 'react';

import { Button } from 'antd';
import statistic from 'assets/statistic.svg';
import { useNavigate } from 'react-router-dom';

const StatisticButton = ({ currentWell }) => {
  const navigate = useNavigate();

  const redirectToStatistics = () => {
    if (!currentWell) {
      alert('Please select a well first before to go statitics module.');
    } else {
      navigate(`/home/statistics?wellId=${currentWell.well_uid}`);
    }
  };

  return (
    <Button data-cy="statistic-button" className="btn-statistic" onClick={redirectToStatistics}>
      <img src={statistic} alt="statistics module" />
      Statistics Module
    </Button>
  );
};

export default StatisticButton;
