import React, { useState, useEffect, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ClipboardJS from 'clipboard';
import clippyIcon from './images/copy.svg';
import Button from '@mui/material/Button';

import {
  ClipboardInput, ClipboardBtn, ContainterTextField, ClipboardContainer
} from './styles';

new ClipboardJS('.default-clipboard__btn');

const Clipboard = ({ value, defaultValue, clipboardTarget }) => {
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  let timerId = useRef();  
  
  const displayTooltipClipboard = () => {
    setVisibleTooltip((visible) => !visible);
    //@ts-ignore
    timerId.current = setTimeout(() => {
      setVisibleTooltip(false);
    }, 2000);
  };

  const handleTooltipClose = () => {
    setVisibleTooltip(false);
  };

  useEffect(() => {
    return () => {
      if (timerId?.current) {
        clearInterval(timerId?.current);
      }
    };
  }, []);

  return (
    <ClipboardContainer>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip 
          title="Copied!"
          PopperProps={{
            disablePortal: true,
          }}
          open={visibleTooltip}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <div style={{ padding: 0, display: 'flex' }}>
            <ClipboardBtn
              type="button"
              className="default-clipboard__btn"
              data-clipboard-target={`#${clipboardTarget}`}
              onClick={displayTooltipClipboard}
              aria-label="This is the tooltip on the North West side.">
              <img src={clippyIcon} width="15" height="15" alt="Copy top range" />
            </ClipboardBtn>
            <ContainterTextField>
              <ClipboardInput
                id={clipboardTarget}
                type="text"
                readOnly
                value={value >= 0 ? Number(value)?.toFixed(2) : defaultValue}
              />
            </ContainterTextField>
          </div>
        </Tooltip>
      </ClickAwayListener>
    </ClipboardContainer>
  );
}

export default Clipboard;
