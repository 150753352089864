import React, { useMemo, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
// import store from 'store';
import { Alert, Button } from 'antd';
import { ZoneType, ZoneProps, ZoneItemType } from './types/zone';
import BtnAction from './components/BtnAction/BtnAction';
import { useZones } from './hooks/useApi';
import { config } from './config/index';
import List from './components/List/index';
import Form from './components/Form/Form';
import { useToggle, ViewModes } from './hooks/useToggle';
import { sortZones } from './utils/sort';


import './styles/app.scss';

export const Zone = ({
  currentWell,
  submitZone,
  afterDelete,
  customCallbackActions,
  isThereNoSensorType,
  closeEtheneAlert,
  isLoadingZones,
  // afterRevalidate -> callback that will provide new lists of zones when revalidat receiving new items we need to dispach to redux or do another thing after having a new one
  afterRevalidate,
  zoneData
}: ZoneProps) => {
  const urlParams = new URLSearchParams(window.location.search);
  const wellId = urlParams.get('wellId');
  
  // const isCloseFluidType = store.get('close-fluid-type');
  const { isLoading, isValidating } = useZones(wellId, config.auth, currentWell);
  const [currentZoneToEdit, setCurrentZoneToEdit] = useState<ZoneItemType | undefined>();
  const [zonesList, setZonesList] = useState<ZoneItemType[] | undefined>();
  const [indexBottomZoneCreated, setIndexBottomZoneCreated] = useState();
  const { viewMode, setListViewMode, setFormViewMode } = useToggle();

  useEffect(() => {
    const newZoneItems =  zoneData ?? [];
    if ((newZoneItems?.length && !zonesList) || (zonesList?.length !== newZoneItems.length)) {
      setZonesList(newZoneItems);
      afterRevalidate?.(newZoneItems);
    }
  }, [zoneData, setZonesList, afterRevalidate]);

  // Shoud apply config and generate actino button
  const callbackActions: ((zone: ZoneType) => React.JSX.Element)[] = useMemo(() =>
    customCallbackActions.map(([cb, title, actionConfig]: [cb: any, title: any, actionConfig: any]) => {
      return BtnAction(cb, title, actionConfig);
    }),
    [customCallbackActions]
  );
  const isListViewMode = ViewModes.LIST === viewMode;
  const isFormViewMode = ViewModes.FORM === viewMode;

  const title = currentZoneToEdit?.top && currentZoneToEdit?.bottom ? 'Update' : 'Create';

  const isLoadingAllZone = isLoadingZones || isLoading || isValidating;
  
  const afterUpdate = useCallback((zoneId: string) => {
    const normalizeZones = zoneData ?? [];
    const zone = normalizeZones.find(
      (z: ZoneItemType) => z?.uuid === zoneId
    ) as ZoneItemType | undefined;
    setCurrentZoneToEdit(zone);
    setFormViewMode();
  }, [zoneData, setFormViewMode]);

  const handleDeleteZone = (zoneId: string) => {
    setCurrentZoneToEdit(undefined);
    afterDelete(zoneId);
  };

  // useEffect(() => {
  //   // const shouldUpdateZoneList = store.get('close-fluid-type');
  //   // if (shouldUpdateZoneList === true) {
  //     // mutate();
  //     console.log('close-fluid-type', store.get('close-fluid-type'));
  //     // store.set('close-fluid-type', false);
  //   // }
  // }, [store.get('close-fluid-type')]);

  const afterOpenForm = () => {
    setListViewMode();
  };

  const formViewModeInsert = () => {
    if (zoneData) {
      const lastZone = zoneData?.slice(-1)?.pop();
      setIndexBottomZoneCreated(lastZone.bottom_depth_index);
    }
    setCurrentZoneToEdit(undefined);
    setFormViewMode();
  };

  const defaultLastBottom = useMemo(() => {
    const normalizeZones = zoneData ?? [];
    const sortedZones = sortZones(normalizeZones);
    const lastValue = sortedZones.slice(-1).pop();
    return lastValue?.bottom;
  }, [zoneData]);

  return (
    <div className={classNames('zones-container')}
    >
      {
        isListViewMode && zoneData?.length > 0 && !isLoading ? (
          <Button
            onClick={formViewModeInsert}
            className="zones__insert-btn"
            data-cy="insert-zone"
            disabled={isLoadingAllZone}
          >
            Insert Zone
          </Button>
        ) : ''
      }
      {
        isThereNoSensorType ? (
          <div style={{ marginBottom: 30 }}>
            <Alert
              afterClose={closeEtheneAlert}
              message="There is no data in Relative Ethene!"
              type="warning"
              description="Please, select a type of sensor FID or MS when to create a well."
              closable
              showIcon
            />
          </div>
        ) : null
      }
      {isFormViewMode && !isLoading ? (
        <div className="zones-form__container">
          <h2 className="zones-form__title">{`${title} Zone`}</h2>
          <Form
            toggleCancel={afterOpenForm}
            submitText={`${title} Zone`}
            submitZone={submitZone}
            afterSubmit={afterOpenForm}
            zoneBottom={defaultLastBottom ?? 0}
            loadingZone={false}
            zoneCount={zoneData?.length ? zoneData?.length + 1 : 1}
            currentWell={currentWell}
            currentZoneToEdit={currentZoneToEdit}
            indexBottomZoneCreated={indexBottomZoneCreated}
          />
        </div>
      ) : null }
      {
        !isFormViewMode ? (
          <List
            isLoading={isLoadingAllZone}
            isValidating={isValidating}
            zones={zoneData ?? []}
            customActions={callbackActions}
            afterDelete={handleDeleteZone}
            afterUpdate={afterUpdate}
            setFormViewMode={setFormViewMode}
          />
        ) : null
      }
    </div>
  );
}
