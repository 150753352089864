import styled from "styled-components";
import { Box, Container } from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { FullScreen } from 'react-full-screen';
import { WrapperLasProps } from "./types";

export const ContainerStyled = styled(Container)`
  && {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0;
    width: 100%;
  }
`

export const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const OpenFullLas = styled(OpenInFullIcon)`
  && {
    color: #E5E7EB;
  }
`

export const WrapperLas = styled(Box)<WrapperLasProps>`
  padding: 16px;
  background-color: #1F2937;
  color: #FFFFFF;
  border-radius: 8px;
  white-space: nowrap;
  height: ${(props) => props.fullScreenActive ? '94vh' : 'calc(100vh - 162px)'};
  overflow: auto;
  width: 100%;
`

export const FullScreenStyled = styled(FullScreen)`
  width: 100%;
  height: fit-content;
`

export const CustomScrollbar = styled.div`
  background-color: #FFFFFF;
  height: 30px;
  opacity: 0.7;
  border-radius: 8px;
  cursor: pointer;
`

export const TrackVertical = styled.div`
  position: absolute;
  width: 18px !important;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
`

export const TrackHorizontal = styled.div`
  height: 16px !important;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 3px;
`