import React from 'react'
import DrawerNavigation from './components/DrawerNavigation';
import TabsPanel from './components/TabsPanel';

import Input from './components/MatchingCurves/components/InputContainer';
export const InputContainer = Input;

import { Label as SelectWithouControl } from './components/Label/index';
export const Label = SelectWithouControl;

export const Importdata = ({ getDataType, onSubmit, changedUnit, isOpen, lasInfo, defaultData, wellOptions, onClose, isLoading }) => {
  return (
    <DrawerNavigation isOpen={isOpen}>
      <TabsPanel
        getDataType={getDataType}
        onSubmit={onSubmit}
        changedUnit={changedUnit}
        lasInfo={lasInfo}
        defaultData={defaultData}
        wellOptions={wellOptions}
        onClose={onClose}
        isLoading={isLoading}
      />
    </DrawerNavigation>
  )
};

