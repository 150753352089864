import './style.scss';
import React, { useState } from 'react';
import {
    Button
} from 'antd';
import { warningNotification } from 'utils';
import get from 'lodash.get';
import { useNavigate, useLocation } from 'react-router-dom';
import { DashboardQC } from './components/DashsboardQC';
import {
    useStatisticWizard,
    WizardProvider
} from '../Statistics/components/ModalStatistics/useStatisticsWizard';

const QualityControl = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [_, wellId] = location && location.search ? location.search.split('=') : '-1';
    if (wellId.toString() === '-1') {
        navigate(`/home?wellId=${wellId}`);
    }
   
    return (
        <WizardProvider>
            <div className="quality__control_container">
                <div className="content-quality-control-page">
                    <DashboardQC navigate={navigate} wellId={wellId} />
                </div>
            </div>
        </WizardProvider>
    );
};

export default QualityControl;
