import React, {
  Component,
  useEffect,
  useState
} from 'react';
import get from 'lodash.get';
import store from 'store';
import { useSelector } from 'react-redux';
import { useScales } from '../../../../hooks/useScales';
import { getRawCurveData } from 'utils/getRawCurveData';
import { DEFAULT_SCALES } from '../../../../utils/constants';
import {
  defaultCrossHair,
  defaultValuesToCrosshair,
  disableDefaultZoom,
  disableDots,
  disableLabels,
  displayZoomBtn,
  getToolTipValues,
  nFormatter,
  propagateZoom,
  syncronizeTooltip,
  toggleTrack,
  toggleWobChart
} from '../../../../utils';

export const curveTypes = ['rop'];

const updatedHeader = {
  scales: store.get('configScales') || { ...DEFAULT_SCALES },
  rop: 0,
  gasRatio: 0
};

const initialHeader = {
  rop: 0,
  gasRatio: 0
};

export const ChartHeader = () => {
  const wells = useSelector(state => state.wells);

  const {
    currentScale, setHeaderState, headerState
  } = useScales(
    curveTypes,
    wells.currentWell,
    initialHeader
  );

  // --- Toggle Tracks
  const [tracks, setToggle] = useState({
    isOpen: false,
    rop: true,
    gasRatio: true
  });

  const tooggleCallbacks = {
    rop: toggleWobChart,
    gasRatio: toggleWobChart
  };

  const toggleTrackCurrentTrack = (event) => {
    const { dataset: { name, index } } = event.target;
    const trackName = tracks[name];
    const callback = tooggleCallbacks[name];
    if (callback) {
      callback(parseInt(index))(event);
      setToggle({
        ...tracks,
        isOpen: !tracks.isOpen,
        [name]: !trackName
      });
    }
  };

  // --- Open modal with config
  const dispatchModal = (typeOfTitle) => (event) => {
    event.persist();
    event.stopPropagation();
    if (event.target && event.target.dataset.cmd === 'toggle') {
      toggleTrackCurrentTrack(event);
      return;
    } else if (event.target && event.target.dataset.cmd === 'openConfig') {
      const openModal = new CustomEvent('openConfigModal', {
        detail: {
          type: typeOfTitle
        }
      });
      document.dispatchEvent(openModal);
      return;
    }
  };

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const rop = get(getRawCurveData('rop', wells.currentWell), 'data', []);
      const index = wells.selectedDepthIndex;
      const ropValue = rop && rop.length ? rop[index] : 0;
      setHeaderState({
        ...headerState,
        rop: ropValue ? ropValue?.toFixed(2) : 0,
      });
    }
    if (!wells.currentWell) {
      setHeaderState({
        ...headerState,
        rop: 0
      });
    }
  }, [wells]);

  useEffect(() => {
    setHeaderState({
      scales: store.get('configScales')
    });
    // --- Listeners to receive the scale values or color
    const ropConfigListener = ({ detail }) => {
      setHeaderState({
        ...headerState,
        ...updatedHeader
      });
    };
    document.addEventListener('rop', ropConfigListener);
    // ---- Remove listeners
    return () => {
      document.removeEventListener('rop', ropConfigListener);
    };
  }, []);
  const { scales, rop } = headerState;

  return (
    <div
      className="rop-header-chart"
      style={{ width: 120 }}
    >
      <div
        className="rop-title-wrapper"
        data-cmd="openConfig"
      >
        <div
          className="rop-title"
          onClick={dispatchModal('rop')}
          data-name="title"
          data-title="rop"
          style={{
            borderColor: currentScale?.rop?.color,
            color: currentScale?.rop?.color,
            pointerEvents: 'auto'
          }}
          data-cmd="openConfig"
          data-cy="open-config-rop"
        >
          <div
            style={{ width: '160px', justifyContent: 'flex-start'}}
            data-name="title"
            className="target-rop"
            data-cmd="openConfig"
          >
            <span data-cy="scales-rop-min">{nFormatter(currentScale?.rop?.min)}</span>
            <span>&nbsp;</span>
            <span
              className="title-rop"
              data-cmd="openConfig"
            >
              {rop ? `ROP (${rop})` : `ROP (m/h)`}
            </span>
            <span>&nbsp;</span>
            <label className="show-checkbox-rop container-checkbox">
              <input
                data-cmd="toggle"
                type="checkbox"
                checked={tracks.rop}
                data-name="rop"
                data-index="1"
                data-cy="checkbox-rop"
              />
              <span className="checkmark" style={{backgroundColor: currentScale?.rop?.color}}/>
            </label>
            <span data-cy="scales-rop-max">{nFormatter(currentScale?.rop?.max)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
