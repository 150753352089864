import React from 'react';
import { useFullScreenHandle } from 'react-full-screen';
import { Scrollbars } from 'react-custom-scrollbars';
import { Title } from '../Title';
import { Button } from '../Button';
import { LasReviewProps } from './types';

import {
  ContainerStyled, CustomScrollbar, FullScreenStyled, Header, OpenFullLas, TrackHorizontal, TrackVertical, WrapperLas
} from "./styles";

export const LasReview = ({ lasInfo }: LasReviewProps) => {
  const handle = useFullScreenHandle();

  const splitLas = (lasFile: string) => {
    return lasFile.split(/\n/);
  }

  const onClickFullScreen = handle.active ? handle.exit : handle.enter;

  return (
    <ContainerStyled>
      <Header>
        <Title text='LAS Review' />
        <Button
          theme='gray'
          width='fit-content'
          onClick={onClickFullScreen}
        >
          <OpenFullLas fontSize='small' />
        </Button>
      </Header>
      <FullScreenStyled handle={handle}>
          <WrapperLas fullScreenActive={handle.active}>
          <Scrollbars
            renderTrackHorizontal={props => <TrackHorizontal {...props} />}
            renderTrackVertical={props => <TrackVertical {...props} />}
            renderThumbVertical={props => <CustomScrollbar {...props} />}
            renderThumbHorizontal={props => <CustomScrollbar {...props} />}
          >
            {splitLas(lasInfo).map(
              value => (
                <>
                  <label>{value}</label>
                  <br />
                </>
              )
            )}
          </Scrollbars>
          </WrapperLas>
      </FullScreenStyled>
    </ContainerStyled>
  )
}