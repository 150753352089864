import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Content from '../Content';

type Anchor = 'right';

const positionDrawer = 'right';

export default function DrawerNavigation({ children, isOpen }:any) {
  const [state, setState] = React.useState({
    right: false,
  });

  React.useEffect(() => {
    setState({ ...state, 'right': isOpen });
  }, [isOpen])

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };
  return (
    <>
      <Drawer
        anchor={positionDrawer}
        open={state[positionDrawer]}
        onClose={toggleDrawer(positionDrawer, false)}
      >
        <Content>
          {children}
        </Content>
      </Drawer>
    </>
  );
}
