import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Button, Input, Form, Row, Col } from 'antd';
import { CloseOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { receiveWell } from '../../../../actions/wells';
import { postNormalizeGas, PostGqcPayload } from '../../../../api/statistics';
import { useDispatch } from 'react-redux';
import Loading from '../../../Statistics/components/Loading';
import { defaultNotification } from '../../../../utils';

const ExtractionModal = ({ isModalOpen, close, setPayload, payload }) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (pairKey, fieldName, value) => {
    const updatedFormData = { ...formData, [pairKey]: { ...formData[pairKey], [fieldName]: value } };
    setFormData(updatedFormData);
  };

  const closeModal = () => {
    close();
  };

  const prepareGasData = (formData, wells) => {
    const normalizationValues = Object.entries(formData).reduce((combined, [key, subObj]) => {
      Object.keys(subObj).forEach(attrName => {
        combined[attrName] = subObj[attrName];
      });
      return combined;
    }, {});

    const gasData = {
      curves: {},
      weights: {},
    };

    const attributeLabels = Object.keys(normalizationValues);

    attributeLabels.forEach(label => {
      if (wells.currentWell && wells.currentWell.hasOwnProperty(label)) {
        gasData.curves[label] = wells.currentWell[label];
      }
      gasData.weights[label] = parseFloat(normalizationValues[label]) / 100 || 1;
    });

    Object.keys(gasData.curves).forEach(key => {
      if (gasData.curves[key].length === 0) {
        delete gasData.curves[key];
      }
    });

    return gasData;
  };

  const transformData = (data) => {
    const normalizationSettings = {};

    Object.entries(data).forEach(([key, value]) => {
      const attributes = key.split("-");

      attributes.forEach(attribute => {
        if (attribute !== "" && value[attribute] != null && value[attribute] !== "") {
          normalizationSettings[attribute] = `${parseFloat(value[attribute]) / 100 || 1}`;
        }
      });
    });

    return normalizationSettings;
  };


  const SendExtraction = async () => {
    setLoading(true);
    const newGasData = prepareGasData(formData, wells);
    const normalizationSettings = transformData(formData);
    const wellId = wells.currentWell.well_uid;
    // const payload = {
    //   "well_uid": wellId,
    //   "normalization_settings": normalizationSettings,
    //   "remove_data_settings": null,
    //   "cutoff_settings": null,
    //   "reduce_noise_settings": null,
    // }

    setPayload(prevState => ({
      ...prevState,
      normalization_settings: normalizationSettings
    }));

    
    const current = wells.currentWell;
    // dispatch(
    //   receiveWell({
    //     ...current,
    //     ...response.data,
    //     enabling1Normalized: true,
    //   })
    // );
    // if(payload)
    //   console.log("PAYLOAD ===> NORMALIZATION", payload)
    //   await PostGqcPayload(payload);

    setLoading(false);
    closeModal();
    defaultNotification('Gas normalization applied !');
  };

  const wells = useSelector(state => state.wells);

  useEffect(() => {
    if (wells.currentWell) {
      const predefinedOptions = [
        "c1", "c2", "c3", "ic4", "nc4", "c4", "ic5", "nc5", "c5"
      ];
      const filteredOptions = predefinedOptions.map(option => ({ label: option, value: option }));
      setOptions(filteredOptions);

    }
  }, [wells.currentWell]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          width: '100%',
          height: '806px',
          padding: '20px',
          backgroundColor: '#09090B',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
        <Form>
          <div>
            <h3 style={{ textAlign: 'start', marginBottom: '16px', color: 'white' }}>
              <b>Extraction Efficiency Coefficients (%)</b>
            </h3>
            {options.map((option, index) => {
              if (index % 2 === 0) {
                const pairKey = `${option.label}-${options[index + 1]?.label || ''}`;
                const fieldName1 = option.label;
                const nextOption = options[index + 1];
                const fieldName2 = options[index + 1]?.label || '';

                return (
                  <Row key={pairKey} gutter={6} style={{ marginBottom: '8px' }} >
                    <Col span={12}>
                      <p style={{ fontFamily: 'roboto', color: 'white' }}>{fieldName1.toUpperCase()}</p>
                      <Input
                        value={formData[pairKey]?.[fieldName1] || ''}
                        onChange={(e) => handleInputChange(pairKey, fieldName1, e.target.value)}
                        style={{ width: '100%', backgroundColor: '#18181B', color: 'white', border: '1px solid #52525B' }}
                        placeholder={`${fieldName1} %`}
                      />
                    </Col>
                    <Col span={12}>
                      {nextOption && (
                        <>
                          <p style={{ fontFamily: 'Roboto', color: 'white' }}>{nextOption.label.toUpperCase()}</p>
                          <Input
                            value={formData[pairKey]?.[nextOption.label] || ''}
                            onChange={(e) => handleInputChange(pairKey, nextOption.label, e.target.value)}
                            style={{ width: '100%', backgroundColor: '#18181B', color: 'white', border: '1px solid #52525B' }}
                            placeholder={`${nextOption.label} %`}
                          />
                        </>
                      )}
                    </Col>
                  </Row>
                );
              }
              return null;
            })}
          </div>
        </Form>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={closeModal} style={{ marginRight: '12px' }}>
            <CloseOutlined />
            Cancel
          </Button>
          <Button onClick={SendExtraction} style={{ backgroundColor: '#233BB6', color: 'white', border: 'none' }}>
            <CheckOutlined />
            Apply
          </Button>
        </div>
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1000,
            }}
          >
            <Loading customStyles={{ width: 900, height: 500 }} />
          </div>
        )}
      </div>
    </Modal >
  );
};

export default ExtractionModal;
