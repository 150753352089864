import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = { wellId: string };

const SelectedWell = ({ wellId }: Props) => {
  const navigate = useNavigate();

  useEffect(
    () => {
      if (wellId && wellId !== '-1') {
        navigate(`?wellId=${wellId}`);
      }
    },
    [wellId]
  );

  return null;
};

export default SelectedWell;
