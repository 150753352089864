import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import { Button } from 'antd';
import ChartContainer from 'components/Shared/ChartContainer';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import highchartsHistogram from 'highcharts/modules/histogram-bellcurve';
import ToggleButton from 'react-toggle-button';

import { Queue } from '../../../../utils/Queue';
import { calculatePercenteOfOCurrences } from '../../utils';
import { useStatisticWizard } from '../ModalStatistics/useStatisticsWizard';

Exporting(Highcharts);
highchartsMore(Highcharts);
highchartsHistogram(Highcharts);

const ZoomQueue = new Queue();

const EnableSelectCriticalAreas = ({ afterChange }) => {
  const [isActivated, setActive] = useState(false);
  const toggleActive = () => {
    afterChange(!isActivated);
    setActive(!isActivated);
  };
  return (
    <div className="cross-plot-toggle" style={{ marginBottom: 10 }}>
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: 0,
        fontSize: 16,
        color: '#7D7D7D'
      }}>
        Select critical Areas:
      </div>
      <ToggleButton
        colors={{
          activeThumb: {
            base: 'rgb(255,255,255)'
          },
          inactiveThumb: {
            base: 'rgb(255,255,255)'
          },
          active: {
            base: '#65c970',
            hover: '#65c970'
          },
          inactive: {
            base: 'rgb(65,66,68)',
            hover: 'rgb(95,96,98)'
          }
        }}
        value={isActivated}
        onToggle={toggleActive}
      />
    </div>
  );
};


const ChartSignificanceLevel = ({ id, onSubmit, curveName, movingAvarage, enableSignificanceLevel, defaultConfig }) => {
  const wizardState = useStatisticWizard();

  const movingAvarageAbbreviated = (String(movingAvarage).toLowerCase().trim() === 'absolute difference') 
    ? 'ABS DIFF' : 'REL DIFF';

  const chartData = wizardState && curveName && wizardState[`chartData${curveName}`]
    ? wizardState[`chartData${curveName}`] : undefined;

  let binsNumber = chartData && chartData?.data ? chartData?.data * 0.01 : 100;
  if (chartData && chartData?.data?.length) {
    binsNumber = chartData?.data * 0.01;
  }

  let currentChartData = chartData && chartData?.data ? chartData?.data : [];
  if (chartData && chartData?.data.length) {
    currentChartData = chartData;
  }

  if (curveName === 'gqr' || curveName === 'GQR') {
    currentChartData = currentChartData.map(info => info * 1000);
  }

  const zoomRefId = useRef(null);
  const [chartInstance, setChart] = useState(null);
  const [visible, setVisible] = useState(false);
  const [showCutLine, enableCutLine] = useState(false);
  const [depthIndexToCut, setDepthIndexToCut] = useState();
  const [percenteOfOccurrencesSignificanceLevel, setPercenteOfOcurrences] = useState();

  const extremes = chartInstance && chartInstance.xAxis[0].getExtremes();
  const getXAxisMiddleValue = extremes ? (Math.round(extremes.max) - extremes.min) / 2 : 0;

  useEffect(() => {
    document.addEventListener(`zoom-in-significance-${curveName}`, () => {
      setVisible(true);
    });
  }, []);

  useEffect(() => {
    enableSignificanceLevel(showCutLine)
  }, [showCutLine]);

  useEffect(() => {
    if (chartInstance && showCutLine) {
      const { processedXData, processedYData } = chartInstance.axes[0].series[0];
      const percentOfOcurrenccess = calculatePercenteOfOCurrences(processedXData, processedYData, depthIndexToCut);
      setPercenteOfOcurrences(percentOfOcurrenccess);
    }
  }, [depthIndexToCut, chartInstance, showCutLine]);

  const resetZoom = ({min, max}) => {
    const last = ZoomQueue.dequeue();

    let newMin = 0;
    let newMax = 0;

    if (last) {
      newMin = last.min; // last undo
      newMax = last.max;
    } else {
      newMin = min; // from state
      newMax = max;
    }

    if (chartInstance && chartInstance.xAxis[0].setExtremes) {
      chartInstance.xAxis[0].setExtremes(newMin, newMax, false, false);
      chartInstance.redraw();
    }

    if (ZoomQueue.getSize() <= 0) {
      setVisible(false);
    }
  };

  const showBtnZoom = () => {
    if (visible) {
      return (
        <div style={{ padding: 5 }}>
          <Button
            type="primary"
            style={{ padding: 5, fontWeight: 'bold' }}
            onClick={resetZoom}
          >
            Reset Zoom
          </Button>
        </div>
      )
    }
    return <div/>;
  };

  useEffect(() => {
    const chartConfig = !showCutLine ? {
      zoomType: 'x',
      afterCreateChart: () => {},
      xAxis: null,
      events: {
        selection: (event) => {
          const zoomInButton = new CustomEvent(`zoom-in-significance-${curveName}`, {
            detail: { visible: true }
          });
          document.dispatchEvent(zoomInButton)
          const { min, max } = event.xAxis[0];
          ZoomQueue.enqueue({ min, max })
        }
      }
    } : {
      events: null,
      zoomType: null,
      xAxis: {
        plotLines: [{
          color: 'red',
          width: 3,
          value: getXAxisMiddleValue,
          dashStyle: 'LongDash',
          zIndex: 5,
          onDragFinish (newValue) {
            setDepthIndexToCut(newValue);
          },
          label: {
            // useHTML: true,
            // text: 'teste',
            // formatter () {
            //   return `<button>Drag</button>`;
            // // },
            // formatter () {
            //   return `<img src=${indicatorMove} alt="indicator move"/>`;
            // },
            // style: {
            //   cursor: 'pointer'
            // },
            verticalAlign: 'middle',
            textAlign: 'center',
            rotation: 0,
            x: -15,
            y: 0
          }
        }]
      },
      afterCreateChart: (chart) => {
        function draggablePlotLine(axis) {
          let clickX;
          let clickY;
          let newTranslation

          const getPlotLine = () => chart.xAxis[0].plotLinesAndBands[0];

          const getValue = () => {
            const plotLine = getPlotLine();
            // const translation = plotLine.label.translateX;
            const translation = plotLine.svgElem.translateX;
            let newValue = axis.toValue(translation) - axis.toValue(0) + plotLine.options.value;
            newValue = Math.max(axis.min, Math.min(axis.max, newValue));
            return newValue;
          };

          const dragStep = (e) => {
            const plotLine = getPlotLine();
            newTranslation = axis.horiz ? e.pageX - clickX : e.pageY - clickY;
            let newValue = axis.toValue(newTranslation) - axis.toValue(0) + plotLine.options.value;

            newValue = Math.max(axis.min, Math.min(axis.max, newValue));
            newTranslation = axis.toPixels(newValue + axis.toValue(0) - plotLine.options.value);

            // plotLine.label.translate(
            //   axis.horiz ? newTranslation : 0,
            //   axis.horiz ? 0 : newTranslation);
            // plotLine.label.attr({
            //   'x': newTranslation
            // });

            plotLine.svgElem.translate(
              axis.horiz ? newTranslation : 0,
              axis.horiz ? 0 : newTranslation);

            if (plotLine.options.onDragChange) {
              plotLine.options.onDragChange(newValue);
            }
          };

          const dragStop = () => {
            document.removeEventListener('mousemove', dragStep);
            document.removeEventListener('mouseup', dragStop);

            const plotLine = getPlotLine();
            const plotLineOptions = plotLine && plotLine.options;
            // Remove + Re-insert plot line
            // Otherwise it gets messed up when chart is resized
            if (plotLine.svgElem.hasOwnProperty('translateX')) {
              plotLineOptions.value = getValue()
              axis.removePlotLine(plotLineOptions.id);
              axis.addPlotLine(plotLineOptions);

              if (plotLineOptions.onDragFinish) {
                plotLineOptions.onDragFinish(plotLineOptions.value);
              }
            }

            // getPlotLine().label
            //   .translate(0, 0)
            //   .on('mousedown', dragStart);;
            getPlotLine().svgElem
              .css(
                {'cursor': 'col-resize	'}
              )
              .translate(0, 0)
              .on('mousedown', dragStart);
          };

          const dragStart = (e) => {
            document.addEventListener('mousemove', dragStep);
            document.addEventListener('mouseup', dragStop);

            const plotLine = getPlotLine();
            clickX = e.pageX - plotLine.svgElem.translateX;

            if (plotLine.options.onDragStart) {
              plotLine.options.onDragStart(getValue());
            }
          };

          dragStop();
        };

        draggablePlotLine(chart.xAxis[0], 'diff');
      }
    };

    const defaultChartData = defaultConfig?.chartData || currentChartData?.data;
    const dataCurrentChart = defaultChartData?.filter(item => item !== null);
    const chartInstanceConfig = Highcharts.chart(`${id}-${curveName}`, {
      chart: {
        paddingTop: 80,
        resetZoomButton: {
          position: { x: 20, y: 20 }
        },
        zoomType: chartConfig.zoomType,
        type: 'histogram',
        events: chartConfig.events
      },
      title: {
        text: ''
      },
      tooltip: {
        headerFormat: '',
        pointFormat: 'Occurrences: <b>{point.y}</b><br/>'
      },
      xAxis: {
        ...chartConfig.xAxis,
        title: {
          text: movingAvarageAbbreviated
        }
      },
      yAxis: [{
        title: {
          text: 'FREQUENCY'
        }
      }],
      plotOptions: {
        column: {
          pointPlacement: 'between'
        },
        binsNumber,
        series: {
          // type: 'histogram',
          binsNumber,
          name: `${curveName} - Analysis`,
          groupPadding: 0,
          pointPadding: 0,
          borderWidth: 0,
          events: {
            legendItemClick(e) {
              e.preventDefault();
            }
          }
        }
      },
      series: [{
        name: 'Histogram',
        type: 'histogram',
        xAxis: 0,
        yAxis: 0,
        baseSeries: 's1',
        binsNumber: chartData?.data ? chartData?.data * 0.01 : 100,
        zIndex: -1
      }, {
        name: '',
        type: 'scatter',
        data: dataCurrentChart,
        visible:false,
        id: `s1`,
        marker: {
          radius: 0
        }
      }]
    }, chartConfig.afterCreateChart);

    if (chartInstanceConfig) {
      setChart(chartInstanceConfig);

      // keep last zoom before to show the cut line
      const lastZoom = ZoomQueue.getLast();
      if (lastZoom && showCutLine) {
        const { min, max } = lastZoom;
        chartInstanceConfig.xAxis[0].setExtremes(min, max);
        chartInstanceConfig.redraw();
      }
    }
  }, [chartData && chartData.formType, showCutLine, defaultConfig?.chartData]);

  useEffect(() => () => {
    clearTimeout(zoomRefId);
  }, [chartInstance])

  const handleChangeSelectCritialAreas = (isActive) => {
    enableCutLine(isActive);

    if (isActive === false) {
      setDepthIndexToCut(undefined)
    } else {
      setDepthIndexToCut(getXAxisMiddleValue)
    }
  }

  const showButtonReset = showBtnZoom();

  const onSbumitSignificanceLevel = useCallback(() => {
    onSubmit(0, 'cut', depthIndexToCut, showCutLine, percenteOfOccurrencesSignificanceLevel);
  }, [depthIndexToCut, showCutLine, percenteOfOccurrencesSignificanceLevel]);

  return (
    <ChartContainer
      className="total-gas-container"
    >
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>{showButtonReset}</div>
      <EnableSelectCriticalAreas afterChange={handleChangeSelectCritialAreas} />
      <span className="container-significance-form__label">
        {showCutLine && `Calculate until: ${depthIndexToCut || ''}`}
      </span>
      {showCutLine && (
        <Button
          type="button"
          onClick={onSbumitSignificanceLevel}
          className="container-significance-form__button"
        >
          Apply and cut
        </Button>
      )}
      <div
        id={`${id}-${curveName}`}
        style={{
          height: 'calc(100% - 130px)',
          width: '800px'
        }}
        data-cy="chart-total-gas"
      />
    </ChartContainer>
  );
}

ChartSignificanceLevel.defaultProps = {
  id: 'significance-level',
  parentContainer: '.charts-container'
};

export default ChartSignificanceLevel;
