// import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
// import { Select, Button, Input } from 'antd';
// import { CheckOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux'; // useDispatch 
// import { promise } from 'zod';
// import { createCutOffFilter } from '../../../../api/cutoff';
// import store from 'store';
// import { fetchCutOffs, deleteCutOff } from '../../../../actions/cutoff';
// import { getAllCutOffs } from '../../../../api/cutoff';
import CutoffProxy from 'components/CutOffs';
import { CutOffViewerBtn } from '../../../../components/CutOffs/Chart/CutOffViewerBtn';

// const { Option } = Select;

const CutOffModal = ({ isModalOpen, close, payload, setPayload, setCutoffSettings, wellActions }) => {
  // const [curves, setCurves] = useState([]);
  // const [operator, setOperator] = useState('');
  // const [selectedCurves, setSelectedCurves] = useState([]);
  // const dispatch = useDispatch();
  const wells = useSelector(state => state.wells);

  const closeModal = () => {
    close();
  };

  // const handleInputChange = (label, field, value) => {
  //   const updatedCurves = curves.map((curve) =>
  //     curve.label === label ? { ...curve, [field]: value } : curve
  //   );
  //   setCurves(updatedCurves);
  // };

  // const handleOperatorChange = (value) => {
  //   setOperator(value)
  // }

  // const toggleRow = (label) => {
  //   const updatedSelectedCurves = selectedCurves.includes(label)
  //     ? selectedCurves.filter((selectedLabel) => selectedLabel !== label)
  //     : [...selectedCurves, label];
  //   setSelectedCurves(updatedSelectedCurves);
  // };

  // const reshapeData = () => {
  //   const selectedData = curves.filter((curve) => selectedCurves.includes(curve.label));
  //   const transformedData = selectedData.map((curve) => {
  //     let transformedOperator = curve.operator;
  //     if (transformedOperator === '=') {
  //       transformedOperator = '===';
  //     } else if (transformedOperator === '<>') {
  //       transformedOperator = '!==';
  //     }

  //     return {
  //       curveName: curve.label,
  //       expression: operator,
  //       expressions: `${curve.label} ${transformedOperator} ${curve.value}`,
  //       filterFnExpressions: `return ${operator}`,
  //       filterFnKey: curve.label,
  //       filterKey: curve.label,
  //       operator: operator,
  //       wellId: wells.currentWell.wellId,
  //       currentFilter: {
  //         checkbox1: true,
  //         curve1Row1: curve.curveName,
  //         curve2Row1: curve.value,
  //         operator1: transformedOperator,
  //       }
  //     };
  //   });

  //   return transformedData;
  // }

  // const SendCutOff = async () => {
  //   const wellId = wells.currentWell.well_uid;
  //   const transformedData = reshapeData();
  //   const token = store.get('token');
  //   const cutoffs = await getAllCutOffs(token, wellId);
  //   // await dispatch(deleteCutOff(token, cutoffs.data, wellId));
  //   const promises = transformedData.map((data) => createCutOffFilter(data, token));
  //   const response = await Promise.all(promises);
  //   const newCutoffs = await getAllCutOffs(token, wellId);
  //   await dispatch(fetchCutOffs(token, wellId));
  //   closeModal();
  // };

  // useEffect(() => {
  //   if (wells.currentWell && wells.currentWell.curvesKeys != null) {
  //     const filteredOptions = Object.entries(wells.currentWell.curvesKeys)
  //       .filter(([key, value]) => value !== null)
  //       .map(([key, value]) => ({ label: key, operator: '>', value: '', enabled: false }));
  //     setCurves(filteredOptions);
  //   }
  // }, [wells.currentWell]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          width: '100%',
          height: '806px',
          padding: '20px',
          backgroundColor: '#09090B',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <h3 style={{ textAlign: 'start', marginBottom: '16px', color: 'white' }}><b>Cutoffs</b></h3>
        <br />
        <CutOffViewerBtn />
        <CutoffProxy
          disableCutOffViewer
          enableSaveButton
          currentWell={wells.currentWell}
          setCutoffSettings={setCutoffSettings}
          wellActions={wellActions}
        />
        {/*
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px', color: 'white' }}>
          <span style={{ flex: 1, textAlign: 'start' }}>Curve</span>
          <span style={{ flex: 1, textAlign: 'start' }}>Operator</span>
          <span style={{ flex: 1, textAlign: 'start' }}>Value</span>
        </div>
        {curves.map((curve) => (
          <div key={curve.label} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
            <input
              type="checkbox"
              checked={selectedCurves.includes(curve.label)}
              onChange={() => toggleRow(curve.label)}
              style={{ marginRight: '8px' }}
            />
            <Input
              value={curve.label}
              style={{
                flex: 1,
                background: '#18181B',
                color: 'white',
                border: '1px solid #52525B',
              }}
              disabled
            />
            <Select
              value={curve.operator}
              onChange={(value) => handleInputChange(curve.label, 'operator', value)}
              style={{
                flex: 1,
                marginRight: '8px',
                background: '#18181B',
                color: 'white',
                border: '1px solid #52525B',
              }}

            >
              {['>', '<', '=', '<=', '<>', '>='].map((op) => (
                <Option key={op} value={op} disabled={!selectedCurves.includes(curve.label)}>
                  {op}
                </Option>
              ))}
            </Select>
            <Input
              value={curve.value}
              onChange={(e) => handleInputChange(curve.label, 'value', e.target.value)}
              style={{
                flex: 1,
                background: '#18181B',
                color: 'white',
                border: '1px solid #52525B',
              }}
              placeholder="Ex.: 1000"
              disabled={!selectedCurves.includes(curve.label)}
            />
          </div>
        ))}
        <Select
          defaultValue={'&&'}
          onChange={handleOperatorChange}
          style={{ width: '15%' }}
        >
          <Option value={'&&'}>AND</Option>
          <Option value={'||'}>OR</Option>
        </Select>
        <div style={{ flex: 1 }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={SendCutOff} style={{ backgroundColor: '#233BB6', color: 'white', border: 'none' }}>
            <CheckOutlined />
            Apply
          </Button>
        </div> */}
      </div>
    </Modal>
  );
};

export default CutOffModal;
