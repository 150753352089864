import React, { Component } from 'react';
import { Button } from 'antd';
import Highcharts from 'highcharts';
import get from 'lodash.get';
import Exporting from 'highcharts/modules/exporting';
import classNames from 'classnames';
import {
  calculateCoordinates, calculateTransitionByX
} from '../../../utils/CalculatingCoordinatesArea';
import { ignoreNegativeNumbers, getPointsByC1C45Zones } from '../../../utils';
import { drawPath } from '../../../utils/Svg';

Exporting(Highcharts);

/*
* Oil Zone
* 1-2: y = 0,3706x-3,657
* 2-3: y = 0,292x1,2285
* 3-4: y = 66656x-3,657
* 4-1: y = 4,4904x1,2264
*/

// 4-1: y = 4,4904x1,2264
// 1-2: y = 0,3706x-3,657
const P1 = calculateCoordinates(
  0.3706, -3.657, 4.4904, 1.2264
);

// 1-2: y = 0,3706x-3,657
// 2-3: y = 0,292x1,2285
const P2 = calculateCoordinates(
  0.3706, -3.657, 0.292, 1.2285
);

// 2-3: y = 0,292x1,2285
// 3-4: y = 66656x-3,657
const P3 = calculateCoordinates(
  0.292, 1.2285, 66656, -3.657
);

// 3-4: y = 66656x-3,657
// 4-1: y = 4,4904x1,2264
const P4 = calculateCoordinates(
  4.4904, 1.2264, 66656, -3.657
);

/*
* Gas Zone
* 5-6: y = 2E+06x-3,657
* 6-7: y = 0,0589x1,2126
* 7-8: y = 4E+11x-3,657
* 8-5: y = 10,218x1,1454
*/

// 5-6: y = 2e+06x-3,657
// 6-7: y = 0,0589x1,2126
const P5 = calculateCoordinates(
  2 * Math.pow(10, 6),
  -3.657,
  10.218,
  1.1454
);

const P6 = calculateCoordinates(
  0.0589,
  1.2126,
  2 * Math.pow(10, 6),
  -3.657
);

// * 7-8: y = 4E+11x-3,657
// * 6-7: y = 0,0589x1,2126
const P7 = calculateCoordinates(
  4 * Math.pow(10, 11),
  -3.657,
  0.0589,
  1.2126
);

// * 7-8: y = 4E+11x-3,657
// * 8-5: y = 10,218x1,1454
const P8 = calculateCoordinates(
  4 * Math.pow(10, 11),
  -3.657,
  10.218,
  1.1454
);

/*
P1 = calculateCoordinates(0.3706, -3.657, 4.4904, 1.2264)
{x: 0.5999990295280129, y: 2.399986844817644 }

P2 = calculateCoordinates(0.3706, -3.657, 0.292, 1.2285)
{x: 1.0500011099708821, y: 0.3100376735313096}

P3 = calculateCoordinates(0.292, 1.2285, 66656, -3.657);
{x: 12.497069550920054, y: 6.498478837533861}

P4 = calculateCoordinates(66656, -3.657, 4.4904, 1.2264);
{x: 7.148773264981659, y: 50.10886536234771}

P5 = calculateCoordinates(2 * Math.pow(10, 6), -3.657, 10.218, 1.1454);
{x: 12.643842598695072, y: 186.83498324567495}

P6 = calculateCoordinates(2 * Math.pow(10, 6), -3.657, 0.0589, 1.212);
{x: 35.2132941472749, y: 4.412896687358703}

P7 = calculateCoordinates(0.0589, 1.2126, 4 * Math.pow(10, 11), -3.657);
{x: 431.63104922145453, y: 92.35340837872408}

P8 = calculateCoordinates(4 * Math.pow(10, 11), -3.657, 10.218, 1.1454);
{x: 160.58627165316872, y: 3433.873696646201}
*/

// Transition Upper:
// y = 1,2515x1,1771
// Min: x = 9,5
// Max: x = 19,2
/*
upperMin --> {x: 9.5, y: 17.713701001575956}
upperMax --> {x: 19.2, y: 40.55129521926865}
bottomMin --> {x: 12.5, y: 6.493130090484502}
bottomMax --> {x: 28.5, y: 9.564967135912005}
*/
const upperMin = calculateTransitionByX(9.5, 1.2515, 1.1771);
const upperMax = calculateTransitionByX(19.2, 1.2515, 1.1771);

// Transition Bottom:
// y = 1,9811x0,47
// Min: x = 12,5
// Max: x = 28,5
const bottomMin = calculateTransitionByX(12.5, 1.9811, 0.47);
const bottomMax = calculateTransitionByX(28.5, 1.9811, 0.47);

// Oil area
const drawOil = chart => drawPath(
  P1.x, P1.y,
  P2.x, P2.y,
  P3.x, P3.y,
  P4.x, P4.y,
  chart,
  {
    'fill': '#d1dabc',
    'stroke': '#718656',
    'stroke-width': 2,
    'opacity': '0.5'
  }
);

// Gas area
const drawGas = (chart) => drawPath(
  P5.x, P5.y,
  P6.x, P6.y,
  P7.x, P7.y,
  P8.x, P8.y,
  chart,
  {
    'fill': '#f1a7a7',
    'stroke': '#f15555',
    'stroke-width': 2,
    'opacity': '0.5'
  }
);

// Transition area
const drawTransition = (chart) => drawPath(
  upperMin.x, upperMin.y,
  bottomMin.x, bottomMin.y,
  bottomMax.x, bottomMax.y,
  upperMax.x, upperMax.y,
  chart,
  {
    'fill': '#f4c094',
    'stroke': '#ed713e',
    'stroke-width': 2,
    'opacity': '0.5'
  }
);

/*
* All gases should be in molar composition
*
* CC5 = C1 / C3
* CD5 = C1 / C45
* AS5 = C1*  Mol
*
* =IF(
*   AND(CC5<=23,4; CC5>=10,1; CD5<=33; CD5>=7,8)=TRUE; 20 ; IF(AS5<=13;10;30)
* )
*/
function calculateFluidTypeFromC1C45Chart(CC5, CD5, AS5) {
  let yValue = 0;
  if (CC5 <= 23.4 && CC5 >= 10.1 && CD5 <= 33 && CD5 >= 7.8) {
    yValue = 20;
  } else if (AS5 <= 13) {
    yValue = 10;
  } else if (AS5 > 13) {
    yValue = 30;
  }
  return yValue;
}

class ScatterPlotC1C45 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true
    };
    this.chart = null;
  }

  componentDidMount() {
    this.createScatterPlot();
    this.setupChart()
  }

  shouldComponentUpdate(nextProps) {
    const { data } = this.props;
    if ((nextProps.data && nextProps.data.length) !== (data && data.length)) {
      this.createScatterPlot(nextProps);
      this.setupChart();
      return true;
    }
    return false;
  }

  setupChart = () => {
    this.drawOil = () => drawOil(this.chart);
    this.drawGas = () => drawGas(this.chart);
    this.drawTransition = () => drawTransition(this.chart);

    // Oil
    this.chart.oil = this.drawOil();
    // Gas area
    this.chart.gas = this.drawGas();
    // Transition area
    this.chart.transition = this.drawTransition();

    const { idListener } = this.props;

    document.addEventListener(`${idListener}-chart5`, ({ detail }) => {
      const { config } = detail;
      const {yAxis, xAxis} = this.chart;
      if (yAxis && yAxis[0]) {
        yAxis[0].update({
          min: config.y.min,
          max: config.y.max,
          title: {
            text: config.y.title
          }
        });
      }
      if (xAxis && xAxis[0]) {
        xAxis[0].update({
          min: config.x.min,
          max: config.x.max,
          title: {
            text: config.x.title
          }
        });
      }

      this.chart.oil.destroy();
      this.chart.oil = this.drawOil();

      this.chart.gas.destroy();
      this.chart.gas = this.drawGas();

      this.chart.transition.destroy();
      this.chart.transition= this.drawTransition();

      this.setState({
        isVisible: config.isVisible
      });
    });
  }

  createScatterPlot = () => {
    const {
      id, yScale, xScale,
      data, receivePointsIsideZone, dataWithDepth
    } = this.props;

    // Oil Points
    const oilP1 = { x: 0.5999990295280129, y: 2.399986844817644 };
    const oilP2 = { x: 1.0500011099708821, y: 0.3100376735313096 };
    const oilP3 = { x: 12.497069550920054, y: 6.498478837533861 };
    const oilP4 = { x: 7.148773264981659, y: 50.10886536234771 };


    // Gas Points
    const gasP1 = P5;
    const gasP2 = P6;
    const gasP3 = P7;
    const gasP4 = P8;

    const resultInterestedArea = getPointsByC1C45Zones(
      data,
      oilP1, oilP2, oilP3, oilP4,
      gasP1, gasP2, gasP3, gasP4,
      bottomMin, bottomMax, upperMin, upperMax
    );

    /*
    * Filter the points with at the same values associated with  the depth
    * we need to filter to get the right depth to able to plot inside
    * the Fluid Type chart points`
    */
    if (resultInterestedArea) {
      const filteredPoints = this.shouldFilterPointsWithinZoneByDepth(dataWithDepth, resultInterestedArea);
      receivePointsIsideZone(filteredPoints);
    }

    const getTooltipWithDepth = (dataWithDepth = []) => ({
      headerFormat: '<b>{series.name}</b><br>',
      // pointFormat: `x: {point.x}, y: {point.y}`,
      formatter: function() {
        const itemWithDepth = dataWithDepth?.find(
          value => value?.item?.[0] === this?.x && value?.item?.[1] === this?.y
        );
        return `
          <b>Depth</b>: ${itemWithDepth?.depth}<br/>
          <b>(C1 / C4 + C5)</b>: ${itemWithDepth?.item?.[0]?.toFixed(2)}  <br/>
          <b>(C1 / C3)</b>: ${itemWithDepth?.item?.[1]?.toFixed(2)}
        `;
      },
      enabled: true
    });

    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'scatter'
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      credits: {
        enabled: false
      },
      fillOpacity: 1,
      opacity: 1,
      exporting: { enabled: false },
      xAxis: {
        ...xScale,
        title: {
          enabled: true,
          text: get(xScale, 'title', '')
        },
        startOnTick: true,
        endOnTick: true,
        lineWidth: 1,
        lineColor: '#ccd6eb',
        type: 'logarithmic',
        minorTickInterval: 0.1,
        gridLineWidth: 0
      },
      yAxis: {
        ...yScale,
        title: {
          text: get(yScale, 'title', '')
        },
        lineWidth: 1,
        lineColor: '#ccd6eb',
        type: 'logarithmic',
        pointStart: 0.1,
        gridLineWidth: 0,
        minorTickInterval: 0.1
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: -14,
        floating: true,
        backgroundColor: '#FFFFFF',
        borderWidth: 0
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 3,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          // tooltip: {
          //   headerFormat: '<b>{series.name}</b><br>',
          //   pointFormat: '{point.x} , {point.y}',
          //   enabled: true
          // }
        }
      },
      tooltip: getTooltipWithDepth(this.props.dataWithDepth),
      series: [
        // {
        // name: '',
        // color: 'red',
        // data: interestedCoordinates,
        // marker: {
        // fillColor: 'rgba(0, 0, 0, 0)',
        // symbol: 'circle',
        // radius: 2,
        // },
        // tooltip: {
        // enabled: true,
        // }
        // },
        {
          name: ' ',
          color: 'rgba(231, 76, 60, 0.5)',
          data: ignoreNegativeNumbers(data),
          marker: {
            fillColor: 'rgba(231, 76, 60, 0.5)',
            symbol: 'circle',
            radius: 2
          }
        }
      ]
    });
  }

  /*
  * shouldFilterPointsWithinZoneByDepth
  *  Will receive the dataWith Depth to filter oil, gas, transition
  *  the purpose is recognize the pont values associated with a depth
  *  to return and use it.
  *  NOTE: on this point we need to apply the normalization to the xPoint value
  *  I found it on the EXCELL spreadsheet, they analize it with 10, 20, 30 values
  */
  shouldFilterPointsWithinZoneByDepth = (dataWithDepth, resultInterestedArea) => {
    let filteredPoints = [];
    filteredPoints = dataWithDepth.reduce((acc, currentValue) => {
      // Check if this oil point is equal to the point associated with a depth
      resultInterestedArea.oil.reduce((acc2, oilPoint) => {
        if (
          JSON.stringify(oilPoint) === JSON.stringify(currentValue.item)
        ) {
          // Normalize the points
          /*
            CC5 = C1 / C3
            CD5 = C1 / C45
            AS5 = C1*  Mol
            calculateFluidTypeFromC1C45Chart(CC5, CD5, AS5)
          */
          const { c1c3, c1c45, c1 } = currentValue;
          const normalizedX = calculateFluidTypeFromC1C45Chart(c1c3, c1c45, c1);
          // x, y
          acc.push([normalizedX, currentValue.depth]);
        }
        return acc2;
      }, []);
      resultInterestedArea.gas.reduce((acc3, gasPoint) => {
        if (
          JSON.stringify(gasPoint) === JSON.stringify(currentValue.item)
        ) {
          const { c1c3, c1c45, c1 } = currentValue;
          const normalizedX = calculateFluidTypeFromC1C45Chart(c1c3, c1c45, c1);
          acc.push([normalizedX, currentValue.depth]);
        }
        return acc3;
      }, []);
      resultInterestedArea.transition.reduce((acc4, transitionPoint) => {
        if (
          JSON.stringify(transitionPoint) === JSON.stringify(currentValue.item)
        ) {
          const { c1c3, c1c45, c1 } = currentValue;
          const normalizedX = calculateFluidTypeFromC1C45Chart(c1c3, c1c45, c1);
          acc.push([normalizedX, currentValue.depth]);
        }
        return acc4;
      }, []);
      return  acc;
    }, []);
    return filteredPoints;
  }

  render() {
    const {
      id, withMinorGridLines, className,
      openConfigureScale
    } = this.props;
    const { isVisible } = this.state;
    return (
      <div
        className="scatter-general c1Byc45-container"
        style={{
          width: '400px',
          display: isVisible ? 'flex' : 'none',
        }}
      >
        {
          openConfigureScale && (
            <Button
              type="primary"
              shape="circle"
              icon="setting"
              size="large"
              className="toggle-crossplot-config--right"
              onClick={openConfigureScale}
            />
          )
        }
        <div
          className={classNames(
            className,
            'deactivate-legend',
            { 'reset-minor-grids': !withMinorGridLines }
          )}
          id={id}
          style={{
            height: '350px',
            width: '390px',
            flex: 1
          }}
        />
      </div>
    );
  }
}

ScatterPlotC1C45.defaultProps = {
  withMinorGridLines: false,
  className: 'scatter-plot'
};

export default ScatterPlotC1C45;

