import styled from 'styled-components';
import ButtonBase from './ButtonBase';
import { ButtonStyleProps } from './types';

const ButtonSecondary = styled(ButtonBase)<ButtonStyleProps>`
  && {
    width: ${(props) => props.width};
    border: 1px solid #6B7280;
  }
`;

export default ButtonSecondary