import React from 'react';
import { Step, Typography, Stepper } from '@mui/material';
import { StepsProps } from './types';

import { StepLabelStyled, StepTitle } from './styles';

const Steps = ({ activeStep, steps, skipped }: StepsProps) => {
  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  return (
    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: {
          optional?: React.ReactNode;
        } = {};
        if (isStepOptional(index)) {
          labelProps.optional = (
            <Typography variant="caption"></Typography>
          );
        }
        if (isStepSkipped(index)) {
          stepProps.completed = false;
        }
        return (
          <Step key={label} {...stepProps}>
            <StepLabelStyled {...labelProps}>
              <StepTitle isActiveStep={activeStep >= index}>{label}</StepTitle>
            </StepLabelStyled>
          </Step>
        );
      })}
    </Stepper>
  )
}

export default Steps;