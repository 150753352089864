import './style.scss';

import React, {
  useEffect,
  useState
} from 'react';

import { Table } from 'antd';
import { fetchInWellWatcherSites } from 'api/wellwatcher'
import Spinner from 'components/Spinner';
import ToggleButton from 'react-toggle-button';
import store from 'store';

const columns = [
  {
    title: 'Name',
    dataIndex: 'nameWell',
    key: 'nameWell'
  },
  {
    title: 'Log Name',
    dataIndex: 'logName',
    key: 'logName'
  },
  {
    title: 'Start',
    dataIndex: 'startIndex',
    key: 'startIndex'
  },
  {
    title: 'Stop',
    dataIndex: 'endIndex',
    key: 'endIndex'
  },
  {
    title: 'Unit',
    dataIndex: 'indexUnit',
    key: 'indexUnit'
  },
  {
    title: 'Status',
    key: 'isActive',
    render: (data) => {
      if (data?.isActive === 'false') {
        return (
          <div style={{ color: 'red', display: 'flex' }}>
            <div style={{
              width: 6, height: 6, background: 'red',
              borderRadius: '50%', marginTop: 6, marginRight: 4
            }} />
            <span style={{ textTransform: 'capitalize', fontSize: 12, }}>Deactivated</span>
          </div>
        );
      }

      if (data?.isActive === 'true') {
        return (
          <div style={{ color: '#24ff24', display: 'flex' }}>
            <div style={{
              width: 6, height: 6, background: '#24ff24',
              borderRadius: '50%', marginTop: 8, marginRight: 4
            }} />
            <span style={{ textTransform: 'capitalize' }}>Active</span>
          </div>
        );
      }
      return 'N/A';
    }
  },
];

const TableSelectWell = ({ onSelectWell }) => {
  const [allWells, setAllWells] = useState([]);
  const [inWellList, setInWells] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wellsType, setWellsType] = useState(true);

  const rowSelection = {
    type: 'radio',
    onSelect: (row) => {
      onSelectWell(row);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await fetchInWellWatcherSites(store.get('token'));

        let activeWells = [];
        if (wellsType) {
          activeWells = (response.data || []).filter(w => w?.isActive === "true");
        } else {
          activeWells = (response.data || []).filter(w => w?.isActive === "false");
        }
        setInWells(activeWells);
        setAllWells(response.data || []);
      } catch (e) {
        console.log('TableSelectWell fetchInWells error', e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    let activeWells = [];
    if (wellsType) {
      activeWells = (allWells || []).filter(w => w?.isActive === 'true');
    } else {
      activeWells = (allWells || []).filter(w => w?.isActive === 'false');
    }
    setInWells(activeWells);
  }, [wellsType]);

  return (
    <>
      {loading && <div className="cuttoff-load-container">
        <Spinner />
        <br />
        <div className="cuttoff-load-text">
          Loading...
        </div>
      </div>}
      {!loading && (
        <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end', justifyContent: 'flex-end' }}>
          <span style={{ color: '#fff', fontFamily: 'sans-serif', fontSize: 13 }}>Filter by Status:</span><div>&nbsp;</div>
          <ToggleButton
            inactiveLabel="All"
            activeLabel="Active"
            value={wellsType}
            colors={{
              activeThumb: {
                base: 'rgb(255,255,255)'
              },
              inactiveThumb: {
                base: 'rgb(255,255,255)',
                hover: 'rgb(255,255,255)'
              },
              active: {
                base: '#65c970',
                hover: '#65c970'
              },
              inactive: {
                base: '#ff000099',
                hover: '#ff000099'
              }
            }}
            onToggle={(value) => {
              setWellsType(!value);
            }}
            disabled={loading}
          />
        </div>
      )}
      {!loading &&
        <Table
          columns={columns}
          dataSource={inWellList.map((item, key) => ({ ...item, key }))}
          // pagination={false} 
          className="table-select-inWell"
          rowSelection={rowSelection}
          pagination={{ defaultPageSize: 5, showSizeChanger: true, pageSizeOptions: ['5', '10'] }}
        />
      }
    </>
  );
};

export default TableSelectWell;
