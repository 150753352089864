import React, {
  useEffect,
  useState
} from 'react';

import { useSelector } from 'react-redux';

import IndicatorsCard from './IndicatorsCard';
import { getCalculatedCurveData } from '../utils/getCalculatedCurveData';

const CarbonCard = () => {
  const wells = useSelector(state => state.wells);
  const [totalCarbonValue, setHeaderState] = useState('N/A');

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const totalCarbon = getCalculatedCurveData('total_carbon', wells.currentWell);
      const index = wells.selectedDepthIndex || 0;
      const depthValue = totalCarbon && totalCarbon.data[index] ? totalCarbon.data[index] : 0;
      setHeaderState(depthValue?.toFixed(2));
    }
  }, [wells]);

  return (
    <IndicatorsCard
      className="wrapper-carbon"
      description="Total Carbon"
      unit=""
      value={Math.floor(totalCarbonValue) || 'N/A'}
      type="carbon"
    />
  );
};

export default CarbonCard;

