import React from 'react';
import { Popover } from 'antd';
import help from 'assets/help-circle.svg';

const content = (openModal) => (
  <div className="content-popover">
    <p>
      Significance is a parameter that, <br/>
      succinctly, represents the percentage <br/>
      of the most significant values ​​<br/>
      calculated by the metrics selected.
    </p> 
    <div onClick={() => openModal()}>Read more</div>
  </div>
);

const PopoverConcepts = ({openModal}) => (
  <Popover
    content={content(openModal)}
    placement="left"
    title="Significance concepts"
    overlayClassName="popover"
  >
    <img
      className="help-popover"
      src={help}
      alt="help"
    />
  </Popover>
);

export default PopoverConcepts;