import React, {useState, useEffect, useRef} from 'react';
import { Select } from 'antd';
import { useStatisticWizard } from '../ModalStatistics/useStatisticsWizard';
const { Option } = Select;

const MultiSelect = ({ title, afterSelect, listOfDefaultCurves }) => {
  const selectRef = useRef();
  const [value, setValue] = useState();
  const { setCurves, curves } = useStatisticWizard();

  useEffect(() => {
    if (!value?.length && listOfDefaultCurves) {
      setValue(listOfDefaultCurves);
    }
  }, [listOfDefaultCurves, value]);
  const options = ['C1', 'C2', 'C3', 'C4', 'IC4', 'C5', 'IC5', 'C1%', 'C2%', 'C3%', 'C4%', 'C5%', 'GQR'];

  const onSelectGasCurves = (selectedCurves) => {
    setValue(selectedCurves);
    const formattedCompositionCurve = selectedCurves.map((curve) => {
      if (curve.includes('%')) {
        return `${curve.replace('%', 'Composition')}`;
      }
      return curve;
    });
    if (selectedCurves && selectedCurves.length && afterSelect) {
      afterSelect(formattedCompositionCurve);
    }
    setCurves(formattedCompositionCurve);
  };

  useEffect(() => {
    const formattedCompositionCurves = (curves || []).map((curve) => {
      if (curve.includes('Composition')) {
        return `${curve.replace('Composition', '%')}`;
      }
      return curve;
    });
    setValue(formattedCompositionCurves);
  }, [curves]);

  const defaultOptions = options.map(field => <Option key={field}>{field}</Option>);

  return (
    <>
      <h1 className="multiselect__title">{title}</h1>
      <Select
        ref={selectRef}
        name="gasCurves"
        mode="multiple"
        placeholder="Please select"
        defaultValue={listOfDefaultCurves}
        value={value}
        onChange={onSelectGasCurves}
        className="multiselect__select"
        data-cy="gas-curves-select"
      >
        {defaultOptions}
      </Select>
    </>
  );
}

export default MultiSelect;
