import keys from 'lodash.keys';

// TODO document each function and creat the unit tests
export const appllyFilters = (data, filterFnKey, filterFnExpressions) => {
  /* eslint no-new-func: 0 */
  const fnWithExpressions = new Function(
    filterFnKey,
    `return ${filterFnExpressions || filterFnKey}`
  );
  return data.reduce((acc, currentItem) => {
    const isValid = fnWithExpressions(currentItem);
    if (isValid) {
      acc.push(currentItem);
    } else {
      acc.push(-1);
    }
    return acc;
  }, []);
};

// TODO improve this check to avoid some performance issues
export const checkFilters = (newFilters, oldFilters) => newFilters &&
  JSON.stringify(newFilters) !== JSON.stringify(oldFilters);

export const populateChartDataWithFilters = (
  chart, newFilters, oldFilters, curveData,
  curveFilterName, curveFilterFnExpressions,
  indexSerie
) => {
  // Check if the component received new filters
  const thereAreFilters = checkFilters(newFilters, oldFilters);
  if (thereAreFilters) {
    const filteredData = appllyFilters(
      curveData,
      curveFilterName,
      curveFilterFnExpressions
    );
    chart.series[indexSerie].update({
      data: filteredData
    }, true);
    chart.redraw();
  }
  if (keys(newFilters).length === 0) {
    chart.series[indexSerie].update({
      data: curveData
    }, true);
    chart.redraw();
  }
};

export const getSettingCurve = (setting, curveName, selectedWell) => {
  return setting &&
    setting.items &&
    setting.items[selectedWell] &&
    setting.items[selectedWell].curveName ? setting.items[selectedWell] : { filters: [] };
};

const generatePlotLines = (min, max) => {
  return [
    {
      color: '#000',
      width: 2,
      value: min,
      zIndex: 2,
      dashStyle: 'dash'
    },
    {
      color: '#000',
      width: 2,
      value: max,
      zIndex: 2,
      dashStyle: 'dash'
    }
  ];
};

export const displayGridLines = (
  chart,
  nextShouldDisplayGrids,
  propsShouldDisplayGrids,
  gridMin,
  gridMax,
  indexYaxis = 0
) => {
  if (
    nextShouldDisplayGrids !== propsShouldDisplayGrids
    || nextShouldDisplayGrids && gridMax && gridMax
  ) {
    let plotLines = [];
    if (gridMin && gridMax && nextShouldDisplayGrids) {
      plotLines = generatePlotLines(
        gridMin,
        gridMax
      );
    }
    chart.yAxis[indexYaxis].update({
      plotLines
    });
    chart.redraw();
  }
};

export const formatCutoffsForRedux = (cutoffSettings) => {
  if (!cutoffSettings) {
    return []
  }

  const { cutoffs, association } = cutoffSettings;
  const newCutoffs = cutoffs.reduce((acc, currentCutoff, index) => {
    const { curve, operator, value } = currentCutoff;
    const curveLowerCase = curve.toLowerCase();
    const formattedCutoff = {
      currentFilter: {
        [`checkbox${index+1}`]: true,
        [`curve1Row${index+1}`]: curve,
        [`curve2Row${index+1}`]: value,
        [`operator${index+1}`]: operator
      },
      curveName: curveLowerCase,
      expression: '0',
      expressions: `${curveLowerCase} ${operator} ${value}`,
      filterFnExpressions: 'return 0',
      filterFnKey: curveLowerCase,
      filterKey: curveLowerCase,
      operator: association === 'AND' ? '&&' : '||'
    };
    acc.push(formattedCutoff);

    return acc;
  }, [])

  return newCutoffs;
};
