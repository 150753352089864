import reduce from 'lodash.reduce';
import map from 'lodash.map';
import {
  RECEIVE_ZONE, DELETE_ZONE, UPDATE_ZONE, RECEIVE_ZONES,
  DELETE_ALL
} from '../constants/zone';
import { createReducer } from './createReducer';

/*
* Zone Scheme
*
* This is a format of each object within items
*
* const zone = {
*   id: '45',
*   wellId: 'a5g6e89w',
*   cutOffsIds: ['a6v6', 'h4f6'],
*   name: 'zone1', // value displayed on the zone
*   color: '#000',
*   top: 100,
*   bottom: 200,
*   borderColor: '#000',
*   borderWidth: 2,
*   chartToApply: 'gasComposition' || 'all', // chartID or allCharts
*   status: 'active',
* };
*/

const initialState = {
  items: [],
};

function receiveZone(state, action) {
  const { zone } = action;
  console.log('receive zone: ', state, zone)
  return {
    ...state,
    items: [
      ...state.items,
      zone
    ]
  }
}

function deleteAll() {
  return { items: [] };
}

function deleteZoneWell(state, action) {
  const { zoneId } = action;
  const items = reduce(state.items, (acc, zone) => {
    if (zone.id !== zoneId) {
      acc[zone.id] = zone;
    }
    return acc;
  }, {});
  return {
    ...state,
    items
  };
}

function updateZone(state, action) {
  const { zone } = action;
  const updatedZones = map(state.items, (z) => {
    if (z.label === zone.zone_set_name) {
      return zone.zones[0];
    }
    return z;
  });
  return {
    ...state,
    items: updatedZones,
  };
}

function receiveZones(state, action) {
  const { zones } = action;
  console.log('receive zones', zones);
  return {
    ...state,
    items: zones,
  };
}

export default createReducer(initialState, {
  [RECEIVE_ZONE]: receiveZone,
  [DELETE_ZONE]: deleteZoneWell,
  [DELETE_ALL]: deleteAll,
  [UPDATE_ZONE]: updateZone,
  [RECEIVE_ZONES]: receiveZones
});
