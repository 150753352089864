import React from 'react';
import classNames from 'classnames';
import Card from './Card';

const CardIndicators = (props) => {
  const {
    description, value, unit, classes, type
  } = props;
 
  return (
    <Card classes={{
      customContainer: classes.customClass
    }}>
      <div className="card__container">
        <div className={classNames(
          'card__description',
          { [type]: !!type }
        )}>
          <p>{description}</p>
          {unit && <p>{unit}</p>}
        </div>
        <div className="card__value" 
          style={ 
            ((value.toString().length) >= 11) ? {fontSize: '18px'} : {fontSize: '24px'} 
          }
        >
          <p>{value}</p>
        </div>
      </div>
    </Card>
  );
};

CardIndicators.defaultProps = {
  classes: {
    customClass: 'card--mini',
    raised: 'raised'
  },
  value: 'N/A',
  raised: false
};

export default CardIndicators;
