const textByDiameter = {
  '6.87': '6 7/8',
  '6.75': '6 3/4',
  '6.125': '6 1/8',
  '7.87': '7 7/8',
  '8.75': '8 3/4',
  '8.5': '8 1/2',
  '8.375': '8 3/8',
  '9.5': '9 1/2',
  '10.625': '10 5/8',
  '10.25': '12 1/4',
  '13.5': '13 1/2',
  '16': '16',
  '17.5': '17 1/2',
};

interface wellPhasesContent {
  top: number;
  base: number;
  diameter: number;
}

export const formatDiameter = (wellPhases: wellPhasesContent[]) => {
  return wellPhases.map((phase) => ({
    ...phase,
    diameter: textByDiameter[String(phase.diameter)]
  }))
}