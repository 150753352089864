export const toggleCurves = (chart, setSeries, index) => {
  if (chart.series) {
    setSeries((series) => {
      const shouldDisplay = !series[index];
      const currentTrack = chart.series[index];
      if (shouldDisplay) {
        currentTrack.show();
      } else {
        currentTrack.hide();
      }
      return { ...series, [index]: shouldDisplay };
    });
  }
};


const DEFAULT_LAST_SELECTED_DEPTH = 200;

export const applyDefaultZoomToChart = chartInstance =>
  setTimeout(() => {
    if (chartInstance && chartInstance.xAxis && chartInstance.xAxis[0]) {
      chartInstance.xAxis[0].setExtremes(0, DEFAULT_LAST_SELECTED_DEPTH);
    }
  }, 300);
