import store from 'store';
import {
  createWellPhases,
  getAllWellPhases,
  getWellPhase,
  editWellPhases,
  deleteWellPhases,
} from '../api/wellphases';
import {
  RECEIVE_WELL_PHASES,
  RECEIVE_WELL_PHASE,
  REMOVE_WELL_PHASE
} from '../constants/wellphases';

export const receiveWellPhases = (wellphases) => ({
  type: RECEIVE_WELL_PHASES,
  wellphases
});

export const receiveWellPhase = (wellphases) => ({
  type: RECEIVE_WELL_PHASE,
  wellphases
});

export const createBucketOfWellPhases = (wellphases, token, wellId, afterSuccess) => {
  return async (dispatch, getState) => {
    try {
      await createWellPhases(wellphases, token);
      dispatch(fetchWellPhases(store.get('token'), wellId));
      afterSuccess && afterSuccess();
    } catch (e) {
      console.log('error', e);
    }
  };
};


export const updateBucketOfWellPhases = (data, wellphaseId, wellId, afterSuccess) => {
  return async (dispatch, getState) => {
    try {
      await editWellPhases(data, wellphaseId);
      dispatch(fetchWellPhases(store.get('token'), wellId));
      afterSuccess && afterSuccess();
    } catch (e) {
      console.log('error', e);
    }
  };
};

export const fetchWellPhase = (token, id) => {
  return async (dispatch) => {
    try {
      await getWellPhase(token, id);
    } catch (e) {
      console.log('error', e)
    }
  };
};

export const fetchWellPhases = (token, wellId, afterSuccess) => {
  return async (dispatch) => {
    try {
      const response = await getAllWellPhases(token, wellId);
      dispatch(receiveWellPhases(response.data));
      afterSuccess && afterSuccess();
    } catch (e) {
      console.log('error', e)
    }
  };
};


export const removeWellPhases = (wellPhaseIds, wellId) => {
  return async (dispatch) => {
    try {
      const promises = wellPhaseIds.map((p) => deleteWellPhases(p.wellPhaseId));
      await Promise.all(promises);
      dispatch(fetchWellPhases(store.get('token'), wellId));
      //afterSuccess && afterSuccess();
    } catch (e) {
      console.log('error', e)
    }
  };
};

