/* eslint-disable */
import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  Select
} from 'antd';
import Spinner from 'components/Spinner';
import keys from 'lodash.keys';
import reduce from 'lodash.reduce';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import store from 'store';

import * as wellActionsCutOffs from '../../actions/cutoff';
import * as wellActionsFns from '../../actions/wells';
import cutoff from '../../assets/cutoff-icon.png';
import { defaultNotification } from '../../utils';
import GlobalCutOff from '../../utils/GlobalCutOff';
import AutoSuggest from '../Autocomplete';
import PlotBox from './Chart/PlotBox';
import { InputContainer } from '@geowellex/shared-importdata';
import { Box } from '@mui/material';
import { SelectWithoutControl as CustomSelect } from '@geowellex/shared-importdata/src/components/Select';
import { Label } from '@geowellex/shared-importdata/src/components/Label';

import './styles.scss';


const { Option } = Select;

const formatValue = x => ({ value: x });

export const matchStateToTerm = (state, value) => {
  return (
    state.nameAutoComplete.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
};

const transformFilters = (filters) => {
  const cutoffs = Object.keys(filters).map((key) => {
    const filter = filters[key];
    const curveKey = Object.keys(filter).find(k => k.startsWith('curve'));
    const operatorKey = Object.keys(filter).find(k => k.startsWith('operator'));
    const valueKey = Object.keys(filter).find(k => k.startsWith('curve2'));

    return {
      curve: filter[curveKey],
      operator: filter[operatorKey],
      value: parseFloat(filter[valueKey])
    };
  });

  return {
    cutoff_settings: {
      cutoffs: cutoffs,
      association: "AND",
      log_set_names: ["GQC"],
      curves_location: "GQC"
    }
  };
};

export const metadataCutoffKeys = {
  C1: 'c1',
  C2: 'c2',
  C3: 'c3',
  NC4: 'nc4',
  IC4: 'ic4',
  NC5: 'nc5',
  IC5: 'ic5',
  GQR: 'gqr',
  'C1%': 'c1_perc',
  'C2%': 'c2_perc',
  'C3%': 'c3_perc',
  'C4%': 'c4_perc',
  'C5%': 'c5_perc',
  'Wetness': 'wh_ratio',
  'Wetness': 'wh_ratio',
  'Character': 'ch_ratio',
  'Balance': 'bh_ratio',
  'IC4/NC4': 'ic4nc4',
  'IC5/NC5': 'ic5nc5',
  'C1/C2': 'c1c2',
};

const chartsCurvesMap = {
  chromaC1: ['C1'].map(formatValue),
  chromaC2: ['C2'].map(formatValue),
  chromaC3: ['C2'].map(formatValue),
  chromaNc4: ['NC4'].map(formatValue),
  chromaIc4: ['IC4'].map(formatValue),
  chromaNc5: ['NC5'].map(formatValue),
  chromaIc5: ['IC5'].map(formatValue),
  gasCompC1: ['C1%'].map(formatValue),
  gasCompC2: ['C2%'].map(formatValue),
  gasCompC3: ['C3%'].map(formatValue),
  gasCompC4: ['C4%'].map(formatValue),
  gasCompC5: ['C5%'].map(formatValue),
  gqr: ['GQR'].map(formatValue),
  // gasRatio: ['Gamma Ray'].map(formatValue),
  wetnessRatio: ['Wetness'].map(formatValue),
  characterRatio: ['Character'].map(formatValue),
  balanceRatio: ['Balance'].map(formatValue),
  ic4Nc4: ['IC4/NC4'].map(formatValue),
  ic5Nc5: ['IC5/NC5'].map(formatValue),
  c1c2: ['C1/C2'].map(formatValue),
  // sf: ['Slow Factor'].map(formatValue)
};

const initialValues = {
  cutOffsWithId: undefined,
  operationsOptions: ['>', '<', '=', '<=', '<>', '>='],
  logicalOperators: ['AND', 'OR'],
  logicalOperator: 'AND',
  classSelect: '',
  operator1: 'Operator',
  operator2: 'Operator',
  operator3: 'Operator',
  operator4: 'Operator',
  operator5: 'Operator',
  operator6: 'Operator',
  operator7: 'Operator',
  operator8: 'Operator',
  selected1: '',
  selected2: '',
  selected3: '',
  selected4: '',
  selected5: '',
  selected6: '',
  selected7: '',
  selected8: '',
  checkbox1: false,
  checkbox2: false,
  checkbox3: false,
  checkbox4: false,
  checkbox5: false,
  checkbox6: false,
  checkbox7: false,
  checkbox8: false,
  curve1Row1: '',
  curve2Row1: '',
  curve1Row2: '',
  curve2Row2: '',
  curve1Row3: '',
  curve2Row3: '',
  curve1Row4: '',
  curve2Row4: '',
  curve1Row5: '',
  curve2Row5: '',
  curve1Row6: '',
  curve2Row6: '',
  curve1Row7: '',
  curve2Row7: '',
  curve1Row8: '',
  curve2Row8: '',
  selectedOperatorRow2: '',
  data: {},
  group1: {},
  checkbox: null,
  messageError: null,
  statusValidation: false,
  fieldNameSelect: '',
  groupNa1meSelect: '',
  groupNameCurve: '',
  fieldNameCurve: null,
  groupName: null,
  fieldName: [],
  autoCompleteDisplay: false,
  focus: false,
  value: '',
  nameAutoComplete: 'Ma',
  loading: false,
};

const classes = {
  root: {
    background: "#45444f",
    transition: 'none',
    color: "#ffffff",
    borderRadius: 3,
    disableUnderline: true
  },
  input: {
    width: 120,
    height: 35,
    color: "#ffffff"
  }
};

const normalizeCutOffKey = curve => {
  let currentCurve = curve.replace('%', 'Composition');
  if (['c4Composition', 'c5Composition'].includes(currentCurve) ) {
    currentCurve = `n${currentCurve}`;
  }
  return currentCurve;
};

const WatchCutoffFromCutoffViewChart = ({ currentWell, wellId, populateForm }) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newCutoffs = store.get('guideline-cutoff');
      if (currentWell && wellId && newCutoffs) {
        populateForm(newCutoffs);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentWell, wellId]);

  return null;
};

const normalizec4c5 = expressions => {
  let normalizedExpression = expressions;
  if (
    expressions.includes('c4Composition') || expressions.includes('c5Composition')
  ) {
    normalizedExpression = `n${expressions}`;
  }
  return normalizedExpression;
};

class CutOffs extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialValues;
  }

  async componentDidMount() {
    const { selectedWell, currentWell } = this.props.wells;
    await this.listCutOffs();
    this.changeCutoffs();
    if (selectedWell !== -1) {
      GlobalCutOff.setAction(this.props?.wellActionCutOff?.receiveCutOffs);
      this.insertCutoffsValues();
      this.changeCutoffs();
    }

    document.addEventListener('changeCutoffs', this.changeCutoffs);
    document.addEventListener('deleteCuttOffs', this.deleteAllCutoffs);
  }

  // shouldComponentUpdate(nextProps) {
  //   console.log('1 should ====>', {
  //     props: this.props?.cutoffs?.length,
  //     nextProps: nextProps?.cutoffs?.length,
  //   });
  //   if (this.props?.cutoffs?.length !== nextProps?.cutoffs?.length) {
  //     console.log('shouldComponentUpdate =======>', nextProps?.cutoffs?.length);
  //     return true;
  //   }
  //   return true;
  // }

  shouldUpdateCutoffs = (newValues) => {
    this.insertCutoffsValues(newValues);
  }

  deleteAllCutoffs = () => {
    const { currentWell } = this.props.wells;
    const listAllCutOffs = this.props.cutoff && this.props.cutoff.items || [];
    const qualityDataGQC = currentWell?.calculated?.qualityData;
    this.setState({ loading: true });
    this.props.wellActionCutOff.deleteCutOff(
      store.get('token'),
      listAllCutOffs,
      currentWell.wellId,
      this.afterSuccess
    );

    this.props.wellActionCutOff.receiveCutOffs([]);
    this.resetCutoffsValues();
  }

  afterSuccess = () => {
    this.setState({ loading: false });
  }

  listCutOffs = async () => {
    const { currentWell, selectedWell } = this.props.wells;
    this.setState({ ...initialValues, loading: true });
    await this.props.wellActionCutOff.fetchCutOffs(
      store.get('token'),
      selectedWell ? selectedWell: currentWell && currentWell.wellId,
      this.afterSuccess
    );
    // const cutoffs = currentWell?.filtered.settings;
    // this.props.wellActionCutOff.fetchCutOffs(store.get('token'), cutoffs, this.afterSuccess);
    // await this.props.wellActionCutOff.fetchCutOffs(
    //   store.get('token'),
    //   selectedWell ? selectedWell: currentWell && currentWell.wellId,
    //   this.afterSuccess
    // );
  }

  changeCutoffs = async () => {
    await this.listCutOffs();
    const cutOffsItems = this.props?.cutoff?.items || [];
    if (cutOffsItems && cutOffsItems.length && cutOffsItems[0]) {
      this.resetCutoffsValues();
      this.insertCutoffsValues();
    }

    if (cutOffsItems && cutOffsItems.length === undefined)  {
      this.resetCutoffsValues();
    }
  }

  resetCutoffsValues = () => {
    this.setState({
      ...initialValues,
    }, () => {
      this.forceUpdate();
    });
  }
  // TODO olhar aqui
  insertCutoffsValues = (newValues) => {
    const cutoffsItems = newValues ? newValues : (this.props?.cutoff?.items || []);
    let newData = {};

    const fieldValues = cutoffsItems?.reduce((acc, item, index) => {
      // const rowIndex = index + 1;
      acc = {
        ...acc,
        ...item?.currentFilter,
        // [`checkbox${rowIndex}`]: true,
        // [`curve1Row${rowIndex}`]: item.curve,
        // [`operator${rowIndex}`]: item.operator,
        // [`curve2Row${rowIndex}`]: item.value,
        // curveName: item.curve,
        // operator: item.operator
      };
      return acc;
    }, {});

    this.setState({
      ...newData,
      ...fieldValues
    }, () => {
      this.forceUpdate();
    });
  }

  onChangeSelectOperator = (value) => {
    this.setState({
      logicalOperator: value,
      touched: true,
    });
  }

  onChangeCheckbox = (groupName, fieldName) => (event) => {
    const data =  {
      [groupName]: {
        ...this.state.data[groupName],
        [fieldName]: event.target.checked
      }
    };
    this.setState((state) => ({
      data: {
        ...state.data,
        ...data
      },
      touched: true,
      [fieldName]: event.target.checked
    }));
  }

  onChangeSelect = (groupName, fieldName) => (value) => {
    const data =  {
      [groupName]: {
        ...this.state.data[groupName],
        [fieldName]: value
      }
    };

    this.setState((state) => ({
      data: {
        ...state.data,
        ...data
      },
      touched: true,
      groupName,
      [fieldName]: value
    }));
  }

  handleChangeAutoComplete = (groupName, fieldName, value) => {
    if (value) {
      this.setState({
        autoCompleteDisplay: false
      });
    }

    this.setState({
      autoCompleteDisplay: true
    });

    const data =  {
      [groupName]: {
        ...this.state.data[groupName],
        [fieldName]: value
      }
    };
    this.setState((state) => ({
      touched: true,
      data: {
        ...state.data,
        ...data
      },
      [fieldName]: value
    }));
  }

  handleChange = (groupName, fieldName) => (event) => {
    const { chartName } = this.props;
    const curveNames = chartsCurvesMap[chartName];

    const groupName = event.target
      && event.target.dataset
      && event.target.dataset.group ? event.target.dataset.group : groupName;

    const name = event.target
      && event.target
      && event.target.name ? event.target.name : fieldName;

    const value = event.target && event.target.value ? event.target.value : event;

    const data =  {
      [groupName]: {
        ...this.state.data[groupName],
        [name]: value
      }
    };

    const allFields = [];
    const resfield = allFields.push(name);

    this.setState((state) => ({
      touched: true,
      data: {
        ...state.data,
        ...data
      },
      groupName,
      fieldName: resfield,
      [fieldName]: value
    }));
  }

  removeCutoffViewer = () => {
    store.remove('cutoff-c1');
    store.remove('cutoff-c2');
    store.remove('cutoff-c3');
    store.remove('cutoff-nc4');
    store.remove('cutoff-ic4');
    store.remove('cutoff-nc5');
    store.remove('cutoff-ic5');
    store.remove('cutoff-c1%');
    store.remove('cutoff-c2%');
    store.remove('cutoff-c3%');
    store.remove('cutoff-c4%');
    store.remove('cutoff-c5%');
  }

  submitCutOff = (shouldSave) => () => {
    const curvesChecked = Object.keys(this.state).reduce((acc, key, index) => {
      if (key?.includes('checkbox') && this?.state?.[key] === true) {
        acc.push(key.split('checkbox')[1]);
      }
      return acc;
    }, []);

    // key = 1, 2, 3...
    const formatedData = (curvesChecked || []).reduce((acc, key, index) => {
      const curve1 = `curve1Row${key}`;
      const curve2 = `curve2Row${key}`;
      const operator = `operator${key}`;
      const checkbox = `checkbox${key}`;
      acc[key] = {
        [checkbox]: this.state[checkbox],
        [curve1]: this.state[curve1],
        [curve2]: this.state[curve2],
        [operator]: this.state[operator],
      };
      return acc;
    }, {});

    if (this?.props?.enableSaveButton && !shouldSave) {
      this.submitApplyCutOff(formatedData);
    }
    if (this?.props?.enableSaveButton && shouldSave) {
      this.submitCutOffAndPersist(formatedData);
    }
  }

  submitCutOffAndPersist = async (filters) => {
    this.removeCutoffViewer();

    const { logicalOperator } = this.state;
    const { wells } = this.props;
    const logical = logicalOperator && logicalOperator === 'AND' ? '&&' : '||';

    // TODO move it out to an filter utils and crate a the unit tests
    // gererate the predicates
    const allFilters = reduce(filters, (acc, currentFilter, filterKey) => {
      const numberRow = filterKey.slice(-1); // 1, 2, 3 ...8
      if (currentFilter[`checkbox${numberRow}`] === true || this.state[`checkbox${numberRow}`]) {
        let a = currentFilter[`curve1Row${numberRow}`] || this.state[`curve1Row${numberRow}`];
        if(Number.isNaN(Number(a))) {
          a = a;
        } else if (typeof Number(a) === 'number') {
          defaultNotification('Ops! You should inform a curve and value. Not a value and curve');
          return;
        }

        let operator = currentFilter[`operator${numberRow}`] || this.state[`operator${numberRow}`];
        if (operator === '=') {
          operator = '===';
        }
        if (operator === '<>') {
          operator = '!==';
        }
        const b = currentFilter[`curve2Row${numberRow}`] || this.state[`curve2Row${numberRow}`];
        const leftSide = Number.isNaN(Number(a)) ? a.toLowerCase().replace(' ', '_') : a;
        const rightSide = Number.isNaN(Number(b)) ? b.toLowerCase().replace(' ', '_') : b;
        const expression = `${leftSide} ${operator} ${rightSide}`;

        const currentExpression = `${expression} ${acc.index && acc.index < acc.totalLength - 1 ? logical : ''}`;

        const lastCharacterOnTheExpression = currentExpression.slice(-2);
        const normalizeToRemoveLastOperator = lastCharacterOnTheExpression === '&&'
          || lastCharacterOnTheExpression === '||' ? currentExpression.slice(0, -2) : currentExpression;

        // TODO implement cutoff comparing curves values
        // prevent to apply filter by two curves
        const thereAreNoTwoCurves = !(Number.isNaN(Number(leftSide))
          && !Number.isNaN(Number(rightSide)) || !Number.isNaN(Number(leftSide))
          && Number.isNaN(rightSide))
        if (!thereAreNoTwoCurves) {
          acc.filters.push({
            currentFilter,
            expression: lastCharacterOnTheExpression,
            filterFnExpressions: `return ${lastCharacterOnTheExpression}`,
            filterKey: normalizeCutOffKey(leftSide),
            filterFnKey: normalizeCutOffKey(leftSide),
            curveName: normalizeCutOffKey(leftSide),
            operator: logical,
            expressions: normalizec4c5(normalizeCutOffKey(normalizeToRemoveLastOperator))
          });
        }
      }
      if (acc && acc.index) {
        acc.index += 1;
      }
      return acc;
    }, {
      filters: [],
      index: 1,
      totalLength: keys(filters).length
    });

    const { selectedWell, currentWell } = wells;
    const cutOffsItems = this.props.cutoff && this.props.cutoff.items || [];
    //const oldValue = await this.props.wellActionCutOff.fetchCutOffs(store.get('token'), currentWell && currentWell.wellInfo && currentWell.wellInfo.wellId);

    const newCurrentValues = allFilters && allFilters.filters.filter(filterCutOff => {
      return cutOffsItems.filter(oldValue => {
        return oldValue === filterCutOff;
      })
    });

    if (selectedWell !== -1) {
      // const newWell = {
      //   ...currentWell,
      //   cutOffs: allFilters && allFilters.filters
      // };
      // const newCutOffs = reduce(wells.items, (acc, item) => {
      //   if (item.wellId === newWell.wellInfo.wellId) {
      //     acc.push(newWell);
      //   } else {
      //     acc.push(item);
      //   }
      //   return acc;
      // }, []);

      // const wellWithCutOffs = newCutOffs.filter(item => {
      //   return item && item.cutOffs && item.cutOffs.length > 0 && item;
      // });

      const cutOffWithId = (newCurrentValues || []).map((cutOff) => {
        return {...cutOff, wellId: currentWell.wellId };
      });

      // GlobalCutOff.connectToRedux(wellAction.updateWell, newWell);
      GlobalCutOff.registerFilters(
        currentWell.wellId,
        allFilters && allFilters.filters,
        true,
        currentWell
      );

      const token = store.get('token');

      if (allFilters && allFilters.filters.length === 0) {
        defaultNotification('Filter can\'t be applyed, please provide a curve and value!');
      } else if (allFilters !== undefined) {
        defaultNotification('Applied Cutoff!');
        // setOnIndexDB('wells', newCutOffs);
      } else {
        alert('Ops! Please you need to select an well first.');
      }
    }
    try {
      const { cutoff_settings } = transformFilters(filters);
      // await this.props.wellActions.addCutoff(
      //   currentWell.well_uid,
      //   cutoff_settings,
      //   logical
      // );

      this.props.setCutoffSettings(cutoff_settings);
   } catch(error) {
   }
  }

  submitApplyCutOff = (filters) => {
    this.removeCutoffViewer();

    const { logicalOperator } = this.state;
    const { wells } = this.props;
    const logical = logicalOperator && logicalOperator === 'AND' ? '&&' : '||';
    // TODO move it out to an filter utils and crate a the unit tests
    // gererate the predicates
    const allFilters = reduce(filters, (acc, currentFilter, filterKey) => {
      const numberRow = filterKey.slice(-1); // 1, 2, 3 ...8
      if (currentFilter[`checkbox${numberRow}`] === true || this.state[`checkbox${numberRow}`]) {
        let a = currentFilter[`curve1Row${numberRow}`] || this.state[`curve1Row${numberRow}`];
        if(Number.isNaN(Number(a))) {
          a = a;
        } else if (typeof Number(a) === 'number') {
          defaultNotification('Ops! You should inform a curve and value. Not a value and curve');
          return;
        }

        let operator = currentFilter[`operator${numberRow}`] || this.state[`operator${numberRow}`];
        if (operator === '=') {
          operator = '===';
        }
        if (operator === '<>') {
          operator = '!==';
        }
        const b = currentFilter[`curve2Row${numberRow}`] || this.state[`curve2Row${numberRow}`];
        const leftSide = Number.isNaN(Number(a)) ? a.toLowerCase().replace(' ', '_') : a;
        const rightSide = Number.isNaN(Number(b)) ? b.toLowerCase().replace(' ', '_') : b;
        const expression = `${leftSide} ${operator} ${rightSide}`;

        const currentExpression = `${expression} ${acc.index && acc.index < acc.totalLength - 1 ? logical : ''}`;

        const lastCharacterOnTheExpression = currentExpression.slice(-2);
        const normalizeToRemoveLastOperator = lastCharacterOnTheExpression === '&&'
          || lastCharacterOnTheExpression === '||' ? currentExpression.slice(0, -2) : currentExpression;

        // TODO implement cutoff comparing curves values
        // prevent to apply filter by two curves
        const thereAreNoTwoCurves = !(Number.isNaN(Number(leftSide))
          && !Number.isNaN(Number(rightSide)) || !Number.isNaN(Number(leftSide))
          && Number.isNaN(rightSide));
        if (!thereAreNoTwoCurves) {
          acc.filters.push({
            currentFilter,
            expression: lastCharacterOnTheExpression,
            filterFnExpressions: `return ${lastCharacterOnTheExpression}`,
            filterKey: normalizeCutOffKey(leftSide),
            filterFnKey: normalizeCutOffKey(leftSide),
            curveName: normalizeCutOffKey(leftSide),
            operator: logical,
            expressions: normalizec4c5(normalizeCutOffKey(normalizeToRemoveLastOperator))
          });
        }
      }
      if (acc && acc.index) {
        acc.index += 1;
      }
      return acc;
    }, {
      filters: [],
      index: 1,
      totalLength: keys(filters).length
    });

    const { selectedWell, currentWell } = wells;
    const cutOffsItems = this.props.cutoff && this.props.cutoff.items || [];
    //const oldValue = await this.props.wellActionCutOff.fetchCutOffs(store.get('token'), currentWell && currentWell.wellInfo && currentWell.wellInfo.wellId);

    const newCurrentValues = allFilters && allFilters.filters.filter(filterCutOff => {
      return cutOffsItems.filter(oldValue => {
        return oldValue === filterCutOff;
      })
    });

    if (selectedWell !== -1) {
      // const newWell = {
      //   ...currentWell,
      //   cutOffs: allFilters && allFilters.filters
      // };
      // const newCutOffs = reduce(wells.items, (acc, item) => {
      //   if (item.wellId === newWell.wellInfo.wellId) {
      //     acc.push(newWell);
      //   } else {
      //     acc.push(item);
      //   }
      //   return acc;
      // }, []);

      // const wellWithCutOffs = newCutOffs.filter(item => {
      //   return item && item.cutOffs && item.cutOffs.length > 0 && item;
      // });

      const cutOffsWithId = (newCurrentValues || []).map((cutOff) => {
        return {...cutOff, wellId: currentWell.wellId };
      });

      this.state.cutOffsWithId = cutOffsWithId;

      // GlobalCutOff.connectToRedux(wellAction.updateWell, newWell);
      GlobalCutOff.registerFilters(
        currentWell.wellId,
        allFilters && allFilters.filters,
        false,
        currentWell,
      );

      if (allFilters && allFilters.filters.length === 0) {
        defaultNotification('Filter can\'t be applyed, please provide a curve and value!');
      } else if (allFilters !== undefined) {
        defaultNotification('Applied Cutoff!');
        // setOnIndexDB('wells', newCutOffs);
      } else {
        alert('Ops! Please you need to select an well first.');
      }
    }
  }

  // only save on api when click in save button and has at least one selected
  saveCutOff = async () => {
    const token = store.get('token');
    if (cutOffsWithId) {
      await this.props.wellActionCutOff.createCutOff(
        this?.cutOffWithId,
        token,
        thihs?.props?.currentWell?.wellId
      );
    }
  }

  openCrossPlots = () => {
    const detail = {
      type: 'cutOff'
    };
    const openWindow = new CustomEvent('plotBoxCuttOff', { detail });
    document.dispatchEvent(openWindow);
  };

  clearCutoffs = () => {
    const { wells, cutoff } = this.props;
    const { currentWell, items, selectedWell } = wells;

    if (selectedWell !== -1) {
      if (cutoff && cutoff.items && cutoff.items.length) {
        let newWell = [];

        items.map((currentItem) => {
          if (currentItem.wellId === currentWell.wellId) {
            newWell.push({
              ...currentWell,
              cutOffs: {}
            });
          } else {
            newWell.push({
              ...currentItem
            });
          }
        });

        this.setState({
          ...initialValues,
        });

        const listAllCutOffs = this.props.cutoff && this.props.cutoff.items || [];

        this.setState({ loading: true });
        this.props.wellActionCutOff.deleteCutOff(
          store.get('token'),
          listAllCutOffs,
          currentWell.wellId,
          this.afterSuccess
        );

        // list all and get all id cutoff
        // const pathFile = wellsWithPathFile. find(w => w.wellId === currentWell.wellInfo.wellId);
        // wellAction.updateWell({ ...currentWell, cutOffs: {} });

        // const cleanDataCutOff = {
        //   ...currentWell,
        //   cutOffs: {}
        // };

        // await this.props.wellActionCutOff.deleteCutOff(
        //   cleanDataCutOff, store.get('token'),
        //   pathFile.pathFile,
        // );
        // await this.props.wellActionCutOff.deleteCutOff(
        //   store.get('token'),
        //   // id
        // );
        // setOnIndexDB('wells', newWell);

        GlobalCutOff.resetCurves([], currentWell, this.props.wellActions.receiveQualityData);
        this.props.setCutoffSettings(null);
        defaultNotification('All cutoffs cleaned sucessfully!');
      }
      this.deleteAllCutoffs();
    } else {
      alert('Ops! Please you need to select an well first.');
    }
  };

  onSaveCutoff = () => {
    this.wellActions.shoudldSave
  }

  render() {
    const modalSuggestions = reduce(chartsCurvesMap, (acc, item) => {
      const [curve] = item;
      acc.push(curve.value);
      return acc;
    }, []);

    return (
      <div className="wrapper-form-cutoffs">
        <WatchCutoffFromCutoffViewChart
          currentWell={this.props.wells.currentWell}
          wellId={this.props.wells.selectedWell}
          populateForm={this.shouldUpdateCutoffs}
        />
        <>
        {this.state.loading && <div className="cuttoff-load-container">
          <Spinner />
          <br/>
          <div className="cuttoff-load-text">
            Loading cutoffs...
          </div>
        </div>}
        {!this.state.loading && (
          <>
          {this.props.disableCutOffViewer ? '' : (
            <div className="wrapper-cutoff-button">
              <button
                type="button"
                className="button-cutoff-viewer"
                onClick={this.openCrossPlots}
              >
                <img className="img-cutoff-viewer" src={cutoff} alt="Cutoff viewer"/>
                Cutoff Viewer
              </button>
              <PlotBox />
            </div>
          )}
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
            novalidate
          >
            <div className="wrapper-cut-offs">
              <div className="main-inputs">
                {/* Head */}
                <div className="wrapper-input-cutoffs wrapper-main-info wrapper-input-filters wrapper-main-form">
                  <div className="wrapper-info wrapper-info-form">
                    <div className="box-input box-checkbox box-checkbox-header">
                      <Checkbox
                        className="checkbox-cut-offs"
                        name="checkboxHead"
                        disabled
                      />
                    </div>
                    <div className="box-info-head info-head-form-1">
                      <span>Curve</span>
                    </div>
                    <div className="box-info-head info-head-form-2">
                      <span>Operator</span>
                    </div>
                    <div className="box-info-head info-head-form-3">
                      <span>Value</span>
                    </div>
                  </div>
                </div>

                {/* First Row */}
                {/* <InputContainer>
                  <Box sx={{ mb: 1 }}>
                    <Label text='Depth Reference' />
                  </Box>
                  <CustomSelect
                    name="depth_reference"
                    label=""
                    options={
                      [{mnemonic: 'c1' },{mnemonic: 'c2' },].map(curve => ({
                          text: `${curve.mnemonic}`,
                          value: curve.mnemonic
                        })
                      )
                    }
                    // defaultValue={
                    //   defaultData?.log_sets?.[0]?.index_curve?.mnemonic
                    // }
                    // value={depthReference}
                    // handleChange={handleDepthReference}
                  // />
                </InputContainer> */}
                <div className="wrapper-input-cutoffs wrapper-input-filters">
                  <div className="box-input-cutoffs box-checkbox">
                    <Checkbox
                      onChange={this.onChangeCheckbox('filter1', 'checkbox1')}
                      className="checkbox-cut-offs"
                      id="checkbox1"
                      name="checkbox1"
                      data-group="filter1"
                      checked={this.state.checkbox1}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    {/* <Autocomplete
                      value={this.state.nameAutoComplete}
                      inputProps={{ id: 'states-autocomplete' }}
                      wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                      items={modalSuggestions}
                      getItemValue={(item) => {console.log('item: ', item); return(item.nameAutoComplete)}}
                      shouldItemRender={matchStateToTerm}
                      sortItems={sortStates}
                      onChange={(event, value) => this.setState({ nameAutoComplete: value })}
                      onSelect={value => this.setState({ nameAutoComplete: value })}
                      renderMenu={children => (
                        <div className="menu">
                          {children}
                        </div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                          key={item.nameAutoComplete}
                        >{item.nameAutoComplete}</div>
                      )}
                    /> */}

                    <AutoSuggest
                      suggestions={modalSuggestions}
                      classes={{ input: 'input-autocomplete' }}
                      className={classes.root}
                      initialValue={this.state.curve1Row1}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter1', 'curve1Row1', value)}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <Select
                      defaultValue="Operator"
                      value={this.state.operator1}
                      name="operator"
                      className="select-cut-offs"
                      onChange={this.onChangeSelect('filter1', 'operator1')}
                      dropdownMenuStyle={{ color: "#fff" }}
                    >
                      {this.state.operationsOptions.map((option) => (
                        <Option className="option-select" value={option}>{option}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={[]}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve2Row1}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter1', 'curve2Row1', value)}
                    />
                  </div>
                </div>

                {/* Second Row */}
                <div className="wrapper-input-cutoffs wrapper-input-filters">
                  <div className="box-input-cutoffs box-checkbox">
                    <Checkbox
                      onChange={this.onChangeCheckbox('filter2', 'checkbox2')}
                      className="checkbox-cut-offs"
                      id="checkbox2"
                      name="checkbox2"
                      data-group="filter2"
                      checked={this.state.checkbox2}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={modalSuggestions}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve1Row2}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter2', 'curve1Row2', value)}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <Select
                      defaultValue="Operator"
                      value={this.state.operator2}
                      name="operator"
                      className="select-cut-offs"
                      onChange={this.onChangeSelect('filter2', 'operator2')}
                      dropdownMenuStyle={{ color: "#fff" }}
                    >
                      {this.state.operationsOptions.map((option) => (
                        <Option className="option-select" value={option}>{option}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={[]}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve2Row2}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter2', 'curve2Row2', value)}
                    />
                  </div>
                </div>

                {/* Third Row */}
                <div className="wrapper-input-cutoffs wrapper-input-filters">
                  <div className="box-input-cutoffs box-checkbox">
                    <Checkbox
                      onChange={this.onChangeCheckbox('filter3', 'checkbox3')}
                      className="checkbox-cut-offs"
                      id="checkbox3"
                      name="checkbox3"
                      data-group="filter3"
                      checked={this.state.checkbox3}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={modalSuggestions}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve1Row3}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter3', 'curve1Row3', value)}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <Select
                      defaultValue="Operator"
                      value={this.state.operator3}
                      name="operator"
                      className="select-cut-offs"
                      onChange={this.onChangeSelect('filter3', 'operator3')}
                      dropdownMenuStyle={{ color: "#fff" }}
                    >
                      {this.state.operationsOptions.map((option) => (
                        <Option className="option-select" value={option}>{option}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={[]}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve2Row3}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter3', 'curve2Row3', value)}
                    />
                  </div>
                </div>

                {/* fourth Row */}
                <div className="wrapper-input-cutoffs wrapper-input-filters">
                  <div className="box-input-cutoffs box-checkbox">
                    <Checkbox
                      onChange={this.onChangeCheckbox('filter4', 'checkbox4')}
                      className="checkbox-cut-offs"
                      id="checkbox4"
                      name="checkbox4"
                      data-group="filter4"
                      checked={this.state.checkbox4}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={modalSuggestions}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve1Row4}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter4', 'curve1Row4', value)}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <Select
                      defaultValue="Operator"
                      value={this.state.operator4}
                      name="operator"
                      className="select-cut-offs"
                      onChange={this.onChangeSelect('filter4', 'operator4')}
                      dropdownMenuStyle={{ color: "#fff" }}
                    >
                      {this.state.operationsOptions.map((option) => (
                        <Option className="option-select" value={option}>{option}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={[]}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve2Row4}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter4', 'curve2Row4', value)}
                    />
                  </div>
                </div>

                {/* fifth Row */}
                <div className="wrapper-input-cutoffs wrapper-input-filters">
                  <div className="box-input-cutoffs box-checkbox">
                    <Checkbox
                      onChange={this.onChangeCheckbox('filter5', 'checkbox5')}
                      className="checkbox-cut-offs"
                      id="checkbox5"
                      name="checkbox5"
                      data-group="filter5"
                      checked={this.state.checkbox5}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={modalSuggestions}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve1Row5}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter5', 'curve1Row5', value)}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <Select
                      defaultValue="Operator"
                      value={this.state.operator5}
                      name="operator"
                      className="select-cut-offs"
                      onChange={this.onChangeSelect('filter5', 'operator5')}
                      dropdownMenuStyle={{ color: "#fff" }}
                    >
                      {this.state.operationsOptions.map((option) => (
                        <Option className="option-select" value={option}>{option}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={[]}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve2Row5}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter5', 'curve2Row5', value)}
                    />
                  </div>
                </div>

                {/* Six Row */}
                <div className="wrapper-input-cutoffs wrapper-input-filters">
                  <div className="box-input-cutoffs box-checkbox">
                    <Checkbox
                      onChange={this.onChangeCheckbox('filter6', 'checkbox6')}
                      className="checkbox-cut-offs"
                      id="checkbox6"
                      name="checkbox6"
                      data-group="filter6"
                      checked={this.state.checkbox6}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={modalSuggestions}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve1Row6}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter6', 'curve1Row6', value)}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <Select
                      defaultValue="Operator"
                      value={this.state.operator6}
                      name="operator"
                      className="select-cut-offs"
                      onChange={this.onChangeSelect('filter6', 'operator6')}
                      dropdownMenuStyle={{ color: "#fff" }}
                    >
                      {this.state.operationsOptions.map((option) => (
                        <Option className="option-select" value={option}>{option}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={[]}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve2Row6}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter6', 'curve2Row6', value)}
                    />
                  </div>
                </div>

                {/* Seven Row */}
                <div className="wrapper-input-cutoffs wrapper-input-filters">
                  <div className="box-input-cutoffs box-checkbox">
                    <Checkbox
                      onChange={this.onChangeCheckbox('filter7', 'checkbox7')}
                      className="checkbox-cut-offs"
                      id="checkbox7"
                      name="checkbox7"
                      data-group="filter7"
                      checked={this.state.checkbox7}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={modalSuggestions}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve1Row7}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter7', 'curve1Row7', value)}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <Select
                      defaultValue="Operator"
                      value={this.state.operator7}
                      name="operator"
                      className="select-cut-offs"
                      onChange={this.onChangeSelect('filter7', 'operator7')}
                      dropdownMenuStyle={{ color: "#fff" }}
                    >
                      {this.state.operationsOptions.map((option) => (
                        <Option className="option-select" value={option}>{option}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={[]}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve2Row7}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter7', 'curve2Row7', value)}
                    />
                  </div>
                </div>

                {/* Eight Row */}
                <div className="wrapper-input-cutoffs wrapper-input-filters">
                  <div className="box-input-cutoffs box-checkbox">
                    <Checkbox
                      onChange={this.onChangeCheckbox('filter8', 'checkbox8')}
                      className="checkbox-cut-offs"
                      id="checkbox8"
                      name="checkbox8"
                      data-group="filter8"
                      checked={this.state.checkbox8}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={modalSuggestions}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve1Row8}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter8', 'curve1Row8', value)}
                    />
                  </div>
                  <div className="box-input-cutoffs">
                    <Select
                      defaultValue="Operator"
                      value={this.state.operator8}
                      name="operator"
                      className="select-cut-offs"
                      onChange={this.onChangeSelect('filter8', 'operator8')}
                      dropdownMenuStyle={{ color: "#fff" }}
                    >
                      {this.state.operationsOptions.map((option) => (
                        <Option className="option-select" value={option}>{option}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="box-input-cutoffs">
                    <AutoSuggest
                      suggestions={[]}
                      classes={{ input: 'input-autocomplete' }}
                      initialValue={this.state.curve2Row8}
                      onAfterChange={(value) => this.handleChangeAutoComplete('filter8', 'curve2Row8', value)}
                    />
                  </div>
                </div>
              </div>
              <div className="wrapper-logical-operator">
                <div className="box-input-cutoffs box-and-or">
                  <Select
                    // defaultValue="Operator"
                    name="operator"
                    className="select-cut-offs"
                    defaultValue={this.state.logicalOperator}
                    onChange={this.onChangeSelectOperator}
                    dropdownMenuStyle={{ color: "#fff" }}
                  >
                    {this.state.logicalOperators.map((option) => (
                      <Option className="option-select" value={option}>{option}</Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>

            {/*
            <div className="wrapper-grid-lines">
              <span>Display grid lines</span>
              <ToggleButton
                colors={{
                  activeThumb: {
                    base: 'rgb(255,255,255)',
                  },
                  inactiveThumb: {
                    base: 'rgb(255,255,255)',
                  },
                  active: {
                    base: '#65c970',
                    hover: '#65c970',
                  },
                  inactive: {
                    base: 'rgb(65,66,68)',
                    hover: 'rgb(95,96,98)',
                  }
                }}
                value={this.state.shouldDisplayGrids}
                onToggle={this.toggleDisplayGridLines}
              />
            </div>
            <InputNumber
              size="small"
              min={0}
              max={10000000}
              defaultValue={this.state.gridMin}
              data-name="gridMin"
              onChange={this.handleChange('filter1', 'gridMin')}
            />
            <InputNumber
              size="small"
              min={0}
              max={10000000}
              defaultValue={this.state.gridMax}
              data-name="gridMax"
              onChange={this.handleChange('filter1', 'gridMax')}
            /> */}
            <div className="wrapper-cutoff-button">
              <button
                type="button"
                className="cutoff-save-button cutoff-clear-button"
                onClick={this.clearCutoffs}
              >
                Reset Cutoffs
              </button>
              <button
                type="button"
                onClick={this.submitCutOff(false)}
                className="cutoff-save-button"
              >
                Apply
              </button>
              {this?.props?.enableSaveButton ? (
                <button
                  onClick={this.submitCutOff(true)}
                  type="button"
                  className="cutoff-save-button"
                >
                  Save
                </button>
              ) : null}
            </div>
          </form>
          </>
          )}
        </>
      </div>
    );
  }
}

// TODO refactor with SWR prevent do a fectch everytime to open modal
// because we need to keep the last applied
export const CutoffProxy = (props) => {
  const [shouldUpdate, setShouldUpdate] = useState(true);
  // const wells = useSelector(state => state.wells);
  const cutoff = useSelector(state => state.cutoff.items);

  useEffect(() => {
    if (cutoff?.items?.length) {
      setShouldUpdate(false);
    }
  }, [cutoff]);

  return (
    <CutOffs
      cutoffs={cutoff.items}
      shouldUpdate={shouldUpdate}
      {...props}
    />
  );
};

const mapStateToProps = ({ wells, cutoff, ...props }) => ({
  wells,
  cutoff,
  props
});

const mapDispatchToProps = dispatch => ({
  wellActions: bindActionCreators(wellActionsFns, dispatch),
  wellActionCutOff: bindActionCreators(wellActionsCutOffs, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CutoffProxy);
