import React from 'react';
import { Alert } from 'antd';
import './style.scss';

const defaultDescription = 'There is already a configuration created with these specifications: [selected curves] and [selected top-bottom] interval. We recommend that you select new curves and depth intervals or edit the module already created.';
const defaultTitle = 'Conflict error';

const AlertError = ({ messageTitle, description }) => {
  return (
    <div className="content-alert-error">
      <Alert
        message={messageTitle || defaultTitle}
        type="error"
        description={description || defaultDescription}
        closable
      />
    </div>
  );
}

export default AlertError;