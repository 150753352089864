import { ZoneItemType } from '../types/zone';

export const defaultZoneValues: ZoneItemType = {
  uuid: '',
  top: '',
  bottom: '',
  topX: '',
  bottomX: '',
  cutOffsIds: [],
  name: '',
  label: '',
  zone_plot_settings: {},
  color: {
    r: '241',
    g: '112',
    b: '19',
    a: '0.50'
  },
  borderColor: 'blue',
  borderWidth: '1',
  chartToApply: 'all',
  id: '',
  active: true,
  status: false,
  wellId: ''
};

export const defaultZone = {
  top: '',
  bottom: '',
  label: '',
  color: {
    r: '241',
    g: '112',
    b: '19',
    a: '0.50'
  },
  borderColor: 'blue',
  borderWidth: '1',
  chartToApply: 'all',
  id: null,
  active: true
};

export const defaultZoneFluidType = {
  top: '',
  bottom: '',
  name: '',
  color: {
    r: '241',
    g: '112',
    b: '19',
    a: '0.50'
  },
  borderColor: 'blue',
  borderWidth: '1',
  chartToApply: 'all',
  id: null,
  active: true,
  fluidType: 'Oils'
};