/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
import React, {
  memo,
  useEffect,
  useState,
  useRef
} from 'react';
import get from 'lodash.get';
import NewWindow from './NewWindow';
import store from 'store';
import uuid from 'uuid/v1';

import { defaultCrossPlotScales } from '../utils';
import { getCalculatedCurveData } from '../utils/getCalculatedCurveData';
import { getRawCurveData } from '../utils/getRawCurveData';
import { getCurveData } from 'utils/getCurveDataBySet';

// TODO create unit tests to these functions
/*
*  BalanceChart2
*  y: (C4 + C5) / (C1 + C2)
*  x: (C1 + C2) / C3
*/
export const calculateBalanceChart2 = (c1, c2, c3, c4, c5) => {
  const x = (c1 + c2) / c3;
  const y = (c4 + c5) / (c1 + c2);
  return [
    isNaN(x) ? 0 : x,
    isNaN(y) ? 0 : y
  ];
};

/*
*  BalanceChart4
*  y: (C1 / C3)
*  x: C1
*/
export const calculateBalanceChart4 = (c1, c3) => [
  c1,
  c1 === 0 || c3 === 0 ? 0 : (c1 / c3)
];

/*
*  BalanceChart5
*  y: (C1 / C3)
*  x: C1 / (C4 + C5) */
export const calculateBalanceChart5 = (c1, c3, c4, c5) => [
  c1 === 0 || c3 === 0 || c4 === 0 || c5 === 0 ? 0 : c1 / (c4 + c5),
  c1 === 0 || c3 === 0 ? 0 : (c1 / c3)
];


export const calculateBioDegradationChart1 = (c1, c2, depth) => {
  const x = isNaN(c1 / c2) ? 0 : c1 / c2;
  return [x, depth];
};

export const calculateC1C2ByC3Bio = (c1, c2, c3, defaultNull = 0.0001) => {
  const x = isNaN(c2 / c3) || c3 === 0 ? defaultNull : c2 / c3;
  const y = isNaN(c1 / c2) || c2 === 0 ? defaultNull : c1 / c2;

  return [x, y];
};

function calculatePPMToCarbonComposition(valuePPM, totalCarbon) {
  return valuePPM === 0 || totalCarbon === 0 ? 0 : (valuePPM / totalCarbon) * 100;
}

function calculateC2Fid(sensorType, c2s, c2PPM, c2H4, c3h6) {
  const isAValidC2s = (c2s - c2PPM) > -10 && c2PPM !== 0;
  if (sensorType) {
    return c3h6 / (c3h6 + c2H4) * c2s;
  } if (c2s - c2PPM > -10 && c2PPM !== 0) {
    return c2PPM;
  } if (isAValidC2s) {
    return c2PPM;
  }
  // when there is no fid use c2PPM
  return c2PPM;
}

// TODO: improve the variables names
export const generateGeochemistryCharts = (type, min, max, currentWell, curvesKeys, sensorType, well) => {
  const c1 = getCurveData('c1', currentWell, 'gqcData').data || get(getRawCurveData('c1', currentWell), 'data', []);
  const c2 = getCurveData('c2', currentWell, 'gqcData').data || get(getRawCurveData('c2', currentWell), 'data', []);
  const c3 = getCurveData('c3', currentWell, 'gqcData').data || get(getRawCurveData('c3', currentWell), 'data', []);
  const ic5 = getCurveData('ic5', currentWell, 'gqcData').data || get(getRawCurveData('ic5', currentWell), 'data', []);
  const depth = getCurveData('depth', currentWell, 'gqcData').data || get(getRawCurveData('depth', currentWell), 'data', []);
  const slopeFactor = getCurveData('slope_factor', currentWell, 'gqcData').data || get(getCalculatedCurveData('slope_factor', currentWell), 'data', []);
  const nc5 = getCurveData('nc5', currentWell, 'gqcData').data || get(getRawCurveData('nc5', currentWell), 'data', []); 
  const ic4 = getCurveData('ic4', currentWell, 'gqcData').data || get(getRawCurveData('ic4', currentWell), 'data', []);
  const nc4 = getCurveData('nc4', currentWell, 'gqcData').data || get(getRawCurveData('nc4', currentWell),'data', []);
  const c2s = getCurveData('c2s', currentWell, 'gqcData').data || get(getRawCurveData('c2s', currentWell), 'data', []);
  const c2h4 = getCurveData('c2h4', currentWell, 'gqcData').data || get(getRawCurveData('c2h4', currentWell), 'data', []);
  const c3h6 = getCurveData('c3h6', currentWell, 'gqcData').data || get(getRawCurveData('c3h6', currentWell), 'data', []);
  const totalCarbon = getCurveData('total_carbon', currentWell, 'gqcData').data || get(getCalculatedCurveData('total_carbon', currentWell), 'data', []);
  const ic4PPMformatted = (ic4 || []).filter(item => item !== null);
  const ic4PPM = ic4PPMformatted?.length > 0 ? ic4PPMformatted?.slice(min, max) : [];
  const ic5PMMformatted = (ic5 || []).filter(item => item !== null);

  const ic5PMM = ic5PMMformatted?.length > 0 ? ic5PMMformatted?.slice(min, max) : [];
  const ic4NormalPPM = ic4 && ic4?.length > 0 ? ic4?.slice(min, max) : [];
  const nc4Value = nc4 && nc4?.length > 0 ? nc4?.slice(min, max) : [];

  const c1formatted = (c1 || []).filter(item => item !== null);
  const c1PPM = c1formatted?.length > 0 ? c1formatted?.slice(min, max) : [];
  const c2PPM = c2?.length > 0 ? c2?.slice(min, max) : [];
  const c3PPM = c3?.length > 0 ? c3?.slice(min, max) : [];
  const ic5NormalPPM = ic5?.length > 0 ? ic5?.slice(min, max) : [];
  const nc5Value = nc5 && nc5?.length > 0 ? nc5?.slice(min, max) : [];

  const totalDepth = depth?.length > 0 ? depth?.slice(min, max) : [];

  const formattedSlopeFactor = slopeFactor?.length > 0 ? slopeFactor?.slice(min, max) : [];

  const c1c2 = getCurveData('c1c2', currentWell, 'gqcData').data || get(getCalculatedCurveData('c1c2', currentWell), 'data', []);
  const c1c2Formated = c1c2?.length ? c1c2?.slice(min, max) : [];

  const chart1 = (totalDepth || []).reduce((acc, currentC1, index) => {
    const currentDepth = totalDepth?.[index] || null;
    const y = currentDepth;
    const x = c1c2Formated?.[index] || null;
    acc.items.push([x, y]);
    acc.itemsWithDepth.push({
      depth: currentDepth,
      x,
      y
    });
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart2 = (totalDepth || []).reduce((acc, currentDepth, index) => {
    const currentSlopeFactor = formattedSlopeFactor?.[index] || null;
    acc.items.push([currentSlopeFactor, currentDepth]);
    acc.itemsWithDepth.push({
      depth: currentDepth,
      x: currentSlopeFactor,
      y: currentDepth
    });
    return acc;
  }, { items: [], itemsWithDepth: [] });


  const chart3 = (totalDepth || []).reduce((acc, currentDepth, index) => {
    const c1Byc2Value = c1c2Formated?.[index] || null;
    const currentSlopeFactor = formattedSlopeFactor?.[index] || null;

    const x = c1Byc2Value;
    const y = currentSlopeFactor;

    acc.items.push([x, y]);
    acc.itemsWithDepth.push({
      depth: currentDepth,
      x,
      y
    });
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart7 = (totalDepth || []).reduce((acc, currentDepth, index) => {
    const currentC1 = c1PPM?.[index] ? c1PPM[index] : 0;
    let item = [];
    const currentC2 = c2PPM[index];
    const currentC3 = c3PPM[index];
    item = calculateC1C2ByC3Bio(
      currentC1, currentC2, currentC3, 0
    );
    acc.items.push(item);
    acc.itemsWithDepth.push({
      depth: currentDepth,
      ...item,
      x: item[0],
      y: item[1]
    });
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart8 = (totalDepth || []).reduce((acc, currentDepth, index) => {
    const currentC1 =  c1PPM && c1PPM[index] ? c1PPM[index] : 0;
    const currentC3PPM = c3PPM && c3PPM[index] ? c3PPM[index] : 0;
    let currentIc4PPM = 0;
    if (ic4PPM && ic4PPM[index] && ic4PPM[index].length) {
      currentIc4PPM = ic4PPM[index][1][0];
    } else if (!ic4PPM[index]?.length && ic4PPM) {
      currentIc4PPM = ic4PPM[index];
    }
    const currentNc4PPM = nc4Value && nc4Value[index] ? nc4Value[index] : 0;
    let currentIc5PPM = 0;
    if (ic5PMM && ic5PMM[index] && ic5PMM[index].length) {
      currentIc5PPM = ic5PMM[index][1][0];
    } else if (ic5PMM && ic5PMM[index] && !ic5PMM[index].length && ic5PMM && ic5PMM[index]) {
      currentIc5PPM = ic5PMM[index];
    } else {
      currentIc5PPM = 0;
    }
    const currentNc5PPM = nc5Value && nc5Value[index] ? nc5Value[index] : 0;
   
    const currentTotalCarbon = totalCarbon && totalCarbon[index] ? totalCarbon[index] : 0;

    const currentC2s = c2s && c2s[index] ? c2s[index] : 0;
    const currentC2PPM = c2PPM && c2PPM[index] ? c2PPM[index] : 0;
    const currentC2H4 = c2h4 && c2h4[index] ? c2h4[index] : 0;
    const currentc3h6 = c3h6 && c3h6[index] ? c3h6[index] : 0;

    const c2Fid = calculateC2Fid(sensorType, currentC2s, currentC2PPM, currentC2H4, currentc3h6);
    const c1Carbon = calculatePPMToCarbonComposition(currentC1, currentTotalCarbon);
    const c2Carbon = calculatePPMToCarbonComposition(c2Fid, currentTotalCarbon);
    const c3Carbon = calculatePPMToCarbonComposition(currentC3PPM, currentTotalCarbon);
    const ic4Carbon = calculatePPMToCarbonComposition(currentIc4PPM, currentTotalCarbon);
    const nc4Carbon = calculatePPMToCarbonComposition(currentNc4PPM, currentTotalCarbon);
    const ic5Carbon = calculatePPMToCarbonComposition(currentIc5PPM, currentTotalCarbon);
    const nc5Carbon = calculatePPMToCarbonComposition(currentNc5PPM, currentTotalCarbon);

    const curvesCarbonCalc2 =  c1Carbon + c2Carbon + c3Carbon + ic4Carbon + nc4Carbon + ic5Carbon + nc5Carbon;
    const c2CompToBiode =  c2Carbon * 100 === 0 && curvesCarbonCalc2 === 0 ? 0 : (c2Carbon * 100) / curvesCarbonCalc2;

    const curvesCarbonCalcC3 = c1Carbon + c2Carbon + c3Carbon + ic4Carbon + nc4Carbon + ic5Carbon + nc5Carbon;
    const c3CompToBiode = c3Carbon * 100 === 0 && curvesCarbonCalcC3 === 0 ? 0 : (c3Carbon * 100) / curvesCarbonCalcC3;

    const c2CompToBiodegradation = c2Carbon * 100 === 0 && c1Carbon + c2Carbon + c3Carbon + ic4Carbon + nc4Carbon + ic5Carbon + nc5Carbon === 0 ? 0 : c2CompToBiode;
    const c3CompToBiodegradation = c3Carbon * 100 === 0 && c1Carbon + c2Carbon + c3Carbon + ic4Carbon + nc4Carbon + ic5Carbon + nc5Carbon === 0 ? 0 : c3CompToBiode;

    const y = c2CompToBiodegradation === 0 && c3CompToBiodegradation === 0 ? 0 : c2CompToBiodegradation / c3CompToBiodegradation;
    const x = c2Carbon === 0 && ic4Carbon === 0 ? 0 : c2Carbon / ic4Carbon;

    acc.items.push([x, y]);
    acc.itemsWithDepth.push({
      depth: currentDepth,
      x,
      y
    });

    return acc;
  }, { items: [], itemsWithDepth: [] });

  console.log('chart 8 ==>', chart8);
  const chart9 = totalDepth.reduce((acc, currentDepth, index) => {
    const currentIc5 = ic5NormalPPM[index] ? ic5NormalPPM[index] : 0
    const currentNc5 = nc5Value[index] ? nc5Value[index] : 0;

    const currentC5 = currentIc5 === 0 || currentNc5 === 0 ? 0 : currentIc5 / currentNc5;

    acc.items.push([currentC5, currentDepth]);
    acc.itemsWithDepth.push({
      depth: currentDepth,
      x: currentC5,
      y: currentDepth,
    });
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart10 = totalDepth.reduce((acc, currentDepth, index) => {
    const currentIc4 = ic4NormalPPM[index] ? ic4NormalPPM[index] : 0;
    const currentNc4 = nc4Value[index] ? nc4Value[index] : 0;

    const currentC4 = currentIc4 === 0 || currentNc4 === 0 ? 0 : currentIc4 / currentNc4;

    acc.items.push([currentC4, currentDepth]);
    acc.itemsWithDepth.push({
      depth: currentDepth,
      x: currentC4,
      y: currentDepth,
    });
    return acc;
  }, { items: [], itemsWithDepth: [] });

  // const chart11 = c1.reduce((acc, currentC1, index) => {
  //   acc.items.push(currentC1);
  //   const currentDepth = totalDepth[index];
  //   acc.itemsWithDepth.push([currentC1, currentDepth]);
  //   return acc;
  // }, { items: [], itemsWithDepth: [] });
  return {
    chart1,
    chart2,
    chart3,
    // chart4: { items: [], itemsWithDepth: [] },
    // chart5: { items: [], itemsWithDepth: [] },
    // chart6: [],
    chart7,
    chart8,
    chart9,
    chart10,
    // chart11
  };
};

export const generateBalanceCrossplots = (type, min, max, data, curvesKeys, minLabel, maxLabel, well) => {
  const c2 = getCurveData('c2', data, 'gqcData')?.data || get(getRawCurveData('c2', data), 'data', []);
  const c3 = getCurveData('c3', data, 'gqcData')?.data || get(getRawCurveData('c3', data), 'data', []);
  const nc4 = getCurveData('nc4', data, 'gqcData')?.data || get(getRawCurveData('nc4', data), 'data', []);
  const ic4 = getCurveData('ic4', data, 'gqcData')?.data || get(getRawCurveData('ic4', data), 'data', []);

  const depth = getCurveData('depth', data, 'gqcData')?.data || data?.RAW?.depth?.data || data?.depth?.data || [];

  const balanceRatioData = getCurveData('bh_ratio', data, 'gqcData')?.data || get(getCalculatedCurveData('bh_ratio', data), 'data', []);
  const wetnessRatio = getCurveData('wh_ratio', data, 'gqcData')?.data || get(getCalculatedCurveData('wh_ratio', data), 'data', []);

  const c1Composition = getCurveData('c1_perc', data, 'gqcData')?.data || get(getCalculatedCurveData('c1_perc', data), 'data', []);
  const c2Composition = getCurveData('c2_perc', data, 'gqcData')?.data || get(getCalculatedCurveData('c2_perc', data), 'data', []);
  const c3Composition = getCurveData('c3_perc', data, 'gqcData')?.data || get(getCalculatedCurveData('c3_perc', data), 'data', []);
  const c4Composition = getCurveData('c4_perc', data, 'gqcData')?.data || get(getCalculatedCurveData('c4_perc', data), 'data', []);
  const c5Composition = getCurveData('c5_perc', data, 'gqcData')?.data || get(getCalculatedCurveData('c5_perc', data), 'data', []);
  const nc4Composition = getCurveData('c4_perc', data, 'gqcData')?.data || get(getCalculatedCurveData('c4_perc', data), 'data', []);
  const nc5Composition = getCurveData('c5_perc', data, 'gqcData')?.data || get(getCalculatedCurveData('c5_perc', data), 'data', []);

  // api gor
  let formattedMinLabel = minLabel;
  let formattedMaxLabel = maxLabel;
  // when there is a critical point format we should get only the depth value
  // "6528.34 - Critical points to: C1, C2, C3, C4, IC4, C5, IC5"
  if (formattedMinLabel && typeof formattedMinLabel === 'string' && formattedMinLabel.split('-') && formattedMinLabel.split('-').length === 2) {
    formattedMinLabel = parseFloat(formattedMinLabel.split('-')[0]);
  }

  if (formattedMaxLabel && typeof formattedMaxLabel === 'string' && formattedMaxLabel.split('-') && formattedMaxLabel.split('-').length === 2) {
    formattedMaxLabel = parseFloat(formattedMaxLabel.split('-')[0]);
  }

  // TODO refactor to use just one reduce loop
  // const chart2 = calculateChart2(c1, c2, c3, c4, c5);
  const minMax = depth.reduce((acc, d, index) => {
    if (d === Number(formattedMinLabel)) {
      acc.min.index = index;
      acc.min.result = d;
    }
    if (d === Number(formattedMaxLabel)) {
      acc.max.index = index;
      acc.max.result = d;
    }
    return acc;
  }, {
    min: { result: undefined, index: 0 },
    max: { result: undefined, index: 0 }
  });

  if (!minMax.min.result && !minMax.max.result) {
    minMax.min.index = depth.findIndex(d => d === Number(formattedMinLabel));
    minMax.min.result = depth.findIndex(d => d === Number(formattedMinLabel));
    minMax.max.index = depth?.findIndex(d => d === Number(formattedMaxLabel));
  }

  const c1Comp = c1Composition ? c1Composition.slice(minMax.min.index, minMax.max.index) : [];
  const c2Comp = c2Composition ? c2Composition.slice(minMax.min.index, minMax.max.index) : [];
  const c3Comp = c3Composition ? c3Composition.slice(minMax.min.index, minMax.max.index) : [];
  const c4Comp = nc4Composition ? nc4Composition.slice(minMax.min.index, minMax.max.index) : [];
  const c5Comp = nc5Composition ? nc5Composition.slice(minMax.min.index, minMax.max.index) : [];

  const balance = balanceRatioData ? balanceRatioData.slice(minMax.min.index, minMax.max.index) : [];
  const wetness = wetnessRatio ? wetnessRatio.slice(minMax.min.index, minMax.max.index) : [];
  const totalDepth = depth ? depth.slice(minMax.min.index, minMax.max.index).map(n => n) : [];

  const chart2 = totalDepth.reduce((acc, currentDepth) => {
    let item = [];

    const indexFromStep = totalDepth.findIndex((depthStep) => depthStep === currentDepth);

    const currentC1 = c1Comp[indexFromStep];
    const currentC2 = c2Comp[indexFromStep];
    const currentC3 = c3Comp[indexFromStep];
    const currentC4 = c4Comp[indexFromStep];
    const currentC5 = c5Comp[indexFromStep];

    item = calculateBalanceChart2(
      currentC1, currentC2, currentC3, currentC4, currentC5
    );

    if (item[0] && item[1]) {
      acc.items.push(item);
      acc.itemsWithDepth.push({
        depth: currentDepth,
        item,

        c1: currentC1,
        c2: currentC2,
        c3: currentC3,
        c4: currentC4,
        c5: currentC5
      });
    }

    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart3 = totalDepth.reduce((acc, currentDepth, index) => {
    const currentWetness = wetness[index];

    const indexFromStep = totalDepth.findIndex((depthStep) => depthStep === currentDepth);
    const currentBalance = balance[indexFromStep];

    const item = [
      currentBalance,
      currentWetness
    ];

    if (item[0] && item[1]) {
      acc.items.push(item);
      acc.itemsWithDepth.push({
        depth: currentDepth,
        item,
        balance: item[0],
        wetness: item[1]
      });
    }
    return acc;
  }, { items: [], itemsWithDepth: [] });

  const chart4 = totalDepth.reduce((acc, currentDepth) => {
    let item = [];
    const indexFromStep = totalDepth.findIndex((depthStep) => depthStep === currentDepth);

    item = calculateBalanceChart4(
      c1Comp[indexFromStep], c3Comp[indexFromStep]
    );

    if (item[0] && item[1]) {
      acc.items.push(item);
      acc.itemsWithDepth.push({
        depth: currentDepth,
        item,
        c1: item[0],
        c1c3: item[1]
      });
    }
    return acc;
  }, { items: [], itemsWithDepth: [] });

  // ScatterPlotC1C45
  const chart5 = totalDepth.reduce((acc, currentDepth) => {
    let item = [];

    const indexFromStep = totalDepth.findIndex((depthStep) => depthStep === currentDepth);
    const currentC1 = c1Comp[indexFromStep];
    const currentC3 = c3Comp[indexFromStep];
    const currentC4 = c4Comp[indexFromStep];
    const currentC5 = c5Comp[indexFromStep];

    item = calculateBalanceChart5(
      currentC1, currentC3, currentC4, currentC5
    );

    if (item[0] && item[1]) {
      acc.items.push(item);
      acc.itemsWithDepth.push({
        depth: currentDepth,
        item,
        c1: currentC1,
        c1c45: item[0],
        c1c3: item[1]
      });
    }
    return acc;
  }, { items: [], itemsWithDepth: [] });

  return {
    chart2,
    chart3,
    chart4,
    chart5,
    c2,
    c3,
    ic4,
    nc4
  };
};

/*
*
* (Pipeline)
*
* 1. Chart- When select a area in reason or balance chart the event is
*    dispatched with the min and the max ranges selected.
*
* 2. PlotBox - Access the data, cut the array, apply reasons for while
*    on localStorage for this time.
*
* 3. I will redirect to the crossplots page with the current selection id
*
* 3. The pop up will open with a loader that relies of this flag
*    be finished when proccessed.
*
* 4. The new window will get the value on localStorage.
*
* 5. Hide the loader and display the charts according with the type.
*
*/
const PlotBox = ({ selectedWell, well, curvesKeys, data, sensorType, zones }) => {

  const idRef = useRef(uuid());

  const [display, setToggle] = useState(false);
  const [currentChart, setChart] = useState(null);
  const [stateType, setStateType] = useState(null);
  const [depth, setDepth] = useState([]);
  const [newData, setData] = useState(undefined);

  const [count, setCount] = useState(0);
  const [windows, setUpdate] = useState({
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null
  });

  const toggleWindow = () => {
    setUpdate((oldState) => ({ ...oldState, [count]: null }));
    setCount((oldCount) => oldCount - 1);
  };

  const toggleWindows = event => {
    const { detail: { type, min, max, minLabel, maxLabel, name } } = event;
    // if (maxLabel - minLabel > 500) {
    //   const removePlotBands = new CustomEvent('removePlotBands');
    //   document.dispatchEvent(removePlotBands);
    //   alert('Select a range less than 500m')
    //   return;
    // }
    setStateType(type);

    const newWindow = {
      id: idRef.current,
      type
    };
    setUpdate((oldState) => ({ ...oldState, [count]: newWindow }));
    setCount((oldCount) => oldCount + 1);

    // TODO after proccess set data here
    const currentScales = defaultCrossPlotScales[type];
    let crossplotsData = {};

    // C1C2SF or balanceRatio Chart
    // Calculating Data
    const normalizedMin = min > 13 ? min - 13 : min;
    const normalizedMax = max > 13 ? max - 13 : max;

    // balanceRatioToggle
    if (type === 'balanceRatioToggle') {
      crossplotsData = generateBalanceCrossplots(
        type, normalizedMin, normalizedMax, {...newData, depth }, curvesKeys, minLabel, maxLabel, idRef.current
      );
    }

    if (type === 'C1C2SF') {
      crossplotsData = generateGeochemistryCharts(
        type, min, max, {...newData, depth }, curvesKeys, sensorType
      );
    }

    store.set('querys', {
      [data?.well_uid]: {
        sensorType,
        type,
        data: crossplotsData,
        depth,
        min,
        max,
        minLabel,
        maxLabel,
        name,
        scales: currentScales,
        wellId: newData.well_uid,
        token: store.get('token')
      }
    });
  };

  useEffect(() => {
    if (data && newData?.well_uid !== data?.well_uid) {
      setData(data);
    }
  }, [data]);

  useEffect(() => {
    // const allDepth = get(getRawCurveData('depth', data), 'data', []);
    const allDepth = getCurveData('depth', data, 'gqcData') || data?.RAW?.depth?.data || data?.depth?.data || [];
    if (well && allDepth?.length) {
      setDepth(allDepth);
    }
    if (newData) {
      window?.document?.addEventListener?.('showPlotBox', toggleWindows);
    }
    return () => {
      if (newData && count > 0) {
        window?.document?.removeEventListener?.('showPlotBox', toggleWindows);
      }
    };
  }, [count, windows, data, well, window?.document]);
  const well_id = data?.well_uid;
  return (
    <div className="scatters-plot">
      {Object.keys(windows).length &&
        Object.keys(windows).map((key, index) => {
          const currentWindow = windows[key];
          if (!currentWindow) {
            return null;
          }
          return (
            <NewWindow
              key={key}
              url={`/crossplots/${well_id}--${currentWindow.type}`}
              features={{ left: 200, top: 200, width: 1600, height: 900 }}
              onUnload={() => {
                const queryData = store.get('querys') || {};
                const { [well_id]: _, ...result } = queryData;
                // store.set('querys', result);

                // decrease to clear plotbands
                const countCrossPlots = store.get('openedCrossPlots')
                  ? parseInt(store.get('openedCrossPlots'))
                  : 0;

                // eslint-disable-next-line no-unused-expressions
                countCrossPlots && store.set('openedCrossPlots', countCrossPlots - 1);
                const removePlotBands = new CustomEvent('removePlotBands');
                window?.document?.dispatchEvent?.(removePlotBands);
                const unselectChart = new CustomEvent(currentChart);
                window?.document?.dispatchEvent?.(unselectChart);
                setCount((oldCount) => oldCount - 1);
              }}
            >
              <h2>-</h2>
            </NewWindow>
          );
        })}
    </div>
  );
}

export default memo(PlotBox);
