import { YValueWithIndex } from '../types/submitZone';
import { DepthValueT } from '../types/depth';

/**
 * (findIndexOfDepthValue)
 * This function should find and compare the currentDepthValue to find the index of depth value
 * @param depthValue current depth value
 * @param data array of current depth
 * @returns should returns the depth value with index when found
 */
export const findIndexOfDepthValue = (
  depthValue: number | string,
  data: number[]
): YValueWithIndex => {
  return data.reduce(
    (
      acc?: YValueWithIndex,
      depth?: number,
      index?: number
    ): YValueWithIndex => {
      if (depth === depthValue) {
        return {
          value: {
            y: depth,
            index: index
          }
        };
      }
      return acc;
    },
    undefined
  );
};

/**
 * (findDepthIndexValueToApplyInPlotBand)
 * This function should find and compare the currentDepthValue to find the index of depth value
 * @param depthValue current depth value
 * @param data array of current depth
 * @returns the current index that macthes the given depth
 */
export const findDepthIndexValueToApplyInPlotBand = (
  depthValue: number | string, data?: number[]
) => {
  return data?.find((value: any) => {
    const resultDepth = String(value.y).split('.');
    const [currentDepth] = resultDepth;
    return currentDepth === depthValue;
  });
};

/**
 * (normalizedBottomAndTop)
 * This function is used to format and extract top/bottom values when has
 * critical values need to remove the purpose is to get only depth
 * @param values values of depth and top from form
 * @param depthData array of depth values
 * @returns y value with index necessary to add to zone
 */
export const normalizedBottomAndTop = (values: DepthValueT, depthData: number[]): {
  bottomXValue: YValueWithIndex;
  topXValue: YValueWithIndex;
} => {
  const normalizedBottom: string = values?.bottom?.includes('-')
    ? values?.bottom?.split('-')?.[0]?.trim()
    : values?.bottom;

  const normalizedTop: string = values?.top?.includes('-')
    ? values?.top?.split('-')?.[0]?.trim() 
    : values?.top;

  const bottomXValue: YValueWithIndex = findIndexOfDepthValue(
    Number(normalizedBottom),
    depthData
  );

  const topXValue: YValueWithIndex = findIndexOfDepthValue(
    Number(normalizedTop),
    depthData
  );

  return {
    bottomXValue,
    topXValue
  };
};
