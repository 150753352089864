import React from 'react';

import Spinner from 'components/Spinner';

const MiniSpinner = () => {
  return (
    <div
      style={{ 
        marginBottom: 40,
        witdth: '100%',
        height: 300,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#fff'
      }}
    >
      <Spinner classes="spinner--small" />
      <br />Loading...
    </div>
  );
};

export default MiniSpinner;