import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import store from 'store';

import { doAuthenticateData } from '../types/api';
import { API_URL, API_URL_AUTH, API_URL_LOGOUT, API_URL_USER_INFO } from '../config/urls';

const Api = axios.create({
  baseURL: API_URL,
  headers: { 'content-type': 'application/x-www-form-urlencoded' }
});

export const doAuthenticate = async (data: doAuthenticateData) => {
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(data),
    url: API_URL_AUTH,
    withCredentials: false
  };
  const response = await axios(options);
  return response;
};

export const getUserInfo = async () => {
  const options: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${store.get('token')}`
    },
    url: API_URL_USER_INFO,
    withCredentials: false
  };
  delete axios.defaults.headers['access-token'];
  const response = await axios(options);
  return response;
};

export const doLogout = async () => {
  const response = await Api.get(API_URL_LOGOUT);
  return response;
};

export default Api;
