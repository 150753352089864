import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Button, Select, Slider, Steps, message, Row, Col, InputNumber, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import NoiseCard from './card';
import { calculateReduceNoise, PostGqcPayload } from '../../../../api/statistics';
import { useDispatch } from 'react-redux';
import { receiveWell } from '../../../../actions/wells';
import Loading from '../../../Statistics/components/Loading';
import { defaultNotification } from '../../../../utils';

const { Option } = Select;
const { Step } = Steps;

const NoiseModal = ({ isModalOpen, close, setPayload, payload }) => {
  const dispatch = useDispatch();
  const wells = useSelector(state => state.wells);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [sliderValues, setSliderValues] = useState([
    wells?.currentWell?.log_sets?.RAW?.start_depth, wells?.currentWell?.log_sets?.RAW?.stop_depth
  ]);
  const [max, setMax] = useState(wells?.currentWell?.log_sets?.RAW?.stop_depth);
  const [min, setMin] = useState(wells?.currentWell?.log_sets?.RAW?.start_depth);
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (wells.currentWell) {
      const predefinedOptions = [
        "c1", "c2", "c3", "ic4", "nc4", "c4", "ic5", "nc5", "c5"
      ];
      const filteredOptions = predefinedOptions.map(option => ({ label: option, value: option }));
      setOptions(filteredOptions);

    }
  }, [wells.currentWell]);

  const handleMultiSelectChange = (value) => {
    setSelectedOptions(value);
  };

  const handleSliderChange = (value) => {
    setSliderValues(value);
  };

  const handleTopInputChange = (value) => {
    setSliderValues([value, sliderValues[1]]);
  };

  const handleBaseInputChange = (value) => {
    setSliderValues([sliderValues[0], value]);
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const closeModal = () => {
    close();
  };


  const apply = async () => {
    setLoading(true);
    const wellId = wells.currentWell.well_uid;
    console.log("CURVES ======>>>>", data);
    setPayload(prevState => ({
      ...prevState,
      reduce_noise_settings: {
        curves_to_calculate: data
      }
    }));

 
    setSelectedOptions([]);
    setSliderValues([wells.currentWell.RAW.depth.data.slice(-1)[0], wells.currentWell.maxDepth]);
    setData([]);
    setCurrentStep(0);
    setLoading(false);
    close();
    defaultNotification('Reduce noise applied !');
  }

  const steps = [
    {
      title: 'Select Options',
      content: (
        <>
          <Form>
            <div style={{ marginBottom: '36px', color: 'white', marginTop: '30px' }}>
              <label htmlFor="curves" style={{ marginRight: '8px' }}>
                Curves:
              </label>
              <Select
                id="curves"
                mode="multiple"
                allowClear
                style={{ width: '100%', color: 'white', marginTop: '4px' }}
                placeholder="Please select curves"
                value={selectedOptions}
                onChange={handleMultiSelectChange}
                dropdownStyle={{ backgroundColor: '#18181B' }}
              >
                {options.map((option) => (
                  <Option
                    key={option.value}
                    value={option.value}
                    style={{ backgroundColor: '#3F3F46', color: 'white' }}
                  >
                    {option.label}
                  </Option>
                ))}
              </Select>
            </div>
          </Form>
          <label htmlFor="slider" style={{ marginRight: '8px' }}>
            Selected Interval:
          </label>
          <Slider range step={10} value={sliderValues} onChange={handleSliderChange} min={min} max={max} />
          <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between' }}>
            <div span={4}>
              <h5 style={{ color: 'white' }}>Top</h5>
              <InputNumber
                min={min}
                max={max}
                style={{ backgroundColor: '#18181B', color: 'white' }}
                value={sliderValues[0]}
                onChange={handleTopInputChange}
              />
            </div>
            <div span={4}>
              <h5 style={{ color: 'white' }}>Base</h5>
              <InputNumber
                min={min}
                max={max}
                style={{ backgroundColor: '#18181B', color: 'white' }}
                value={sliderValues[1]}
                onChange={handleBaseInputChange}
              />
            </div>
          </div>
        </>
      ),
    },
    {
      title: 'Adjust Slider',
      content: (
        <>
          {selectedOptions.map((option) => (
            <NoiseCard
              key={option.value}
              curveName={option}
              setData={setData}
              selectedOptions={selectedOptions}
              data={data}
              sliderValues={sliderValues}
            />
          ))}
        </>
      ),
    },
  ];

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          width: '100%',
          height: '809px',
          padding: '20px',
          backgroundColor: '#09090B',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <div style={{ marginBottom: '24px' }}>
          <Steps current={currentStep}>
            {steps.map((item) => (
              <Step key={item.title} title={<span style={{ color: 'white' }}>{item.title}</span>} />
            ))}
          </Steps>
        </div>
        <div style={{ marginBottom: '16px', color: 'white', flex: 1 }}>{steps[currentStep].content}</div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
          {currentStep === 0 ? (
            <Button onClick={closeModal} style={{ marginRight: '12px' }}>
              <CloseOutlined />
              Cancel
            </Button>
          ) : (
            <Button onClick={handlePreviousStep} style={{ marginRight: '12px' }}>
              Previous
            </Button>
          )}
          <Button onClick={currentStep === steps.length - 1 ? apply : handleNextStep} style={{ backgroundColor: '#233BB6', color: 'white', border: 'none' }}>
            {currentStep === steps.length - 1 ? 'Apply' : 'Next'}
          </Button>
        </div>
      </div>
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            zIndex: 1000,
          }}
        >
          <Loading customStyles={{ width: 900, height: 500 }} />
        </div>
      )}
    </Modal>
  );
};

export default NoiseModal;
