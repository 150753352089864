import React from 'react'
import { useFormContext } from "react-hook-form";
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { InputText } from '../TextField';
import { Select } from '../Select';
import { Title } from '../Title';

import { WrapperInputsLatLong, WrapperPageTitle, WrapperReference } from './styles';

const referenceData = ['WGS 84'];

export const WellCoordinates = ({ defaultData }) => {
  const { control } = useFormContext();

  const referenceDataNormalized = referenceData.map(item => {
    return {
      value: item,
      text: item
    }
  });

  return (
    <>
    <WrapperPageTitle>
      <Title text='Well Coordinates' />
    </WrapperPageTitle>
    <Grid container rowSpacing={1} columnSpacing={4}>
      <Grid xs={6}>
        <Box>
          <Title text='Latitude' />
        </Box>
        <WrapperInputsLatLong>
          <InputText control={control} name="degrees_lat" label="Degrees" type="number" errorBadge />
          <InputText control={control} name="minutes_lat" label="Minutes" type="number" errorBadge />
          <InputText control={control} name="seconds_lat" label="Seconds" type="number" errorBadge />
        </WrapperInputsLatLong>
      </Grid>
      <Grid xs={6}>
        <Box>
          <Title text='Longitude' />
        </Box>
        <WrapperInputsLatLong>
          <InputText control={control} name="degrees_long" label="Degrees" type="number" errorBadge />
          <InputText control={control} name="minutes_long" label="Minutes"  type="number" errorBadge />
          <InputText control={control} name="seconds_long" label="Seconds" type="number" errorBadge />
        </WrapperInputsLatLong>
      </Grid>
    </Grid>
    <WrapperReference>
      <Select
        name="reference"
        control={control}
        label="Reference"
        options={referenceDataNormalized}
      />
    </WrapperReference>
    <Grid container rowSpacing={1} columnSpacing={4}>
      <Grid xs={6}>
        <InputText control={control} name="x_value" label="X" type="number" />
      </Grid>
      <Grid xs={6}>
        <InputText control={control} name="y_value" label="Y" type="number" />
      </Grid>
      <Grid xs={6}>
        <InputText control={control} name="zone" label="Zone" errorBadge />
      </Grid>
      <Grid xs={6}>
        <Select
          name="reference_zone"
          control={control}
          label="Reference"
          options={referenceDataNormalized}
        />
      </Grid>
    </Grid>
    </>
  )
};
