import './style.scss';

import React, {
  useEffect,
  useState
} from 'react';

import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  Icon
} from 'antd';

import {
  errorNotification,
  successNotification
} from '../../../../utils';

const SelectedZones = ({
  onClose, visible, zones,
  defaultCheckedZones, openAddBaseline, onSubmit,
  setDirty, dirty, compare, setCompare
}) => {
  const [checkedValues, setChecked] = useState();

  const zonesLength = (checkedValues ? checkedValues.filter(z => !!z.checked) : []).length;

  // make sure to update the default state when zones are active on the first load
  useEffect(() => {
    if (!dirty && defaultCheckedZones) {
      setChecked(defaultCheckedZones);
    }
  }, [dirty, defaultCheckedZones]);

  // find and check or uncheck zone
  const onToggleZone = (currentZone) => {
    setDirty(true);
    const newZones = checkedValues.map((z) => {
      if (z.id === currentZone.target.value) {
        return {
          id: z.id,
          checked: currentZone.target.checked
        };
      }
      return z;
    });
    setChecked(newZones);
  };

  const onCompare = (event) => {
    const shouldCompare = event.target.checked;
    setCompare(shouldCompare);
    setDirty(true);
  };

  const onSubmitZonesConfig = () => {
    if (checkedValues.filter(z => z.checked).length === 0) {
      errorNotification(`You should select at least one zone to save this settings.`);
      return;
    }

    onSubmit({
      checkedZones: checkedValues,
      compare
    });

    successNotification(`Settings applyed successfully`);
    onClose();
  };

  return(
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={385}
      className="selected-zones"
      getContainer={false}
    >
      <div className="selected-zones-title">Selected Zones</div>
      <Divider className="selected-zones-divider" />
      <div className="wrapper-zones-container">
        <div className="wrapper-zones-title">Zones</div>
        <div className="wrapper-zones-name-container">
          {(zones ?? []).map((zone, index) => {
            const { color } = zone.zone_plot_settings;
            return (
              <div className="zone-list-item">
                <Checkbox
                  value={zone.id}
                  onChange={onToggleZone}
                  name="checkbox-zone-1"
                  data-group="filter1"
                  checked={checkedValues && checkedValues[index] ? checkedValues[index].checked : false}
                />
                <div className="zone-circle" style={{ background: `rgba(${color.r}, ${color.g}, ${color.b}, 1)` }} />
                <div className="wrapper-zones-name">{zone.label}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <Divider className="selected-zones-divider" />
        <div className="wrapper-zones-title">Options</div>
        <div className="wrapper-zones-name-container flex compare-section">
          <Checkbox
            name="checkbox-compare"
            data-group="compare"
            checked={compare}
            disabled={zonesLength < 2}
            onChange={onCompare}
          />
          <div
            className={`wrapper-zones-name compare-text ${zonesLength < 2 ? 'disabled-label' : ''}`}
          >
            Compare
          </div>
        </div>
      </div>
      <Divider className="selected-zones-divider" />
      <div className="add-baseline-section">
        <Button
          className="btn-add-baseline"
          onClick={openAddBaseline}
        >
          <Icon type="setting" /> CONFIGURE BASELINE
        </Button>
      </div>
      <div className="container-actions">
        <Button
          type="primary"
          className="btn-apply"
          onClick={onSubmitZonesConfig}
        >
          APPLY
        </Button>
        <Button
          className="btn-cancel"
          onClick={onClose}
        >
          CANCEL
        </Button>
      </div>
    </Drawer>
  );
}

export default SelectedZones;
