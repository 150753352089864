import { useState, useEffect, useReducer } from 'react';
import store from 'store';
import { DEFAULT_SCALES } from 'utils/constants';

export const shouldUpdateScale = (type, scalesFromConfig, defaultScales) => {
  return scalesFromConfig?.[type] && (
    scalesFromConfig?.[type]?.min !== defaultScales?.[type]?.min ||
    scalesFromConfig?.[type]?.max !== defaultScales?.[type]?.max ||
    scalesFromConfig?.[type]?.color !== defaultScales?.[type]?.color
  );
};

export function reducerFunction(state, action) {
  return {
    ...state,
    [action.type]: action.value,
  };
}

export const useScales = (
  curvesTypes,
  currentWell,
  initialHeaderState
) => {
  const initialState = store.get('configScales');
  const [state, dispatch] = useReducer(reducerFunction, initialState);

  const [headerState, setHeaderState] = useState({
    ...initialHeaderState,
    scales: DEFAULT_SCALES,
  });

  useEffect(() => {
    /*
    * Check when current well receives the scales object and is different of localStorage
    * we should update the colors and min and max on dashboard based on each curve config
    **/
    const defaultScales = store.get('configScales');
    curvesTypes.forEach((curveName) => {
      const checkToUpdateScales = shouldUpdateScale(
        curveName,
        currentWell?.scales,
        defaultScales
      );

      if (currentWell && checkToUpdateScales) {
        const newConfig = currentWell?.scales?.[curveName];
        dispatch({ type: curveName, value: newConfig });
        store.set('configScales', {
          ...defaultScales,
          [curveName]: currentWell?.scales?.[curveName]
        });
      }
    });
  }, [currentWell?.scales, store.get('configScales')]);

  return {
    setHeaderState,
    headerState,
    currentScale: state
  }
};
