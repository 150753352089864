/* eslint-disable */
import React, {
  useEffect,
  useState
} from 'react';

import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import More from 'highcharts/highcharts-more';
import ChartContainer from '../../../Shared/ChartContainer';
import { getChartConfig } from './config';
import RenderChart from './RenderChart';


Exporting(Highcharts);
More(Highcharts);

const createChart = (
  config,
  id,
) => {
  const chart = new Highcharts.chart(id, {
    ...config,
    chart: {
      ...config.chart
    }
  });
  return chart;
};

const MolarChart = ({ defaultBaseline, customSeries, chartId, minY, maxY }) => {
  const [chart, setChart] = useState(null);
  const [seriesLength, setSeriesLength] = useState();

  useEffect(() => {
    const config = getChartConfig(customSeries);
    const chartInstance = createChart(config, chartId || 'molar-chart');
    setChart(chartInstance);
  }, [customSeries]);

  useEffect(() => {
    if (chart && chart.yAxis && chart.yAxis[0] && minY && maxY) {
      chart.yAxis[0].update({ min: minY, max: maxY });
      // chart.yAxis[1].update({ min: minY, max: maxY });
      chart.redraw();
    }
  }, [chart, minY, maxY]);

  useEffect(() => {
    if (
      (!seriesLength && defaultBaseline)
      || ((seriesLength && seriesLength.length) !== (customSeries && customSeries.length) && defaultBaseline)
      || defaultBaseline && (defaultBaseline.c1MolPercent || defaultBaseline.c1MolPercent >= 0)
      && (defaultBaseline.c2MolPercent || defaultBaseline.c2MolPercent >= 0)
      && (defaultBaseline.c3MolPercent || defaultBaseline.c3MolPercent >= 0)
      && (defaultBaseline.c4MolPercent || defaultBaseline.c4MolPercent >= 0)
      && (defaultBaseline.c5MolPercent || defaultBaseline.c5MolPercent >= 0)
    ) {
      const {
        c1MolPercent, c2MolPercent, 
        c3MolPercent, c4MolPercent, c5MolPercent,
        c1TStdMolPercent, c2TStdMolPercent, c3TStdMolPercent,
        c4TStdMolPercent, c5TStdMolPercent
      } = defaultBaseline;
      const baselineSerie = {
        data: [
          ['C1', 0, c1MolPercent, c1TStdMolPercent],
          ['C2', 1, c2MolPercent, c2TStdMolPercent],
          ['C3', 2, c3MolPercent, c3TStdMolPercent],
          ['C4', 3, c4MolPercent, c4TStdMolPercent],
          ['C5', 4, c5MolPercent, c5TStdMolPercent],
        ],
        type: 'spline',
        name: 'Baseline',
        lineColor: 'blue',
        color: 'blue',
      };

      if (chart && chart.series) {
        const findIndexBaseline = chart.series.findIndex(s => s.name === 'Baseline');
        if (findIndexBaseline >= 0) {
          chart.series[findIndexBaseline].setData(baselineSerie.data);
        } else {
          chart.addSeries(baselineSerie);
        }
        chart.redraw();
      }
    }
  }, [chartId, defaultBaseline]);

  return (
    <div className="molar-chart">
      <div className="molar-chart-container">
        <ChartContainer>
          <RenderChart id={chartId || 'molar-chart'} />
        </ChartContainer>
      </div>
    </div>
  );
}

export default MolarChart;
