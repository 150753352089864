import React from 'react';
import Clipboard from '../Clipboard';
import { ClipboardContainer, ClipboardWrapper } from './styles';

const ClipBoardWrapperDiv = ({ children }) => <ClipboardWrapper>{children}</ClipboardWrapper>;

const BitSizeClipboard = ({ rangeTop, rangeBottom, bottom, top }) => (
  <ClipboardContainer>
    <ClipBoardWrapperDiv>
      <Clipboard
        value={rangeTop}
        defaultValue={top}
        clipboardTarget="topValue"
      />
    </ClipBoardWrapperDiv>
    <ClipBoardWrapperDiv>
      <Clipboard
        value={rangeBottom}
        defaultValue={bottom}
        clipboardTarget="bottomValue"
      />
    </ClipBoardWrapperDiv>
  </ClipboardContainer>
);

export default BitSizeClipboard;
