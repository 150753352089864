import React, { useState } from 'react';
import Modal from 'react-modal';
import { modalStyleCreateWell } from 'utils';
import close from 'assets/close-white.svg';
import significanceLevel from 'assets/significance-level.svg';

import './style.scss';

const modalStyleSensor = {
  content : {
    ...modalStyleCreateWell.content,
    background: '#141414',
    padding: 0
  },
  overlay: {
    ...modalStyleCreateWell.overlay,
    backgroundColor: 'transparent'
  }
};

const ModalSignificance = ({ render }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => setIsOpen(false);

  const openModal = () => setIsOpen(true);

  return (
    <>
      {render && render({ openModal })}
      <Modal
        isOpen={isOpen}
        style={modalStyleSensor}
      >
        <div className="header-significance">
          <div>
            <span>Concepts</span>
            <img
              onClick={closeModal}
              src={close}
              alt="close"
            />
          </div>
        </div>
        <div className="contents-significance">
          <span>Significance</span>
          <p>
            Significance is a parameter that, succinctly, represents the percentage of the <br/>
            most significant values ​​calculated by the metrics selected by the user <br/>
            (Mean Absolute Deviation or Relative Difference) that will be considered as <br/>
            critical values. For example, a Significance = 2 will analyze the 2% highest <br/>
            values ​​calculated for the aforementioned metrics and consider these to be <br/>
            critical points: points at which for that gas curve and with the <br/>
            parameterizations selected by the user, a significant change was observed <br/>
            mathematically in their behavior, thus being able to represent a new gas <br/>
            zone in the well. In this way, it will be shown in the user interface at which <br/>
            depths the points where there were variations in the gas behavior were <br/>
            identified in the selected gas curves.
          </p>
          <div className="significance-level">
            <img src={significanceLevel} alt="significance level"/>
            <span>Significance Level</span>
          </div>
          <div className="how-apply">
            <span role="img" aria-label="Light Bulb">💡 How to apply</span>
            <p>
              You can select the level of significance for each gas by browsing <br/>
              through the tabs (located in the upper left corner). <br/><br/>
              To set the level of significance, you can define: <br/>
              &ensp; • Manually, enter a value in the numeric field; <br/>
              &ensp; • Interactively, moving the red line on the chart; <br/><br/>
              Regardless of the format, after that, confirm the action by clicking <br/>
              on the {"Apply"} button.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalSignificance;