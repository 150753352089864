import {
  createCutOffFilter,
  deleteCutOffFilter,
  editCutOffFilter,
  getAllCutOffs,
  listOneCutOffFilter
} from '../api/cutoff';
import {
  RECEIVE_CUT_OFF,
  RECEIVE_CUT_OFFS
} from '../constants/cutoff';
import cutoff from '../reducers/cutoff';

export const receiveCutOffs = (cutoffs) => ({
  type: RECEIVE_CUT_OFFS,
  cutoffs
});

export const receiveCutOff = (cutOff) => ({
  type: RECEIVE_CUT_OFF,
  cutOff
});

export const createCutOff = (cutOff, token, well_uid) => {
  return async (dispatch, getState) => {
    const oldCutoffs = getState().cutoff;
    try {
      // if (oldCutoffs && oldCutoffs.previous && oldCutoffs.previous.length) {
      // await createCutOffFilter({
      //   well_well_uid: well_uid,
      //   ...cutOff
      // });
      // delete all
      // if (oldCutoffs && oldCutoffs.previous && oldCutoffs.previous.length) {
      //   await dispatch(deleteCutOff(token, oldCutoffs.previous, wellId));
      // }
      // const arrayOfPromises = cutOffs.map(c => createCutOffFilter(c, token));
      // await Promise.all(arrayOfPromises);
      dispatch(fetchCutOffs(token, well_uid));
    } catch (e) {
      console.log('error', e)
    }
  };
};

export const getOneCutOff = (token, id) => {
  return async (dispatch) => {
    try {
      await listOneCutOffFilter(token, id);
    } catch (e) {
      console.log('error', e)
    }
  };
};

export const fetchCutOffs = (token, wellId, afterSuccess) => {
  return async (dispatch, getState) => {
    const mockedCutoffs = getState().cutoff.items;
    const currentWellId = getState().wells.selectedWell;
    try {
      // TODO remove this comment
      // const response = await getAllCutOffs(token, currentWellId || wellId);
      // console.log('response ==>', response);
      dispatch(receiveCutOffs(mockedCutoffs));
      afterSuccess && afterSuccess();
    } catch (e) {
      console.log('error', e)
    }
  };
};

export const updateCutOff = (cutOffs, idCutOffs, token) => {
  return async (dispatch) => {
    try {
      await editCutOffFilter(cutOffs, idCutOffs, token);
    } catch (e) {
      console.log('error', e)
    }
  };
};

export const deleteCutOff = (token, cutOffsIds, wellId, afterSuccess) => {
  return async (dispatch) => {
    try {
      const arrayOfPromises = cutOffsIds.filter(c => c.id).map(c => deleteCutOffFilter(token, c.id));
      await Promise.all(arrayOfPromises);
      dispatch(fetchCutOffs(token, wellId));
      afterSuccess && afterSuccess();
    } catch(e) {
      console.log(`error cuttoofff`, e);
    }
  }
};

