import React, { useEffect } from 'react';

import SpinnerLogo from 'components/SpinnerLogo';
import { createBrowserHistory } from 'history';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import {
  useNavigate,
  createBrowserRouter
} from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';

import { WizardProvider } from 'screens/Statistics/components/ModalStatistics/useStatisticsWizard';
import CutOffChartPage from './components/CutOffs/Chart';
import MolarAnalysis from './components/MolarAnalysis';
import RelativeEthene from './components/RelativeEthene';
import ResetSystem from './components/ResetSystem';
import UpdatePassword from './components/UpdatePassword';
import Crossplots from './components/Crossplots';
import {
  ProvideAuth,
  useProvideAuth,
} from '@geowellex/shared-login/src/hooks/useAuth';
import App from './screens/App';
import {
  Login,
} from '@geowellex/shared-login';
import { configureStore } from './store';
import StatisticsModule from './screens/Statistics';
import Root from './screens/Root';
import { Dashboard } from './components/Dashboard';
import QualityControl from './screens/QualityControl';
import { QualityControlProvider } from './screens/QualityControl/hooks/useQualityControl';
import { RealtimeProvider } from '@geowellex/shared-hooks';

import { listWell } from './actions/wells';
import { parseWellDataIntegration, updateWellData } from './api/import-data';
import { WELLEX_DATA_TEMP_URL, fetchInWellWatcherSite } from './api/wellwatcher';
import { errorNotification, successNotification } from './utils';

export const history = createBrowserHistory();

const storeReducer = configureStore();

Modal.setAppElement('#root');

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { checkValidSession, checkingToken, isValidToken } = useProvideAuth();

  useEffect(() => {
    checkValidSession();
  }, []);

  if (checkingToken) {
    return <SpinnerLogo />;
  }

  if (!isValidToken && isValidToken !== undefined && !checkingToken) {
    navigate('/login');
  }

  return <div>{children}</div>;
};

const RootRouter = createBrowserRouter([
  {
    path: "/",
    element: <StylesProvider injectFirst>
      <Provider store={storeReducer} history={history}>
        <ProvideAuth>
          <Root />
        </ProvideAuth>
      </Provider>
    </StylesProvider>
  },
  {
    path: "/login",
    element: <StylesProvider injectFirst>
      <ProvideAuth>
        <Login />
      </ProvideAuth>
    </StylesProvider>
  },
  {
    path: "/home",
    element:
    <PrivateRoute>
      <StylesProvider injectFirst>
        <Provider store={storeReducer} history={history}>
          <ProvideAuth>
            <RealtimeProvider
              listWell={listWell}
              parseWellDataIntegration={parseWellDataIntegration}
              updateWellData={updateWellData}
              fetchInWellWatcherSite={fetchInWellWatcherSite}
              afterSuccess={successNotification}
              afterError={errorNotification}
              WELLEX_DATA_TEMP_URL={WELLEX_DATA_TEMP_URL}
            >
              <QualityControlProvider>
                <App />
              </QualityControlProvider>
            </RealtimeProvider>
          </ProvideAuth>
        </Provider>
      </StylesProvider>
    </PrivateRoute>,
    children: [
      {
        path: "statistics",
        element: <StylesProvider injectFirst>
          <Provider store={storeReducer} history={history}>
            <ProvideAuth>
              <WizardProvider>
                <StatisticsModule />
              </WizardProvider>
            </ProvideAuth>
          </Provider>
        </StylesProvider>
      },
      {
        path: "quality-control",
        element: <StylesProvider injectFirst>
          <Provider store={storeReducer} history={history}>
            <ProvideAuth>
              <WizardProvider>
                <QualityControlProvider>
                  <QualityControl />
                </QualityControlProvider>
              </WizardProvider>
            </ProvideAuth>
          </Provider>
        </StylesProvider>
      },
      {
        path: '/home',
        element: 
        <RealtimeProvider
            listWell={listWell}
            parseWellDataIntegration={parseWellDataIntegration}
            updateWellData={updateWellData}
            fetchInWellWatcherSite={fetchInWellWatcherSite}
            afterSuccess={successNotification}
            afterError={errorNotification}
            WELLEX_DATA_TEMP_URL={WELLEX_DATA_TEMP_URL}
          >
          <Dashboard />
        </RealtimeProvider>
      }
    ]
  },
  {
    path: "/crossplots/:id",
    element: <StylesProvider injectFirst>
      <Provider store={storeReducer} history={history}>
        <ProvideAuth>
          <Crossplots />
        </ProvideAuth>
      </Provider>
    </StylesProvider>
  },
  {
    path: "/cutoff-plotbox/:id",
    element: <StylesProvider injectFirst>
      <Provider store={storeReducer} history={history}>
        <ProvideAuth>
          <CutOffChartPage />
        </ProvideAuth>
      </Provider>
    </StylesProvider>
  },
  {
    path: "/relative-ethene/:id",
    element: <StylesProvider injectFirst>
      <Provider store={storeReducer} history={history}>
        <ProvideAuth>
          <RelativeEthene />
        </ProvideAuth>
      </Provider>
    </StylesProvider>
  },
  {
    path: "/molar-analysis/:id/:wellId",
    element: <StylesProvider injectFirst>
      <Provider store={storeReducer} history={history}>
        <ProvideAuth>
          <MolarAnalysis />
        </ProvideAuth>
      </Provider>
    </StylesProvider>
  },
  {
    path: "/updatepassword/:id/",
    element: <StylesProvider injectFirst>
      <Provider store={storeReducer} history={history}>
        <ProvideAuth>
          <UpdatePassword />
        </ProvideAuth>
      </Provider>
    </StylesProvider>
  },
  {
    path: "/reset",
    element: <StylesProvider injectFirst>
      <Provider store={storeReducer} history={history}>
        <ProvideAuth>
          <ResetSystem />
        </ProvideAuth>
      </Provider>
    </StylesProvider>
  }
]);

export default RootRouter;
