import React, {
  useEffect,
  useState
} from 'react';

import {
  Input,
  Radio,
  Slider
} from 'antd';
import { useFormik } from 'formik';

import {
  validateCurveConfigurationForm
} from '../../../../../utils/validationCurvesStatistics';
import { useStatisticWizard } from '../../ModalStatistics/useStatisticsWizard';

const PanelCurveConfigForm = ({
  curveName,
  data
}) => {
  const [validForms, setValidForms] = useState([]);
  const wizardState = useStatisticWizard();
  const { setValidFormsNames, validFormsCurves, curves } = wizardState;
  const currentForm = wizardState[`formCollapse${curveName}`];
  const setForm = wizardState[`setFormCollapse${curveName}`];

  const windowLength = data?.[`${curveName}`]?.window_length_value;
  const differenceType = data?.[`${curveName}`]?.difference_type;
  const movingAverageType = data?.[`${curveName}`]?.moving_average_type;
  const sensitivity = data?.[`${curveName}`]?.sensitivity_value;
  const sensitivityValue = sensitivity && sensitivity !== 0 ? sensitivity : 10;

  useEffect(() => {
    if (!validForms?.length && validFormsCurves?.length) {
      setValidForms(validFormsCurves);
    }
  }, [validFormsCurves]);

  const onSubmitForm = (values) => {
    validateCurveConfigurationForm(curveName, values, setValidFormsNames, validForms, curves);
    setForm({...values, formType: curveName});
  };

  const formik = useFormik({
    initialValues: {
      window_length_value: currentForm && currentForm.window_length_value ? currentForm.window_length_value : windowLength,
      moving_average_type: currentForm && currentForm.moving_average_type ? currentForm.moving_average_type : movingAverageType,
      difference_type: currentForm && currentForm.difference_type ? currentForm.difference_type : differenceType,
      sensitivity_value: currentForm?.sensitivity_value && currentForm?.sensitivity_value !== 0  ? currentForm.sensitivity_value : sensitivityValue
    },
    enableReinitialize: true,
    onSubmit: onSubmitForm
  });

  const { handleSubmit, values, setFieldValue, initialValues } = formik;

  const handleChangeModel = (e) => {
    // ensure to keep sensitivity value with 10 value
    if (e.target.value === 'EMA') {
      setFieldValue('sensitivity_value', initialValues.sensitivity_value);
      setFieldValue('moving_average_type', e.target.value);
      return;
    }
    setFieldValue('sensitivity_value', undefined);
    setFieldValue('moving_average_type', e.target.value);
  };

  const handleChangeMetrics = (e) => {
    setFieldValue('difference_type', e.target.value);
  };

  const handleChangeInput= (e) => {
    setFieldValue('window_length_value', e.target.value);
  };

  const handleChangeSensitivity = (value) => {
    setFieldValue('sensitivity_value', value);
  };

  return (
    <div>
      <div className="section-checkbox">
        <div className="label-checkbox label-checkbox--margin">
          <label className="label-checkbox__title">Model</label>
        </div>
        <Radio.Group
          onChange={handleChangeModel}
          defaultValue={currentForm && currentForm.moving_average_type || movingAverageType}
        >
          <Radio
            className="section-checkbox__text"
            value="SMA"
            data-cy="simple-moving-average"
          >
            Simple Moving Average
          </Radio>
          <Radio
            className="section-checkbox__text"
            value="EMA"
          >
            Exponential Moving Average
          </Radio>
        </Radio.Group>
      </div>
      {values && values.moving_average_type === 'EMA' && (
        <div className="panel-input">
          <div className="label-input">
            <label className="label-input__title">
              Sensitivity: {values?.sensitivity_value || initialValues.sensitivity_value}
            </label>
          </div>
          <div className="wrapper-ranger">
            <Slider
              min={1}
              max={100}
              onChange={handleChangeSensitivity}
              defaultValue={initialValues.sensitivity_value}
            />
          </div>
        </div>
      )}
      <div className="panel-input">
        <div className="label-input">
          <label className="label-input__title">Window length</label>
        </div>
        <Input
          className="panel-input__input"
          onChange={handleChangeInput}
          name="window_length_value"
          type="number"
          placeholder="0"
          defaultValue={currentForm && currentForm.window_length_value || windowLength}
          data-cy="window-length-input"
        />
      </div>
      <div className="section-checkbox">
        <div className="label-checkbox label-checkbox--margin">
          <label className="label-checkbox__title">Metrics</label>
        </div>
        <Radio.Group
          onChange={handleChangeMetrics}
          defaultValue={currentForm && currentForm.difference_type || differenceType}
        >
          <Radio
            className="section-checkbox__text"
            value="ABS"
            data-cy="absolute-difference"
          >
            Absolute Difference
          </Radio>
          <Radio
            className="section-checkbox__text"
            value="REL"
          >
              Relative Difference
          </Radio>
        </Radio.Group>
      </div>
      <div className="wrapper-btn-icon">
        <button
          type="submit"
          onClick={handleSubmit}
          style={{
            backgroundColor: '#1890ff',
            fontSize: 14,
            border: 'none',
            width: 75,
            height: 30,
            color: '#fff',
            borderRadius: 4,
            cursor: 'pointer'
          }}
          data-cy="save-one-curve"
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default PanelCurveConfigForm;
