import React from 'react';
import { Tabs, Drawer } from 'antd';
import XaxisForm from './XaxisForm';
import YaxisForm from './YaxisFormTab';

const { TabPane } = Tabs;

const SettingsTab = ({
  visible, onClose, onSubmitYaxisForm, onSubmitXaxisForm, onChangeTab,
  yAxisMin, yAxisMax
}) => (
  <Drawer
    placement="right"
    closable={false}
    onClose={onClose}
    visible={visible}
    width={330}
    bodyStyle={{background: '#333333', height: '100%', width: '100%'}}
    getContainer={false}
  >
    <div className="wrapper-drawer-molar-analysis">
      <div className="wrapper-settings-header" />
      <div className="wrapper-menu wrapper-drawer-molar-analysis">
        <Tabs defaultActiveKey="1" onChange={onChangeTab}>
          <TabPane tab="yAxis" key="1" className="tab-settings-molar-chart">
            <YaxisForm onSubmitYaxisForm={onSubmitYaxisForm} onClose={onClose} yAxisMin={yAxisMin} yAxisMax={yAxisMax} />
          </TabPane>
          {/* <TabPane tab="xAxis" key="2">
              <XaxisForm onSubmitXaxisForm={onSubmitXaxisForm} onClose={onClose} />
            </TabPane> */}
        </Tabs>
      </div>
    </div>
  </Drawer>
);

export default SettingsTab;
