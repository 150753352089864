import React from 'react';
import { Badge } from 'antd';

const ListItem = ({ item, unit }: any) => {
  let bottom = item.bottom;
  if (item && item.bottom && typeof item.bottom === 'string' && item.bottom.includes('-')) {
    bottom = parseFloat(item.bottom.split('-')[0].trim())
  }

  let top = item.top;
  if (item && item.top && typeof item.top === 'string' && item.top.includes('-')) {
    top = parseFloat(item.top.split('-')[0].trim())
  }
  const r = item?.zone_plot_settings?.color?.r;
  const g = item?.zone_plot_settings?.color?.g;
  const b = item?.zone_plot_settings?.color?.b;
  const a = item?.zone_plot_settings?.color?.a;
  return (
    <div className="zones-info">
      <Badge color={`rgb(${r}, ${g}, ${b}, ${a}`} />
      <ZoneName name={item?.label} />
      <Badge color={item.borderColor} />
      {/* <ZoneStatus active={item.fluidType || 'Undefined'} /> */}
      <ZoneFluidTypeOption fluidType={item.fluidType} />
      <ZoneRange top={Number(top || 0)?.toFixed(2)} bottom={Number(bottom || 0)?.toFixed(2)} unit={unit} />
    </div>
  );
};

const ZoneName = ({ name }: any) => (
  <div
    className="zones-list__item_name truncate"
    data-cy="zone-name"
  >
    {name}
  </div>
);

// const ZoneStatus = ({ active }: any) => (
//   <div
//     className="zones-list__item"
//     data-cy="zone-state"
//   >
//     {active ? 'Enabled' : 'Disabled' }
//   </div>
// );


const ZoneFluidTypeOption = ({ fluidType }: any) => (
  <div
    className="zones-list__item"
    data-cy="zone-state"
  >
    {fluidType ? fluidType : '-' }
  </div>
);

const ZoneRange = ({ top, bottom, unit }: any) => (
  <div
    className="zones-list__item_top_bottom truncate"
    data-cy="zone-depth"
  >
    {`${top} - ${bottom} (${unit})`}
  </div>
);

export default ListItem;
