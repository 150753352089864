import React, { Component } from 'react';
import Highcharts from 'highcharts';
import keys from 'lodash.keys';
import { Button } from 'antd';
import Exporting from 'highcharts/modules/exporting';
import classNames from 'classnames';
import { drawPath, drawPathWithPadding } from '../../../utils/Svg';
import { getUnit } from '../../../utils';

Exporting(Highcharts);

const P1X2 = 0;
const P2X3 = 1.5;
const P3X1 = 1.5;
const P4X1 = 0;
const P5X = 1.7;
const P6X = 1.7;
const P7X = 4;
const P8X = 4;

// Oil area
const drawOil = (chart, min, max) => drawPathWithPadding(0)(
  P4X1, max,
  P1X2, min,
  P2X3, min,
  P3X1, max,
  chart,
  {
    'fill': '#d1dabc',
    'stroke': '#718656',
    'stroke-width': 2,
    'opacity': '0.5'
  },
  true
);
 
// Gas area
const drawGas = (chart, min, max) => drawPath(
  P6X, max,
  P5X, min,  
  P7X, min,
  P8X, max,
  chart,
  {
    'fill': '#f1a7a7',
    'stroke': '#f15555',
    'stroke-width': 2,
    'opacity': '0.5'
  }
);

// Transition area
const drawTransition = (chart, min, max) => drawPath(
  P3X1, max,
  P2X3, min,  
  P5X, min,
  P6X, max,
  chart,
  {
    'fill': '#f4c094',
    'stroke': '#ed713e',
    'stroke-width': 2,
    'opacity': '0.4'
  }
);

class VerticalScatterPlotC25 extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
  }

  // TO DO Create a cutomized hook to dealing with event listening
  componentDidMount() {
    this.createScatterPlot();
    this.drawTransition = (min, max) => drawTransition(this.chart, min, max);
    this.drawOil = (min, max) => drawOil(this.chart, min, max);
    this.drawGas = (min, max) => drawGas(this.chart, min, max);

    const min = keys(this.chart.series[1].yAxis.ticks)[0];
    const max = keys(this.chart.series[1].yAxis.ticks).slice(-1)[0];

    // Transition area
    this.chart.transition = this.drawTransition(min, max);
    // Oil
    this.chart.oil = this.drawOil(min, max);
    // Gas area
    this.chart.gas = this.drawGas(min, max);

    const { idListener } = this.props;

    document.addEventListener(`${idListener}-chart2`, ({ detail }) => {
      const { config } = detail;
      const {yAxis, xAxis} = this.chart;
      if (yAxis && yAxis[0]) {
        yAxis[0].update({
          min: config.y.min,
          max: config.y.max,
          title: {
            text: config.y.title
          }
        });
      }
      if (xAxis && xAxis[0]) {
        xAxis[0].update({
          min: config.x.min,
          max: config.x.max,
          title: {
            text: config.x.title
          }
        });
      }

      this.chart.oil.destroy();
      this.chart.oil = this.drawOil(config.y.min, config.y.max);

      this.chart.gas.destroy();
      this.chart.gas = this.drawGas(config.y.min, config.y.max);

      this.chart.transition.destroy();
      this.chart.transition = this.drawTransition(config.y.min, config.y.max);

      this.setState({
        isVisible: config.isVisible
      });
    });
  }

  createScatterPlot = () => {
    const { id, data } = this.props;

    const minX = data.length && data[0] && data[0][1] ? Math.floor(data[0][1]) : 0;
    const maxX = data.length && data.length - 1 >= 0 && data[data.length - 1][1] ? Math.round(data[data.length - 1][1]) : 0;

    const getTooltipWithDepth = (dataWithDepth = []) => ({
      headerFormat: '<b>{series.name}</b><br>',
      // pointFormat: `x: {point.x}, y: {point.y}`,
      formatter: function() {
        const itemWithDepth = dataWithDepth?.find(
          value => value?.x === this?.x && value?.y === this?.y
        );
        return `
          <b>Depth</b>: ${itemWithDepth?.depth}<br/>
          <b>(SF C2-5ppm)</b>: ${itemWithDepth?.x?.toFixed(2)}  <br/>
        `;
      },
      enabled: true
    });

    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'scatter'
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      credits: {
        enabled: false
      },
      fillOpacity: 1,
      opacity: 1,
      exporting: { enabled: false },
      xAxis: {
        title: {
          enabled: true,
          text: '<b>SF C2-5 (ppm)</b>',
          y: 0
        },
        opposite: true,
        startOnTick: true,
        endOnTick: true,
        type: '',
        showLastLabel: true,
        tickInterval: 0.5,
        minorTickInterval: 1,
        pointStart: 0,
        max: 4,
        min: 0,
        tickPosition: 'outside',
        gridLineWidth: 0
      },
      yAxis: {
        title: {
          text: `MD Depth (${this.props.currentUnit})`
        },
        lineWidth: 1,
        lineColor: '#ccd6eb',
        type: '',
        showLastLabel: true,
        pointStart: 0,
        min: minX,
        max: maxX,
        tickInterval: 1,
        reversed: true,
        gridLineWidth: 1
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: -14,
        floating: true,
        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
        borderWidth: 0
      },
      plotOptions: {
        scatter: {
          marker: {
            radius: 5,
            states: {
              hover: {
                enabled: true,
                lineColor: 'rgb(100,100,100)'
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          // tooltip: {
          //   headerFormat: '<b>{series.name}</b><br>',
          //   pointFormat: '{point.x} cm, {point.y} kg'
          // }
        }
      },
      tooltip: getTooltipWithDepth(this?.props?.dataWithDepth),
      series: [
        {
          name: ' ',
          color: 'rgba(223, 83, 83, .5)',
          data,
          marker: {
            fillColor: 'rgba(45, 52, 54, 0.7)',
            symbol: 'circle',
            radius: 2.5
          }
        },
        {
          name: ' ',
          color: 'rgba(119, 152, 191, 0.5)',
          data,
          marker: {
            fillColor: 'rgba(231, 76, 60, 0.7)',
            symbol: 'circle',
            radius: 3
          }
        }
      ]
    });
  }

  render() {
    const {
      id, withMinorGridLines, className, deactivateLegend, openConfigureScale
    } = this.props;

    return (
      <div className="scatter-general verticalScatter-plot-c-25">
        {
          openConfigureScale && (
            <Button
              type="primary"
              shape="circle"
              icon="setting"
              size="large"
              className="toggle-crossplot-config"
              onClick={openConfigureScale}
            />
          )
        }
        <div
          className={classNames(
            className,
            { 'reset-minor-grids': !withMinorGridLines },
            { 'deactivate-legend': deactivateLegend }
          )}
          id={id}
          style={{
            paddingTop: '12px',
            minWidth: '326px',
            height: '450px',
            maxWidth: '330px',
            flex: 1
          }} />
      </div>
    );
  }
}

VerticalScatterPlotC25.defaultProps = {
  withMinorGridLines: false,
  deactivateLegend: true,
  className: 'scatter-plot'
};

export default VerticalScatterPlotC25;
