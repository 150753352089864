import React, {
  useEffect,
  useState
} from 'react';

import { useSelector } from 'react-redux';

import IndicatorsCard from '../IndicatorsCard';
import { getRawCurveData } from '../../utils/getRawCurveData';

const NOT_AVAILABLE = 'N/A';
const API = 'api';
const GOR = 'gor';

const calculateStdApiC2C3 = (c2, c3, ic4, nc4, selectedDepthIndex = 0) => {
  const c2c3 = c2 && c3 && selectedDepthIndex >= 0 && c2[selectedDepthIndex] && c3[selectedDepthIndex]
    ? c2[selectedDepthIndex] / c3[selectedDepthIndex] : null;
  const apiC2C3 = 20.467 * c2c3 ** 1.0563;
  return apiC2C3 ? (apiC2C3.toFixed(2)) : 'N/A';
};

const calculateStdApiC2C34 = (c2, c3, ic4, nc4, selectedDepthIndex = 0) => {
  const ic4Value = ic4 ? ic4[selectedDepthIndex] : 0;
  const nc4Value = nc4 ? nc4[selectedDepthIndex] : 0;
  const c4 = ic4Value + nc4Value;
  const c2Value = c2 ? c2[selectedDepthIndex] : 0;
  const c3Value = c3 ? c3[selectedDepthIndex] : 0;
  const c2c34 =  c2Value / (c3Value + c4);
  const apiC2C34 = 33.533 * c2c34 + 0.1591;
  return apiC2C34 ? (apiC2C34.toFixed(2)) : 'N/A';
};

const calculateStdGORC2C3 = (c2, c3, ic4, nc4, selectedDepthIndex = 0) => {
  const c2Value = c2 ? c2[selectedDepthIndex] : 0;
  const c3Value = c3 ? c3[selectedDepthIndex] : 0;

  const c2c3 = c2Value / c3Value;
  const gorC2C3 = 1533.5 * c2c3 - 2104.6;
  return gorC2C3 || 'N/A';
};

const calculateStdGORC2C34 = (c2, c3, ic4, nc4, selectedDepthIndex = 0) => {
  const ic4Value = ic4 ? ic4[selectedDepthIndex] : 0;
  const nc4Value = nc4 ? nc4[selectedDepthIndex] : 0;
  const c4 = ic4Value + nc4Value;
  const c2Value = c2 ? c2[selectedDepthIndex] : 0;
  const c3Value = c3 ? c3[selectedDepthIndex] : 0;
  const c2c34 = c2Value / (c3Value + c4);
  const gorC2C34 = 359.43 * c2c34**4.1898;
  return gorC2C34 || 'N/A';
};

const getCardValue = (key, state, selectedWell) => {
  const value = state[key];
  // see which is lower
  let lowerLimit = Math.min(value.c2c3, value.c2c34);
  let upperLimit = Math.max(value.c2c3, value.c2c34);

  // if one of the values is equal or less than zero or null, change it to N/A
  if (lowerLimit <= 0 || !lowerLimit) {
    lowerLimit = NOT_AVAILABLE;
  }

  if (upperLimit <= 0  || !upperLimit) {
    upperLimit = NOT_AVAILABLE;
  }

  return value && value !== NOT_AVAILABLE && selectedWell
    ? `${String(lowerLimit)} - ${String(upperLimit)}` : NOT_AVAILABLE;
};

const ApiGorCards = ({ selectedWell }) => {
  const wells = useSelector(state => state.wells);
  const [state, setState] = useState({
    api: NOT_AVAILABLE,
    gor: NOT_AVAILABLE
  });

  useEffect(() => {
    const { currentWell, selectedDepthIndex } = wells;
    if (currentWell && selectedDepthIndex >= 0) {

      const c2 = getRawCurveData('c2', currentWell);
      const c3 = getRawCurveData('c3', currentWell);
      const ic4 = getRawCurveData('ic4', currentWell);
      const nc4 = getRawCurveData('nc4', currentWell);

      const calculateApiC2C3 = calculateStdApiC2C3(c2.data, c3.data, ic4.data, nc4.data, selectedDepthIndex);
      const calculateApiC2C34 = calculateStdApiC2C34(c2.data, c3.data, ic4.data, nc4.data, selectedDepthIndex);

      const calculateGORC2C3 = calculateStdGORC2C3(c2.data, c3.data, ic4.data, nc4.data, selectedDepthIndex);
      const calculateGORC2C34 = calculateStdGORC2C34(c2.data, c3.data, ic4.data, nc4.data, selectedDepthIndex);

      setState({
        ...state,
        api: { c2c3: calculateApiC2C3, c2c34: calculateApiC2C34 } || 0,
        gor: { c2c3: Math.round(calculateGORC2C3), c2c34: Math.round(calculateGORC2C34)} || 0
      });
    }
  }, [wells.selectedDepthIndex]);

  const apiValue = getCardValue(API, state, selectedWell);
  const gorValue = getCardValue(GOR, state, selectedWell);

  return (
    <>
      <IndicatorsCard
        description="API"
        type={API}
        value={apiValue}
      />
      <IndicatorsCard
        description="GOR"
        type={GOR}
        value={gorValue}
      />
    </>
  );
};

export default ApiGorCards;
