import './style.scss';

import React, { useState } from 'react';
import flatten from 'lodash.flatten';
import Spinner from 'components/Spinner';

import {
  AutoComplete,
  Button,
  Input
} from 'antd';
import {
  ErrorMessage,
  Field,
  FieldArray,
  Formik
} from 'formik';
import reduce from 'lodash.reduce';

import closeIcon from '../../images/cancel.png';

const { Option } = AutoComplete;

const BitSizeInput = ({ field, form: { setFieldValue } }) => {
  const [bitSize, setbitSize] = useState([]);

  const onChangeField = value => setFieldValue(`${field.name}`, value);

  const handleSearch = () => {
    let options = [];
    options = [
      '6 7/8',
      '6 3/4',
      '6 1/8',
      '7 7/8',
      '8 3/4',
      '8 1/2',
      '8 3/8',
      '9 1/2',
      '10 5/8',
      '12 1/4',
      '13 1/2',
      '16',
      '17 1/2'
    ];
    setbitSize(options);
  };

  const children = bitSize.map(values => (
    <Option key={values} value={values}>
      {values}
    </Option>
  ));

  return (
    <AutoComplete
      onFocus={handleSearch}
      onChange={onChangeField}
      defaultValue={field.value || undefined}
      placeholder="6 7/8"
      data-cy="bit-size-inch"
    >
      {children}
    </AutoComplete>
  );
};

const defaultBitSize = {
  top: '',
  bottom: '',
  bitSize: 0
};

const initialValues = {
  bitSize: [defaultBitSize]
};

const applyErrors = (row, index, setFieldError, isBitSize = false) => {
  reduce(row, (acc, value, key) => {
    if (!value) {
      setFieldError(`bitSize.${index}.${key}`, 'Required');
    }

    if (isBitSize) {
      setFieldError(`bitSize.${index}.bitSize`, 'Invalid Format');
    }
  }, {});
};

const isInvalidFields = ({ top, bottom, bitSize }) => (
  top === '' || bottom === '' || bitSize === 0
)

const checkBitSize = ({ bitSize }) => {
  const [left, right] = String(bitSize).split(' ');
  const DIVISION_SIGN = '/';
  if ((!right && !left) || (left && Number.isNaN(Number(left)))) {
    return true;
  } if (right) {
    const [dividend, divider] = String(right).split(DIVISION_SIGN);
    if (!divider || !dividend) {
      return true;
    }
  }
  return false;
};

const checkAndValidateBitSizes = (bitSizeValues, setFieldError) => {
  return bitSizeValues.reduce((acc, row, index) => {
    if (isInvalidFields(row)) {
      applyErrors(
        row, index, setFieldError
      );
      acc = false;
    }
    const isBitSizeInvalid = row.bitSize ? checkBitSize(row) : false;
    if (isBitSizeInvalid) {
      applyErrors(
        row, index, setFieldError, true
      );
      acc = false;
    }
    return acc;
  }, true);
};

const submitAndValidate = onSubmit => (values, { setFieldError }) => {
  const bitSizeValues = values.bitSize;
  const isValid = checkAndValidateBitSizes(bitSizeValues, setFieldError);
  if (isValid) {
    onSubmit(values);
  }
};

const BitSizeForm = ({
  onSubmit, current, processing,
  goPrev, steps, closeWell,
  removeWellPhase, wellphases, loadingWellPhases,
  loading
}) => {
  const initialWellPhases = wellphases ?? [];
  return (
    <>
      {loading ? (
        <div style={{
          marginBottom: 40, witdth: '100%', height: 300, display: 'flex',
          justifyContent: 'center', alignItems: 'center',
          flexDirection: 'column'
        }}>
          <Spinner classes="spinner--small" />
          <br/>Loading...
        </div>
      ) : (
        <Formik
          initialValues={wellphases ? { bitSize: initialWellPhases } : initialValues }
          onSubmit={submitAndValidate(onSubmit)}
          enableReinitialize
        >
          {({ handleSubmit, values, handleChange, handleBlur }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <FieldArray name="bitSize">
                {({ remove, push }) => {
                  return (
                    <>
                      {values.bitSize.length > 0 && (
                        values.bitSize.map((bit, index) => {
                          return (
                            <div className="home__modal-form-group">
                              <div className="phase-title">
                                <span className="phase-title__text">
                                  Phase {index + 1}
                                </span>
                              </div>
                              <div className="form-well-phase">
                                <label
                                  htmlFor={`bitSize.${index}.top`}
                                  className="form-well-phase__label"
                                >
                                  Top
                                </label>
                                <Input
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={bit.top}
                                  placeholder="0"
                                  type="number"
                                  data-cy="bit-size-top"
                                  name={`bitSize.${index}.top`}
                                  className="form-well-phase__input"
                                />
                                <ErrorMessage
                                  name={`bitSize.${index}.top`}
                                  component="span"
                                  className="error-style-well-phases"
                                  data-cy="top-error"
                                />
                              </div>
                              <div className="form-well-phase">
                                <label
                                  htmlFor={`bitSize.${index}.bottom`}
                                  className="form-well-phase__label"
                                >
                                  Bottom
                                </label>
                                <Input
                                  id="bottom"
                                  placeholder="0"
                                  name={`bitSize.${index}.bottom`}
                                  onChange={handleChange}
                                  data-cy="bit-size-bottom"
                                  type="number"
                                  onBlur={handleBlur}
                                  value={bit.bottom}
                                  className="form-well-phase__input"
                                />
                                <ErrorMessage
                                  name={`bitSize.${index}.bottom`}
                                  component="span"
                                  className="error-style-well-phases"
                                  data-cy="bottom-error"
                                />
                              </div>

                              <div className="form-well-phase">
                                <label
                                  htmlFor={`bitSize.${index}.bitSize`}
                                  className="form-well-phase__label"
                                >
                                  Bit Size (inches)
                                </label>
                                <Field
                                  name={`bitSize.${index}.bitSize`}
                                  index={index}
                                  value={bit.bitSize}
                                  component={BitSizeInput}
                                />
                                <ErrorMessage
                                  name={`bitSize.${index}.bitSize`}
                                  component="span"
                                  className="error-style-well-phases"
                                  data-cy="bitSize-error"
                                />
                              </div>
                              <div className="form-well-phase__button-wrapper">
                                <button
                                  type="button"
                                  className="secondary form-well-phase__button"
                                  onClick={() => {
                                    remove(index);
                                    removeWellPhase(index);
                                  }}
                                >
                                  <img src={closeIcon} alt="Remove Phase" />
                                </button>
                              </div>
                            </div>
                          )})
                      )}
                      <div className="well-phase__btn-container">
                        <Button
                          type="primary"
                          data-cy="button-add-bitSize"
                          onClick={() => push(defaultBitSize)}
                        >
                          + Add Bit Size
                        </Button>
                      </div>
                    </>)}}
              </FieldArray>
              <div style={{ marginTop: '24px', display: 'flex', justifyContent: 'flex-end' }}>
                {current < steps.length - 1 && (
                  <Button
                    style={{ marginLeft: 8 }}
                    onClick={closeWell}
                    className="cy-btn-cancel"
                  >
                    Cancel
                  </Button>
                )}
                {current > 0 && !processing && (
                  <Button
                    style={{ marginLeft: 8 }}
                    className="cy-btn-previous"
                    onClick={() => goPrev(values.bitSize)}
                  >
                    Previous
                  </Button>
                )}
                {(current === 0 || current === 1) && (
                  <Button
                    style={{ marginLeft: 8 }}
                    type="primary"
                    className="cy-btn-next"
                    htmlType="submit"
                    loading={loadingWellPhases}
                  >
                    {loadingWellPhases ? 'Saving...' : 'Save'}
                  </Button>
                )}
              </div>
            </form>
          )}
        </Formik>
      )}
    </>
  )
};

export default BitSizeForm;
