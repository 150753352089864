import React, { useEffect, useState } from 'react';

import Range from 'rc-slider/lib/Range';
import { getUnit } from 'utils';

import './style.css';

const TopBottomSlider = ({
  rangeTopValue, rangeBottomValue, rangeTopIndex, rangeBottomIndex, afterChange, depth
}) => {
  const [value, setValue] = useState([rangeTopIndex, rangeBottomIndex]);
  const [unit, setUnit] = useState(getUnit());

  useEffect(() => {
    if (unit !== getUnit()) {
      setValue([rangeTopIndex, rangeBottomIndex]);
    }
  }, [getUnit()]);

  useEffect(() => {
    if (rangeTopValue === 0 && rangeBottomValue === 0 && depth) {
      setValue([0, depth.length-1]);
      afterChange([0, depth.length-1]);
    }
  }, []);

  const onChange = (depthIndexs) => {
    if (depth) {
      setValue(depthIndexs);
      afterChange(depthIndexs);
    }
  };

  const showRangeTopBottom = () => {
    const top = depth && value ? depth[value[0]] : 'N/A';
    const bottom = depth && value ? depth[value[1]] : 'N/A';

    return `${top} - ${bottom}`
  }

  return (
    <div
      style={{
        display: 'flex',
        marginTop: '20px',
        position: 'relative'
      }}
      className="home__modal-form-group bit-size__form-group"
      data-cy="top-bottom-slider"
    >
      <label
        htmlFor="top"
        style={{ color: '#fff', textAlign: 'left', width: '90px' }}
      >
        Top - Bottom
      </label>
      <label
        htmlFor="top"
        style={{
          textAlign: 'center',
          color: '#fff',
          fontSize: '14px',
          top: '-17px',
          position: 'absolute',
          left: '47%',
          width: '136px'
        }}
      >
        {showRangeTopBottom()}
      </label>
      <Range
        range
        allowCross={false}
        defaultValue={[rangeTopIndex, rangeBottomIndex]}
        min={0}
        max={depth && depth.length-1}
        step={1}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default TopBottomSlider;
