import axios from 'axios';

/*
  These functions are responsible to store, fetching one well or a list of wells, and syncing
  to google cloud functions to prevent the user from loose the current local well sites using
  wellex-gold.
  https://console.cloud.google.com/functions/details/us-central1/well
*/

const API_URL = 'https://prod.gateway.geowellex.com/wellex-gold/v2';

const resource = 'cutoffs';

export function createCutOffFilter(data, token) {
  return axios.post(
    `${API_URL}/${resource}/`,
    data,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function listOneCutOffFilter(data, token, id) {
  return axios.get(
    `${API_URL}/${resource}/${id}`,
    data,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function getAllCutOffs(token, wellId) {
  return axios.get(
    `${API_URL}/${resource}/?wellId=${wellId}`,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function listCutOffFilter(token, id) {
  return axios.get(
    `${API_URL}/${resource}/`,
    id,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function editCutOffFilter(data, idCutOffs, token) {
  return axios.put(
    `${API_URL}/${resource}/${idCutOffs}`,
    data,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}
  
export function deleteCutOffFilter(token, idCutOffs) {
 return axios.delete(
    `${API_URL}/${resource}/${idCutOffs}`,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}
