import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import classes from 'classnames';
import removeIcon from '../../assets/close.svg';
import { modalStyle } from '../../utils';
import { Progress, Icon, Alert, Spin } from 'antd';

import 'antd/dist/antd.css';

function UploadWellItem({ wellName, syncedUp, increase }) {
  const [percent, setPercent] = useState(0);
  const [status, setStatus] = useState('active');

  const updatePercent = async () => {
    setPercent((prevPercent) => {
      if (prevPercent >= 80) {
        return prevPercent;
      }
      return prevPercent + increase;
    });
  };

  useEffect(() => {
    const currentInterval = setInterval(() => {
      updatePercent();
    }, 800);
    return () => {
      clearInterval(currentInterval);
    };
  }, []);

  useEffect(() => {
    if (syncedUp && percent >= 80) {
      setStatus('success');
      setPercent(100);
    }
  }, [syncedUp, percent]);

  return (
    <div>
      <div style={{ display: 'flex', }}>
        <Icon type="file" className="file-sync" />
        <div className="wellName">{wellName}</div>
        {percent < 100 && <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
          <Spin />
        </div>}
      </div>
      <div style={{ display: 'flex' }}>
        <Progress percent={percent} status={status} showInfo={false} />
      </div>
    </div>
  );
}

function PopUpList({ wellList, syncedUp }) {
  return (
    <div>
      <Alert
        message="Action Required"
        description="We detected wellsites in your local application, wait until sync all to cloud."
        type="warning"
        showIcon
      />
      <br/>
      <div className="sync-icon-container">
      </div>
      {wellList.map((wellName, index) => {
        return (
          <UploadWellItem
            wellName={wellName}
            syncedUp={syncedUp}
            increase={Math.floor(Math.random(index + 1) * 10) + 10}
            showInfo
          /> 
        );
      })}
      <br/>
    </div>
  );
}

const Title = ({ title, className, classTitle }) => (
  <h3 className={classes(
    'title-row',
    {
      [className]: !!className,
      [classTitle]: !!classTitle
    }
  )}
  >
    {title}
  </h3>
);

Title.defaultProps = {
  classTitle: 'modal-scale__title'
};

const SyncOldWellsModal = ({ isModalOpen, onAfterOpen, wellList, syncedWells }) => {
  const [isOpen, setModal] = useState(false);

  const closeModal = () => {
    setModal(false);
  };

  useEffect(() => {
    if (!isOpen && isModalOpen) {
      setModal(isModalOpen);
      onAfterOpen();
    }
  }, [isModalOpen]);

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyle}
      maskClosable={false}
      onRequestClose={() => {}}
    >
      <div
        className="modal-scale__header"
      >
        <Title title="Sync wellsites to cloud" className="sync-modal-title" />
        <img
          onClick={closeModal}
          src={removeIcon}
          className="modal-scale__close"
          data-cy="close-modal"
          alt="close modal"
        />
      </div>
      <div
        className="modal-scale__form"
      >
        <PopUpList wellList={wellList} syncedUp={syncedWells} />
      </div>
    </Modal>
  );
};

export default SyncOldWellsModal;
