import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Select, Input, Button } from 'antd';
import InputColor from 'react-input-color';
import { keysFromObjectToArray, ErrorMessage } from '../../../utils';

const { Option } = Select;

const axisLeftSchema = Yup.object().shape({
  min: Yup.number()
    .required('Required'),
  max: Yup.number()
    .required('Required')
});

const AxisLeftForm = ({
  currentCurve, onSubmitLeft, currentScaleConfig,
  onClose, curves
}) => {
  const { min, max, color, curve } = currentScaleConfig.yLeft;

  const formik = useFormik({
    initialValues: {
      curve,
      min,
      max,
      color
    },
    enableReinitialize: true,
    onSubmit: onSubmitLeft,
    validationSchema: axisLeftSchema
  });

  const { handleSubmit, handleChange, values, errors } = formik;

  const handleChangeColor = ({ hex }) => {
    handleChange({
      target: {
        value: hex,
        name: 'color'
      }
    });
  };


  return (
    <div className="content-tab">
      <form onSubmit={handleSubmit}>
        <div className="box-inputs">
          <label>Curve</label>
          <div className="select-curve select-xAxis">
            <Select
              defaultValue={currentCurve}
              onChange={handleChange}
              name="curve"
              value={formik.values.curve}
              disabled
            >
              {keysFromObjectToArray(curves).map(
                item => <Option className="option-select" name="curve" value={item}>{item}</Option>
              )}
            </Select>
          </div>
          <label>Min</label>
          <div className="text-min">
            <Input
              onChange={handleChange}
              name="min"
              type="number"
              placeholder="min"
              value={values.min}
            />
            {errors && errors.min && <ErrorMessage message={errors.min} />}
          </div>
          <label>Max</label>
          <div className="text-max">
            <Input
              onChange={handleChange}
              name="max"
              type="number"
              placeholder="max"
              value={values.max}
            />
            {errors && errors.max && <ErrorMessage message={errors.max} />}
          </div>
          <div className="box-color">
            <label>Color:</label>
            <div className="input-color">
              <InputColor
                initialHexColor={values.color}
                onChange={handleChangeColor}
                name="color"
              />
            </div>
          </div>
        </div>
        <div className="wrapper_buttons">
          <div className="button-ok">
            <Button type="primary" onClick={onClose}>Ok</Button>
          </div>
          <div className="button-apply">
            <Button htmlType="submit" type="primary">Apply</Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AxisLeftForm;
