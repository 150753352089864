import React from 'react';
import { Alert } from 'antd';
import fileText from 'assets/file-text.svg';

const content = (openModal) => (
  <div className="wrapper-description">
    <p>
      To fill in the fields correctly, we recommend that you visualize the concept of each <br/>
      step to make an accurate choice based on the desired parameters.
    </p>
    <button
      type="button"
      onClick={openModal}
    >
      <img src={fileText} alt="file text"/>
      Statistics module concept
    </button>
  </div>
); 

const AlertStatisticsConcepts = ({ openModal }) => {
  return (
    <div className="container-alert-statistics">
      <Alert
        message="Instructions"
        description={content(openModal)}
        type="info"
        closable
      />
    </div>
  );
}

export default AlertStatisticsConcepts;