import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUnit } from '../utils';
import IndicatorsCard from './IndicatorsCard';
import { getCalculatedCurveData } from '../utils/getCalculatedCurveData';

const initialHeader = {
  selectedDepth: 'N/A',
  unit: getUnit()
};

const HoleDepthCard = () => {
  const wells = useSelector(state => state.wells);
  const [unitValue] = useState();

  const [currentDepth, setHeaderState] = useState({
    ...initialHeader
  });

  useEffect(() => {
    if (wells.currentWell && wells.selectedDepthIndex >= 0) {
      const depth = getCalculatedCurveData('depth', wells.currentWell);
      const index = wells.selectedDepthIndex || 0;
      const depthValue = depth && depth.data.length ? depth.data[index] : 0;
      setHeaderState({
        ...currentDepth,
        selectedDepth: depthValue?.toFixed(2)
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wells]);

  return (
    <IndicatorsCard
      description="Hole Depth"
      unit={unitValue ? `(${unitValue})` : ''}
      value={currentDepth.selectedDepth ? `${currentDepth.selectedDepth}` : 'N/A'}
      type="hole"
    />
  );
};

export default React.memo(HoleDepthCard);
