/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import NewWindow from 'react-new-window';

// @TODO: should refactor it to reuse just one PlotBox
const PlotBox = () => {
  const wells = useSelector(state => state.wells);

  const [stateType, setStateType] = useState(null);
  const [count, setCount] = useState(0);
  const [windows, setUpdate] = useState({
    0: null, 1: null, 2: null,
    3: null, 4: null, 5: null,
    6: null, 7: null, 8: null
  });

  useEffect(() => {
    const toggleWindows = event => {
      const { detail: { type } } = event;

      // prevent to open a empty chart when there is no well selected
      if (!wells.currentWell && !wells) {
        alert('Please select a well first!');
        return;
      }

      setStateType(type);
      const currentWell = wells?.items?.find(w => w.wellId === wells.selectedWell);
      if (currentWell) {
        const newWindow = {
          id: currentWell.wellId.replaceAll('/','+'),
          type
        };

        setUpdate({ ...windows, [count]: newWindow });
        setCount((count + 1));
      }
    };

    document.addEventListener('plotBoxCuttOff', toggleWindows);
    return () => {
      document.removeEventListener('plotBoxCuttOff', toggleWindows);
    };
  }, [windows, wells.currentWell, wells]);

  // @TODO create a function of onUnload and add it on the utils.js
  return (
    <div className="scatters-plot">
      {Object.keys(windows).length && Object.keys(windows).map((key, index) => {
        const currentWindow = windows[key];
        if (!currentWindow) {
          return null;
        }
        return (
          <div>
            <NewWindow
              key={index}
              url={`/cutoff-plotbox/${currentWindow.id}--${currentWindow.type}`}
              features={{ left: 200, top: 200, width: 1600, height: 900 }}
              onUnload={() => {
                const queryData = store.get('querys') || {};
                const { [currentWindow.id]: _, ...result } = queryData;
                store.set('querys', result);
              }}
            />
          </div>
        )
      })}
    </div>
  );
}

export default PlotBox;
