/* eslint-disable react/no-unused-state */
/* eslint-disable no-unused-expressions */
import React, {
  Component,
  useEffect,
  useState
} from 'react';

import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';
import store from 'store';
import { getRawCurveData } from 'utils/getRawCurveData';
import { ROP } from 'utils/constants';
import get from 'lodash.get';
import { shouldUpdateScale } from '../../../hooks/useScales';
import { applyDefaultZoomToChart } from '../../../lib/charts';
import {
  defaultCrossHair,
  defaultValuesToCrosshair,
  disableDefaultZoom,
  disableDots,
  disableLabels,
  displayZoomBtn,
  getToolTipValues,
  nFormatter,
  propagateZoom,
  syncronizeTooltip,
  toggleTrack,
  toggleWobChart
} from '../../../utils';
import { ChartHeader } from './ChartHeader/ChartHeader';
import { DEFAULT_SCALES } from '../../../utils/constants';

const getDefaultSeries = (scales, wobData, ropData) => {
  const wobColor = '#9600ff';
  const placeholderData = defaultValuesToCrosshair(ropData, 0);
  return [
    {
      crosshair: true,
      fillOpacity: 0.1,
      name: 'gasratio',
      type: 'spline',
      data: placeholderData,
      lineColor: wobColor,
      yAxis: 0,
      zIndex: 2,
      index: 0,
      lineWidth: 0.8,
      ...disableDots
    },
    {
      crosshair: true,
      fillOpacity: 0.5,
      name: 'rop',
      type: 'area',
      data: ropData,
      lineColor: scales?.rop?.color,
      color: scales?.rop?.color,
      yAxis: 1,
      zIndex: 1,
      index: 1,
      step: true,
      lineWidth: 0.8,
      shadow: false,
      ...disableDots
    }
  ]
};

class RopChart extends Component {
  constructor(props) {
    super(props);
    this.chart = null;

    this.state = {
      min: 0,
      max: 0,
      series: {
        0: true,
        1: true
      },
      rop: 0,
      gasRatio: 0,
      ropData: []
    };
  }

  componentDidMount() {
    const { parentContainer, id } = this.props;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.vpHeight = document.querySelector(parentContainer).clientHeight || 0;

    if (this.chart === null) {
      this.createChart();
    }
    // TODO refactor this syncronization
    document.addEventListener('sincronize-selected', event => {
      const { detail: { min, max } } = event;
      this.chart.xAxis[0].setExtremes(min, max, true, true);
      this.chart.redraw();
    });
    syncronizeTooltip(`#${id}`);

    document.addEventListener('toggle-wob-chart', ({ detail: { index } }) => {
      toggleTrack(this)(index);
    });

    document.addEventListener('rop', ({ detail }) => {
      this.chart.yAxis[1].update({
        min: Number(detail.min),
        max: Number(detail.max)
      });
      this.chart.yAxis[1].series[0].update({
        color: detail.color,
        lineColor: detail.color
      }, true);
      this.chart.redraw();
    });
    document.addEventListener('gasRatio', ({ detail }) => {
      this.chart.yAxis[0].update({
        min: detail.min,
        max: detail.max
      });
      this.chart.series[0].color = detail.color;
      this.chart.series[0].redraw();
    });

    if (this.chart) {
      this.zoomId = applyDefaultZoomToChart(this.chart);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { ropData, min, max, selectedWell } = nextProps;
    const { props } = this;

    if (
      props.updateChart || selectedWell === -1
    ) {
      this.chart.update({
        series: getDefaultSeries(nextProps?.currentWell?.scales, [], ropData)
      });
      this.chart.redraw();
      this.zoomId = setTimeout(() => {
        this.chart.xAxis[0].setExtremes(min, max);
      }, 300);

      return true;
    }

    // should update the color of series when receive a new config from well scale
    const defaultScales = store.get('configScales');
    const checkToUpdateScales = shouldUpdateScale(
      ROP,
      this.props.currentWell?.scales,
      defaultScales
    );
    if (this.props.currentWell && checkToUpdateScales) {
      this.chart.update({
        series: getDefaultSeries(
          this.props.currentWell?.scales,
          rop
        )
      });
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    this.zoomId && clearTimeout(this.zoomId);
  }

  createChart = () => {
    const { wobData, ropData, id, currentWell } = this.props;
    const { rop } = store.get('configScales');

    this.chart = Highcharts.chart(id, {
      chart: {
        zoomType: 'x',
        type: 'area',
        inverted: true,
        width: 100,
        margin: 0,
        padding: 0,
        marginTop: 0,
        backgroundColor: window.color || '#fff',
        ...disableDefaultZoom,
        events: {
          selection: (event) => {
            displayZoomBtn();
            propagateZoom(event);
          }
        }
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          enableMouseTracking: false,
          showInLegend: false
        }
      },
      exporting: { enabled: false },
      tooltip: {
        tooltip: {
          ...defaultCrossHair,
          formatter: getToolTipValues('rop')
        }
      },
      title: ' ',
      subtitle: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: [
        {
          crosshair: {
            color: '#FA8072'
          },
          tickLength: 0,
          labels: {
            enabled: false
          },
          minorGridLineWidth: 1,
          minorGridLineColor: '#E0E0E0',
          gridLineColor: '#a4a4a4',
          gridLineWidth: 1
        }
      ],
      yAxis: [
        {
          min: 0,
          max: 100,
          labels: {
            enabled: false
          },
          name: 'gas-ratio',
          title: {
            y: 45,
            useHTML: true,
            color: '#9600ff',
            // get the size from the grid
            text: ''
          },
          allowDecimals: true,
          minorGridLineWidth: 1,
          startOnTick: false,
          endOnTick: false,
          showFirstLabel: true,
          showLastLabel: true,
          ...disableLabels,
          ...disableDots,
          minorGridLineColor: 'rgba(0, 0, 0, 0)',
          gridLineColor: 'rgba(0, 0, 0, 0)',
          type: 'linear',
          zIndex: 200,
          index: 0
        },
        {
          name: 'rop',
          min: currentWell?.scales?.min || rop.min,
          max: currentWell?.scales?.max || rop.max,
          labels: {
            enabled: false
          },
          title: {
            y: -264,
            useHTML: true,
            color: '#27E03D',
            text: ''
          },
          allowDecimals: true,
          minorGridLineWidth: 1,
          offset: 5,
          ...disableLabels,
          ...disableDots,
          minorGridLineColor: '#E0E0E0',
          minorTickInterval: 10,
          type: 'linear',
          zIndex: 200,
          index: 0
        }
      ],
      series: getDefaultSeries(
        currentWell?.scales || DEFAULT_SCALES,
        wobData,
        ropData
      )
    });
  }

  render() {
    const { id } = this.props;
    return (
      <div
        className="chart__box-container"
      >
        <ChartHeader />
        <div
          id={id}
          style={{
            height: 'calc(100% - 128px)'
          }}
          data-cy="chart-rop"
        />
      </div>
    );
  }
}

RopChart.defaultProps = {
  ropColor: '#27E03D',
  wobColor: '#9600ff',
  parentContainer: '.charts-wrapper',
  wobData: [],
  ropData: [],
  id: 'rop'
};

export default React.memo(RopChart);
