import * as Yup from 'yup';

export const validateDepthValue = (top: any, bottom: any): string[] => {
  if (top === undefined && bottom === undefined) {
    alert('Please, Select a valid top and bottom depth value');
    return ['top', 'bottom'];
  }
  if (top === undefined) {
    alert('Please, Select a valid top depth value');
    return ['top'];
  }
  if (bottom === undefined) {
    alert('Please, Select a valid bottom depth value');
    return ['bottom'];
  }
  return [];
};

export const ZoneScheme = Yup.object().shape({
  // top: Yup.string().required('Required'),
  // bottom: Yup.string().required('Required'),
  label: Yup.string().required('Required'),
  // color: Yup.string().required('Required'),
  // borderColor: Yup.string().required('Required'),
  // borderWidth: Yup.string().required('Required'),
  // chartToApply: Yup.string().required('Required'),
  fluidType: Yup.string().nullable(),
});
