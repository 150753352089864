import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Tree } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { modalStyle } from '../../utils';
import closeWhite from '../../assets/close-white.svg';

const treeData = (data) => {
  return(
    data && data.reduce((acc, currentValue, index) => {
      let tree = {};

      if (currentValue === 'api' || currentValue === 'gor') {
        tree = {
          title: currentValue,
          key: index,
          children: [
            {
              title: 'c2c3',
              key: `${index}-0`
            },
            {
              title: 'c2c34',
              key: `${index}-1`
            }
          ]
        }
      } else {
        tree ={
          title: currentValue,
          key: index
        }
      }
      
      acc.push(tree);
      return acc;
    }, [])
  );
};

const checkboxKeys = (sizeValue) => {
  const vector = [];

  for(let i = 0; i<sizeValue; i++) {
    vector.push(i);
    if (i === 1 || i === 2) {
      vector.push(`${i}-0`);
      vector.push(`${i}-1`);
    }
  }
  
  return vector
}

const removeParentFromDeleteCurves = (curvesDeleted, title) => {
  const indexCurvesDeleted = curvesDeleted.indexOf(title);
  
  if (indexCurvesDeleted !== -1) {
    curvesDeleted.splice(indexCurvesDeleted, 1);
  }
}

const removeChildFromDeleteCurves = (curvesDeleted, child, parent) => {
  const indexCurvesDeleted = curvesDeleted.findIndex(element =>
    element.curve === child && element.parent === parent);
  if (indexCurvesDeleted !== -1) {
    curvesDeleted.splice(indexCurvesDeleted, 1);
  }

}

const addParentAndOrChildInDeleteCurves = (curvesDeleted, title, nameCurve, parent) => {
  const resultCurvesDeleted = curvesDeleted.find(element => element.parent === parent);
        
  if (resultCurvesDeleted !== undefined) {
    nameCurve = [
      parent,
      {curve: title, parent}
    ];
  } else {
    nameCurve = [{curve: title, parent}];
  }

  return nameCurve;
}

const addParentAndChildrenInDeleteCurves = (nameCurve, parent) => {
  nameCurve = [
    parent,
    {curve: 'c2c34', parent},
    {curve: 'c2c3', parent}
  ];

  return nameCurve;
}

// TO DO implement it using filter to avoid mutate object and improve performance
const deletingCurves = (deleteCurves, currentValue) => {
  
  // Losing complete object reference
  let currentCurve = JSON.parse(JSON.stringify(currentValue));

  const { api, gor } = currentCurve;
  delete currentCurve.api;
  delete currentCurve.gor;
  currentCurve = {
    ...currentCurve,
    'api-c2c3': api.c2c3,
    'api-c2c34': api.c2c34,
    'gor-c2c3': gor.c2c3,
    'gor-c2c34': gor.c2c34
  }
  
  if (deleteCurves.length !== 0) {
    deleteCurves.map((value) => {
      if (typeof value === 'object') {
        // Deleting curves of the api or gor
        if (value.parent === 'api' && currentCurve['api-c2c3'] !== undefined && value.curve === 'c2c3') {
          delete currentCurve['api-c2c3'];
        } else if (value.parent === 'api' && currentCurve['api-c2c34'] !== undefined && value.curve === 'c2c34') {
          delete currentCurve['api-c2c34'];
        } else if (value.parent === 'gor' && currentCurve['gor-c2c3'] !== undefined && value.curve === 'c2c3') {
          delete currentCurve['gor-c2c3'];
        } else if (value.parent === 'gor' && currentCurve['gor-c2c34'] !== undefined && value.curve === 'c2c34') {
          delete currentCurve['gor-c2c34'];
        }
      } else if (value === 'api' ) {
        delete currentCurve['api-c2c3'];
        delete currentCurve['api-c2c34'];
      } else if (value === 'gor') {
        delete currentCurve['gor-c2c3'];
        delete currentCurve['gor-c2c34'];
      } else {
        delete currentCurve[value];
      }
      return currentCurve;
    });
  }
  return currentCurve;
}


const ModalExportData = ({isOpen, changeModal, prepareToParseInCSV, exportCurvesToCsv}) => {

  const [data, setData] = useState([]);
  const [deleteCurves, setDeleteCurves] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState({});

  useEffect(() => {
    if (prepareToParseInCSV[0]) {
      // Getting the names of the curves
      const arrayData = Object.keys(prepareToParseInCSV[0]);
      setData(arrayData)
      const result = checkboxKeys(arrayData.length);
      setCheckedKeys(result);
    }
  }, [prepareToParseInCSV]);

  const exportData = (values) => {
    const result = values.reduce((acc, currentValue) => {
      const resultDelete = deletingCurves(deleteCurves, currentValue);
      acc.push({...resultDelete});
      return acc;
    }, []);

    setDeleteCurves([]);
    exportCurvesToCsv(result);
    changeModal();
  }

  const onCheck = (checkedKeysProps, info) => {
    const { title, pos: position } = info.node.props;

    // Adding or removing curves in deleteCurves array
    if (info.checked === false) {
      let nameCurve = [title];

      if (position === '0-1-0' || position === '0-1-1') {
        nameCurve = addParentAndOrChildInDeleteCurves(deleteCurves, title, nameCurve, 'api');

      }else if (position === '0-2-0' || position === '0-2-1') {
        nameCurve = addParentAndOrChildInDeleteCurves(deleteCurves, title, nameCurve, 'gor');

      }else if (title === 'gor') {
        nameCurve = addParentAndChildrenInDeleteCurves(nameCurve, 'gor');

      }else if (title === 'api') {
        nameCurve = addParentAndChildrenInDeleteCurves(nameCurve, 'api');
      }

      setDeleteCurves([
        ...deleteCurves,
        ...nameCurve
      ]);

    } else if (title === 'api' || title === 'gor') {
      removeParentFromDeleteCurves(deleteCurves, title);
      removeChildFromDeleteCurves(deleteCurves, 'c2c3', title);
      removeChildFromDeleteCurves(deleteCurves, 'c2c34', title);
      
    } else if (position === '0-2-0' || position === '0-2-1') {
      removeChildFromDeleteCurves(deleteCurves, title, 'gor');
      removeParentFromDeleteCurves(deleteCurves, 'gor');

    } else if (position === '0-1-0' || position === '0-1-1') {
      removeChildFromDeleteCurves(deleteCurves, title, 'api');
      removeParentFromDeleteCurves(deleteCurves, 'api');

    } else if (title !== 'api' && title !== 'gor') {
      removeParentFromDeleteCurves(deleteCurves, title);
    }
    
    setCheckedKeys(checkedKeysProps);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyle}
    >
      <div className="export-modal-container">
        <div className="head-export-modal">
          <span>Selected Export data</span>
          <img
            onClick={changeModal}
            src={closeWhite}
            alt="close"
          />
        </div>
        <div className="wrapper-body-export-modal">
          <div className="body-export-modal">
            <Tree
              checkable
              switcherIcon={<DownOutlined />}
              defaultExpandAll='true'
              checkedKeys={checkedKeys}
              onCheck={onCheck}
              treeData={treeData(data)}
              className="tree-export-modal"
            />

          </div>
          <div className="btn-wrapper-export-modal">
            {/* <button
              type="button"
              onClick={changeModal}
              className="btn-ok-export-modal"
            >
              Ok
            </button> */}
            <button
              type="button"
              onClick={() => exportData(prepareToParseInCSV)}
              className="btn-export-modal"
            >
              Export
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalExportData;