import store from 'store';
import {
  defaultValuesToCrosshair,
  disableDots
} from 'utils';
import { DEFAULT_SCALES } from '../../../utils/constants';

export const createDefaultConfig = (
  scales,
  displayZoomBtn,
  propagateZoom,
  getToolTipValues,
  getDefaultSeries,
  defaultCrossHair,
  totalGasData,
  totalCarbonData,
  c1NormalizedData,
  gasQualityRatioData
) => ({
  chart: {
    inverted: true,
    width: 280,
    marginTop: 0,
    margin: 0,
    marginLeft: 4,
    zoomType: 'x',
    events: {
      selection: (event) => {
        displayZoomBtn();
        propagateZoom(event);
      }
    },
    animation: false,
    shadow: false
  },
  credits: {
    enabled: false
  },
  title: {
    text: ''
  },
  subtitle: {
    text: ''
  },
  xAxis: {
    allowDecimals: true,
    tickPosition: 'inside',
    tickColor: '#fff',
    gridLineColor: '#a4a4a4',
    gridLineWidth: 1,
    minorTickInterval: 1,
    zIndex: 800,
    opposite: true,
    tickLength: 0,
    labels: {
      enabled: true,
      y: -300
    }
  },
  yAxis: [
    {
      title: {
        text: ''
      },
      padding: 0,
      margin: 0,
      minorGridLineWidth: 1,
      minorGridLineColor: '#E0E0E0',
      tickStart: 0.1,
      type: 'logarithmic',
      minorTickInterval: 0.1,
      min: DEFAULT_SCALES?.total_gas?.min || 0.1,
      max: DEFAULT_SCALES?.total_gas?.max || 100,
      color: 'rgba(0, 0, 0, 0)',
      index: 0,
      gridLineWidth: 1,
      tickInterval: 1,
      zIndex: 10,
      step: true,
      allowDecimals: true,
      labels: {
        enabled: true,
        y: -2000
      }
    },
    {
      title: {
        text: ''
      },
      type: 'logarithmic',
      minorTickInterval: 0.1,
      min: DEFAULT_SCALES?.total_gas?.min || 0.1,
      max: DEFAULT_SCALES?.total_gas?.max || 100,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      color: DEFAULT_SCALES?.total_gas?.color,
      index: 1,
      allowDecimals: true,
      labels: {
        enabled: true,
        y: -10000
      },
      gridLineWidth: 1,
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      tickInterval: 1,
      zIndex: 10,
      step: true
    },
    {
      type: 'logarithmic',
      minorTickInterval: 0.1,
      title: {
        text: ''
      },
      min: DEFAULT_SCALES?.totalCarbon?.min || 0.1,
      max: DEFAULT_SCALES?.totalCarbon?.max || 100,
      color: DEFAULT_SCALES?.totalCarbon?.color,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      index: 2,
      allowDecimals: true,
      labels: {
        enabled: true,
        y: -10000
      },
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      tickInterval: 1,
      zIndex: 10,
      step: true
    },
    {
      type: 'logarithmic',
      minorTickInterval: 0.1,
      title: {
        text: ''
      },
      min: DEFAULT_SCALES?.c1Normalized?.min || 0.1,
      max: DEFAULT_SCALES?.c1Normalized?.max || 100,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      color: DEFAULT_SCALES?.c1Normalized?.color,
      index: 3,
      allowDecimals: true,
      labels: {
        enabled: false
      },
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      tickInterval: 1,
      zIndex: 10,
      step: true
    },
    {
      name: 'gqr',
      title: {
        text: ''
      },
      type: 'logarithmic',
      minorTickInterval: 0.2,
      min: DEFAULT_SCALES?.gqr?.min || 0.2,
      max: DEFAULT_SCALES?.gqr?.max || 2,
      gridLineColor: 'rgba(0, 0, 0, 0)',
      color: DEFAULT_SCALES?.gqr?.color || '#000',
      index: 4,
      allowDecimals: true,
      labels: {
        enabled: true,
        y: -10000
      },
      gridLineWidth: 1,
      minorGridLineWidth: 1,
      minorGridLineColor: 'rgba(0, 0, 0, 0)',
      tickInterval: 1,
      zIndex: 10,
      step: true
    },
  ],
  plotOptions: {
    series: {
      marker: {
        enabled: false
      },
      enableMouseTracking: false,
      showInLegend: false,
      hover: false
    }
  },
  exporting: { enabled: false },
  tooltip: {
    shared: true,
    ...defaultCrossHair,
    formatter: getToolTipValues('total-gas')
  },
  series: getDefaultSeries(
    totalGasData,
    totalCarbonData,
    c1NormalizedData,
    gasQualityRatioData
  )
});

export const getDefaultSeries = (
  totalGasData, totalCarbonData, c1NormalizedData, gasQualityRatioData
) => {
  const { total_gas, total_carbon, c1Normalized, gqr } = DEFAULT_SCALES;
  const totalGasPlaceholderData = defaultValuesToCrosshair(totalGasData, 0.1);
  return [
    {
      type: 'area',
      ...disableDots,
      name: 'totalPlaceholder',
      data: totalGasPlaceholderData,
      color: '#fff',
      min: total_gas?.min,
      max: total_gas?.max,
      index: 0,
      yAxis: 0,
      allowDecimals: true
    },
    {
      type: 'spline',
      ...disableDots,
      name: 'totalGas',
      data: totalGasData,
      color: total_gas.color,
      lineColor: total_gas.color,
      min: total_gas.min,
      max: total_gas.max,
      index: 1,
      yAxis: 1,
      fillOpacity: 0.1,
      allowDecimals: true
    },
    {
      type: 'spline',
      ...disableDots,
      name: 'totalCarbon',
      data: totalCarbonData,
      color: total_carbon.color,
      lineColor: total_carbon.color,
      min: total_carbon.min,
      max: total_carbon.max,
      index: 2,
      yAxis: 2,
      allowDecimals: true
    },
    {
      type: 'spline',
      ...disableDots,
      name: 'c1Normalized',
      data: c1NormalizedData,
      color: c1Normalized.color,
      lineColor: c1Normalized.color,
      min: c1Normalized.min,
      max: c1Normalized.max,
      index: 3,
      yAxis: 3,
      allowDecimals: true
    },
    {
      type: 'spline',
      ...disableDots,
      name: 'gqr',
      data: gasQualityRatioData,
      color: gqr && gqr.color ? gqr.color : '#000',
      lineColor: gqr && gqr.color ? gqr.color : '#000',
      min: gqr && gqr.min ? gqr.min : 0.2,
      max: gqr && gqr.max ? gqr.max : 2,
      index: 4,
      yAxis: 4,
      allowDecimals: true
    }
  ];
};

