import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Highcharts from 'highcharts';
import ZoomQueue from '../../utils/Queue';

import './style.scss';

const ZoomButtom = ({ min, max }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    document.addEventListener('zoom-in', () => {
      setVisible(true);
    });
  }, []);

  const resetZoom = () => {
    const last = ZoomQueue.dequeue();

    let newMin = 0;
    let newMax = 0;

    if (last) {
      newMin = last.min; // last undo
      newMax = last.max;
    } else {
      newMin = min; // from state
      newMax = max;
    }

    // TODO leo refactor it to unify this loop with crosshair
    for (let i = 0; i < Highcharts.charts.length; i++) {
      let chart = null;
      chart = Highcharts.charts[i];
      if (chart && chart.xAxis[0].setExtremes) {
        chart.xAxis[0].setExtremes(newMin, newMax, false, false);
        chart.redraw();
      }
    }

    const syncTotalDepthEvent = new CustomEvent(
      'total-depth-sync',
      { detail: { min: newMin, max: newMax } }
    );
    document.dispatchEvent(syncTotalDepthEvent);

    if (ZoomQueue.getSize() <= 0) {
      setVisible(false);
    }
  };
  // style={{ display: visible ? 'flex' : 'none', zIndex: 200 }}
  return(
    <div className="zoom__container">
      <button
        type="button"
        className={classNames(
          'btn-zoom',
          {
            'zoom-visible': visible,
            'zoom-hidden': !visible
          }
        )}
        onClick={resetZoom}
      >
        Reset Zoom
      </button>
    </div>
  );
};

export default ZoomButtom;

