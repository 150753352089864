import React from 'react';
import { Alert } from 'antd';
import './style.scss';

const defaultDescription = (curves) => `There is already a configuration created for these curves: ${curves}. We recommend that you select new curves or these curves will be overwritten.`;
const defaultTitle = 'Curve overwriting';

const AlertWarning = ({ messageTitle, description, curves }) => {
  return (
    <div className="content-alert-warning">
      <Alert
        message={messageTitle || defaultTitle}
        type="warning"
        description={description || defaultDescription(curves)}
        closable
      />
    </div>
  );
}

export default AlertWarning;
