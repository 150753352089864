import 'aos/dist/aos.css';

import React, { useEffect } from 'react';
import Aos from 'aos';

import { CopyrightProps } from '../../types/login'
import { CopyrightContainer, CopyrightImage, CopyrightInfoText } from '../../styles/styles';

export const Copyright = ({ logo }: CopyrightProps) => {

  useEffect(() => {
    Aos.init();
  }, [])

  return (
    <CopyrightContainer
      data-aos="fade-up"
      data-aos-delay="650"
      data-aos-duration="550"
      data-aos-easing="ease"
      data-testid="copyright"
    >
      <CopyrightImage
        src={logo}
        data-testid="copyright-logo"
      />
      <CopyrightInfoText>Version 1.39.53</CopyrightInfoText>
      <CopyrightInfoText>Copyright © 2020 - 2024 All Rights Reserved</CopyrightInfoText>
    </CopyrightContainer>
  )
};
