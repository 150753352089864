
export const getChartConfig = (customSeries) => ({
  chart: {
    minWidth: 800,
    height: 500,
  },
  title: {
    text: ''
  },
  navigation: {
    buttonOptions: {
      enabled: false
    }
  },
  xAxis: {
    // crosshair: true,
    tickInterval: 1,
    accessibility: {
      rangeDescription: 'Range: 1 to 10'
    },
    categories: [
      '<b>C1</b>',
      '<b>C2</b>',
      '<b>C3</b>',
      '<b>C4</b>',
      '<b>C5</b>'
    ],
    lineColor: '#000',
    lineWidth: 1
  },
  legend: {
    enabled: true,
    verticalAlign: 'top',
    align: 'left',
    text: 'Zone 1',
    symbolWidth: 20
  },
  yAxis: [
    // {
    //   // allowDecimals: true,
    //   // type: 'linear',
    //   // type: 'logarithmic',
    //   min: 0.1,
    //   max: 100,
    //   title: {
    //     text: '<b style="font-size: 18px;">mol %</b>'
    //   },
    //   keys: ['name', 'custom.x', 'y', 'custom.stdv']
    // },
    {
      // labels: {
      //   enabled: false
      // },
      // allowDecimals: true,
      type: 'logarithmic',
      title: {
        text: '<b style="font-size: 18px;">mol %</b>'
      },
      keys: ['name', 'custom.x', 'y', 'custom.stdv'],
      min: 0.1,
      max: 100,
      tooltip: {
        enabled: false
      }
    }
  ],
  tooltip: {
    pointFormat: (
      '<b>{point.series.name}</b>: <br/><b>%mol:</b> {point.options.y} - <b>stdv:</b> {point.options.custom.stdv}'
    )
  },
  series: customSeries,
  credits: {
    enabled: false
  }
});
