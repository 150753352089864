import get from 'lodash.get';

import * as ACTIONS from '../constants/wells';
import { createReducer } from './createReducer';
import { v4 as uuidV4 } from 'uuid';
import { uniqBy } from 'lodash';

const initialState = {
  items: [],
  wellsWithPathFile: [],
  currentWell: null,
  selectedWell: -1,
  depthData: [],
  wellOptions: [],
  selectedDepthIndex: null,
  beforeWell: null,
  filteredData: null,
  storeCutoff: false,
  metadata: undefined,
};

const generateWellOption = (wellName, wellId) => ({
  label: wellName,
  value: wellId
});

function filterWell(state, action) {
  const { wellId } = action;
  const filteredWell = state.items.find(well => {
    if (well.wellId === wellId) {
      return true;
    }
    return false;
  });
  return {
    ...state,
    selectedWell: get(filteredWell, 'wellId', get(filteredWell, 'well_uid', -1)),
    currentWell: filteredWell || null,
    wellOptions: filteredWell ? state.wellOptions : [],
    depthData: filteredWell ? state.depthData : [],
    beforeWell: filteredWell || null
  };
}

function receiveWell(state, action) {
  let { well } = action;

  const currentWell = state.items.find(w => w.uid === well.well_uid);
  const { well_uid: wellUid } = well;

  let wellOptions = state.wellOptions;

  let items = state.items;
  if (currentWell) {
    items = items.map(w => {
      if (w.uid === wellUid) {
        return { ...well, ...w };
      }
      return w;
    });
  } else {
    // when crating well that well options is not listed we need to push the new value into the wells list
    items = [...items, well];
    wellOptions = items.map(wellValue => {
      const wellName = get(wellValue, 'name', get(state.metadata, 'name', wellValue.uid));
      const wellOption = generateWellOption(wellName, wellValue.uid);
      return { ...wellOption, wellId: wellValue.uid };
    });
  }

  return {
    ...state,
    items,
    selectedDepthIndex: state?.selectedDepthIndex || 0,
    wellOptions,
    currentWell: {
      ...currentWell,
      ...well,
      statistics: [],
      ethene_sensor: state?.metadata?.ethene_sensor
    },
    selectedWell: wellUid,
    currentWellKey: wellUid,
    beforeWell: { ...currentWell, ...well, statistics: [] }
  };
}

function receiveWells(state, action) {
  const { wells } = action;
  const wellOptions = wells.map(well => {
    const wellName = get(well, 'name', get(state.metadata, 'name', well.uid));
    const wellOption = generateWellOption(wellName, well.uid);
    return { ...wellOption, wellId: well.wellId };
  });
  const formattedOptions = wells.map(w => ({ ...w, wellId: w.uid, wellName: w.name }));
  return {
    ...state,
    items: formattedOptions,
    wellOptions,
    wellsWithPathFile: formattedOptions
  };
}

function deleteWell(state, action) {
  const { wellId } = action;
  const items = state.items.filter(well => well.wellId !== wellId);
  const wellOptions = state.wellOptions.filter(well => well.value !== wellId);
  return {
    ...state,
    items,
    wellOptions,
    selectedWell: -1,
    currentWellKey: null,
    depthData: [],
    currentWell: null
  };
}

function receiveDepthData(state, action) {
  return {
    ...state,
    depthData: action.data
  };
}

function receiveMetadata(state, action) {
  const lat_long_coordinates = {
    // degrees_lat: 80,
    // minutes_lat: 50,
    // seconds_lat: 5,
    // degrees_long: 120,
    // minutes_long: 50,
    // seconds_long: 50,
    // reference: 'WGS 84',
    degrees_lat: '',
    minutes_lat: '',
    seconds_lat: '',
    degrees_long: '',
    minutes_long: '',
    seconds_long: '',
    reference: ''
  };

  const utm_coordinates = {
    x_value: '',
    y_value: '',
    zone: '',
    reference_zone: ''
  };

  const top = action.metadata?.log_sets?.[0]?.start_depth;
  const base = action.metadata?.log_sets?.[0]?.stop_depth;
  // TODO remove this mock
  const well_phases = [];
  // { 'top': top, 'base': base, 'diameter': 8.5 }

  const normalizeMetadata = {
    ...action.metadata,
    // lat_long_coordinates: undefined,
    lat_long_coordinates: lat_long_coordinates,
    utm_coordinates: utm_coordinates,
    well_phases: well_phases
  };

  return {
    ...state,
    currentWell: {
      ...(state.currentWell || {}),
      log_sets: action?.metadata?.log_sets,
    },
    metadata: normalizeMetadata
  };
}

function updateWell(state, action) {
  const { well } = action;
  const items = state.items.map(item => item.wellId === state.selectedWell ? well : item);
  const wellOptions = state.wellOptions.map(item => item.value === well.wellId
    ? generateWellOption(well.wellName, well.wellId) : item
  );
  return {
    ...state,
    items,
    currentWell: well,
    beforeWell: well,
    wellOptions
  };
}

function receiveSelectedDepthIndex(state, action) {
  return {
    ...state,
    selectedDepthIndex: action.index
  };
}

function receiveStatistics(state, action) {
  const statisticsInfo = action.statistics;

  const statistics = statisticsInfo.reduce((forms, formData) => {
    if (formData.curves && formData.curves[0]) {
      const formName = `formCollapse${formData.curves[0].includes('Composition') ? formData.curves[0] : formData.curves[0].toUpperCase()}`;
      forms.forms.push({
        formName,
        ...formData,
        ...formData.curveInfo,
        validFormsCurves: [...forms.curves, formData.curves[0]]
      });
      forms.curves = [...forms.curves, formData.curves[0]];
      forms.rangeMin = formData.rangeMin;
      forms.rangeMax = formData.rangeMax;
      forms.forms.id = formData.id;
    }
    return forms;
  }, { forms: [], rangeMin: 0, rangeMax: 0, curves: [], validFormsCurves: [] });

  return {
    ...state,
    currentWell: {
      ...state.currentWell,
      originalStatistics: {
        ...statistics.forms,
        rangeMin: statistics.rangeMin,
        rangeMax: statistics.rangeMax,
        curves: statistics.curves
      },
      statisticsId: uuidV4(),
      statistics: statistics.forms || []
    }
  };
}

function receiveStatisticsData(state, action) {
  const statisticsData = action.statistics;
  const data = action.data;
  const wellId = action.wellId;

  return {
    ...state,
    currentWell: {
      ...state.currentWell,
      statistics: statistics || []
    }
  };
};

function receiveSelectedMinMax(state, action) {
  const { min, max } = action.data;

  return {
    ...state,
    currentWell: {
      ...state.currentWell,
      selectedMin: min,
      selectedMax: max,
    }
  }
}

function receiveFilteredData(state, action) {
  const {
    filteredData,
    storeCutoff
  } = action;

  return {
    ...state,
    filteredData,
    storeCutoff,
  }
}

function receiveLogsetGqc(state, action) {

  const gqcData = action.gqc || { data: [] };
  const gqcObject = gqcData?.data.reduce((acc, row) => {
    acc[row.type] = row;
    return acc;
  }, {});
  return {
    ...state,
    currentWell: {
      ...state.currentWell,
      gqc: action.gqc,
      gqcData: gqcObject,
    }
  }
}

function receiveCriticalAreas(state, action) {
  return {
    ...state,
    currentWell: {
      ...state.currentWell,
      ...(action.criticalAreas || {}),
    }
  }
}

function updateMetadata(state, action) {
  return {
    ...state,
    metadata: action.metadata,
  };
}

function updateCurrentWellData(state, action) {
  return {
    ...state,
    currentWell: {
      ...state.currentWell,
      ...(action.well || {}),
    }
  }
}

function receiveQualityDataChart(state, action) {
  return {
    ...state,
    currentWell: {
      ...state.currentWell,
      calculated: {
        ...state.currentWell.calculated,
        qualityData: action.data,
      }
    }
  };
}

export default createReducer(initialState, {
  [ACTIONS.UPDATE_CURRENT_WELL_DATA]: updateCurrentWellData,
  [ACTIONS.RECEIVE_CRITICAL_AREAS]: receiveCriticalAreas,
  [ACTIONS.UPDATE_METADATA]: updateMetadata,
  [ACTIONS.RECEIVE_LOGSET_GQC]: receiveLogsetGqc,
  [ACTIONS.RECEIVE_METADATA]: receiveMetadata,
  [ACTIONS.FILTERED_DATA]: receiveFilteredData,
  [ACTIONS.FILTER_WELL]: filterWell,
  [ACTIONS.RECEIVE_WELL]: receiveWell,
  [ACTIONS.RECEIVE_WELLS]: receiveWells,
  [ACTIONS.DELETE_WELL]: deleteWell,
  [ACTIONS.REGISTER_DEPTH_DATA]: receiveDepthData,
  [ACTIONS.UPDATE_WELL]: updateWell,
  [ACTIONS.RECEIVE_SELECTED_INDEX]: receiveSelectedDepthIndex,
  [ACTIONS.RECEIVE_STATISTICS]: receiveStatistics,
  [ACTIONS.RECEIVE_SELECTED_MIN_MAX]: receiveSelectedMinMax,
  [ACTIONS.RECEIVE_QUALITY_DATA]: receiveQualityDataChart
});
