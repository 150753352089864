import { useEffect, useState } from 'react';
import store from 'store';
import * as wellActions from '../../actions/wells';
import * as zoneActions from '../../actions/zone';
import { useSelector, useDispatch } from 'react-redux';
import { formatZones } from '../../utils/Zones';
import { map } from 'lodash';
import {
  applyZoneToCharts,
} from '@geowellex/shared-zone/src/utils/applyZoneToCharts';
import Highcharts from 'highcharts';
import {
  removeAllZones
} from '@geowellex/shared-zone/src/global-utils/Zones';

/**
 * This component provides a hook that makes it possible to update Home page after
 * someone adding a zone using crossplot window.
 * We will check and fetch the wells with zone_sets to update the zone list on home page.
*/
export const ListWellAfterFocus = () => {
  const [wellsList, setWells] = useState<undefined | []>();
  const dispatch = useDispatch();
  // @ts-ignore
  const { currentWell, wellsWithPathFile } = useSelector(state => state?.wells);
   // @ts-ignore
  const zonesState = useSelector(state => state?.zone);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (currentWell && currentWell?.wellId && store.get('close-fluid-type') === true) {
        const wells = await dispatch(wellActions.listWells());
        // @ts-ignore
        const selectedWell = (wells || []).find((w: any) => w?.uid === currentWell?.wellId);
        const currentZones = zonesState.items || [];
        const zoneData = formatZones(selectedWell?.zone_sets);

        if (!wellsList !== selectedWell.length) {
          setWells(selectedWell?.zone_sets);
          map(currentZones || [], (zone) => {
            removeAllZones(zone?.uuid, Highcharts);
          });
          dispatch(zoneActions.receiveZones(zoneData));
          map(zoneData, (zone: any) => applyZoneToCharts('total-gas')(zone, Highcharts?.charts as any));
          store.set('close-fluid-type', false);
        } else if (wellsList?.length && wellsList?.length !== selectedWell?.zone_sets.length) {
          console.log('else clear interval');
          setWells(selectedWell?.zone_sets);
          map(currentZones || [], (zone) => {
            removeAllZones(zone?.uuid, Highcharts);
          });
          dispatch(zoneActions.receiveZones(zoneData));
          map(zoneData, (zone: any) => applyZoneToCharts('total-gas')(zone, Highcharts?.charts as any));
          store.set('close-fluid-type', false);
        }
      }
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentWell, currentWell?.wellId, zonesState]);

  return null;
};
