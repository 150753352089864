import { Autocomplete, Popper } from "@mui/material";
import styled from "styled-components";

export const AutocompleteStyled = styled(Autocomplete)`
  && {
    .MuiOutlinedInput-root {
      background-color: #1F2937;
      padding: 8px;
      color: #FFFFFF;
      font-size: 16px;

      input {
        padding: 0;
      }

      svg {
        color: #FFFFFF;        
      }

      fieldset {
        border-color: #4B5563;
      }
      &.Mui-focused fieldset {
        border-color: #4B5563;
      }
      &:hover fieldset {
        border-color: #4B5563;
      }
    }
    .css-gdh49b-MuiAutocomplete-listbox {
      background-color: #1F2937 !important;
    }
    
  }
`

export const PopperStyled = styled(Popper)`
  && {
    .css-gdh49b-MuiAutocomplete-listbox {
      background-color: #1F2937;
      font-size: 16px;
      color: #FFFFFF;
      padding: 0;
      border-radius: 4px;

      .MuiAutocomplete-option{
        padding: 8px;
        min-height: auto;
      }

      .MuiAutocomplete-option.Mui-focused {
        background-color: #374151;
      }
    }
  }
`