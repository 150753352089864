import React from 'react';
import Modal from 'react-modal';
import close from '../../assets/close-white.svg';
import { modalStyleCreateWell } from '../../utils';
import './style.scss';

const modalStyleSensor = {
  content : {
    ...modalStyleCreateWell.content,
    background: 'rgb(47, 46, 54)',
    padding: 0
  },
  overlay: {
    ...modalStyleCreateWell.overlay,
    backgroundColor: 'transparent'
  }
}

const ModalSensorType = ({ openModal, closeModal }) => {
  const closeSensor = () => closeModal(false);
  return (
    <Modal
      isOpen={openModal}
      style={modalStyleSensor}
    >
      <div className="wrapper-modal-sensor">
        <div className="header-FID-MS">
          <div>FID and MS Sensor</div>
          <div>
            <img
              src={close}
              alt="close"
              onClick={closeSensor}
              className="icon-close-modal-sensor"
            />
          </div>
        </div>
        <div className="info-modal-sensor">
          <div>
            <label className="text-sensor-weight">FID</label> is a flame ionization
            detector sensor connected to a gas chromatography system. 
          </div>
          <div>
            An FID generally uses a hydrogen/air flame through which the sample
            passes to oxidize organic molecules and produces electrically charged
            particles (ions). The ions are collected and produce an electrical
            signal that is then measured.
          </div>
          <div className="info-modal-ms">
            <label className="text-sensor-weight">MS</label> is an equipment
            that applies a physical-chemical procedure that characterizes
            molecular structures through ionization techniques. For chromatography,
            the chemical structure of the gases is ionized, so that they
            can be separated and, thus, detect the presence of different gases in a sample.
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalSensorType;
