import React from 'react';
import AlertStatisticsConcepts from '../AlertStatisticsConcepts';
import ModalStatisticsConcepts from '../ModalStatisticsConcepts';

const InstructionsMessage = () => (
  <ModalStatisticsConcepts
    render={(openModal) => (
      <AlertStatisticsConcepts
        openModal={openModal}
      />
    )}
  />
);

export default InstructionsMessage;
