import uuid from 'uuid/v1';

import {
  RECEIVE_WELL_PHASE,
  RECEIVE_WELL_PHASES,
  REMOVE_WELL_PHASE
} from '../constants/wellphases';
import { createReducer } from './createReducer';

const initialState = {
  items: [],
  lastVersion: [],
};

function receiveWellPhases(state, action) {
  const { wellphases } = action;
  return {
    ...state,
    lastVersion: !state.items.length ? state.items : state.lastVersion,
    items: wellphases.map(phase => ({ ...phase, uuid: uuid() }))
  }
}

function receiveWellPhase(state, action) {
  const { wellphase } = action;
  return {
    ...state,
    items: wellphase
  }
}

function removeWellPhase(state, action) {
  const { wellphase } = action;
  return {
    ...state,
    items: wellphase
  }
}

export default createReducer(initialState, {
  [RECEIVE_WELL_PHASES]: receiveWellPhases,
  [RECEIVE_WELL_PHASE]: receiveWellPhase,
  [REMOVE_WELL_PHASE]: removeWellPhase,
});
