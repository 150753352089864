import React from 'react';
import { CheckboxInput, CheckboxWrapper } from './CheckboxInput';
import { Controller } from 'react-hook-form';

export const CheckBoxDataGrid = ({ name, control, defaultChecked, afterChange }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field:  { value, ...field } }) => {
        return (
          <CheckboxWrapper>
            <CheckboxInput
              {...field}
              defaultChecked={defaultChecked || !!value?.checked}
              onChange={(e) => {
                field.onChange({ ...value, checked: e?.target?.checked });
                afterChange?.();
              }}
              checked={value?.checked}
              className="checkbox-matching-curves"
					  />
          </CheckboxWrapper>
        );
      }}
    />  
  );
};
