import axios from 'axios';
import store from 'store';

/*
  These functions are responsible to store, fetching one well or a list of wells, and syncing
  to google cloud functions to prevent the user from loose the current local well sites using
  wellex-gold.
  https://console.cloud.google.com/functions/details/us-central1/well
*/

const API_URL = 'https://prod.gateway.geowellex.com/wellex-gold/v2';

const resource = 'well-phases';

export function createWellPhases(data, token) {
  return axios.post(
    `${API_URL}/${resource}/`,
    data,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function getAllWellPhases(token, wellId) {
  return axios.get(
    `${API_URL}/${resource}/?wellId=${wellId}`,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function getWellPhase(token, wellPhasesId) {
  return axios.get(
    `${API_URL}/${resource}/${wellPhasesId}`,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function editWellPhases(data, wellPhaseId) {
  return axios.put(
    `${API_URL}/${resource}/${wellPhaseId}`,
    data,
    {
      headers: {
        'access-token': store.get('token'),
        'Content-type': 'application/json'
      }
    }
  );
}

export function deleteWellPhases(idWellPhase) {
  return axios.delete(
    `${API_URL}/${resource}/${idWellPhase}`,
    {
      headers: {
        'access-token': store.get('token'),
        'Content-type': 'application/json'
      }
    }
  );
}