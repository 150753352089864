import React, {
  useEffect,
  useState
} from 'react';

import NewWindow from 'react-new-window';
import { useSelector } from 'react-redux';
import store from 'store';
import uuid from 'uuid/v1';

const PlotBox = () => {
  const wells = useSelector(state => state.wells);

  // eslint-disable-next-line no-unused-vars
  const [stateType, setStateType] = useState(null);
  const [count, setCount] = useState(1);
  const [windows, setUpdate] = useState({
    0: null, 1: null, 2: null,
    3: null, 4: null, 5: null,
    6: null, 7: null, 8: null
  });

  useEffect(() => {
    const toggleWindows = event => {
      const { detail: { type, minLabel, maxLabel, wellId, pathFile } } = event;
      // prevent to open a empty chart when there is no well selected
      if (!wells.currentWell) {
        alert('Please select a well first!');
        return;
      }

      setStateType(type);
      const id = uuid();
      const newWindow = {
        id,
        type,
        pathFile
      };

      setUpdate({ ...windows, [count]: newWindow });
      setCount((count + 1));

      store.set('querys', {
        [id]: {
          type,
          minLabel,
          maxLabel,
          wellId,
          pathFile
        }
      });
    };

    document.addEventListener('plotBoxMolarAnalysis', toggleWindows);
    return () => {
      document.removeEventListener('plotBoxMolarAnalysis', toggleWindows);
    };

  }, [windows, wells.currentWell]);

  // @TODO create a function of onUnload and add it on the utils.js
  return (
    <div className="scatters-plot">
      {Object.keys(windows).length && Object.keys(windows).map((key) => {
        const currentWindow = windows[key];
        if (!currentWindow) {
          return null;
        }
        return (
          <div>
            <NewWindow
              key={key}
              url={`/molar-analysis/${currentWindow.id}--${currentWindow.type}/${wells.currentWell.wellId}`}
              features={{ left: 200, top: 200, width: 1600, height: 900 }}
              onUnload={() => {}}
            />
          </div>
        )
      })}
    </div>
  );
}

export default PlotBox;
