// url to fetch the zones
export const BASE_URL = 'https://prod.gateway.geowellex.com/wellex-gold/v2';

export const config = { auth: '' };

export const setupToken = (token: string) => config.auth = token;

export const allCharts: any = {
  instances: {
    gasComposition: null
  }
};

export const chartsToApplyZones = [
  'rop',
  'total-gas',
  'chromatography',
  'gas-composition',
  'balance-ratio',
  'reason-chart',
  'isotope-s13'
];
