import React from 'react';
import { ZoneListType } from '../../types/zone';
import { sortZones } from '../../utils/sort.ts';
import { List as ListAntd, Button, Skeleton, Tooltip } from 'antd';
import EmptyZoneState from '../EmptyZoneState/index.ts';
import { getUnit } from '../../utils/getUnit.ts';
import ListItem from '../ListItem/ListItem.tsx';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
export type sortZoneType = {
  zoneA: { top: string };
  zoneB: { top: string };
};

export const List = ({
  zones = [],
  isLoading,
  setFormViewMode,
  customActions,
  afterDelete,
  afterUpdate,
  isValidating,
}: ZoneListType) => {
  const sortedZonesByTop = sortZones(zones);
  return (
    <div className="ant-list">
      <ListAntd
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={sortedZonesByTop}
        loading={{tip: 'Loading', spinning: isLoading}}
        locale={{ emptyText: (
          !isLoading ? <EmptyZoneState setFormViewMode={setFormViewMode} /> : ''
        )}}
        renderItem={(item: any) => {
          const isDisabled =!item.label || isLoading || isValidating;
          return (
            <ListAntd.Item
              className="list-btn-edit"
              actions={[
                <div
                  className="zone-buttons" 
                >
                  {customActions.map((fnAction) => fnAction(item))}
                  <Tooltip title="Edit" key="edit">
                    <Button
                      type="link"
                      ghost
                      // @ts-ignore
                      icon={<EditOutlined />}
                      onClick={isDisabled ? () => {} : () => afterUpdate(item.uuid) }
                      data-cy="edit-zone-button"
                      className='list-btn'
                    />
                  </Tooltip>
                  <Tooltip title="Delete" key="delete">
                    <Button
                      type="link"
                      ghost
                      // @ts-ignore
                      icon={<DeleteOutlined />}
                      onClick={isDisabled ? () => {} : () => afterDelete(item)}
                      data-cy="remove-zone-button"
                      className='list-btn'
                    />
                  </Tooltip>
                </div>
              ]}
            >
             <Skeleton avatar title={false} loading={false} active>
                <ListItem item={item} unit={getUnit()} />
              </Skeleton>
            </ListAntd.Item>
          )
        }}
      />
    </div>
  );
};
