import React, { useEffect, useState, createContext, useContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import store from 'store';
import get from 'lodash.get';

const initialState = {
    isLoading: false,
    setIsLoading: () => undefined,
}

export const QualityControlContext = createContext(initialState)

export const QualityControlProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [wellInfo, setWellInfo] = useState();
    const [isQualityControlOn, setIsQualityControlOn] = useState(false);
    const [removeDataIntervals, setRemoveDataIntervals] = useState();

    const urlParams = location && location.search ? location.pathname.split('/') : '-1';
    const wells = useSelector(state => state.wells) || {};
    const currentWell = get(wells, 'currentWell', null);

    const dispatch = useDispatch();

    useEffect(() => {
        const selectedWellInfo = store.get('selectedWellInfo');
        if (selectedWellInfo && !wellInfo) {
            setWellInfo(selectedWellInfo);
        }
    }, [store.get('selectedWellInfo')])



    useEffect(() => {
        if(urlParams.includes('quality-control')){
            setIsQualityControlOn(true);
        }else{
            setIsQualityControlOn(false);
        }
    }, [urlParams])

    return (
        <QualityControlContext.Provider value={{
            isLoading,
            setIsLoading,
            isQualityControlOn,
            initialState,
            removeDataIntervals, 
            setRemoveDataIntervals
        }}>
            {children}
        </QualityControlContext.Provider>
    )
}

const useQualityControlContext = () => {
    const context = useContext(QualityControlContext)

    if (context === undefined) {
        throw new Error('useQualityControl must be used within a QualityControlProvider')
    }
    return context
}

export default useQualityControlContext;
