import React, { useState, useCallback, useMemo } from 'react';
import * as wellActions from 'actions/zone';
import * as wellActionsWells from 'actions/wells';
import { useDispatch, useSelector } from 'react-redux';
import { Zone, FluidTypingZone } from '@geowellex/shared-zone';
import { setupToken, allCharts } from '@geowellex/shared-zone/src/config/index';
import Highcharts from 'highcharts';
import ethene from '../../assets/ethene.svg';
import molIcon from '../../assets/mol_icon.svg';
import { BarChartOutlined, DotChartOutlined } from '@ant-design/icons';
import store from 'store';
import classNames from 'classnames';
import { Tabs } from 'antd';
import { defaultNotification } from '../../utils';
import Nav from '../Nav';
import CutOffs from '../CutOffs';
import BackIcon from './BackIcon';
import { setupZones } from '@geowellex/shared-zone/src/utils/setup';
import { openCrossPlots, openRelativeEthene, openMolarAnalysis } from '@geowellex/shared-zone/src/utils/crossplots';
import { applyZoneToCharts } from '@geowellex/shared-zone/src/utils/applyZoneToCharts';
import { useZones } from '@geowellex/shared-zone/src/hooks/useApi';
import { findDepthIndexValueToApplyInPlotBand, normalizedBottomAndTop } from '@geowellex/shared-zone/src/utils/submitZone';
import { removeAllZones } from '@geowellex/shared-zone/src/global-utils/Zones';

import { Input } from '@geowellex/shared-input';
import { useLocation } from 'react-router-dom';
import { addUuidOnlyInOneZone, formatZones } from '../../utils/Zones';

const { TabPane } = Tabs;

export const SwipeRightNav = ({ wellId }) => {
  const location = useLocation();
  const token = store.get('token');
  setupZones(token);
  setupToken(token);

  const {
    deleteAndMutateZone,
    insertNewZone,
    updateAndMutateZone
  } = useZones(wellId, token);
  const dispatch = useDispatch();
  const { currentWell: currentWellInfo, items: wells } = useSelector(state => state.wells);
  const { zone } = useSelector(state => state);
  const [isDisplayEtheneAlert, setDisplayEtheneAlert] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);
  const zoneData = zone.items;
  const [isLoadingZones, setLoadingZones] = useState(false);

  const submitZone = useCallback((currentWell, allZones) => async (values, isUpdate) => {
    setLoadingZones(true);
    if (!values) {
      setLoadingZones(false)
      alert('Top and bottom are required fields.');
      return;
    }

    if (currentWell && !currentWell.well_uid) {
      setLoadingZones(false)
      alert('Please select a well first!');
      return;
    }

    const depth = currentWell.RAW.depth.data;
    let { bottomXValue, topXValue } = normalizedBottomAndTop(values, depth);

    /*
      * when the well there have acquisitions steps values they have a decimal or in specific
      * ranges, we need to round it to get some close value
    */
    if (!bottomXValue && !bottomXValue.value && !bottomXValue.value.y) {
      bottomXValue = findDepthIndexValueToApplyInPlotBand(values.bottom, depth);
    }

    if (!topXValue && !topXValue.value && !topXValue.value.y) {
      topXValue = findDepthIndexValueToApplyInPlotBand(values.top, depth);
    }

    if (!bottomXValue && !topXValue) {
      setLoadingZones(false)
      alert('Please input valid top and bottom values.');
      return;
    }


    const newZone = {
      ...values,
      top: Number(values?.top?.split(' ')[0]),
      bottom: Number(values?.bottom?.split(' ')[0]),
      bottom_depth_index: bottomXValue.value.index,
      top_depth_index: topXValue.value.index,
      zone_type: "",
      zone_plot_settings: {
        border_color: "blue",
        border_width: "1",
        color: {
          r: values.zone_plot_settings.color.r.toString(),
          g: values.zone_plot_settings.color.g.toString(),
          b: values.zone_plot_settings.color.b.toString(),
          a: values.zone_plot_settings.color.a.toString()
        }
      }
    };

    if (allZones.length > 0) {
      if (isUpdate) {
        // Updating
        const newAllZones = allZones.map((zoneValues) => zoneValues.uuid === newZone.uuid ? newZone : zoneValues);
        const updatedZone = {
          well_uid: currentWell.well_uid,
          zone_set_name: 'gold-zones',
          zones: newAllZones
        }
        try {
          await updateAndMutateZone(currentWell.well_uid, updatedZone, store.get('token'))
          applyZoneToCharts('total-gas')(newZone, Highcharts.charts);
          dispatch(wellActions.receiveZones(newAllZones));
          defaultNotification('Zone updated successfully');
          setLoadingZones(false)
        } catch(e) {
          console.log(`error when update zone ${newZone.label} ==>`, e);
          setLoadingZones(false)
        }
      } else {
        // Creating when zone set exist
        // Uuid is necessary because of the charts
        const newZoneWithUuid = addUuidOnlyInOneZone(newZone);
        const updatedZone = {
          well_uid: currentWell.well_uid,
          zone_set_name: 'gold-zones',
          zones: [...allZones, newZoneWithUuid]
        }
        try {
          await updateAndMutateZone(currentWell.well_uid, updatedZone, store.get('token'))
          applyZoneToCharts('total-gas')(newZoneWithUuid, Highcharts.charts);
          dispatch(wellActions.receiveZone(newZoneWithUuid));
          defaultNotification('Zone created successfully', 'Try to see the curves');
          setLoadingZones(false);
        } catch(e) {
          console.log(`error when create zone ${newZoneWithUuid.label} ==>`, e);
          setLoadingZones(false)
        }
      }
      return;
    }

    // Creating when zone set not exist
    const afterCreate = (newWell) => {
      const zoneSets = newWell.content.well_metadata.zone_sets;
      const lastZoneFormatted = formatZones(zoneSets.slice(-1));
      const lastZone = lastZoneFormatted[0];

      applyZoneToCharts('total-gas')(lastZone, Highcharts.charts);
      dispatch(wellActions.receiveZone(lastZone));
    };

    const uid = store.get('email');
    const username = store.get('preferred_username');
    const zoneCreated = {
      well_uid: currentWell.well_uid,
      zone_set_name: 'gold-zones',
      zones: [newZone],
      user: {
        uid,
        username,
        role: "none"
      }
    }

    insertNewZone(zoneCreated, afterCreate).then(() => {
      defaultNotification('Zone created successfully', 'Try to see the curves');
      setLoadingZones(false)
    }).catch((e) => {
      console.log(`error when create zone ${newZone.label} ==>`, e);
      setLoadingZones(false);
    })
  }, [currentWellInfo?.wellId]);

  const removeZone = useCallback((currentWell, allZones) => async (zoneRemoved) => {
    setLoadingZones(true);
    const wellUid = currentWell.well_uid;
    const { uuid: zoneUuid, label: zoneLabel } = zoneRemoved;
    if (allZones.length === 1) {
      try {
        await deleteAndMutateZone('gold-zones', wellUid);
        removeAllZones(zoneUuid, Highcharts);
        dispatch(wellActions.receiveZones([]));
        defaultNotification('Zone removed successfully');
        setLoadingZones(false);
      } catch(e) {
        console.log('error deleting zones');
      }
      return;
    }

    const newZoneData = allZones.filter(zoneValues => zoneValues.uuid !== zoneUuid);
    const zoneUpdateData = {
      well_uid: wellUid,
      zone_set_name: 'gold-zones',
      zones: newZoneData
    }

    updateAndMutateZone(wellUid, zoneUpdateData, store.get('token')).then(() => {
      removeAllZones(zoneUuid, Highcharts);
      dispatch(wellActions.receiveZones(newZoneData));
      defaultNotification('Zone removed successfully');
      setLoadingZones(false);
    }).catch((e) => {
      console.log(`error when removing zone ${zoneLabel} ==>`, e);
      setLoadingZones(false);
    })
  });

  const displayNoEtheneData = () => {
    setDisplayEtheneAlert(true);
  };

  const closeEtheneAlert = () => {
    setDisplayEtheneAlert(false);
  };

  const customCallbackActions = useMemo(() => {
    return [
    [
      openCrossPlots(
        'balanceRatioToggle',
        zoneData || [],
        currentWellInfo,
        wells
      ),
      'Fluid Type',
      {
        className: '',
        icon: <BarChartOutlined style={{ fontSize: '20px', color: '#fff' }} />,
        children: ''
      }
    ],
    [
      openCrossPlots(
        'C1C2SF',
        zoneData || [],
        currentWellInfo,
        wells
      ),
      'Geochemistry',
      {
        className: '',
        icon: <DotChartOutlined style={{ fontSize: '20px', color: '#fff' }} />,
        children: ''
      }
    ],
    [
      openMolarAnalysis(
        'molarAnalysis',
        zoneData || [],
        currentWellInfo,
        wells
      ),
      'Molar Analysis',
      {
        className: '',
        icon: <img className="img-molar-analysis" src={molIcon} alt="Molar Analysis" />,
        children: ''
      }
    ],
    [
      currentWellInfo && !currentWellInfo?.ethene_sensor?.has_sensor ? displayNoEtheneData : openRelativeEthene(
        'relativeEthene',
        zoneData || [],
        currentWellInfo,
        wells
      ),
      'Relative Ethene',
      {
        className: '',
        icon: <img className="img-ethene" src={ethene} alt="Ethene" />,
        children: ''
      }
    ]
    ];
  }, [currentWellInfo, zoneData, wells]);

  if (location.pathname === '/home/statistics') {
    return null;
  }

  const afterRevalidate = (newZoneItems) => {
    newZoneItems.forEach((z) => {
      applyZoneToCharts('total-gas')(z, Highcharts.charts);
    })
    dispatch(wellActions.receiveZones(newZoneItems));
  };

  return (
    <Nav
      className={classNames(
        { 'swipe-right__nav': 'swipe-right__nav' },
        { 'swipe-right--expanded': !!expanded }
      )}
    >
      <div className="swipe-right__nav-wrapper">
        {/* <Input /> */}
        <button
          type="button"
          className="swipe-right__button"
          onClick={toggleExpanded}
          data-cy="swipe-right__button"
        >
          <BackIcon className={`swipe-right__button-icon ${expanded ? 'mirror' : ''}`} />
        </button>

        <div className="swipe-right__row" style={{ width: 800, height: 1200, overflow: 'scroll' }}>
          <div className="wrapper-tabs">
            <Tabs defaultActiveKey="1" onChange={() => {}} className="tabs-ant">
              <TabPane tab="ZONES" key="1" className="tab__tab-pane">
                <Zone
                  isLoadingZones={isLoadingZones}
                  currentWell={currentWellInfo}
                  submitZone={submitZone(currentWellInfo, zoneData)}
                  afterDelete={removeZone(currentWellInfo, zoneData)}
                  customCallbackActions={customCallbackActions}
                  isThereNoSensorType={isDisplayEtheneAlert}
                  closeEtheneAlert={closeEtheneAlert}
                  afterRevalidate={afterRevalidate}
                  zoneData={zoneData}
                />
              </TabPane>
              {/* <TabPane tab="CUTOFFS" key="2" className="tab__tab-pane">
                <CutOffs />
              </TabPane> */}
            </Tabs>
          </div>
        </div>
      </div>
    </Nav>
  );
};

export default SwipeRightNav;
