import React, { Component } from 'react';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { Button } from 'antd';
import ReactSvg from 'react-svg';
import { rotateElement , startDragAndResize } from '../../../utils';
import { Logs, ADD_BOX } from '../../../utils/EditorBoxArea';
import undoImage from '../../../assets/undo.svg';
import rotateImage from '../../../assets/rotate.svg';
import rotatingIcon from '../../../assets/rotating.svg';
import { drawPath, drawPathWithPadding } from '../../../utils/Svg';
import './style.scss';

Exporting(Highcharts);

const P1X2 = 0;
const P1Y2 = 1.5;

const P2X3 = 7;
const P2Y3 = 1.5;

const P3X1 = 7;
const P3Y1 = 0.5;

const P4X1 = 0;
const P4Y1 = 0.5;

const P5X = 0;
const P5Y = 1.8;

const P6X = 10;
const P6Y = 1.8;

const P7X = 10;
const P7Y = 0.5;

const P8X = 10;
const P8Y = 5.0;

const P9X = 40;
const P9Y = 5.0;

const P10X = 40;
const P10Y = 1.8;

// Oil area
const drawOil = chart => drawPathWithPadding(0)(
  P4X1, P4Y1,
  P1X2, P1Y2,
  P2X3, P2Y3,
  P3X1, P3Y1,
  chart,
  {
    'fill': '#d1dabc',
    'stroke': '#718656',
    'stroke-width': 2,
    'opacity': '0.5'
  },
  true
);
 
// Gas area
const drawGas = (chart) => drawPath(
  P6X, P6Y,
  P8X, P8Y,  
  P9X, P9Y,
  P10X, P10Y,
  chart,
  {
    'fill': '#f1a7a7',
    'stroke': '#f15555',
    'stroke-width': 2,
    'opacity': '0.5'
  }
);

// Transition area
const drawTransition = (chart) => drawPath(
  P4X1, P4Y1,
  P5X, P5Y,  
  P6X, P6Y,
  P7X, P7Y,
  chart,
  {
    'fill': '#f4c094',
    'stroke': '#ed713e',
    'stroke-width': 2,
    'opacity': '0.4'
  }
);


function Box({ id, zIndex, type, rotating }) {
  return (
    <div
      id={`box${id}`}
      className={`drag drag-${id} ${type}`}
      style={{ zIndex }}
      draggable="true"
      data-index={id}
    >
      <div
        className="handle-rotate rotate-1"
      >
        {rotating && <ReactSvg src={rotatingIcon} />}
      </div>
      <div className="box-content">{type}</div>
      <div
        id={`handle-${id}`}
        className="handle"
      >
        <div className="box-resize-square" />
      </div>
    </div>
  );
}

class ScatterPlotC1ByC2 extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.count = 0;
    this.selected = null;
    this.currentSelected = null;
    this.state = {
      boxes: [],
      rotating: null,
      selected: null
    };
  }

  componentDidMount() {
    // TODO create a hook here
    // document.querySelector('.box-editor-area').addEventListener('click', event => {
    //   event.preventDefault();
    //   const element = event.target;
    //   const id = element.dataset.index;
    //   /* eslint-disable */
    //   const { boxes, rotating, selected } = this.state;
    //   if (id) {
    //     this.setState({
    //       selected: `.drag-${id}`
    //     }, () => {
    //       // boxes.length && boxes.forEach((el, index) => {
    //       //   const currentElement = document.querySelector(`.drag-${index}`);
    //       //   if (currentElement) {
    //       //     currentElement.style.boxShadow = 'none';
    //       //   }
    //       //   if (parseInt(id) === index && !rotating) {
    //       //     currentElement.style.boxShadow = 'rgba(0, 0, 0, 0.6) 0px 19px 38px, rgba(0, 0, 0, 0.40) 0px 15px 12px';
    //       //   }
    //       // });
    //       // if (rotating && this.currentSelected) {
    //       //   this.currentSelected.stopResizing();
    //       // } else if (!rotating && id) {
    //       //   this.currentSelected = startDragAndResize(`box${id}`, `handle-${id}`);
    //       // }
    //     });
    //   } else {
    //     boxes.length && boxes.forEach(() => {
    //       const currentElement = document.querySelector(selected);
    //       if (currentElement) {
    //         currentElement.style.boxShadow = 'none';
    //       }
    //     });
    //   }
    // });
    this.createScatterPlot();
    this.drawTransition = () => drawTransition(this.chart);
    this.drawOil = () => drawOil(this.chart);
    this.drawGas = () => drawGas(this.chart);

    // Transition area
    this.chart.transition = this.drawTransition();
    // Oil
    this.chart.oil = this.drawOil();
    // Gas area
    this.chart.gas = this.drawGas();

    const { idListener } = this.props;

    document.addEventListener(`${idListener}-chart3`, ({ detail }) => {
      const { config } = detail;
      const {yAxis, xAxis} = this.chart;
      if (yAxis && yAxis[0]) {
        yAxis[0].update({
          min: config.y.min,
          max: config.y.max,
          title: {
            text: config.y.title
          }
        });
      }
      if (xAxis && xAxis[0]) {
        xAxis[0].update({
          min: config.x.min,
          max: config.x.max,
          title: {
            text: config.x.title
          }
        });
      }

      this.chart.oil.destroy();
      this.chart.oil = this.drawOil();

      this.chart.gas.destroy();
      this.chart.gas = this.drawGas();

      this.chart.transition.destroy();
      this.chart.transition= this.drawTransition();

      // this.setState({
      // isVisible: config.isVisible
      // });
    });
  }

  createScatterPlot = () => {
    const { id, data } = this.props;

    const getTooltipWithDepth = (dataWithDepth = []) => ({
      headerFormat: '<b>{series.name}</b><br>',
      // pointFormat: `x: {point.x}, y: {point.y}`,
      formatter: function() {
        const itemWithDepth = dataWithDepth?.find(
          value => value?.x === this?.x && value?.y === this?.y
        );
        return `
          <b>Depth</b>: ${itemWithDepth?.depth}<br/>
          <b>(C1 / C2)</b>: ${itemWithDepth?.x?.toFixed(2)}  <br/>
          <b>(SF C2-5 ppm)</b>: ${itemWithDepth?.y?.toFixed(2)}
        `;
      },
      enabled: true
    });

    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'scatter'
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      credits: {
        enabled: false
      },
      fillOpacity: 1,
      opacity: 1,
      exporting: { enabled: false },
      xAxis: {
        title: {
          enabled: true,
          text: '<b>C1/C2</b>'
        },
        startOnTick: true,
        endOnTick: true,
        type: '',
        showLastLabel: true,
        pointStart: 0.1,
        tickInterval: 5,
        max: 40,
        min: 0.1,
        allowDecimals: true,
        gridLineWidth: 0,
        gridLineColor: 'blue'
      },
      yAxis: {
        title: {
          text: '<b>SF C2-5(ppm)</b>'
        },
        allowDecimals: true,
        tickInterval: 0.5,
        max: 5,
        min: 0.5,
        gridLineWidth: 0,
        lineWidth: 1,
        lineColor: '#ccd6eb'
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: -14,
        floating: true,
        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
        borderWidth: 0
      },
      plotOptions: {
        scatter: {
          marker: {
            lineWidth: 2,
            radius: 5,
            lineColor: 'rgba(255, 160, 97, 0.98)',
            states: {
              hover: {
                enabled: false
              }
            }
          },
          states: {
            hover: {
              marker: {
                enabled: false
              }
            }
          },
          // tooltip: {
          //   headerFormat: '<b>{series.name}</b><br>'
          // }
        }
      },
      tooltip: getTooltipWithDepth(this?.props?.dataWithDepth),
      series: [
        {
          name: 'C2',
          color: 'rgba(119, 152, 191, 0.5)',
          data,
          marker: {
            widthLine: 1,
            fillColor: 'rgba(255,224,1)',
            symbol: 'circle',
            width: 1,
            height: 1,
            hover: false,
            radius: 2.5,
            states: {
              hover: {
                enabled: false,
                lineColor: 'rgba(255, 160, 97, 0.98)'
              }
            }
          }
        },
        {
          name: 'C1',
          color: 'rgba(119, 152, 191, 0.5)',
          data,
          marker: {
            widthLine: 1,
            fillColor: 'rgba(255,224,1)',
            symbol: 'circle',
            width: 1,
            height: 1,
            hover: false,
            radius: 2.5,
            states: {
              hover: {
                enabled: false,
                lineColor: 'rgba(255, 160, 97, 0.98)'
              }
            }
          }
        }
      ]
    });
  }

  activateRotation = () => {
    // Access the current element selected and disable the resize and drag
    this.setState(state => {
      if (state.currentSelected) {
        state.currentSelected.stopResizing();
      }
      return {
        rotating: rotateElement(state.selected)
      };
    });
  }

  stopRotation = () => {
    // TODO review it when click to times broken
    const {rotating } = this.state;
    if (rotating && rotating.stopRotation) {
      rotating.stopRotation();
      this.setState({
        rotating: null
      });
    }
  }

  removeLastBox = () => {
    this.setState(({ boxes }) => {
      const newBoxes = boxes;
      newBoxes.pop();
      return {
        boxes: newBoxes
      };
    });
  }

  addBox = (type) => () => {
    const { boxes } = this.state;
    const id = this.count;

    // Add a box element here
    this.setState({
      boxes: [
        ...boxes,
        <Box
          key={id}
          id={id}
          zIndex={id}
          type={type}
        />
      ],
      selected: `.drag-${id}`
      // selectedIndex: id
    }, () => {
      // this.currentSelected = ;
      this.setState({
        currentSelected: startDragAndResize(`box${id}`, `handle-${id}`)
      });
    });

    Logs.registerAction({
      [ADD_BOX]: `action-${id}`,
      undo: this.removeLastBox,
      reundo: this.addBox
    });

    // Second Part
    this.count += 1;
  }

  render() {
    const {
      id, openConfigureScale
    } = this.props;
    const { boxes, rotating } = this.state;

    return (
      <div>
        {/*  className="box-editor-container" */}
        {/* <div className="box-editor-base"> */}
          {/* <div className="box-editor-area">
            {[...boxes]}
          </div> */}
          {
            openConfigureScale && (
              <Button
                type="primary"
                shape="circle"
                icon="setting"
                size="large"
                className="toggle-crossplot-config"
                onClick={openConfigureScale}
              />
            )
          }
          <div
            id={id}
            className="scatter-chart deactivate-legend"
          />
        {/* </div> */}
        {/*<div className="box-actions">
          <button
            type="button"
            className="add-box btn-gas"
            onClick={this.addBox('gas')}
          >
          Gas
          </button>
          <button
            type="button"
            className="add-box btn-oil"
            onClick={this.addBox('oil')}
          >
          Oil
          </button>
          <button
            type="button"
            className="add-box btn-transition"
            onClick={this.addBox('transition')}
          >
          Transition
          </button>
          <button
            type="button"
            className="add-box rotate-button"
            style={{
              border: `1px solid ${rotating ? '#0d9cff' : '#ccc'}`
            }}
            onClick={rotating ? this.stopRotation : this.activateRotation}
          >
            <ReactSvg src={rotateImage} />
          </button>
          {Logs.historic.length > 0 && (
            <button
              type="button"
              className="add-box"
              onClick={Logs.undoLastAction}
            >
              <ReactSvg src={undoImage} />
            </button>
          )}
        </div>*/}
      </div>
    );
  }
}

ScatterPlotC1ByC2.defaultProps = {
  withMinorGridLines: false,
  className: 'scatter-plot-base',
  deactivateLegend: true
};

export default ScatterPlotC1ByC2;


      
