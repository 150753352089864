import React from 'react';
import classNames from 'classnames';
import { Select } from 'antd';
import { filterOption } from '../../utils';

const { Option } = Select;

const SelectWrapper = ({ children, id, className, classLabel, label }) => (
  <div
    className={
      classNames(
        'home__modal-group',
        { 'relative_ethene': !!(className && className === 'ethene') },
        { [className]: className }
      )}
  >
    <label
      htmlFor={id}
      className={
        classNames(
          'match-relative-label',
          { 'match-relative-label': classLabel }
        )
      }
    >
      {label}
    </label>
    {children}
  </div>
);

function SelectMnemonicSensorType({
  onChange, options, label, id, value, className, classLabel,
  selectWidth, disabled
}) {
  return (
    <>
      <SelectWrapper id={id} className={className} classLabel={classLabel} label={label}>
        <Select
          showSearch
          id={id}
          defaultValue={value || -1}
          value={value}
          style={{ width: selectWidth || 200 }}
          placeholder=""
          optionFilterProp="children"
          className={`cy-${className}`}
          onChange={onChange}
          onFocus={() => ({})}
          onBlur={() => ({})}
          onSearch={() => ({})}
          filterOption={(input, option) => filterOption(input, option)}
          disabled={disabled}
        >
          <Option value="-1" disabled style={{ color: '#ccc' }}>
            Select a mnemonic
          </Option>
          {options.map((option) => (
            <Option key={option.label} value={option.value}>{option.label}</Option>
          ))}
        </Select>
      </SelectWrapper>
    </>
  )
};

export default SelectMnemonicSensorType;

