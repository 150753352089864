import React, { useEffect, useState } from 'react';

import classes from 'classnames';
import Modal from 'react-modal';

import removeIcon from '../../assets/close.svg';
import geowellexCloud from '../../assets/cloud_geowellex.svg';
import inWellFile from '../../assets/inWellFile.svg';
import { useAuth } from '@geowellex/shared-login/src/hooks/useAuth';
import { modalStyleInWell } from '../../utils';
import TableSelectUserWells from './TableSelectUserWells';
import { Button } from 'antd';

import { styled } from 'styled-components';

const Title = ({ title, className, classTitle }) => (
  <h3 className={classes(
    'title-row',
    {
      [className]: !!className,
      [classTitle]: !!classTitle
    }
  )}
  >
    {title}
  </h3>
);

Title.defaultProps = {
  classTitle: 'modal-scale__title'
};

const ImportFileButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  padding: 10px;
  border: 1px solid #4F4F4F;
  border-radius: 5px;
  justify-content: space-between;
  background-color: #333;
  margin-right: 10px;
  &:hover {
    background-color:#4F4F4F;
    border: 1px solid #1890ff;
    transition: all ease-in-out 0.3s;
  }

`;

export const DataSourceModal = ({ isOpen, uploadFile, wellOptions, nextAction, changeInWellTableModal, selectedWellKey, onCloseModal }) => {
  const { checkInWellIsAllowed } = useAuth();
  const [selectedWells, setSelectedWell] = useState();

  const onSelectWell = (selectedRows) => {
    setSelectedWell(selectedRows);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyleInWell}
      onRequestClose={onCloseModal}
    >
      <div style={{ padding: '15px 40px', display: 'flex', borderBottom:'1px solid #333', justifyContent: 'space-between', alignItems:'center' }}>
        <h2 style={{ color: '#fff', fontSize: 22, margin: 0}}>Select Well</h2>
        <img
          onClick={onCloseModal}
          src={removeIcon}
          className="modal-scale__close"
          data-cy="close-modal"
          alt="close modal"
        />
      </div>
      <div
        className="modal-scale__form"
      >
        <div style={{ display: 'flex', justifyContent: 'flex-start' , marginTop: 10}}>
          <ImportFileButton onClick={uploadFile} >
            <img src={inWellFile} width="20" height="20" />
            <span>Import File</span>
          </ImportFileButton>
          {/* checkInWellIsAllowed()  */}
          <ImportFileButton 
            onClick={() => {
              onCloseModal();
              changeInWellTableModal();
            }}>
            <img src={geowellexCloud} width="20" height="20" />
            <span>Cloud Connection</span>
          </ImportFileButton>
        </div>
        <div>
          <TableSelectUserWells onSelectWell={onSelectWell} selectedWellKey={selectedWellKey} wellOptions={wellOptions} />
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Button
            style={{ background: '#333', color: '#fff', width: 100, height: 40 }}
            onClick={onCloseModal}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedWells}
            type="primary"
            style={{ width: 100, height: 40 }}
            onClick={() => nextAction(selectedWells?.uid)}
          >
            Next
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DataSourceModal;
