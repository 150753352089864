import React from 'react';
import { RGBColor, CustomPicker, CompactPicker, AlphaPicker } from 'react-color';

type Colour = RGBColor & { a : number };

type Props = {
  color?: Colour;
  onChangeColor?: any;
  onChangeOpacity?: any;
};

export const MyPicker = ({ onChangeOpacity, onChangeColor, color }: Props) => {
  return (
  <div className="color-picker-container">
    <div className="color-picker-compact">
      <CompactPicker
        color={color}
        onChangeComplete={onChangeColor}
      />
    </div>
    <div>
      <AlphaPicker
        color={color}
        onChange={onChangeOpacity}
      />
    </div>
  </div>
)};

export default CustomPicker(MyPicker);
