import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Select, Input, Button } from 'antd';
import { useFormik } from 'formik';
import { ErrorMessage } from '../../../utils';
import { curvesOptions } from './index';

const { Option } = Select;

const xAxisSchema = Yup.object().shape({
  min: Yup.number()
    .required('Required'),
  max: Yup.number()
    .required('Required')
  // boundaryMin: Yup.number()
    // .required('Required'),
  // boundaryMax: Yup.number()
    // .required('Required')
});

const XAxisForm = ({
  currentScaleConfig, currentCurve, onSubmitXAxis,
  onClose
}) => {
  const axis = currentScaleConfig.xAxis;
  const formik = useFormik({
    initialValues: {
      curve: axis.curve,
      // boundaryMin: axis.boundaryMin,
      // boundaryMax: axis.boundaryMax,
      min: axis.min,
      max: axis.max
    },
    enableReinitialize: true,
    onSubmit: onSubmitXAxis,
    validationSchema: xAxisSchema
  });

  // syncronize the select out of the form with this select
  useEffect(() => {
    formik.setFieldValue('curve', currentCurve);
  }, [currentCurve]);

  // type is min or max
  // const handleBoundaryMin = (handleChange, type) => (event) => {
  //   event.persist();
  //   const { value } = event.target;
  //   const updateBoundary = new CustomEvent('updateBoundary', { 
  //     detail: { value, type } });
  //   if (value) {
  //     window.dispatchEvent(updateBoundary);
  //   }
  //   handleChange(event);
  // };

  const { handleSubmit, handleChange, values, errors } = formik;

  return (
    <form onSubmit={handleSubmit}>
      <div className="box-inputs">
        <label>Curve</label>
        <div className="select-curve select-xAxis">
          <Select
            defaultValue={currentCurve}
            onChange={(v) => {
              formik.setFieldValue('curve', v);
            }}
            name="curve"
            value={formik.values.curve}
          >
            {curvesOptions.map(curve => (
              <Option className="option-select" value={curve.value}>{curve.label}</Option>
            ))}
          </Select>
        </div>
        <label>Min</label>
        <div className="text-min">
          <Input
            onChange={handleChange}
            name="min"
            type="number"
            placeholder="Min"
            step="0.1"
            value={values.min}
          />
          {errors && errors.min && <ErrorMessage message={errors.min} />}
        </div>
        <label>Max</label>
        <div className="text-max">
          <Input
            onChange={handleChange}
            name="max"
            type="number"
            placeholder="max"
            step="0.1"
            value={values.max}
          />
          {errors && errors.max && <ErrorMessage message={errors.max} />}
        </div>
        {/* <label>Boundary Line Min</label>
        <div className="text-line-min">
          <Input
            onChange={handleBoundaryMin(handleChange, 'min')}
            name="boundaryMin"
            placeholder="Min"
            value={values.boundaryMin}
          />
          {errors && errors.boundaryMin && <ErrorMessage message={errors.boundaryMin} />}
        </div>
        <label>Boundary Line Max</label>
        <div className="text-line-max">
          <Input
            onChange={handleBoundaryMin(handleChange, 'max')}
            name="boundaryMax"
            placeholder="Max"
            value={values.boundaryMax}
          />
          {errors && errors.boundaryMax && <ErrorMessage message={errors.boundaryMax} />}
        </div> */}
      </div>
      <div className="wrapper_buttons">
        <div className="button-ok">
          <Button type="primary" onClick={onClose}>Ok</Button>
        </div>
        <div className="button-apply">
          <Button htmlType="submit" type="primary">Apply</Button>
        </div>
      </div>
    </form>
  );
};

export default XAxisForm;
