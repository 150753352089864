import React from 'react';
import InstructionsMessage from '../InstructionsMessage';
import CollapsePanel from '../CollapsePanel';

const SecondStep = ({ values, curvesConfiguration }) =>  {
  return (
    <>
      <div className="statistic-modal-curves__content">
        <div className="wrapper-instruction-message">
          <div className="title-curve-configuration">Curves Configuration</div>
          <InstructionsMessage />
        </div>
        <div className="wrapper-collapse-panel">
          <CollapsePanel values={values} curvesConfiguration={curvesConfiguration} />
        </div>
      </div>
    </>
  );
}

export default SecondStep;
