import React, { useState } from 'react';
import Modal from 'react-modal';
import { Anchor, Menu } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { modalStyleCreateWell } from 'utils';
import close from 'assets/close-white.svg';
import clock from 'assets/clock.svg';
import { HashLink } from 'react-router-hash-link';

const { Link  } = Anchor;
const { SubMenu } = Menu;

const modalStyleSensor = {
  content : {
    ...modalStyleCreateWell.content,
    background: '#141414',
    padding: 0,
    height: '100vh',
    width: '522px'
  },
  overlay: {
    ...modalStyleCreateWell.overlay,
    backgroundColor: 'transparent'
  }
};

const ModalStatisticsConcepts = ({ render }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => setIsOpen(false);

  const openModal = () => setIsOpen(true);

  return (
    <>
      {render && render(openModal)}
      <Modal
        isOpen={isOpen}
        style={modalStyleSensor}
      >
        <Scrollbars
          renderTrackVertical={props => <div {...props} className="track-vertical"/>}
          renderThumbVertical={props =>
            <div {...props} className="custom-scrollbar custom-scrollbar-statistics" />}
        >
          <div className="header-statistics-concepts">
            <div className="header-statistics-concepts__wrapper">
              <span className="header-statistics-concepts__title">Concepts</span>
              <img
                onClick={closeModal}
                src={close}
                alt="close"
                className="header-statistics-concepts__close"
              />
            </div>
          </div>
        
          <div className="contents-statistics-concepts">
            <p className="contents-statistics-concepts__text">
              Explanation of the concepts applied in the statistics module aims to instruct
              on the operation of each step in the curve configuration process, seeking
              that the options meet the expected result.
            </p>

            <div className="contents-statistics-concepts__clock">
              <img src={clock} alt="clock"/>
                5 min to read
            </div>
        
            <div className="contents-statistics-concepts__summary">
              <span className="contents-statistics-concepts__summary__title">
                Summary
              </span>
              <Menu
                theme="dark"
                className="contents-statistics-concepts__menu"
                mode="inline"
                dire
              >
                <SubMenu
                  key="1"
                  title={
                    <Anchor
                      affix={false}
                      className="contents-statistics-concepts__menu--link"
                    >
                      <HashLink to="#moving-average"><div style={{ color: '#fff'}}>Moving Average</div></HashLink>
                    </Anchor>
                  }
                >
                  <Anchor
                    affix={false}
                    className="contents-statistics-concepts__menu--link contents-statistics-concepts__menu--sublink"
                  >
                    <div className="menu-content-achors">
                      <HashLink to="#simple-moving"><div className="menu-content__hasLink-achor">Simple Moving Average (SMA)</div></HashLink>
                      <HashLink to="#exponential-moving"><div className="menu-content__hasLink-achor">Moving Average (EMA)</div></HashLink>
                    </div>
                  </Anchor>
                </SubMenu>

                <Menu.Item key="2" className="contents-statistics-concepts__menu--item">
                  <Anchor affix={false} className="contents-statistics-concepts__menu--link">
                    <HashLink to="#window-size"><div style={{ color: '#fff'}}>Window Size</div></HashLink>
                  </Anchor>
                </Menu.Item>

                <Menu.Item key="3" className="contents-statistics-concepts__menu--item">
                  <Anchor affix={false} className="contents-statistics-concepts__menu--link">
                    <HashLink to="#sensitivity"><div style={{ color: '#fff'}}>Sensitivity</div></HashLink>
                  </Anchor>
                </Menu.Item>

                <SubMenu
                  key="4"
                  title={
                    <Anchor affix={false} className="contents-statistics-concepts__menu--link">
                      <HashLink to="#metrics"><div style={{ color: '#fff'}}>Metrics</div></HashLink>
                    </Anchor>
                  }
                >
                  <Anchor
                    affix={false}
                    className="contents-statistics-concepts__menu--link contents-statistics-concepts__menu--sublink"
                  >
                    <div className="menu-content-achors">
                      <HashLink to="#absolute-deviation"><div className="menu-content__hasLink-achor">Absolute Deviation</div></HashLink>
                      <HashLink to="#relative-difference"><div className="menu-content__hasLink-achor">Relative Difference</div></HashLink>
                    </div>
     
                  </Anchor>
                </SubMenu>
              </Menu>

            </div>
        
            <span className="contents-statistics-concepts__title" id="moving-average">
              Moving Average
            </span>
            <p className="contents-statistics-concepts__text">
              Moving Average is a statistical metric calculated with the objective of evaluating the trend of
              a set of data ordered in a series. Two types of moving average are notable: Simple Moving
              Average (SMA) and Exponential Moving Average (EMA). For the system, these are the base
              curves for applying metrics that aim to indicate critical points in the gas curves: points where,
              according to the parameterizations, significant variations in the behavior of the curve occur.
            </p>

            <span className="contents-statistics-concepts__subtitle" id="simple-moving">
              Simple Moving Average (SMA)
            </span>
            <p className="contents-statistics-concepts__text">
              SMA is characterized as the simple arithmetic mean between the data
              existing at a given depth and the slightly previous values for the same curve,
              considered within the window size (sample period for evaluating the average
              trend). In the application of this moving average, the tendency is that the
              calculated curve is less sensitive to the data closer to the analyzed depth
              and, therefore, more robust against noise in the curve (depending on the
              size of the window).
            </p>

            <span className="contents-statistics-concepts__subtitle" id="exponential-moving">
              Exponential Moving Average (EMA)
            </span>
            <p className="contents-statistics-concepts__text">
              The EMA is characterized, in essence, as a weighted moving average, giving
              greater weight to the closest data and less weight to the most distant data
              within the size of the evaluated window (sample size for the evaluation of
              the average trend). In the GOLD Software, weights are assigned from an
              inverse exponential function, which is adjusted according to the “Sensitivity”
              factor: The greater the Sensitivity, the greater the difference between the
              weights of a value on the curve and its predecessors. The lower the
              Sensitivity, the smaller the difference between these weights and, therefore,
              closer to the behavior of the Simple Moving Average (which assigns equal
              weights for all data within the evaluated window). The application of EMA
              encourages the calculated curve to follow more closely the instantaneous
              variations in the behavior of a curve.
            </p>

            <span className="contents-statistics-concepts__title" id="window-size">
              Window Size
            </span>
            <p className="contents-statistics-concepts__text">
              Determining the size of the window will define the amount of past and
              immediately preceding values ​​that the moving average will consider in its
              calculation for each point on the curve. The higher this value, the more data
              will be considered in the calculation, while a smaller window will consider
              only the closest data. For more extensive windows, called Long Averages,
              the calculated curve will be smoothed, weighting behaviors of wide ranges
              in the calculation of the average. For shorter windows, called Short
              Averages, the curve will more closely follow the momentary behavior of the
              series.
            </p>

            <span className="contents-statistics-concepts__title" id="sensitivity">
              Sensitivity
            </span>
            <p className="contents-statistics-concepts__text">
              The Sensitivity parameter is applied specifically to the Exponential Moving
              Average (EMA) model. Unlike the Simple Moving Average (SMA), which
              averages from the window size without weighting, the EMA assigns more
              significant weights to slightly earlier data on the curve. The adjustment
              factor for the behavior of these weights is weighted according to a
              parameter named {"Sensitivity"}: The greater the sensitivity, the greater the
              discrepancy between the weights given for values ​​closer to and values ​​<br/>
              further from the curve. The lower the Sensitivity, the smaller the difference
              between the weights given to closer values ​​and more distant values. A
              Sensitivity = 0 represents that the EMA will behave very similarly to the SMA
              (which assigns the same weights for all data within the evaluated window).
            </p>

            <span className="contents-statistics-concepts__title" id="metrics">
              Metrics
            </span>
            <p className="contents-statistics-concepts__text">
              Metrics are used to find critical points, suggestive of zone transitions. In GOLD Software,
              currently up to two types of metrics are considered, namely: Mean Absolute Deviation and
              Relative Difference. In both metrics, mathematical operations are performed involving the
              calculated moving average, and through such operations, deviations can be found in the
              trend found by the moving average. These deviations, in turn, are suggestive of a difference
              in the behavior of the gas curves due to the transition of zones, which can be absolute or
              relative deviations. More information about each metric can be seen below.
            </p>

            <span className="contents-statistics-concepts__subtitle" id="absolute-deviation">
              Absolute Deviation
            </span>
            <p className="contents-statistics-concepts__text">
              The Mean Absolute Deviation can be defined as the absolute difference, point by point in the
              analyzed curve, between itself and the respective value of the moving average in the same
              position. If the values ​​of mean absolute deviation are low, it can be understood that the gas
              curve follows a standard mean trend for the analyzed window. The highest values ​​for this
              metric therefore represent when a gas data on the curve begins to diverge considerably from
              the average pattern found by the moving average, and thus presents itself as a possible
              change in gas zones.
            </p>

            <span className="contents-statistics-concepts__subtitle" id="relative-difference">
              Relative Difference
            </span>
            <p className="contents-statistics-concepts__text">
              The Relative Difference calculates the value of the ratio between two subsequent points
              calculated by the moving average. While the Mean Absolute Deviation considers only
              differences in magnitude between the gas curve and its respective moving average, the
              Relative Difference is able to identify when the Moving Average curve itself is marking
              relative changes in its value. In this way, for example, significantly high relative variations in
              the gas curve, even if they do not represent intense absolute variations, can be seen.
            </p>
        
          </div>
        </Scrollbars>
      </Modal>
    </>
  );
}

export default ModalStatisticsConcepts;