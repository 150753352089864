import React, { Component } from 'react';
import get from 'lodash.get';
import Highcharts from 'highcharts';
import { Button } from 'antd';
import Exporting from 'highcharts/modules/exporting';
import classNames from 'classnames';
import ArrowLine from './ArrowLine';
import { getUnit } from '../utils';

Exporting(Highcharts);

class IcByNc extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {}
  }

  componentDidMount() {
    this.createScatterPlot();

    const { idListener, nameChart } = this.props;

    document.addEventListener(`${idListener}-${nameChart}`, ({ detail }) => {
      const { config } = detail;
      const {yAxis, xAxis} = this.chart;
      if (yAxis && yAxis[0]) {
        yAxis[0].update({
          min: config.y.min,
          max: config.y.max,
          title: {
            text: config.y.title
          }
        });
      }
      if (xAxis && xAxis[0]) {
        xAxis[0].update({
          min: config.x.min,
          max: config.x.max,
          title: {
            text: config.x.title
          }
        });
      }
    });
  }

  createScatterPlot = () => {
    const { id, data, title } = this.props;
    const items = get(this.props, 'data.items', []);
    let verifyValueMax = 0;
    let verifyValueMin = 0;
    if (data.items) {
      verifyValueMax = data && data.items && data.items.length === 0 ? Math.round(data.items[0]) : Math.round(data.items.slice(-1)[0][1]);
      verifyValueMin = data && data.items && data.items.length === 0 ? Math.floor(data.items[0]) : Math.floor(data.items[0][1]);
    }

    const max = data.items ? verifyValueMax : 1000;
    const min = data.items ? verifyValueMin : 0;

    this.chart = Highcharts.chart(id, {
      chart: {
        type: 'spline',
        marginTop: 50,
        events: {
          selection: this.selectingAreas
        }
      },
      title: {
        text: ' '
      },
      subtitle: {
        text: ' '
      },
      credits: {
        enabled: false
      },
      fillOpacity: 1,
      opacity: 1,
      exporting: { enabled: false },
      tooltip: { enabled: false },
      xAxis: {
        title: {
          enabled: true,
          text: title
        },
        opposite: true,
        startOnTick: true,
        endOnTick: true,
        type: '',
        showLastLabel: true,
        minorTickInterval: 1,
        tickInterval: 1,
        max: 5,
        min: 0,
        tickPosition: 'outside',
        gridLineWidth: 0,
        allowDecimals: true
      },
      yAxis: {
        title: {
          text: `MD Depth (${getUnit()})`
        },
        lineWidth: 1,
        lineColor: '#ccd6eb',
        startOnTick: true,
        endOnTick: true,
        min,
        max,
        tickInterval: 1,
        type: '',
        showLastLabel: true,
        reversed: true,
        gridLineWidth: 1
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: 0,
        y: -14,
        floating: true,
        backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF',
        borderWidth: 0
      },
      plotOptions: {
        series: {
          states: {
            hover: {
              enabled: false
            }
          }
        }
      },
      series: [
        {
          name: ' ',
          color: '#000',
          data: items,
          lineWidth: 0.5
        }
      ]
    });
  }

  render() {
    const {
      id, withMinorGridLines, className, deactivateLegend, openConfigureScale
    } = this.props;

    return (
      <div className="scatter-general" style={{ position: 'relative'}}>
        {
          openConfigureScale && (
            <Button
              type="primary"
              shape="circle"
              icon="setting"
              size="large"
              className="toggle-crossplot-config"
              onClick={openConfigureScale}
            />
          )
        }
        <ArrowLine text="Biodegradation" />
        <div
          className={classNames(
            className,
            { 'reset-minor-grids': !withMinorGridLines },
            { 'deactivate-legend': deactivateLegend }
          )}
          id={id}
          style={{
            minWidth: '320px',
            height: '432px',
            maxWidth: '330px',
            flex: 1
          }} />
      </div>
    );
  }
}

IcByNc.defaultProps = {
  withMinorGridLines: false,
  deactivateLegend: true,
  className: 'scatter-plot',
  id: 'ic4ByNc4'
};

export default IcByNc;
