import React from 'react';
import Icon from '../../assets/back.svg';

const BackIcon = ({ className }) => <img src={Icon} className={className} alt="swipe button" />

BackIcon.defaultProps = {
  className: 'swipe-right__button-icon mirror'
};

export default BackIcon;
