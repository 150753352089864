import React, { useState } from 'react';
import ToggleButton from 'react-toggle-button';
import store from 'store';

const GeneratePlotButton = () => {
  const [isActivated, setActive] = useState(store.get('crossPlots') || false);
  const toggleActive = () => {
    const currentValue = !isActivated;
    store.set('crossPlots', currentValue);
    setActive(currentValue);
  };
  return (
    <div className="cross-plot-toggle">
      <span>Generate Cross Plots</span>
      <ToggleButton
        colors={{
          activeThumb: {
            base: 'rgb(255,255,255)'
          },
          inactiveThumb: {
            base: 'rgb(255,255,255)'
          },
          active: {
            base: '#65c970',
            hover: '#65c970'
          },
          inactive: {
            base: 'rgb(65,66,68)',
            hover: 'rgb(95,96,98)'
          }
        }}
        value={isActivated}
        onToggle={toggleActive}
      />
    </div>
  );
};

export default GeneratePlotButton;