import Papa from 'papaparse';
import keys from 'lodash.keys';
import reduce from 'lodash.reduce';
import { getObjectValues } from '.';

export const fluidTypeCsvNames = {
  chart2: 'fluid-type-x-(c1+c2/c3)-y-(c4+c5/c1+c2)',
  chart3: 'fluid-type-x-(balance)-y-(wetness)',
  chart4: 'fluid-type-x-(c1)-y-(c1/c3)',
  chart5: 'fluid-type-x-(c1/c4+c5)-y-(c1/c3)'
};

export const geoChemistryCsvNames = {
  chart1: 'geochemistry-x-(C1/C2)-y-(depth)',
  chart2: 'geochemistry-x-(SFC2-5ppm)-y-(depth)',
  chart10: 'geochemistry-x-(iC4/nC4)-y-(depth)',
  chart9: 'geochemistry-x-(iC5/nC5)-y-(depth)',
  chart7: 'geochemistry-x-(C2/C3)-y-(C1/C2)',
  chart8: 'geochemistry-x-(C2/iC4)-y-(C2/C3)',
};

const fluidColumns = getObjectValues(fluidTypeCsvNames);
const geoChemistryColumns = getObjectValues(geoChemistryCsvNames);

export const crossPlotsKeysToSynchronizeWithDepth = [
  ...fluidColumns,
];

export const fieldsToCsv = [
  'depth',
  'api',
  'gor',
  'totalCarbon',
  'c1',
  'c2',
  'c3',
  'ic4',
  'nc4',
  'ic5',
  'nc5',
  'c1Composition',
  'c2Composition',
  'c3Composition',
  'nc4Composition',
  'nc5Composition',
  'ic4BynC4',
  'ic5BynC5',
  'slopeFactor',
  'c1Byc2',
  'isotope',
  'balanceRatioData',
  'wetnessRatio',
  'characterRatioData',
  fluidTypeCsvNames.chart2,
  fluidTypeCsvNames.chart3,
  fluidTypeCsvNames.chart4,
  fluidTypeCsvNames.chart5,
  geoChemistryCsvNames.chart1,
  geoChemistryCsvNames.chart2,
  geoChemistryCsvNames.chart10,
  geoChemistryCsvNames.chart9,
  geoChemistryCsvNames.chart8,
  geoChemistryCsvNames.chart7
];

export const aliasesToCsv = {
  totalCarbon: 'totalCarbon',
  slopeFactor: 'slopeFactor',
  balanceRatioData: 'balanceRatio',
  wetnessRatio: 'wetnessRatio',
  characterRatioData: 'characterRatio'
};

/* createRowsToCsv
 * @description: Should access the values and to create an object with
 * columns to parse and generate a CSV.
 * @param {Object} well - a well json.
 * @param {Array} fieldsToCsv - represents each chart curve showed on the
 * page. This curve key is stored within an well on redux.
 * @param {Object} aliasesKeys - a map to access and replace the current key
 *  this is a kind of formatter to avoid display
 *  some variables names with camel case for example
 *  the user should read Slope Factor instead of
 *  slopeFactor.
 * @param {Array} keysToRelateWithDepth receive what keys need to be checked
 *                and related this value just if depth is the same. This is
 *                necessary because the crossplots value are not sorted.
 * @return {Array} - This represent each line of the well
 *  such as: [{ c1: 10, c2: 4, depth: 10 }, ...].
 */
export const createRowsToCsv = (
  well, fieldsToMap, aliasesKeys, keysToRelateWithDepth
) => {
  return reduce(well.depth, (acc, depthValue, index) => {
    const currentLine = {};
    fieldsToMap.forEach((key) => {
      if (keys(aliasesKeys).includes(key)) {
        const currentKey = aliasesKeys[key];
        currentLine[currentKey] = well[key][index];
      } else if (
        depthValue && keysToRelateWithDepth.includes(key)
      ) {
        // TODO refactor it
        // this filter and relate each crossplot point with depth
        const crossPlotData = well[key];
        for(let i = 0; i <= crossPlotData.length; i++) {
          const currentCrossPlotValue = crossPlotData[i];
          if (currentCrossPlotValue
            && currentCrossPlotValue.depth
            && currentCrossPlotValue.depth === depthValue
          ) {
            currentLine[key] = currentCrossPlotValue.item;
          }
        }
      } else if (!keysToRelateWithDepth.includes(key)) {
        currentLine[key] = well[key] && well[key][index];
      }
    })
    acc.push(currentLine);
    return acc;
  }, []);
};


export const csvToJSON = (lines, worker) => {
  return new Promise((resolve) => {
    Papa.parse(lines, {
      worker,
      complete: (results) => {
        const csvData = { valid: true, data: null, errors: [] };
        if (results.errors.length) {
          csvData.valid = false;
          csvData.errors = results.errors;
        }
        csvData.data = results.data;
        resolve(csvData);
      }
    });
  });
};

