import axios from 'axios';

const API_URL = 'https://wellex-wells-oofp55ubjq-uc.a.run.app/wellex-wells/v1/wells';

export function getAllZones(wellId: string, token: string) {
  return axios.get(`${API_URL}/zones/?wellId=${wellId}`, {
    headers: {
      'access-token': token,
      'Content-type': 'application/json'
    }
  });
}

export function insertZone(data:any, token:string) {
  return axios.post(
    `${API_URL}/zones`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json'
      }
    }
  );
}

export function updateZoneInfo(wellId:string, data:any, token:string) {
  return axios.put(
    `${API_URL}/${wellId}/zones`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}

export function deleteZoneSet(zoneSetName:string, wellId:string, token:string) {
  return axios.delete(
    `${API_URL}/${wellId}/zones/${zoneSetName}`,
    {
      headers: {
        'access-token': token,
        'Content-type': 'application/json'
      }
    }
  );
}
