import React from 'react';
import {
  Button,
  Empty,
} from 'antd';
import selectImage from '../../assets/select.svg';

type Props = {
  setFormViewMode: () => void;
};

export const EmptyZoneState = ({ setFormViewMode }: Props) => {
  return (
    <Empty
      image={selectImage}
      imageStyle={{ height: '40px', marginTop: '0' }}
      description={
        <span className="zones__message-empty">
          There are no zones!
        </span>
      }
    >
      <Button
        type="primary"
        onClick={() => {
          setFormViewMode();
        }}
        data-cy="create-zone-button"
      >
        Create new zone
      </Button>
    </Empty>
  );
};
