export const uppercaseOptions = {
		"depth": "DEPTH",
		"c1": "C1",
		"c2": "C2",
		"c3": "C3",
		"ic4": "IC4",
		"nc4": "NC4",
		"c4": "C4",
		"ic5": "IC5",
		"nc5": "NC5",
		"c5": "C5",
		"gamma_ray": "GAMMA RAY",
		"rop": "ROP",
		"wob": "WOB",
		"rpm_total": "RPM",
		"torque": "TORQUE",
		"stand_pipe_pressure": "SPP",
		"flow_in": "FLOW",
		"mud_weight_in": "MWIN",
		"mud_weight_out": "MWOUT",
		"temperature_in": "TMPIN",
		"temperature_out": "TMPOUT",
		"total_gas": "TOTAL GAS",
		"c2s": "C2S",
		"c2fid": "C2FID",
		"c2h4": "C2H4",
		"c3h6": "C3H6"
}

export const typeOptions = [
	{
		label: "DEPTH",
		id: "depth"
	},
	{
		label: "C1",
		id: "c1"
	},
	{
		label: "C2",
		id: "c2"
	},
	{
		label: "C3",
		id: "c3"
	},
	
	{
		label: "IC4",
		id: "ic4"
	},
	{
		label: "NC4",
		id: "nc4"
	},
	{
		label: "C4",
		id: "c4"
	},
	{
		label: "IC5",
		id: "ic5"
	},
	{
		label: "NC5",
		id: "nc5"
	},
	{
		label: "C5",
		id: "c5"
	},
	{
		label: "GAMMA RAY",
		id: "gamma_ray"
	},
	{
		label: "ROP",
		id: "rop"
	},
	{
		label: "WOB",
		id: "wob"
	},
	{
		label: "RPM",
		id: "rpm_total"
	},
	{
		label: "TORQUE",
		id: "torque"
	},
	{
		label: "SPP",
		id: "stand_pipe_pressure"
	},
	{
		label: "FLOW",
		id: "flow_in"
	},
	{
		label: "MWIN",
		id: "mud_weight_in"
	},
	{
		label: "MWOUT",
		id: "mud_weight_out"
	},
	{
		label: "TMPIN",
		id: "temperature_in"
	},
	{
		label: "TMPOUT",
		id: "temperature_out"
	},
	{
		label: "TOTAL GAS",
		id: "total_gas"
	},
	{
		label: "C2S",
		id: "c2s"
	},
	{
		label: "C2FID",
		id: "c2fid"
	},
	{
		label: "C2H4",
		id: "c2h4"
	},
	{
		label: "C3H6",
		id: "c3h6"
	},
]