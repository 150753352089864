import React, {
  useEffect,
  useRef,
  useState
} from 'react';

import {
  Slider,
  Typography
} from 'antd';
import { findMinMaxDepth } from 'lib/Well';
import uniq from 'lodash.uniq';
import { connect } from 'react-redux';

import MultiSelect from '../MultiSelect';
import { useStatisticWizard } from './useStatisticsWizard';
import AlertWarning from '../AlertWarning';

const { Paragraph } = Typography;

const FirstStep = ({
  values, errorMessage, errorMultipleSelect,
  onSelectCurves, wells, listOfCurvesCalculated,
  settingsCurve, listOfDefaultCurves
}) => {
  const {
    rangeMin,
    rangeMax,
    setRangeInterval,
    setCurves,
    currentWell
  } = useStatisticWizard();
  const sliderRef = useRef();

  // just to set defaultValue not value
  const [rangeValue, setRangeValue] = useState([0, 0]);
  const [minDepth, setRangeMinDepth] = useState(0);
  const [maxDepth, setRangeMaxDepth] = useState(0);
  const [dirty, setDirty] = useState(false);

  const onChangeSlider = (value) => {
    if (value[0] !== value[1]) {
      setDirty(true);
      setRangeValue(value);
      setRangeInterval(value);
    }
  };

  useEffect(() => {
    if (currentWell) {
      const { start_depth: startDepth, stop_depth: stopDepth } = currentWell;

      setRangeMinDepth(startDepth);
      setRangeMaxDepth(stopDepth);
      setDirty(true);

      if (!rangeValue[0] && !rangeValue[1]) {
        setRangeValue([
          startDepth,
          stopDepth
        ]);
      }

      currentWell.statistics
        && currentWell.statistics.curves
        && setCurves(
          uniq(
            currentWell.statistics.curves.map(c => c.toUpperCase())
          )
        );
      // setRangeInterval([minDepth, maxDepth]);
      // setRangeTop(minDepth);
      // setRangeBottom(maxDepth);
    }
  }, [currentWell]);

  useEffect(() => {
    const [topValue, bottomValue] = rangeValue;
    if (topValue && bottomValue && !dirty) {
      setRangeValue([topValue, bottomValue]);
      sliderRef.current.forceUpdate();
    }
  }, [rangeValue, dirty]);

  useEffect(() => {
    if (wells && wells.currentWell && wells.currentWell.statistics) {
      if (wells.currentWell.statistics.rangeMin && wells.currentWell.statistics.rangeMax) {
        setRangeValue([
          wells.currentWell.statistics.rangeMin,
          wells.currentWell.statistics.rangeMax
        ]);
      }
    }
  }, [wells]);

  return (
    <>
      <div className="statistic-modal-curves-first__content">
        {listOfCurvesCalculated && (
          <div className='container-alert-curves'>
            <div className='alert-curves'>
              <AlertWarning curves={listOfCurvesCalculated} />
            </div>
          </div>
        )}
        <div className="container-conflict-error">
          {errorMultipleSelect ? errorMessage : ''}
        </div>
        <div className="container-multiselect">
          <MultiSelect
            onSelectCurves={onSelectCurves}
            listOfCurvesCalculated={listOfCurvesCalculated}
            listOfDefaultCurves={listOfDefaultCurves}
            title="Selected Gas Curves:"
            values={values}
          />
          <div className="statistic-modal-curve__top-bottom-container">
            <Paragraph className="statistic-modal__top-bottom-label">
              Select top-bottom interval:
            </Paragraph>
            <div className="wrapper-ranger">
              <Slider
                ref={sliderRef}
                tooltipVisible
                range
                tipFormatter={value => value?.toFixed(2)}
                step={1}
                value={rangeValue}
                min={minDepth}
                max={maxDepth}
                onChange={onChangeSlider}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = ({ wells }) => ({
  wells
});

export default connect(
  mapStateToProps
)(FirstStep);
