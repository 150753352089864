/* eslint-disable react/no-unused-state */
import React, { Component, useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import ChartHeader from './ChartHeader';
import store from 'store';
import Exporting from 'highcharts/modules/exporting';
import { applyDefaultZoomToChart } from '../../../lib/charts';
import {
  propagateZoom, syncronizeTooltip,
  getToolTipValues, displayZoomBtn, defaultCrossHair,
  disableDots, toggleGasComposition, toggleTrack as toggleTrackUtils,
  disableLabels, defaultValuesToCrosshair
} from '../../../utils';
import GlobalCutOff from '../../../utils/GlobalCutOff';
import {
  DEFAULT_SCALES,
  GAS_COMP_DEFAULT,
  GAS_COMP_C1,
  GAS_COMP_C2,
  GAS_COMP_C3,
  GAS_COMP_C4,
  GAS_COMP_C5
} from '../../../utils/constants';
import { shouldUpdateScale } from '../../../hooks/useScales';

Exporting(Highcharts);

const getDefaultSeries = (
  scales,
  gasCompositionC1,
  gasCompositionC2,
  gasCompositionC3,
  gasCompositionC4,
  gasCompositionC5
) => {
  const placeholderData = defaultValuesToCrosshair(gasCompositionC1, 100);
  return [
    {
      ...disableDots,
      name: 'placeholder-composition',
      data: placeholderData,
      color: '#fff',
      type: 'spline',
      lineWidth: 0.1,
      index: 0
    },
    {
      ...disableDots,
      name: 'g-composition-c1',
      data: gasCompositionC1,
      color: scales[GAS_COMP_C1].color,
      type: 'spline',
      lineWidth: 1.3,
      index: 1
    },
    {
      ...disableDots,
      name: 'g-composition-c2',
      data: gasCompositionC2,
      color: scales[GAS_COMP_C2].color,
      type: 'spline',
      lineWidth: 1.3,
      index: 2
    },
    {
      ...disableDots,
      name: 'g-composition-c3',
      data: gasCompositionC3,
      color: scales[GAS_COMP_C3].color,
      type: 'spline',
      lineWidth: 1.3,
      index: 3
    },
    {
      ...disableDots,
      name: 'g-composition-c4',
      data: gasCompositionC4,
      color: scales[GAS_COMP_C4].color,
      type: 'spline',
      lineWidth: 1.3,
      index: 4
    },
    {
      ...disableDots,
      name: 'g-composition-c5',
      data: gasCompositionC5,
      color: scales[GAS_COMP_C5].color,
      type: 'spline',
      lineWidth: 1.3,
      index: 5
    }
  ];
};

class GasComposition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      min: 0,
      max: 0,
      gasCompositionC1: [],
      gasCompositionC2: [],
      gasCompositionC3: [],
      gasCompositionC4: [],
      gasCompositionC5: [],
      series: {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true
      },
      rangeValues: [],
      positionsLeft: []
    };
    this.chart = null;
  }

  componentDidMount() {
    const { parentContainer, id } = this.props;
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.vpHeight = document.querySelector(parentContainer).clientHeight || 0;

    this.createChart();
    syncronizeTooltip(`#${id}`);

    if(this.chart) {
      this.zoomId = applyDefaultZoomToChart(this.chart);
    }

    GlobalCutOff.registerInstances(
      'gasComposition',
      this.chart
    );

    // Dispatch the labels to the header
    this.positioningTheLabels();

    // TODO refactor this syncronization
    document.addEventListener('sincronize-selected', event => {
      const { detail: { min, max } } = event;
      this.chart.xAxis[0].setExtremes(min, max, true, true);
      this.chart.redraw();
    });

    document.addEventListener('toggle-gas-composition', ({ detail: { index } }) => {
      toggleTrackUtils(this)(index);
    });

    document.addEventListener(GAS_COMP_DEFAULT, ({ detail }) => {
      if (this.chart.yAxis[0]) {
        this.chart.yAxis[0].update({
          min: detail.min,
          max: detail.max
        });
        this.positioningTheLabels();
      }
    });

    const DEFAULT_MIN = 0;
    document.addEventListener(GAS_COMP_C1, ({ detail }) => {
      if (this.chart.yAxis[0]) {
        this.chart.yAxis[0].update({
          min: detail.min,
          minorTickInterval: detail.min || DEFAULT_MIN,
          max: detail.max
        });

        this.positioningTheLabels();

        const serie = this.chart.series[1];
        serie.update({
          color: detail.color,
          lineColor: detail.color
        });
        serie.redraw();
      }
    });
    document.addEventListener(GAS_COMP_C2, ({ detail }) => {
      if (this.chart.yAxis[0]) {
        this.chart.yAxis[0].update({
          min: detail.min,
          max: detail.max,
          minorTickInterval: detail.min || DEFAULT_MIN
        }, true);

        this.positioningTheLabels();

        const serie = this.chart.series[2];
        serie.update({
          color: detail.color,
          lineColor: detail.color
        });
        serie.redraw();
      }
    });
    document.addEventListener(GAS_COMP_C3, ({ detail }) => {
      if (this.chart.yAxis[0]) {
        this.chart.yAxis[0].update({
          min: detail.min,
          max: detail.max,
          minorTickInterval: detail.min || DEFAULT_MIN
        });

        this.positioningTheLabels();

        const serie = this.chart.series[3];
        serie.update({
          color: detail.color,
          lineColor: detail.color
        })
        serie.redraw();
      }
    });
    document.addEventListener(GAS_COMP_C4, ({ detail }) => {
      if (this.chart.yAxis[0]) {
        this.chart.yAxis[0].update({
          min: detail.min,
          max: detail.max,
          minorTickInterval: detail.min || DEFAULT_MIN
        });

        this.positioningTheLabels();

        const serie = this.chart.series[4];
        serie.update({
          color: detail.color,
          lineColor: detail.color
        })
        serie.redraw();
      }
    });
    document.addEventListener(GAS_COMP_C5, ({ detail }) => {
      if (this.chart.yAxis[0]) {
        this.chart.yAxis[0].update({
          min: detail.min,
          max: detail.max,
          minorTickInterval: detail.min || DEFAULT_MIN
        });

        this.positioningTheLabels();

        const serie = this.chart.series[5];
        serie.update({
          color: detail.color,
          lineColor: detail.color,
          minorTickInterval: detail.min || DEFAULT_MIN
        })
        serie.redraw();
      }
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      gasCompositionC1, gasCompositionC2, gasCompositionC3,
      gasCompositionC4, gasCompositionC5, selectedWell,
      currentWell, min, max
    } = nextProps;
    const { chart, props, state } = this;

    if (
      selectedWell !== props.selectedWell
      || nextProps.selectedWell !== props.selectedWell
      || props.beforeWell?.depth?.length !== currentWell?.depth?.length
      || gasCompositionC1 !== props.gasCompositionC1 || gasCompositionC2 !== props.gasCompositionC2
      || gasCompositionC4 !== props.gasCompositionC4 || gasCompositionC3 !== props.gasCompositionC3
      || gasCompositionC5 !== props.gasCompositionC5
    ) {
      const scales = store.get('configScales');

      if (chart && chart.update) {
        chart.update({
          series: getDefaultSeries(
            scales,
            gasCompositionC1,
            gasCompositionC2,
            gasCompositionC3,
            gasCompositionC4,
            gasCompositionC5
          )
        });
        chart.redraw();
      }

      this.zoomId = setTimeout(() => {
        chart.xAxis[0].setExtremes(min, max);
      }, 300);
      return true;
    }

    // should update the color of series when receive a new config from well scale
    const defaultScales = store.get('configScales');
    [GAS_COMP_C1, GAS_COMP_C2, GAS_COMP_C3, GAS_COMP_C4, GAS_COMP_C5].forEach((curveName) => {
      const checkToUpdateScales = shouldUpdateScale(
        curveName,
        currentWell?.scales,
        defaultScales
      );

      if (currentWell && checkToUpdateScales) {
        chart.update({
          series: getDefaultSeries(
            currentWell?.scales,
            gasCompositionC1,
            gasCompositionC2,
            gasCompositionC3,
            gasCompositionC4,
            gasCompositionC5
          )
        });
        return true;
      }
    });

    if (nextState.rangeValues.length !== state.rangeValues) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-unused-expressions
    this.zoomId && clearTimeout(this.zoomId);
  }

  positioningTheLabels = () => {
    const {childNodes} = this.chart.axes[1].labelGroup.element;
    // access elements child inside the tspan > title
    // example: <tspan><title>10</title><tspan>
    const labels = [...childNodes].map(element => {
      if (element.childNodes.length === 2) {
        return element.childNodes[1].innerHTML;
      }
      return element.innerHTML;

    });
    // access directly and copy the position and apply
    // example: <title>10</title>
    const positionsLeft = [...childNodes].map(element => {
      let el = element;
      if (el.childNodes.length === 2) {
        // eslint-disable-next-line prefer-destructuring
        el = el.childNodes[1];
      }
      return `${element.getAttribute('x')}px`;
    });
    this.setState({ rangeValues: labels, positionsLeft });
  }

  createChart = () => {
    const scales = store.get('configScales') || DEFAULT_SCALES;
    const {
      id,
      gasCompositionC1,
      gasCompositionC2,
      gasCompositionC3,
      gasCompositionC4,
      gasCompositionC5
    } = this.props;

    this.chart = Highcharts.chart(id, {
      chart: {
        inverted: true,
        width: 200,
        zoomType: 'x',
        margin: 0,
        padding: 0,
        offset: 18,
        events: {
          selection: (event) => {
            displayZoomBtn();
            propagateZoom(event);
          }
        },
        animation: false,
        shadow: false
      },
      boost: {
        useGPUTranslations: true,
        usePreAllocated: true
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      subtitle: {
        text: ''
      },
      xAxis: [{
        labels: {
          enabled: false
        },
        offset: 5,
        opposite: true,
        tickLength: 0,
        lineColor: '#fff',
        ...disableLabels,
        minorGridLineWidth: 1,
        minorGridLineColor: '#E0E0E0',
        gridLineColor: '#a4a4a4',
        gridLineWidth: 1
      }],
      yAxis: [
        {
          boostThreshold: 1,
          labels: {
            enabled: true,
            style: {
              color: '#333'
            },
            y: 40000,
            zIndex: 10
          },
          title: {
            text: ''
          },
          min: scales[GAS_COMP_C1].min,
          max: scales[GAS_COMP_C1].max,
          minorGridLineColor: '#E0E0E0',
          minorTickInterval: 20,
          minorGridLineWidth: 1,
          tickInterval: 20,
          type: 'linear',
          zIndex: 200,
          index: 0
        }
      ],
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
          enableMouseTracking: false,
          showInLegend: false
        }
      },
      exporting: { enabled: false },
      tooltip: {
        shared: true,
        ...defaultCrossHair,
        formatter: getToolTipValues('gas-composition')
      },
      series: getDefaultSeries(
        store.get('configScales'),
        gasCompositionC1,
        gasCompositionC2,
        gasCompositionC3,
        gasCompositionC4,
        gasCompositionC5
      )
    });
  }

  render() {
    const { id } = this.props;

    const { rangeValues, positionsLeft } = this.state;
    return (
      <div
        className="chart__box-container"
        style={{
          marginLeft: '20px',
          marginRight: '10px'
        }}
      >
        <ChartHeader
          rangeValues={rangeValues}
          positionsLeft={positionsLeft}
        />
        <div
          id={id}
          style={{
            marginLeft: '3px',
            height: 'calc(100% - 132px)'
          }}
          data-cy="chart-gas-composition"
        />
      </div>
    );
  }
}

GasComposition.defaultProps = {
  id: 'gas-composition',
  parentContainer: '.charts-container',
  gasCompositionC1: [],
  gasCompositionC2: [],
  gasCompositionC3: [],
  gasCompositionC4: [],
  gasCompositionC5: []
};

export default React.memo(GasComposition);
