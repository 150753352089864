import styled from 'styled-components';

export const ContainterTextField = styled.div`
  width: 72px;
  display: flex;
  font-family: Arial;
  align-items: center;
`;

export const ClipboardContainer = styled.div`
  display: flex;
`;

export const ClipboardInput = styled.input`
  color: #fff;
  font-size: 14px;
  font-family: Arial;
  height: 19px;
  border: 2px solid blue;
  padding: 5px;
  border: none;
  outline: none;
  background: transparent;
`;

export const ClipboardBtn = styled.button`
  width: 21px;
  height: 36px;
  cursor: pointer;
  background: transparent;
  border: rgba(255, 255, 255, 0.7);
  border-radius: 7px;
`;