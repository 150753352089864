import React, {
  useEffect,
  useState
} from 'react';
import { Button } from 'antd';
import { useFormik } from 'formik';
import { Input } from 'antd';
import { ColorPicker } from '../ColorPicker/ColorPicker.tsx';
import { formatterAutocompleteData } from '../../utils/formatterAutocompleteData.ts';
import { validateDepthValue, ZoneScheme } from '../../utils/validateForm.ts';
import { defaultZone } from '../../utils/defaultZoneValues.ts';
import FormLabel from '../FormLabel/index.ts';
import InputAutoComplete from '../InputAutoComplete/InputAutoComplete';

const Form = ({
  submitText, submitZone, afterSubmit,
  toggleCancel, zoneBottom, loadingZone,
  zoneCount, currentZoneToEdit, currentWell, indexBottomZoneCreated
}) => {
  const [fieldError, setFieldError] = useState([]);
  const dataSource = formatterAutocompleteData(currentWell);
  const formik = useFormik({
    initialValues: currentZoneToEdit || { ...defaultZone, label: `Zone ${zoneCount}`, top: zoneBottom !== 0 ? dataSource[indexBottomZoneCreated] : '' },
    onSubmit: (values) => {
      const {
        top, top_depth_index, bottom, bottom_depth_index,
        label, zone_type, zone_plot_settings, color, uuid
      } = values;
      const valuesFormatted = {
        uuid,
        top: top.toString(),
        top_depth_index,
        bottom: bottom.toString(),
        bottom_depth_index,
        label: label ?? values.label,
        zone_type,
        zone_plot_settings: {
          ...zone_plot_settings,
          color: color ?? zone_plot_settings.color
        }
      }
    
      const validateTopValue = dataSource.find(depth => valuesFormatted.top === depth);
      const validateBottomValue = dataSource.find(depth => valuesFormatted.bottom === depth);

      const validateDepth = validateDepthValue(validateTopValue, validateBottomValue);
      
      if (validateDepth.length !== 0) {
        setFieldError(validateDepth)
        return;
      }
      afterSubmit();
      submitZone(valuesFormatted, !!currentZoneToEdit);
      formik.resetForm({});
    },
    validationSchema: ZoneScheme,
    enableReinitialize: true
  });

  const { values, setFieldValue, handleSubmit, errors, handleChange, handleBlur, initialValues } = formik;
  // handleChange, handleBlur,

  useEffect(() => {
    if (!values.bottom && zoneBottom) {
      setFieldValue('bottom', zoneBottom);
    }
  }, [values, zoneBottom, setFieldValue]);

  useEffect(() => {
    if (currentZoneToEdit) {
      const { top_depth_index, bottom_depth_index } = currentZoneToEdit;
      const topWithCriticalValues = dataSource[top_depth_index];
      const bottomWithCriticalValues = dataSource[bottom_depth_index];
      setFieldValue('label', currentZoneToEdit.label);
      setFieldValue('top', topWithCriticalValues);
      setFieldValue('bottom', bottomWithCriticalValues);
    }
  }, [currentZoneToEdit, setFieldValue]);

  return (
    <form
      onSubmit={handleSubmit}
      className="zones__form"
    >
      <FormLabel
        label="Zone Name"
        hasError={errors?.label}
        required
        data-cy="label-zone-name"
      >
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          name="label"
          value={values.label || `Zone ${zoneCount}`}
          defaultValue={currentZoneToEdit ? currentZoneToEdit.label : ''}
          className="input--zone"
          data-cy="input-zone-name"
        />
      </FormLabel>
      <br />
      <FormLabel
        label="Top"
        hasError={fieldError.includes('top')}
        required
        data-cy="label-top"
      >
        <InputAutoComplete
          formatterAutocompleteData={formatterAutocompleteData}
          onChange={value => setFieldValue('top', value)}
          type="text"
          name="top"
          placeholder="top"
          value={values.top}
          dataSource={dataSource}
          defaultValue={currentZoneToEdit ? dataSource[currentZoneToEdit.top_depth_index] : initialValues.top}
          className="input--zone"
          dataCy="input-zone-top"
        />
      </FormLabel>
      <br />
      <FormLabel
        label="Bottom"
        hasError={fieldError.includes('bottom')}
        required
        data-cy="label-bottom"
      >
        <InputAutoComplete
          onChange={value => setFieldValue('bottom', value)}
          type="text"
          name="bottom"
          placeholder="bottom"
          value={values.bottom}
          defaultValue={currentZoneToEdit ? dataSource[currentZoneToEdit.bottom_depth_index] : ''}
          dataSource={dataSource}
          className="input--zone"
          dataCy="input-zone-bottom"
        />
      </FormLabel>
      <ColorPicker
        currentZoneToEdit={currentZoneToEdit}
        afterChange={setFieldValue}
      />
      <div className="zones-form__footer">
        <Button
          type="default"
          className="btn-cancel"
          onClick={toggleCancel}
        >
         Cancel
        </Button>
        {loadingZone
          ? <Button
            type="primary"
            htmlType="submit"
            data-cy="submit-zone"
            loading
            >
              {submitText}
            </Button>
          : <Button
              type="primary"
              htmlType="submit"
              data-cy="submit-zone"
            >
              {submitText}
            </Button>
         }

      </div>
    </form>
  );
};

// Move this automcomplete into components/InputAutomcomplete
// const { Option } = AutoComplete;

export default Form;
