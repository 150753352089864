import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './style.scss';

const Loading = ({ size = 24 }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: `${size}px`, color: '#177DDC' }} spin />;
  return (
    <div
      className="wrapper-loading"
    >
      <Spin
        wrapperClassName="loading"
        indicator={antIcon}
      />
    </div>
  );
}

export default Loading;