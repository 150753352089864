import React from 'react';

import {
  Button,
  Drawer,
  Form as AntForm,
  Input
} from 'antd';
import { Formik } from 'formik';

function DrawerComponent({
  toggleDrawer,
  visible,
  initialValues,
  changeScatterConfig,
  closeDrawer
}) {
  return (
    <Drawer
      title="Edit Chart"
      placement="right"
      closable={false}
      onClose={toggleDrawer}
      visible={visible}
      width="320"
      destroyOnClose
    >
      <Formik
        initialValues={initialValues}
        onSubmit={changeScatterConfig}
        enableReinitialize
        render={({
          handleChange,
          handleBlur,
          handleFocus,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            {/*
            <AntForm.Item label="Is Visible" style={{ display: 'flex' }}>
              <Field
                name="isVisible"
                id="isVisible"
                component={CustomSwitchComponent}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />
            </AntForm.Item>
            */}
            <AntForm.Item
              label="yMin"
              style={{ display: 'flex' }}
            >
              <Input
                placeholder={initialValues.yMin}
                onChange={handleChange}
                onBlur={handleBlur}
                id="yMin"
                name="yMin"
              />
            </AntForm.Item>
            <AntForm.Item label="yMax" style={{ display: 'flex' }}>
              <Input
                placeholder={initialValues.yMax}
                onChange={handleChange}
                onBlur={handleBlur}
                id="yMax"
                name="yMax"
              />
            </AntForm.Item>
            <AntForm.Item label="xMin" style={{ display: 'flex' }}>
              <Input
                placeholder={initialValues.xMin}
                onChange={handleChange}
                onBlur={handleBlur}
                id="xMin"
                name="xMin"
              />
            </AntForm.Item>
            <AntForm.Item label="xMax" style={{ display: 'flex' }}>
              <Input
                placeholder={initialValues.xMax}
                onChange={handleChange}
                onBlur={handleBlur}
                id="xMax"
                name="xMax"
              />
            </AntForm.Item>
            <AntForm.Item
              label="Vertical Title"
              style={{ display: 'flex' }}
            >
              <Input
                placeholder={initialValues.yTitle}
                onChange={handleChange}
                onBlur={handleBlur}
                id="yTitle"
                name="yTitle"
              />
            </AntForm.Item>
            <AntForm.Item label="Horizontal Title" style={{ display: 'flex' }}>
              <Input
                placeholder={initialValues.xTitle}
                onChange={handleChange}
                onBlur={handleBlur}
                id="xTitle"
                name="xTitle"
              />
            </AntForm.Item>
            {/*
            <AntForm.Item label="y Type of axis" style={{ display: 'flex' }}>
              <Select
                showSearch
                name="yAxisType"
                id="yAxisType"
                style={{ width: 140 }}
                defaultValue={initialValues.yAxisType}
                placeholder={initialValues.yAxisType}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <Option value="linear">Linear</Option>
                <Option value="logarithmic">Logarithmic</Option>
              </Select>
            </AntForm.Item>
            <AntForm.Item label="x Type of axis" style={{ display: 'flex' }}>
              <Select
                showSearch
                name="xAxisType"
                id="xAxisType"
                defaultValue={initialValues.xAxisType}
                style={{ width: 140 }}
                placeholder={initialValues.xAxisType}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                <Option value="linear">Linear</Option>
                <Option value="logarithmic">Logarithmic</Option>
              </Select>
            </AntForm.Item>
            */}
            <AntForm.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={closeDrawer} style={{ marginRight: '0.5rem', }}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
              >
                Apply
              </Button>
            </AntForm.Item>
          </form>
        )}
      />
    </Drawer>
  );
}

export default DrawerComponent;